var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"formbox "},[_c('el-form',{staticClass:"formbox"},[_c('el-form-item',{attrs:{"label":"创建时间"}},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},model:{value:(_vm.SelectData),callback:function ($$v) {_vm.SelectData=$$v},expression:"SelectData"}}),_c('el-button',{staticStyle:{"background-color":"#36be98","border-color":"#36be98","margin-left":"10px"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.search()}}},[_vm._v("查询 ")]),_c('el-button',{staticStyle:{"background-color":"#eaecf2","border-color":"#eaecf2","color":"#343434","margin-left":"10px"},on:{"click":function($event){return _vm.resetForm()}}},[_vm._v("重置 ")])],1)],1)],1),_c('div',{staticClass:"tableBox"},[_c('vxe-table',{ref:"table",attrs:{"border":"","highlight-hover-row":"","header-align":"center","resizable":"","start-index":(_vm.tablePage.pageNo - 1) * _vm.tablePage.pageSize,"show-header":true,"data":_vm.logTableDate}},[_c('vxe-table-column',{attrs:{"type":"seq","align":"center","title":"序号","width":"60"}}),_c('vxe-table-column',{attrs:{"align":"center","field":"immunityTime","title":"配置文件名称"}}),_c('vxe-table-column',{attrs:{"align":"center","field":"vaccineName","title":"上报时间"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.vaccineName === null ? "--" : row.vaccineName))])]}}])}),_c('vxe-table-column',{attrs:{"align":"center","field":"createTime","title":"下发时间"}}),_c('vxe-table-column',{attrs:{"align":"center","title":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{staticStyle:{"color":"#009DFF"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.ShowDetail(row)}}},[_vm._v(_vm._s(_vm.$t("common.table_btn.detail")))])]}}])})],1),_c('vxe-pager',{ref:"vxePager",staticStyle:{"background-color":"#fff","position":"relative","bottom":"30px","border-radius":"15px"},attrs:{"background":"","current-page":_vm.tablePage.pageNo,"page-size":_vm.tablePage.pageSize,"total":_vm.tablePage.totalCount,"layouts":[
                'PrevPage',
                'JumpNumber',
                'NextPage',
                'Sizes',
                'Total' ]},on:{"page-change":_vm.handlePageChange}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }