











































































































































































































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import FormBtnSearch from "@/components/FormBtnSearch/FormBtnSearch.vue";
import FormBtnReset from "@/components/FormBtnReset/FormBtnReset.vue";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import InputConponentsVue from "@/components/BasicConponents/InputConponentsVue.vue";

import { Commodity } from "@/common/Views/BeefOrdering/CommodityClassification/CommodityClassification";
import { productList } from "@/common/Views/BeefOrdering/productList/productList";

@Component({
    components: {
        PageBox,
        FormBtnSearch,
        FormBtnReset,
        SelectComponentsVue,
        InputConponentsVue,
    },
})
export default class BeefProductList extends Vue {
    private form: any = {
        categoryOneId: "",
        categoryTwoId: "",
        status: "",
        keyword: "",
        brandId: "",
    };
    private tableData = [];
    private tablePage = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    // 状态 0未上架(商品刚发布) 1已上架 2已下架 3待审核 4审核失败
    private statuList = [
        { id: "", name: "全部状态" },
        { id: "0", name: "未上架" },
        { id: "1", name: "已上架" },
        { id: "2", name: "已下架" },
        { id: "3", name: "待审核" },
        { id: "4", name: "审核不通过" },
    ];
    private typeList = [];
    private breedList = [];
    private upLight = false;
    private downLight = false;
    //获取品牌列表
    private BrandList = [];
    private getBrandList() {
        new productList().getBrandList((res: any) => {
            this.BrandList = res.data;
            this.BrandList.forEach((item: any) => {
                item.name = item.brandName;
            });
        });
    }
    //事件
    private checkboxChange() {
        console.log(2277777777777);
        const checkedRows = this.$refs.table.getCheckboxRecords();
        console.log(checkedRows, 229);
        if (checkedRows.length > 0) {
            this.upLight = checkedRows.every(
                (row: any) => row.status == 0 || row.status == 2
            );
            this.downLight = checkedRows.every((row: any) => row.status == 1);
        } else {
            this.upLight = false;
            this.downLight = false;
        }

        console.log(this.upLight, 235);
        console.log(this.downLight, 236);
    }
    private selectAllCheckboxChange() {
        const checkedRows = this.$refs.table.getCheckboxRecords();
        if (checkedRows.length > 0) {
            this.upLight = checkedRows.every(
                (row: any) => row.status == 0 || row.status == 2
            );
            this.downLight = checkedRows.every((row: any) => row.status == 1);
        } else {
            this.upLight = false;
            this.downLight = false;
        }
    }
    private goDetail(row: any) {
        //列表点击查看
        this.$router.push({
            path: "/ViewProduct",
            query: { id: row.id },
        });
    }
    private editDetail(row: any) {
        //列表点击修改
        this.$router.push({
            path: "/AddProduct",
            query: { id: row.id },
        });
    }
    private AddProduct(row: any) {
        //列表点击新增
        this.$router.push({
            path: "/AddProduct",
        });
    }
    //emit
    private SetStatusVal(val: string) {
        this.form.status = val;
    }
    private SetbrandIdVal(val: string) {
        this.form.brandId = val;
    }
    private SetTypeVal(val: string) {
        this.form.typeId = val;
    }
    private SetInputVal(val: string) {
        this.form.keyword = val;
    }

    private handlePageChange(val: any) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getGoodsList();
    }

    private getGoodsList() {
        console.log(111111111111);

        new productList().getGoodsList(
            this.form,
            this.tablePage,
            (res: any) => {
                this.tablePage.totalCount = res.page.totalCount;
                this.tableData = res.data;
                console.log(this.tableData);
                console.log(res.data, 268);
            }
        );
    }
    private handlerSearch() {
        this.tablePage.pageNo = 1;
        this.getGoodsList();
    }
    private handlerReset() {
        this.form = {
            categoryOneId: "",
            categoryTwoId: "",
            status: "",
            keyword: "",
            breedId: "",
        };
        this.treeSelect = [];
        this.tablePage.pageNo = 1;
        this.upLight = false;
        this.downLight = false;
        this.getGoodsList();
    }
    //获取所有商品分类列表
    private TreeOption = [];
    private treeSelect = [];
    private getListTree() {
        new Commodity().getListTree((res: any) => {
            this.TreeOption = res.data;
            console.log(this.TreeOption, 282);
        });
    }
    private handleChange(e: any) {
        if (e[0]) {
            this.form.categoryOneId = e[0];
        }
        if (e[1]) {
            this.form.categoryTwoId = e[1];
        }
    }
    activated() {
        this.form = {
            categoryOneId: "",
            categoryTwoId: "",
            status: "",
            keyword: "",
            brandId: "",
        };
        this.treeSelect = [];
        this.tablePage.pageNo = 1;
        this.getListTree();
        this.getGoodsList();
        this.getBrandList();
    }
    private batchProcessingForm = {
        idList: [],
        status: 0,
    };
    private batchProcessing(status: any) {
        if (status == 1 && this.upLight == false) {
            return;
        }
        if (status == 2 && this.downLight == false) {
            return;
        }
        const table = this.$refs.table;
        const selectedRecords = table.getCheckboxRecords();
        // 获取选中的记录
        if (selectedRecords.length > 0) {
            selectedRecords.forEach((item: any) => {
                this.batchProcessingForm.idList.push(item.id);
            });
        }

        console.log(this.batchProcessingForm, "this.batchProcessingForm");
        this.batchProcessingForm.status = status;
        new productList().updateGoodsStatus(
            this.batchProcessingForm,
            (res: any) => {
                if (status == 1) {
                    this.$message({
                        type: "success",
                        message: "批量上架成功",
                    });
                } else {
                    this.$message({
                        type: "success",
                        message: "批量下架成功",
                    });
                }

                this.upLight = false;
                this.downLight = false;
                this.getGoodsList();
            }
        );
    }
}
