


















































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {HandleTable, HandlePageChange} from '@/common/Views/comm/handleTable';
import {Column, ComboList, Result} from "@/common/Views/system/column/column";
import {AxiosData} from '@/common/http/http';
import { Util } from '@/common/Views/comm/util';
import {ConfType} from '@/common/Views/system/confType/confType';
import {
    PregnancyRecord,
    PregnancyRecordForm
} from "@/common/Views/traceabilityOutManage/pregnancyRecord";
import { BasicsClass } from "../../../common/BasicsClass";

@Component
export default class HelloWorld extends Vue {
    private pregnancyRecord: PregnancyRecord = new PregnancyRecord()

    public column = new Column()

    private dialogFormVisible:boolean = false

    private form: any = {
        // groupCode: [],
        id: '',
        typeConfId: '',
        slaughterRegistrant: '',
        labelNumber: '',
        startTime: '',
        endTime: '',
        time: [],
        netWeightStart: '',
        netWeightEnd: '',
        buyer: '',
        phone: '',
    }

    private formatDate ({ cellValue }) {
        // return XEUtils.toDateString(cellValue, format || 'yyyy-MM-dd HH:mm:ss')
        return cellValue != 'null'?cellValue:null
    }

    private mounted(): void {
        this.getTable();
        this.getTree();
        this.getconfType();
        this.changeGroupCode();
        // this.getType()
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    private treeData: Result[] = [];

    private getTree() {
        Util.getTree((result: Result[])=>{
            this.treeData = result
        })
    }

    private comboList: ComboList[] = [];

    private changeGroupCode(): void {
        this.comboList = [];
        // this.form.farmId = '';
        this.form.typeConfId = '';
        // new Column().getFarm(this.form.groupCode[this.form.groupCode.length - 1], (data: AxiosData<ComboList[]>)=>{
        //     this.comboList = data.data;
        // })
        new BasicsClass().BasicGet('/animal/web/slaughterInfomation/slaughter', {}, false, true, (res: any) => {
            this.comboList = res.data;
        })
        // this.confType();
    }

    // @Watch("form.groupCode")
    // getgroupCode(newVal:Array<object>,oldVal:Array<object>) {
    //     if (newVal !== oldVal) {
    //         this.form.farmId = '';
    //         // this.form.typeConfId = ''; 
    //     }
    // }

    private conTypeList: [] = [];

    private getconfType(): void {

        this.conTypeList = [];
        this.form.checkId = '';
        // new ConfType().getSelectAll(this.form.farmId, (data: any) => {
        //     this.conTypeList = data.data;
        //     console.log(data)
        // });
        // this.pregnancyRecord.getResultList((res: any) => {
        //     this.conTypeList = res.data
        //     this.getTable();
        // })
        new BasicsClass().BasicGet('/animal/web/slaughterInfomation/typeConf', {}, false, true, (res: any) => {
            this.conTypeList = res.data
        })
    }

    private clearForm(): void {
        this.form = {
            id: '',
            typeConfId: '',
            slaughterRegistrant: '',
            labelNumber: '',
            startTime: '',
            endTime: '',
            time: [],
            netWeightStart: '',
            netWeightEnd: '',
            buyer: '',
            phone: '',
        }
        this.getTable()
    }

    // private typeList: [] = [];

    // private getType(): void {
    //     this.typeList = [];
        
    //     new ConfType().getSelectAll(this.form.farmId, (data: any) => {
    //         this.typeList = data.data;
    //         console.log(data)
    //     });
        
    // }

    private seach(): void {
        this.dialogFormVisible = false
        this.tablePage.pageNo = 1
        this.getTable()
    }

    private tableData: any = [];

    private getTable(): void {
        // this.pregnancyRecord.getList(this.form, this.tablePage, (res: any) => {
        //     this.tableData = res.data
        //     this.tablePage.totalCount = res.page.totalCount
        // })
        if(this.form.time){
            this.form.startTime = Util.dateTimeUtil(this.form.time[0])
            this.form.endTime = Util.dateTimeUtil(this.form.time[1])
        }else{
            this.form.startTime = ''
            this.form.endTime = ''
        }

        new BasicsClass().BasicGet('/animal/web/slaughterInfomation/infomation', {
            ...this.form,
            ...this.tablePage
        }, false, true, (res: any) => {
            this.tableData = res.data
            this.tablePage.totalCount = res.page.totalCount
        })
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        // this.form.time = [];
        this.tablePage.pageNo = 1
        this.getTable();
        this.getTree();
        this.getconfType();
        this.changeGroupCode();
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;

        this.getTable();
    }

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.tableData = []
        this.getTable();
    }

    private Export(): void {
        // this.pregnancyRecord.exportList(this.form, (res) => {
        //     console.log(res)
        // })
        new BasicsClass().getFile('/animal/web/slaughterInfomation/exportList', this.form)
    }
}
