












































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
    PersonalDetail,
    PersonalDetailC,
} from "@/common/Views/system/personal/personalDetail";
import { merchant } from "@/common/Views/BeefOrdering/SupplierManagement/SupplierManagement";
import { optionSelect, Rule } from "@/common/Views/comm/formRule";
import { TableResult } from "@/common/Views/system/role/role";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { Util } from "@/common/Views/comm/util";
import { AxiosData } from "@/common/http/http";
import { SchoolList } from "@/common/Views/basics/schoolManage/schoolManage";
import { Result, Column, ComboList } from "@/common/Views/system/column/column";
import { stringify } from "qs";
import {
    LoansConfig,
    LoansConfigForm,
} from "@/common/Views/basics/loansConfig/loansConfig";
import { getBankList315 } from "@/api/index";
import CascaderComponentsVue from "@/components/BasicConponents/CascaderComponentsVue.vue";
import { GuaranteeCompanyAPI } from "@/api/BankModule/index";
import { WebUser } from "@/api/SystemModule";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";

@Component({
    components: { SelectComponentsVue },
})
export default class HelloWorld extends Vue {
    public column = new Column();

    private personDetail: PersonalDetailC;
    private centerDialogVisible: boolean = false;
    private defaultProps: any = {
        children: "childList",
        label: "bankName",
    };
    private CheckBankList: any[] = [];

    constructor() {
        super();
        this.personDetail = new PersonalDetailC();
    }

    private formLabelAlign: any = {
        username: "",
        password: "",
        // identifier: '',
        name: "",
        mobile: "",
        email: "",
        groupCode: [],
        farmIds: [""],
        insuranceCompanyId: "",
        roleList: [],
        status: 1,
        type: 0,
        bankIds: [],
        bankName: "",
        guaranteeCompanyId: "",
        appUserId: "",
        merchantId: "",
    };
    private guaranteeCompanyIdList = [];
    private userSelectType = true;
    private farmSelectType = true;
    //获取担保公司名称列表
    private getGuaranteeCompanyList() {
        new GuaranteeCompanyAPI().getGuaranteeCompany((res: any) => {
            this.guaranteeCompanyIdList = res.data;
        });
    }
    private linkAPPUserList: any[] = [];

    get statusOptions() {
        return [
            {
                value: 1,
                label: this.$t("system.web_user.status_0") as string,
            },
            {
                value: 2,
                label: this.$t("system.web_user.status_1") as string,
            },
            {
                value: 3,
                label: this.$t("system.web_user.status_2") as string,
            },
            {
                value: 4,
                label: this.$t("system.web_user.status_3") as string,
            },
        ];
    }

    private changeFarm(): void {
        if (this.formLabelAlign.farmIds.length > 1) {
            if (this.formLabelAlign.farmIds.indexOf("") != -1) {
                (<string[]>this.formLabelAlign.farmIds).splice(
                    this.formLabelAlign.farmIds.indexOf(""),
                    1
                );
            }
        }
        if (this.formLabelAlign.farmIds.length > 0) {
            this.formLabelAlign.appUserId = "";
            this.userSelectType = false;
        } else {
            this.formLabelAlign.appUserId = "";
            this.userSelectType = true;
        }
    }

    private bankList = [];

    private changeBankValue = {};

    get rules() {
        return {
            username: [
                {
                    required: true,
                    message: this.$t("system.web_user.place_1") as string,
                    trigger: "blur",
                },
                {
                    message: this.$t("system.web_user.place_2") as string,
                    validator: (rule: any, value: any, callback: any) => {
                        if (!/^[0-9A-Za-z]{1,30}$/gi.test(value)) {
                            callback(new Error());
                        } else {
                            callback();
                        }
                    },
                },
            ],
            password: [
                {
                    required: true,
                    message: this.$t("system.web_user.place_3") as string,
                    trigger: "blur",
                },
                {
                    min: 6,
                    max: 16,
                    message: this.$t("system.web_user.place_4") as string,
                    trigger: "blur",
                },
            ],
            groupCode: [
                // @ts-ignore
                {
                    type: "array",
                    required: true,
                    message: this.$t("system.web_user.place_5") as string,
                    trigger: "blur",
                },
            ],
            name: [
                {
                    required: true,
                    message: this.$t("system.web_user.place_6") as string,
                    trigger: "blur",
                },
            ],
            mobile: [
                {
                    required: true,
                    message: this.$t("system.web_user.place_7") as string,
                    trigger: "blur",
                },
                {
                    message: this.$t("system.web_user.place_8") as string,
                    validator: (rule: any, value: any, callback: any) => {
                        if (!/^[0-9]{11}$/g.test(value)) {
                            callback(new Error());
                        } else {
                            callback();
                        }
                    },
                },
            ],
            email: [
                {
                    required: true,
                    message: this.$t("system.web_user.place_9") as string,
                    trigger: "blur",
                },
                {
                    message: this.$t("system.web_user.place_10") as string,
                    validator: (rule: any, value: any, callback: any) => {
                        if (
                            !/^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g.test(
                                value
                            )
                        ) {
                            callback(new Error());
                        } else {
                            callback();
                        }
                    },
                },
            ],
        };
    }

    // private dataRoleList: ResultSchoolGradeClass<string>[] = [];

    // private schoolNameOptions: SchoolGradeClass<string>[] = [];

    private roleOptions: TableResult<string>[] = [];

    private getAllRole(): void {
        if (this.$getResourceCodes("role_all")) {
            new PersonalDetailC()
                .getRoleList()
                .then((data) => {
                    this.roleOptions = data;
                })
                .catch((err) => {
                    this.$message.error(err.message);
                });
        }
    }
    private MerchantListAll = [];
    private getMerchantListAll() {
        new merchant().getMerchantListAll((res: any) => {
            this.MerchantListAll = res.data;
            //增加请选择
            this.MerchantListAll.unshift({
                merchantName: this.$t("common.placeholder.select"),
                id: "",
            });
        });
    }
    activated() {
        this.getAllRole();
        // this.getAllSchoolList();

        // this.getLevelList();

        // this.isAdmin = false;
        this.getLinkList();
        this.changeGroupCode();
        // this.getTree();

        this.getGuaranteeCompanyList();
        this.getMerchantListAll();
        // this.getAllSchoolList();

        if (
            this.$route.name == "personalDetail" &&
            this.$route.query.personId == ""
        ) {
            this.newAlter = false;
            getBankList315("", (res: any) => {
                //新增银行接口 ,
                this.bankList = res.data;
                res.data.forEach((item: any) => {
                    //判断 最外层有没有选中的 如果没有  就用后台给的  如果有 自己筛选一遍
                    item.ifBind
                        ? this.getCheckArrData(item)
                        : (this.CheckBankList = this.testArr);
                });
            });
        } else {
            this.newAlter = true;
        }
    }

    private treeData: Result[] = [];

    private getTree(cb?: Function): void {
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        // column.getTreeData("/animal/web/group/treeList").then((result: Result[]) => {
        // column.getTreeData("/animal/web/provinceCityDistrict/getForGroupCode").then((result: Result[]) => {

        //     this.treeData = new DeepLoop().deepCopy(result);

        //     if(cb) cb();
        // }).catch(err => {
        //     this.$message.error(err.message);
        // });

        this.column.getTissuc(undefined, (res: any) => {
            this.treeData = res.data;
        });
    }

    private comboList: ComboList[] = [];
    private company: any[] = [];
    private SetData: any = {};
    private changeGroupCode(cb?: Function): void {
        this.comboList = [];
        this.formLabelAlign.farmIds = [];
        new Column().getFarmTwo(
            this.formLabelAlign.groupCode[
                this.formLabelAlign.groupCode.length - 1
            ],
            (data: AxiosData<ComboList[]>) => {
                this.comboList = data.data;
                if (cb) cb();
            },
            "100"
        );

        this.company = [];
        this.formLabelAlign.typeId = "";
        new Column().getCompany1((data: AxiosData<ComboList[]>) => {
            this.company = data.data;
            if (cb) cb();
        });
    }

    private changeCompany(): void {}

    private levelListData: SchoolList<string>[] = [];

    // private getLevelList(): void {
    //     new PersonalDetailC().getLevelList((data: AxiosData<SchoolList<string>[]>) => {
    //         this.levelListData = data.data;
    //     });
    // }

    private save(): void {
        // if(this.formLabelAlign.farmIds == 'all'){
        //     this.formLabelAlign.farmIds = []
        //     for (let i in this.comboList) {
        //         this.formLabelAlign.farmIds.push(this.comboList[i].id)
        //     }
        // };
        // console.log(this.formLabelAlign)
        console.log(this.formLabelAlign, "this.formLabelAlign");
        if (this.formLabelAlign.username == "") {
            this.$message({
                message: this.$t("system.web_user.place_1") as string,
                type: "warning",
            });
            return;
        } else if (this.formLabelAlign.password == "") {
            this.$message({
                message: this.$t("system.web_user.place_3") as string,
                type: "warning",
            });
            return;
        } else if (this.formLabelAlign.name == "") {
            this.$message({
                message: this.$t("system.web_user.place_6") as string,
                type: "warning",
            });
        } else if (this.formLabelAlign.mobile == "") {
            this.$message({
                message: this.$t("system.web_user.place_7") as string,
                type: "warning",
            });
        } else if (this.formLabelAlign.email == "") {
            this.$message({
                message: this.$t("system.web_user.place_9") as string,
                type: "warning",
            });
        } else if (this.formLabelAlign.groupCode == "") {
            this.$message({
                message: this.$t("system.web_user.place_5") as string,
                type: "warning",
            });
        }

        (this.$refs.ruleForm as any).validate((val: boolean) => {
            if (val) {
                const readySendData: PersonalDetail<string> = new DeepLoop().deepCopy(
                    this.formLabelAlign
                );

                if (this.formLabelAlign.password === "$%#^@@&*(#@#^") {
                    // this.formLabelAlign.password = this.oldPassword;
                    readySendData.password = this.oldPassword;
                }
                // console.log(readySendData)

                this.personDetail.submit(
                    readySendData,
                    (data: string) => {
                        this.formLabelAlign = new Util().clearObject(
                            this.formLabelAlign
                        );
                        this.$router.go(-1);
                    },
                    (data: string) => {
                        //    do not thing
                    },
                    new Util().getId(this.$route.query.personId.toString())
                );
            }
        });
    }

    //3.1.5
    private NowBankId: string = "";
    private CheckArrData: any[] = [];
    private FatherArrBankId: any[] = [];
    private getCheckArrData(item: any) {
        this.NowBankId = item.bankId; //将当前值存为临时变量 用于定位元素删除
        this.getResData(item); //拿到最外层被选中的数据进行循环 看数据是否是应该全部勾选
    }
    private getResData(item: any) {
        item.childList &&
            item.childList.forEach((res: any) => {
                //数组为真 那么就进行循环
                if (res.ifBind) {
                    //当前值为真  调用自己
                    this.getResData(res); //如果为真那么继续往下查
                } else {
                    let indexNum: number = this.testArr.indexOf(this.NowBankId);
                    this.NowBankId = "";
                    this.testArr.splice(indexNum, 1);
                    this.CheckBankList = this.testArr;
                }
            });
    }

    private testArr: any[] = [];
    private oldPassword: string = "";
    private getDetailInfo(): void {
        if (this.$route.query.personId) {
            this.personDetail
                .getPersonalInfo(this.$route.query.personId)
                .then((data: any) => {
                    //3.1.5
                    console.log(data, "data");
                    this.formLabelAlign.guaranteeCompanyId =
                        data.guaranteeCompanyId;
                    this.testArr = data.bankIds;
                    this.SetData = data;
                    getBankList315(data.id, (res: any) => {
                        //新增银行接口
                        this.bankList = res.data;
                        res.data.forEach((item: any) => {
                            //判断 最外层有没有选中的 如果没有  就用后台给的  如果有 自己筛选一遍
                            item.ifBind
                                ? this.getCheckArrData(item)
                                : (this.CheckBankList = this.testArr);
                        });
                    });
                    if (data.name) {
                        const form: any = this.formLabelAlign;
                        let resultData: any = data;
                        for (const i in resultData) {
                            if (resultData[i]) form[i] = resultData[i];
                        }

                        const arr: any = [];
                        DeepLoop.getDataRoleForDeepLoop(
                            this.treeData,
                            resultData.groupCode,
                            arr
                        );

                        this.treeData = resultData.provinceList;
                        this.formLabelAlign.groupCode = [];
                        // if(resultData.provinceList[0]){

                        // }

                        if (resultData.provinceList[0]) {
                            this.formLabelAlign.groupCode.push(
                                resultData.provinceList[0].id
                            );
                        }

                        this.oldPassword = data.password;
                        if (data.roleIds)
                            this.formLabelAlign.roleList = data.roleIds!.split(
                                ","
                            );
                        this.formLabelAlign.password = "$%#^@@&*(#@#^";

                        this.changeGroupCode(() => {
                            if (data.farmIds) {
                                this.formLabelAlign.farmIds = (<string>(
                                    data.farmIds
                                )).split(",");
                            } else {
                                this.formLabelAlign.farmIds = [];
                            }
                            if (data.typeId) {
                                this.formLabelAlign.typeId = data.typeId.toString();
                            } else {
                                this.formLabelAlign.typeId = "";
                            }
                        });
                        // this.handlerLinkUser(data.appUserId);
                        this.$set(
                            this.formLabelAlign,
                            "appUserId",
                            this.SetData.appUserId.toString()
                        );

                        // this.formLabelAlign.appUserId = "";
                        // this.formLabelAlign.bankIds = data.bankIds
                    }
                });
        }
    }

    public resGroup: Result[] = [];

    public handleCascader(start: any): void {
        if (start == true) {
            console.log(this.resGroup.length);
            if (this.resGroup.length == 0) {
                const column: Column = new Column();
                this.resGroup = this.treeData;
                column.getTissuc(undefined, (res: any) => {
                    // console.log(res, 11111)
                    this.treeData = res.data;
                    this.formLabelAlign.groupCode = [];
                });
            }
            if (this.treeData[0].id == this.resGroup[0].id) {
                const column: Column = new Column();
                this.resGroup = this.treeData;
                column.getTissuc(undefined, (res: any) => {
                    // console.log(res, 11111)
                    this.treeData = res.data;
                    this.formLabelAlign.groupCode = [];
                });
            }
        } else {
            console.log(this.formLabelAlign.groupCode);
            if (this.formLabelAlign.groupCode.length == 0) {
                console.log(1321321);
                this.treeData = this.resGroup;
                this.formLabelAlign.groupCode.push(this.resGroup[0].id);
            }
        }
    }

    public newAlter: boolean = true;

    @Watch("$route")
    private RouterChange() {
        if (
            this.$route.name == "personalDetail" &&
            this.$route.query.personId == ""
        ) {
            this.newAlter = false;
        } else {
            this.newAlter = true;
        }

        if (this.$route.query.personId) {
            this.getDetailInfo();
            this.getGuaranteeCompanyList();
        } else {
            getBankList315("", (res: any) => {
                //新增银行接口
                this.bankList = res.data;
                this.CheckBankList = [];
            });
            this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
            this.changeBankValue = {};
            this.formLabelAlign.status = 1;
        }
    }

    // private SaveCenterDialog(){}
    private getKeyList(data: any, checkData: any) {
        //315选择后将选择的id数组赋值给对象
        // console.log(checkData.checkedKeys)
        this.formLabelAlign.bankIds = checkData.checkedKeys;
    }

    private getLinkList() {
        new WebUser().getLinkAppUser((res: any) => {
            //实现对res.data里的每个对象进行遍历，将其appUserAccount赋值给name属性
            res.data.forEach((item: any) => {
                item.name = item.appUserAccount;
            });
            this.linkAPPUserList = res.data;
            console.log(this.linkAPPUserList, "linkAPPUserList");

            this.linkAPPUserList.unshift({
                name: this.$t("common.placeholder.select"),
                id: "",
            });
            console.log(this.linkAPPUserList, "linkAPPUserList859");
            this.getDetailInfo();
        });
    }

    @Watch("$store.state.lanuage", { immediate: true })
    private changeLanuage() {
        this.linkAPPUserList[0] = this.$t("common.placeholder.select");
    }
    private handlerLinkUser(appUserId: string) {
        console.log(appUserId, "appUserId869");
        if (appUserId) {
            this.formLabelAlign.appUserId = appUserId;
            this.farmSelectType = false;
            this.formLabelAlign.farmIds = [""];
        } else {
            this.farmSelectType = true;
            this.formLabelAlign.farmIds = [];
        }
    }
}
