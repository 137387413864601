



















































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class Drg extends Vue {
    constructor() {
        super();
    }
    // 当前激活的tab
    private activeConfigTab: string = "first";
    // 左侧组件库
    private componentList: any[] = [
        {
            id: 1,
            type: "stall",
            icon: "",
            text: "摊位组件",
        },
        {
            id: 2,
            type: "text",
            icon: "",
            text: "文本组件",
        },
        {
            id: 3,
            type: "picture",
            icon: "",
            text: "图片组件",
        },
    ];
    // 拖拽的组件类型
    private type: string = "";
    // 页面组件列表
    private assemblyList: any = [];
    // 当前操作的组件
    private currentAssembly: any = {
        id: "",
        name: "",
        type: "", //组件类型
        config: {
            datas: false, //是否需要数据支撑
            datastype: "default", //获取数据类型 default和api两种
            autodata: "关闭",
            autodatatime: "10",
            apitypeselect: "POST", //默认显示post
            api: "",
            widgets: {},
            defaultdata: "",
        },
        width: "",
        height: "",
        left: "",
        top: "",
        zindex: "",
    };
    // 空间区处理
    private zooms: any = {
        show: false, //是否展示遮罩拖拽层
        resize: false, //是否缩放，缩放模式，防止点击事件和mousedown事件冲突
        ctrenter: false, //ctr按键是否按下,多选模式
        type: "", //拖拽缩放时候的类型，总共8个，left,top,right,bottom...
        style: {
            width: "",
            height: "",
            top: "",
            left: "",
        }, //拖拽组件的样式保存
    };

    private modules: any = {
        radio: 1,
        moduleid: "",
        setting: {
            height: "3000",
            width: "100%",
        },
        imgstyleright: {
            right: "0px",
        },
        // 缩放的比例
        imgstyle: {
            "background-size": "100% 100%",
            "background-repeat": "no-repeat",
            "background-image": "",
            top: "36px",
            left: "36px",
            right: "0px",
        },
        modulestyle: {
            "background-repeat": "no-repeat",
            "background-image": "",
            "background-color": "",
        },
    };

    // 切换配置项回调
    private handleConfigTabs(tab: any, event: any) {
        this.activeConfigTab = tab.name;
    }

    // 左侧组件开始拖拽
    private handleDraggable(item: any) {
        this.type = item.type;
        console.log("this.type", this.type);
    }
    // 编辑去拖拽放下
    private handleDragenterDrop(e: any) {
        let widgets: any = {};
        switch (this.type) {
            case "stall":
                this.makeAssemblyData(e, this.type, "摊位组件", widgets);
                break;
            case "text":
                widgets.text = "空白文本";
                widgets.style = {
                    fontSize: "14px",
                    color: "red",
                };
                this.makeAssemblyData(e, this.type, "文本组件", widgets);
                break;
            case "picture":
                this.makeAssemblyData(e, this.type, "图片组件", widgets);
                break;
            default:
                break;
        }
    }
    // 处理组件数据
    private makeAssemblyData(e: any, type: string, text: string, widgets: any) {
        // 统计当前组件的数量
        let zIndex = this.computeAssemblyNum(type);
        // 滚动条滚动的高度
        let sh = Math.round((this.$refs.cmpeditor as any).scrollTop);
        // 拖拽左侧的距离
        let dLeft =
            (this.$refs.mainlfet as any).clientWidth +
            (this.$refs.cmpainting as any).clientWidth / 2 -
            (this.$refs.cmpeditor as any).clientWidth / 2;
        // 拖拽右侧的距离
        let tagHeight = this.$store.state.tagsHeight;
        let asideWidth = this.$store.state.asideWidth;
        let dTop = (this.$refs.header as any).clientHeight;
        var data = {
            id: this.handleCreateId(),
            type: type,
            selects: false,
            name: text + zIndex,
            config: {
                datas: true,
                datasType: "default",
                autoData: "close",
                autoDatatime: "10",
                apiType: "POST",
                api: "",
                widgets: widgets,
            },
            style: {
                width: "60px",
                height: "26px",
                left:
                    (e.clientX - dLeft - asideWidth) / this.modules.radio +
                    "px",
                top:
                    (e.clientY + sh - dTop - tagHeight - 60) /
                        this.modules.radio +
                    "px",
                "z-index": zIndex + 1,
                border: "1px dotted rgba(35,195,18,0)",
                position: "absolute",
                display: "",
                "background-color": "",
            },
        };
        this.assemblyList.push(data);
        console.log("this.assemblyList", this.assemblyList);
        this.type = "";
    }

    // 计算组件同一个类型组件的数量
    private computeAssemblyNum(type: string) {
        let num = 0;
        for (let i = 0; i < this.assemblyList.length; i++) {
            num += 1;
        }
        return num;
    }

    private handleDivMouseDown(e: any, id: string) {
        let num = 0;
        for (let i = 0; i < this.assemblyList.length; i++) {
            if (this.assemblyList[i].id == id) {
                this.assemblyList[i].selects = true; //设置当前组件为选中
                this.assemblyList[i].style.border =
                    "1px dotted rgba(35,195,18,1)";
                num = i;
            } else {
                this.assemblyList[i].selects = false;
                this.assemblyList[i].style.border =
                    "1px dotted rgba(35,195,18,0)";
            }
        }
        console.log("this.assemblyList", this.assemblyList);
        //算出鼠标相对元素的位置
        let disX = parseFloat(
            this.assemblyList[num].style.left.replace("px", "")
        );
        let disY = parseFloat(
            this.assemblyList[num].style.top.replace("px", "")
        );
        let currentClientX = e.clientX;
        let currentClientY = e.clientY;
        this.currentAssembly.id = id;
        this.currentAssembly.zindex = this.assemblyList[num].style["z-index"];
        this.currentAssembly.name = this.assemblyList[num].name;
        this.currentAssembly.type = this.assemblyList[num].type;
        this.currentAssembly.config = this.assemblyList[num].config;
        this.zoomsResizeShow();
        document.onmousemove = (e) => {
            //鼠标按下并移动的事件
            //用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
            let left = (e.clientX - currentClientX) / this.modules.radio; //左右移动的距离
            let top = (e.clientY - currentClientY) / this.modules.radio; //上下移动的距离
            this.assemblyList[num].style.left = left + disX + "px";
            this.assemblyList[num].style.top = top + disY + "px";
            //移动元素
            this.zoomsResizeShow();
            this.currentModuleMoveResize();
        };
        document.onmouseup = (e) => {
            if (e.button == 2) {
            } else {
            }
            // 释放内存
            document.onmousemove = null;
            document.onmouseup = null;
        };
    }
    // 选中组件和移动组件时候更新拖拽组件的位置以及宽度和高度
    private zoomsResizeShow() {
        this.zooms.show = true;
        let moveLeft = 100000000; //拖拽遮盖组件距离左侧的浮动距离默认0;
        let moveWidth = 0; //拖拽遮盖组件的宽度，宽度等于选中元素组件里右边距离左侧边界最远的一个
        let moveTop = 100000000; //拖拽遮盖组件距离顶部的浮动距离默认0;
        let moveHeight = 0; //拖拽遮盖组件高度，等于选中元素组件里右边距离顶部边界最远的一个
        // 根据选中组件的宽度高度以及左侧和顶部的距离计算新的遮罩脱妆位置
        for (let i = 0; i < this.assemblyList.length; i++) {
            console.log(this.assemblyList[i].selects);
            if (this.assemblyList[i].selects) {
                // 重新计算遮罩层的宽度和高度
                let itemLeft = parseFloat(
                    this.assemblyList[i].style.left.replace("px", "")
                );
                let itemTop = parseFloat(
                    this.assemblyList[i].style.top.replace("px", "")
                );
                let itemWidth = parseFloat(
                    this.assemblyList[i].style.width.replace("px", "")
                );
                let itemHeight = parseFloat(
                    this.assemblyList[i].style.height.replace("px", "")
                );
                if (itemLeft < moveLeft) {
                    moveLeft = itemLeft;
                }
                if (itemTop < moveTop) {
                    moveTop = itemTop;
                }
                if (Math.abs(itemLeft) + itemWidth > moveWidth) {
                    moveWidth = itemLeft + itemWidth;
                }
                if (Math.abs(itemTop) + itemHeight > moveHeight) {
                    moveHeight = itemTop + itemHeight;
                }
                // 计算点空间的宽高和位置
                this.zooms.style.width =
                    (moveWidth - moveLeft + 2).toFixed(2) + "px";
                this.zooms.style.height =
                    (moveHeight - moveTop + 2).toFixed(2) + "px";
                this.zooms.style.top = moveTop + "px";
                this.zooms.style.left = moveLeft + "px";
            }
        }
    }

    // 点击背景触发操作方法
    private zoomsResizeHidden(e: any) {
        e.preventDefault();
        this.zooms.resize = false; //取消缩放模式
        this.zooms.show = false;
        // 设置所有组减为不选中
        for (let i = 0; i < this.assemblyList.length; i++) {
            this.assemblyList[i].selects = false;
            this.assemblyList[i].style.border = "1px dotted rgba(35,195,18,0)";
        }
    }

    // 缩放控制点处理
    private resizeMouseDown(e: any, type: string) {
        let currentClientX = e.clientX;
        let currentClientY = e.clientY;
        this.zooms.type = type;
        // 鼠标移动
        document.onmousemove = (e) => {
            let left = (e.clientX - currentClientX) / this.modules.radio; //左右移动的距离
            let top = (e.clientY - currentClientY) / this.modules.radio; //上下移动的距离
            currentClientX = e.clientX;
            currentClientY = e.clientY; //重新保存鼠标状态防止再次触发移动事件
            // 重新计算宽度高度以及左侧和顶部距离
            if (this.zooms.type == "rightbottom") {
                this.resizeRightBottom(left, top);
            } else if (this.zooms.type == "right") {
                this.resizeRight(left, top);
            } else if (this.zooms.type == "bottom") {
                this.resizeBottom(left, top);
            } else if (this.zooms.type == "leftbottom") {
                this.resizeLeftBottom(left, top);
            } else if (this.zooms.type == "left") {
                this.resizeLeft(left, top);
            } else if (this.zooms.type == "lefttop") {
                this.resizeLeftTop(left, top);
            } else if (this.zooms.type == "top") {
                this.resizeTop(left, top);
            } else if (this.zooms.type == "righttop") {
                this.resizeRightTop(left, top);
            }
        };
        // 鼠标松开
        document.onmouseup = (e) => {
            this.zooms.resize = true; //设置模式为缩放模式
            this.currentModuleMoveResize();
            // 释放内存
            document.onmousemove = null;
            document.onmouseup = null;
        };
    }
    // 右下控制点
    private resizeRightBottom(left: number, top: number) {
        this.zooms.show = true;
        // X轴,Y轴缩小的比例
        let widthRadio =
            left / parseFloat(this.zooms.style.width.replace("px", ""));
        let heightRadio =
            top / parseFloat(this.zooms.style.height.replace("px", ""));
        // 先变更遮罩层的宽高
        this.zooms.style.width =
            parseFloat(this.zooms.style.width.replace("px", "")) + left + "px";
        this.zooms.style.height =
            parseFloat(this.zooms.style.height.replace("px", "")) + top + "px";
        //拖动和缩放已经选中的组件
        this.reSizeHeightAndWidthModules(widthRadio, heightRadio);
    }
    // 右侧控制点(只计算宽度)
    private resizeRight(left: number, top: number) {
        this.zooms.show = true;
        // X轴,Y轴缩小的比例
        let widthRadio =
            left / parseFloat(this.zooms.style.width.replace("px", ""));
        // 先变更遮罩层的宽高
        this.zooms.style.width =
            parseFloat(this.zooms.style.width.replace("px", "")) + left + "px";
        //拖动和缩放已经选中的组件
        this.reSizeWidthModules(widthRadio);
    }
    // 右上控制点
    private resizeRightTop(left: number, top: number) {
        this.zooms.show = true;
        // X轴,Y轴缩小的比例
        let widthRadio =
            left / parseFloat(this.zooms.style.width.replace("px", ""));
        let heightRadio =
            top / parseFloat(this.zooms.style.height.replace("px", ""));
        let oldZoomLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        let oldZoomTop = parseFloat(this.zooms.style.top.replace("px", ""));
        let newZoomTop = oldZoomTop + top;
        // 先变更遮罩层的宽高
        this.zooms.style.width =
            parseFloat(this.zooms.style.width.replace("px", "")) + left + "px";
        this.zooms.style.height =
            parseFloat(this.zooms.style.height.replace("px", "")) - top + "px";
        this.zooms.style.top = newZoomTop + "px";
        //拖动和缩放已经选中的组件
        this.reSizeRightTopModules(
            widthRadio,
            heightRadio,
            oldZoomLeft,
            oldZoomTop,
            newZoomTop
        );
    }
    // 下方控制点
    private resizeBottom(left: number, top: number) {
        this.zooms.show = true;
        // X轴,Y轴缩小的比例
        let heightRadio =
            top / parseFloat(this.zooms.style.height.replace("px", ""));
        // 先变更遮罩层的宽高
        this.zooms.style.height =
            parseFloat(this.zooms.style.height.replace("px", "")) + top + "px";
        //拖动和缩放已经选中的组件
        this.reSizeHeightModules(heightRadio);
    }
    // 左下方控制点
    private resizeLeftBottom(left: number, top: number) {
        this.zooms.show = true;
        // X轴,Y轴缩小的比例
        let widthRadio =
            left / parseFloat(this.zooms.style.width.replace("px", ""));
        let heightRadio =
            top / parseFloat(this.zooms.style.height.replace("px", ""));
        let oldZoomLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        let newZoomLeft = oldZoomLeft + left;
        // 先变更遮罩层的宽高
        this.zooms.style.width =
            parseFloat(this.zooms.style.width.replace("px", "")) - left + "px";
        this.zooms.style.left = newZoomLeft + "px";
        this.zooms.style.height =
            parseFloat(this.zooms.style.height.replace("px", "")) + top + "px";
        //拖动和缩放已经选中的组件
        this.reSizeLeftBottomModules(
            widthRadio,
            heightRadio,
            oldZoomLeft,
            newZoomLeft
        );
    }
    // 左方控制点
    private resizeLeft(left: number, top: number) {
        this.zooms.show = true;
        // X轴,Y轴缩小的比例
        let widthRadio =
            left / parseFloat(this.zooms.style.width.replace("px", ""));
        let oldZoomLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        let newZoomLeft = oldZoomLeft + left;
        // 先变更遮罩层的宽高
        this.zooms.style.width =
            parseFloat(this.zooms.style.width.replace("px", "")) - left + "px";
        this.zooms.style.left = newZoomLeft + "px";
        //拖动和缩放已经选中的组件
        this.reSizeLeftModules(widthRadio, oldZoomLeft, newZoomLeft);
    }
    // 左上方控制点
    private resizeLeftTop(left: number, top: number) {
        this.zooms.show = true;
        // X轴,Y轴缩小的比例
        let widthRadio =
            left / parseFloat(this.zooms.style.width.replace("px", ""));
        let heightRadio =
            top / parseFloat(this.zooms.style.height.replace("px", ""));
        let oldZoomLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        let oldZoomTop = parseFloat(this.zooms.style.top.replace("px", ""));
        let newZoomLeft = oldZoomLeft + left;
        let newZoomTop = oldZoomTop + top;
        // 先变更遮罩层的宽高
        this.zooms.style.width =
            parseFloat(this.zooms.style.width.replace("px", "")) - left + "px";
        this.zooms.style.height =
            parseFloat(this.zooms.style.height.replace("px", "")) - top + "px";
        this.zooms.style.left = newZoomLeft + "px";
        this.zooms.style.top = newZoomTop + "px";
        //拖动和缩放已经选中的组件
        this.reSizeLeftTopModules(
            widthRadio,
            heightRadio,
            oldZoomLeft,
            newZoomLeft,
            oldZoomTop,
            newZoomTop
        );
    }
    // 上方控制点
    private resizeTop(left: number, top: number) {
        this.zooms.show = true;
        // X轴,Y轴缩小的比例
        let heightRadio =
            top / parseFloat(this.zooms.style.height.replace("px", ""));
        let oldZoomTop = parseFloat(this.zooms.style.top.replace("px", ""));
        let newZoomTop = oldZoomTop + top;
        // 先变更遮罩层的宽高
        this.zooms.style.height =
            parseFloat(this.zooms.style.height.replace("px", "")) - top + "px";
        this.zooms.style.top = newZoomTop + "px";
        //拖动和缩放已经选中的组件
        this.reSizeTopModules(heightRadio, oldZoomTop, newZoomTop);
    }

    // 右下角缩放时，重新计算宽度和高度以及左侧和顶上的距离
    private reSizeHeightAndWidthModules(
        widthRadio: number,
        heightRadio: number
    ) {
        let styleLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        let styleTop = parseFloat(this.zooms.style.top.replace("px", ""));
        for (let j = 0; j < this.assemblyList.length; j++) {
            if (this.assemblyList[j].selects) {
                let disX = parseFloat(
                    this.assemblyList[j].style.width.replace("px", "")
                );
                let disY = parseFloat(
                    this.assemblyList[j].style.height.replace("px", "")
                );
                let disLeft = parseFloat(
                    this.assemblyList[j].style.left.replace("px", "")
                );
                let disTop = parseFloat(
                    this.assemblyList[j].style.top.replace("px", "")
                );
                this.assemblyList[j].style.width =
                    disX + disX * widthRadio + "px";
                this.assemblyList[j].style.height =
                    disY + disY * heightRadio + "px";
                this.assemblyList[j].style.left =
                    disLeft + (disLeft - styleLeft) * widthRadio + "px";
                this.assemblyList[j].style.top =
                    disTop + (disTop - styleTop) * heightRadio + "px";
            }
        }
    }
    // 右下角缩放时，重新计算宽度和高度以及左侧和顶上的距离
    private reSizeWidthModules(widthRadio: number) {
        let styleLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        for (let j = 0; j < this.assemblyList.length; j++) {
            if (this.assemblyList[j].selects) {
                let disX = parseFloat(
                    this.assemblyList[j].style.width.replace("px", "")
                );
                let disLeft = parseFloat(
                    this.assemblyList[j].style.left.replace("px", "")
                );
                this.assemblyList[j].style.width =
                    disX + disX * widthRadio + "px";
                this.assemblyList[j].style.left =
                    disLeft + (disLeft - styleLeft) * widthRadio + "px";
            }
        }
    }
    // 点控件向右上拉动
    private reSizeRightTopModules(
        widthRadio: number,
        heightRadio: number,
        oldZoomLeft: number,
        oldZoomTop: number,
        newZoomTop: number
    ) {
        let styleLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        let styleTop = parseFloat(this.zooms.style.top.replace("px", ""));
        for (let j = 0; j < this.assemblyList.length; j++) {
            if (this.assemblyList[j].selects) {
                let disX = parseFloat(
                    this.assemblyList[j].style.width.replace("px", "")
                );
                let disY = parseFloat(
                    this.assemblyList[j].style.height.replace("px", "")
                );
                let disLeft = parseFloat(
                    this.assemblyList[j].style.left.replace("px", "")
                );
                let disTop = parseFloat(
                    this.assemblyList[j].style.top.replace("px", "")
                );
                this.assemblyList[j].style.width =
                    disX + disX * widthRadio + "px";
                this.assemblyList[j].style.height =
                    disY - disY * heightRadio + "px";
                this.assemblyList[j].style.left =
                    oldZoomLeft +
                    (disLeft - oldZoomLeft) * (1 + widthRadio) +
                    "px";
                this.assemblyList[j].style.top =
                    newZoomTop +
                    (disTop - oldZoomTop) * (1 - heightRadio) +
                    "px";
            }
        }
    }
    // 向下缩放时，重新计算宽度和高度以及左侧和顶上的距离
    private reSizeHeightModules(heightRadio: number) {
        let styleTop = parseFloat(this.zooms.style.top.replace("px", ""));
        for (let j = 0; j < this.assemblyList.length; j++) {
            if (this.assemblyList[j].selects) {
                let disY = parseFloat(
                    this.assemblyList[j].style.height.replace("px", "")
                );
                let disTop = parseFloat(
                    this.assemblyList[j].style.top.replace("px", "")
                );
                this.assemblyList[j].style.height =
                    disY + disY * heightRadio + "px";
                this.assemblyList[j].style.top =
                    disTop + (disTop - styleTop) * heightRadio + "px";
            }
        }
    }
    // 点控件向左下拉动
    private reSizeLeftBottomModules(
        widthRadio: number,
        heightRadio: number,
        oldZoomLeft: number,
        newZoomLeft: number
    ) {
        let styleLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        let styleTop = parseFloat(this.zooms.style.top.replace("px", ""));
        for (let j = 0; j < this.assemblyList.length; j++) {
            if (this.assemblyList[j].selects) {
                let disX = parseFloat(
                    this.assemblyList[j].style.width.replace("px", "")
                );
                let disY = parseFloat(
                    this.assemblyList[j].style.height.replace("px", "")
                );
                let disLeft = parseFloat(
                    this.assemblyList[j].style.left.replace("px", "")
                );
                let disTop = parseFloat(
                    this.assemblyList[j].style.top.replace("px", "")
                );
                this.assemblyList[j].style.width =
                    disX - disX * widthRadio + "px";
                this.assemblyList[j].style.height =
                    disY + disY * heightRadio + "px";
                this.assemblyList[j].style.left =
                    newZoomLeft +
                    (disLeft - oldZoomLeft) * (1 - widthRadio) +
                    "px";
                this.assemblyList[j].style.top =
                    disTop + (disTop - styleTop) * heightRadio + "px";
            }
        }
    }
    // 点控件向左拉动
    reSizeLeftModules(
        widthRadio: number,
        oldZoomLeft: number,
        newZoomLeft: number
    ) {
        let styleLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        for (let j = 0; j < this.assemblyList.length; j++) {
            if (this.assemblyList[j].selects) {
                let disX = parseFloat(
                    this.assemblyList[j].style.width.replace("px", "")
                );
                let disLeft = parseFloat(
                    this.assemblyList[j].style.left.replace("px", "")
                );
                this.assemblyList[j].style.width =
                    disX - disX * widthRadio + "px";
                this.assemblyList[j].style.left =
                    newZoomLeft +
                    (disLeft - oldZoomLeft) * (1 - widthRadio) +
                    "px";
            }
        }
    }
    // 点控件向左上拉动
    reSizeLeftTopModules(
        widthRadio: number,
        heightRadio: number,
        oldZoomLeft: number,
        newZoomLeft: number,
        oldZoomTop: number,
        newZoomTop: number
    ) {
        let styleLeft = parseFloat(this.zooms.style.left.replace("px", ""));
        let styleTop = parseFloat(this.zooms.style.top.replace("px", ""));
        for (let j = 0; j < this.assemblyList.length; j++) {
            if (this.assemblyList[j].selects) {
                let disX = parseFloat(
                    this.assemblyList[j].style.width.replace("px", "")
                );
                let disY = parseFloat(
                    this.assemblyList[j].style.height.replace("px", "")
                );
                let disLeft = parseFloat(
                    this.assemblyList[j].style.left.replace("px", "")
                );
                let disTop = parseFloat(
                    this.assemblyList[j].style.top.replace("px", "")
                );
                this.assemblyList[j].style.width =
                    disX - disX * widthRadio + "px";
                this.assemblyList[j].style.height =
                    disY - disY * heightRadio + "px";
                this.assemblyList[j].style.left =
                    newZoomLeft +
                    (disLeft - oldZoomLeft) * (1 - widthRadio) +
                    "px";
                this.assemblyList[j].style.top =
                    newZoomTop +
                    (disTop - oldZoomTop) * (1 - heightRadio) +
                    "px";
            }
        }
    }
    // 当前组件移动，放大缩小时候重新计算选中组件的位置
    private currentModuleMoveResize() {
        for (let i = 0; i < this.assemblyList.length; i++) {
            if (this.assemblyList[i].id == this.currentAssembly.id) {
                let left = this.assemblyList[i].style.left.replace("px", "");
                let top = this.assemblyList[i].style.top.replace("px", "");
                let width = this.assemblyList[i].style.width.replace("px", "");
                let height = this.assemblyList[i].style.height.replace(
                    "px",
                    ""
                );
                this.reCurrentSizeShow(left, top, width, height);
            }
        }
    }
    // 点控件向上拉动
    private reSizeTopModules(
        heightRadio: number,
        oldZoomTop: number,
        newZoomTop: number
    ) {
        let styleTop = parseFloat(this.zooms.style.top.replace("px", ""));
        for (let j = 0; j < this.assemblyList.length; j++) {
            if (this.assemblyList[j].selects) {
                let disY = parseFloat(
                    this.assemblyList[j].style.height.replace("px", "")
                );
                let disTop = parseFloat(
                    this.assemblyList[j].style.top.replace("px", "")
                );
                this.assemblyList[j].style.height =
                    disY - disY * heightRadio + "px";
                this.assemblyList[j].style.top =
                    newZoomTop +
                    (disTop - oldZoomTop) * (1 - heightRadio) +
                    "px";
            }
        }
    }
    // 重新计算当前选中组件的宽高
    private reCurrentSizeShow(
        left: number,
        top: number,
        width: number,
        height: number
    ) {
        this.currentAssembly.left = Math.round(left);
        this.currentAssembly.top = Math.round(top);
        this.currentAssembly.width = Math.round(width);
        this.currentAssembly.height = Math.round(height);
    }
    // 生成id
    private handleCreateId() {
        return `${new Date().getTime()}-${Math.floor(
            Math.random() * 100000
        )}-${Math.floor(Math.random() * 100000)}`;
    }
    // 帮助
    private handleHelper() {
        this.$message.info("你点击了帮助");
    }
}
