
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FarmTitleData extends Vue {
    @Prop() Title: any;

    private time: any[] = [];

    private changeTitleDate() {
        this.$emit("changeTitleDate", this.time);
    }
}
