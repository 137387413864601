


































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Column, Result} from "@/common/Views/system/column/column";
import {floatJson, DeepLoop} from '@/common/deepLoop/deepLoop';
import { Util } from '@/common/Views/comm/util';
import {Axios} from '@/common/http/http';
import { Rule } from '@/common/Views/comm/formRule';

interface form<T> {
    name: T;
    order: T;
    fatherColumSelect?: string[];
}


interface resource {
    id: string;
    name: string;
    url: string;
    describe: string;
    // status: string;
    _XID?: string;
}

@Component
export default class HelloWorld extends Vue {
    private defaultParenId = '0';
    private showMain: boolean = false;
    private activeName: string = 'user';

    private form: form<string> = {
        name: '',
        order: '',
        fatherColumSelect:[this.defaultParenId],
    };

    private munted() {
        
    }

    private getData(data: Result): void {
        this.showMain = true;
        console.log(data.id)
        // this.form.name = data.name;
        // this.form.url = data.url;
        // this.form.order = data.order;
        this.form = {
            ...data
        }

        this.id = data.id;

        this.form.fatherColumSelect = this.getParentId(true);

    }

    private flatJson: floatJson = {};

    private getParentId(isPop: boolean = false): string[] {

        let fatherColumSelect = [this.defaultParenId];

        if(this.id !== ''){
            const column: Column = new Column();
            fatherColumSelect = column.getParentId(this.flatJson, this.id, this.defaultParenId);

            if(isPop) fatherColumSelect.pop();
        }

        if(fatherColumSelect.length === 0) fatherColumSelect = [this.defaultParenId];

        return  fatherColumSelect;
    }

    private id: string = '';

    private resetForm(): void {
        // 重置
        this.form = new Util().clearObject(this.form);
        this.form.fatherColumSelect = [this.defaultParenId];
        // this.$forceUpdate();
        console.log(this.form.fatherColumSelect!.toString())
        console.log(this.defaultParenId)
    }

    private addColum(): void {
        this.activeName = 'user';
        this.showMain = true;
        console.log(this.form)
        this.resetForm();
        this.form.fatherColumSelect = this.getParentId();

        this.id = '';
    }

    private fatherColumn: Result[] = [];


    // 栏目管理
    private save(): void {
        (this.$refs.ruleForm as any).validate((valid: any)=>{
            if(valid){

                this.handleColumn();

            }
        })
    }

    private rules: form<Rule[]> = {
        name: [
            {required: true, message: '请输入机构名称', trigger:'blur'},
        ],
        order: [
            {required: true, message: '请输入排列序号', trigger:'blur'},
            { type: 'number', message: '排列序号必须为数字'}
        ],
    }

    private axios: Axios;
    constructor() {
        super();
        this.axios = new Axios();
    }

    private handleColumn(): void {
        const url = this.getUrl(this.id);
        // console.log(resource)
        // console.log(this.id)
        // console.log(this.form.fatherColumSelect![this.form.fatherColumSelect!.length - 1])
        // console.log(new Util().getId(this.id))
        this.axios.post<Result>(url, {
            ...this.form,
            type: this.form.fatherColumSelect!.toString() === this.defaultParenId ? 1 : 2,
            parentId: this.form.fatherColumSelect![this.form.fatherColumSelect!.length - 1],
            id: new Util().getId(this.id),
        }, true).then(data=>{
            this.$message.success(data.message);

            this.getTree();

        }).catch(err=>{
            this.$message.error(err.message);
        })
    }

    private getUrl(data: string): string {
        console.log(data)
        return data ? '/animal/web/group/update' : '/animal/web/group/insert';
    }


    private treeData: Result[] = [];

    private getTree(): void {
        if(!this.$getResourceCodes('menu_tree')) return;
        const column: Column = new Column();
        column.getTreeData('/animal/web/group/treeList').then((result: Result[])=>{
            this.treeData = result;

            const deepCopy: DeepLoop = new DeepLoop();
            const fatherData = deepCopy.deepCopy(this.treeData);
            fatherData.unshift({
                id: this.defaultParenId,
                name: '无',
                parentId: this.defaultParenId
            });

            this.fatherColumn = fatherData;

            this.flatJson = deepCopy.deepFlatJson(this.fatherColumn, 'id', 'parentId');

        }).catch(err=>{
            this.$message.error(err.message);
        });
    }

    private activated(): void {
        this.getTree();
    }

    private remove(): void {

        this.delResource(this.id);

    }

    private delResource(id: string): void {

        this.$confirm('删除部门将无法恢复，相关子部门将同步删除！是否确定删除该部门？','操作确认',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(()=>{
            this.axios.post<Result>('/animal/web/group/delete',{
                id: id
            }).then((data)=>{

                this.$message.success(data.message);
                // this.resourceTableData = data.data? data.data.resources : [];
                this.getTree();
                this.showMain = false;

            }).catch(err=>{
                this.$message.error(err.message);
            })
        })

    }
}
