







import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import echarts from "echarts";
@Component({})
export default class NewEchartsVue extends Vue {
    @Prop() DataList: any;

    private echartsdatatimes: any[] = [];
    private echartsdatalist: any[] = [];
    private oestrusList: any[] = [];
    private option: any = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "cross"
            }
        },
        grid: {
            left: "2%",
            right: "6%",
            bottom: "6%",
            top: "6%",
            containLabel: true
        },
        xAxis: {
            type: "category",
            boundaryGap: false,
            data: this.echartsdatatimes
        },
        yAxis: {
            type: "value",
            axisLabel: {
                formatter: "{value}"
            },
            axisPointer: {
                snap: true
            },
            axisLine: {
                lineStyle: {
                    color: "#DDDDDD" //x轴的颜色
                    // width: 8, //轴线的宽度
                }
            },
            axisLabel: {
                textStyle: {
                    color: "#333"
                }
            }
        },
        visualMap: {
            show: false,
            dimension: 0
        },

        series: [
            {
                type: "line",
                smooth: true,
                itemStyle: {
                    normal: {
                        color: "#2EA987",
                        lineStyle: {
                            color: "#2EA987"
                        }
                    }
                },
                data: this.echartsdatalist,
                markArea: {
                    itemStyle: {
                        color: "red"
                    },
                    data: this.oestrusList
                }
            }
        ]
    };

    private EchartsInit(list: any[]) {
        let dom: any = document.getElementById("echarts");
        let myChart = echarts.init(dom);
        this.option.series[0].markArea.data = list;
        this.option.series[0].data = this.echartsdatalist;
        this.option.xAxis.data = this.echartsdatatimes;
        this.option && myChart.setOption(this.option);
    }
    private indexVal: number = 0;
    private getChartsData() {
        this.echartsdatatimes = [];
        this.echartsdatalist = [];
        this.oestrusList = [];
        let listArr: any[] = [];
        this.indexVal = 0;
        this.DataList.forEach((item: any, index: number) => {
            this.echartsdatatimes.push(item.collectTime);
            this.echartsdatalist.push(item.sportVal);
            if (item.feedingStatus == 1) {
                listArr.push({
                    xAxis: item.collectTime
                });
            }
            if (
                item.feedingStatus == 1 &&
                this.DataList[index + 1].feedingStatus != 1
            ) {
                this.oestrusList.push([]);
                this.oestrusList[this.indexVal][0] = listArr[0];
                listArr = [];
                this.oestrusList[this.indexVal][1] = {
                    xAxis: item.collectTime
                };
                this.indexVal++;
            }
        });
        let arrtest: any[] = [];
        this.oestrusList.forEach((item: any) => {
            arrtest.push(item);
        });
        this.oestrusList = arrtest;

        this.EchartsInit(arrtest);
    }

    private mounted() {
        this.$nextTick(() => {
            this.getChartsData();
        });
    }

    @Watch("DataList")
    Changelist(newVal: any) {
        this.$nextTick(() => {
            this.getChartsData();
        });
    }
}
