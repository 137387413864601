
























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {HandleTable, HandlePageChange} from '@/common/Views/comm/handleTable';
import {PersonalGetIp, ipData} from "@/common/Views/system/personal/personalGetIp";


@Component
export default class HelloWorld extends Vue {
    @Prop({default: ''})id!: string;
    private ipData:ipData = [];

    private personnalIp: PersonalGetIp;

    constructor() {
        super();
        this.personnalIp = new PersonalGetIp();
    }

    private tablePage: HandleTable = {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }

    private handlePageChange(val: HandlePageChange){

        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getIpList();
    }

    private created(){
        this.getIpList();
    }

    private getIpList(): void {
        this.personnalIp.getIpList(this.id, this.tablePage)
            .then(data=>{
                this.ipData = data.data;
                this.$set(this.tablePage, 'count', data.page.count);
            });
    }

    private exportData(): void {
        this.personnalIp.exportData(this.id);
    }

    @Watch('id')
    private getIp(): void {
        this.tablePage.pageNo = 1;
        this.getIpList();
    }
}
