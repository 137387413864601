
















































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import TitleSolt
    from '@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue';
import {TraceabilityOutManage, OutManageDetail} from '@/common/Views/traceabilityOutManage/traceabilityOutManage';
import {AxiosData} from "@/common/http/http";
import {Util} from "@/common/Views/comm/util";
@Component({
    components: {TitleSolt}
})
export default class HelloWorld extends Vue {

    private maskImg = '';
    
    private maskShow: boolean = false


    private traceabilityOutManage: TraceabilityOutManage;

    public previewSrc = []

    constructor() {
        super();
        this.traceabilityOutManage = new TraceabilityOutManage();
    }

    private form: OutManageDetail = {
        animalId: '',
        count: 0,
        createTime: '',
        destinationName: '',
        farmName: '',
        groupName: '',
        id: '',
        pictures: '',
        status: 0,
        statusMsg: '',
        typeName: '',
        verifyTime: '',
        verifyUser: '',
    }

    private pictures: string[] = [];

    private getDetail(id: string) {
        this.traceabilityOutManage.getDetail(id, (data: AxiosData<OutManageDetail>)=>{
            if (data.data.pictures) {
                this.pictures = data.data.pictures.split(",");
            }
            this.form = data.data;

        })
    }

    private handleImg(data: any): void {
        this.maskShow = true
        this.maskImg = data
    }

    private handleMask(): void {
        this.maskShow = false
    }


    @Watch("$route", {immediate: true})
    public handleRoute() {
        this.maskShow = false
        if (this.$route.query.slaughterExamineDetailId) {
            this.previewSrc = []

            this.form = new Util().clearObject(this.form);

            this.getDetail(<string>this.$route.query.slaughterExamineDetailId);
        }
    }
}
