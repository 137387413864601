




























































































import {Component, Prop, Watch , Vue} from "vue-property-decorator";
import { Column, Result } from '@/common/Views/system/column/column';
import { DeepLoop } from '@/common/deepLoop/deepLoop';
import {Slaughter, SlaughterForm, SlaughterTable} from "@/common/Views/system/slaughter/slaughter";
import {TableStudentData} from '@/common/Views/basics/studentManage/studentManage';
import { HandleTable, HandlePageChange } from '@/common/Views/comm/handleTable';
import {AxiosData} from "@/common/http/http";
import { Util } from '@/common/Views/comm/util';

@Component
export default class HelloWorld extends Vue {

    public column = new Column()

    private form: SlaughterForm = {
        groupCode: '',
        name: '',
        platform: '',

    }

    private activated(): void {
        this.getTree();
        this.getTable();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }


    private treeData: Result[] = [];
    private getTree(): void {
        if(!this.$getResourceCodes('menu_tree')) return;
        const column: Column = new Column();
        // column.getTreeData('/animal/web/group/treeList').then((result: Result[])=>{
        column.getTreeData('/animal/web/provinceCityDistrict/getForGroupCode').then((result: Result[])=>{

            this.treeData = new DeepLoop().deepCopy(result);

        }).catch(err=>{
            this.$message.error(err.message);
        });
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.getTable();
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        this.getTable();
    }

    private tableData: SlaughterTable[]=[];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }

    private handlePageChange(val: HandlePageChange){

        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }

    private slaughter: Slaughter;

    constructor() {
        super();
        this.slaughter = new Slaughter();
    }



    private getTable(): void{
        this.slaughter.getTable(this.form, this.tablePage, (data: AxiosData<SlaughterTable[]>)=>{
            console.log(data)
            this.tablePage.totalCount = data.page.totalCount;
            this.tableData = data.data;
        })
    }


    private addSlaughter(): void {
        this.$router.push({
            path:'/slaughterDetail',
            query:{
                setFlag:false,
            }
        });
    }

    private goDetail(row: SlaughterTable): void {
        let setFlag:boolean = true;
        let query:any ={
            slaughterId: row.id,
            rowData:row,
            setFlag
        }
        this.$router.push({
            path:'/slaughterDetail',
            query
        })
    }

    private goAudit(row: SlaughterTable): void {
        this.$router.push({
            path: '/slaughterAudit',
            query: {
                slaughterAudit: row.id,
            }
        })
    }

}
