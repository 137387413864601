




































import {Watch , Component , Vue} from 'vue-property-decorator'
import ForageAndDrugFormSearch from '@/views/basics/typeConfig/components/ForageAndDrugFormSearch.vue'
import {BasicsClass} from '@/common/BasicsClass';
@Component({
    components:{
        ForageAndDrugFormSearch
    }
})
export default class DrugShelfConfiguration extends Vue{
    private tableData:any[] = [] //table 数据
    private tablePage:any ={  //分页数据
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }

    //事件
    private handlePageChange(val: any) { //分页 事件
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        let domVc:any = this.$refs.SearchItemRef
        //请求列表数据
        this.getTableListReq(domVc.form)
    }
    private EditData(row:any){//编辑
        this.$router.push({
            path:"/EditDrug",
            query:{
                rowData:row
            }
        })
    }
    private RemoveData(row:any){//删除
         //删除提示框
        this.$confirm('是否确认删除？' , '操作确认' , {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() =>{
            this.$message({
                type: 'success',
                message: '删除成功!'
            }),
            this.DeleteItemDataReq(row.id)
        }).catch(() =>{
            this.$message({
                type: 'info',
                message: '已取消删除'
            })
        })
    }



    //emit事件
    private SearchList(form:object){ //搜索
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        
        this.getTableListReq(form)
    }
    private ResetList(form:object){ //重置
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getTableListReq(form)
    }



    //请求数据
    private getTableListReq(form?:any){//请求table数据
        let formDatas:any = {}
        let formData:any ={}
        if(form != undefined){
            formData = JSON.parse(JSON.stringify(form))
            formDatas.groupCode = formData.groupCode[formData.groupCode.length-1]
            formDatas.drugTenant = formData.forageTenant
            formDatas.drug = formData.confTypeId
            if(formData.enableFlag == 1){
                formDatas.enableFlag = true
            }else if(formData.enableFlag == 2){
                formDatas.enableFlag = false
            }else{
                formDatas.enableFlag = ""
            }
        }
        new BasicsClass().BasicGet1('/animal/web/drug/getDrug',{...formDatas , ...this.tablePage},false,false,(data:any)=>{
            this.tableData = data.data
            this.tablePage.totalCount = data.page.totalCount
        })
    }
    private DeleteItemDataReq(id:string){
        new BasicsClass().BasicPost('/animal/web/drug/deleteDrug',{id},false,false,true,(data:any)=>{
            this.tablePage.pageNo = 1
            this.tablePage.pageSize = 10
            this.getTableListReq() //获取最新数据
        })
    }


    activated(){
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getTableListReq()
    }
}
