import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/Interface/CommonModule/TableAndPage'

export class GuaranteeCompanyAPI {
    /**@param getGuaranteeList 获取担保配置列表 */
    public getGuaranteeList(form: any, page: HandleTable, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/guarantee/list', { ...form, ...page }, false, false, cb)
    }
    /**@param addGuarantee 新增担保 */
    public addGuarantee(form: any, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/guarantee/add", { ...form }, true, true, true, cb)
    }
    /** */
    public editGuarantee(form: any, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/guarantee/edit", { ...form }, true, true, true, cb)
    }
}

// 大屏预警开关设置页面
export class BigScreenWarningSwitchAPI {
    /**@param saveConfig 保存数据 */
    public saveConfig(form: any, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/warningSwitch/save", { ...form }, true, true, true, cb)
    }


    // 获取上次用户保存的状态
    public getUserSettingInfo(cb: Function) {
        new BasicsClass().BasicGet('/animal/web/warningSwitch/getUserSettingInfo', {}, false, false, cb)
    }

    // 获取当前用户所拥有的大屏权限
    public getUserScreenAuthority(cb: Function) {
        new BasicsClass().BasicGet('/animal/web/warningSwitch/getUserScreenAuthority', {}, false, false, cb)
    }

}
export class CattleEarlyWarningConfigurationApi {
    /**@param getCattleEarlyWarningList 获取牛只预警配置列表 */
    public getCattleEarlyWarningList(form: any, page: HandleTable, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/femaleBirthConfig/list', { ...form, ...page }, false, false, cb)
    }
    /**@param addCattleEarlyWarning 新增牛只预警配置 */
    public addCattleEarlyWarning(form: any, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/femaleBirthConfig/save", { ...form }, true, true, true, cb)
    }
    /**修改牛只预警配置 */
    public editCattleEarlyWarning(form: any, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/femaleBirthConfig/edit", {...form}, true, true, true,cb)
    }
    /**删除牛只预警配置 */
    public deleteCattleEarlyWarning(id: any,cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/femaleBirthConfig/delete", {id}, false, false, true,cb)
    }

}

