











import { Watch, Prop, Component, Vue } from "vue-property-decorator";
@Component({})
export default class InputComonentVue extends Vue {
    /** @param FormInput 父组件调用必穿的属性 用于获取父组件绑定值得内容*/
    @Prop() FormInput: any;
    /** @param inputStyle 父组件调用必穿的属性 用于获取父组件绑定值得内容*/
    @Prop() inputStyle: any;
    /**@param  PlaceHolderText 输入框默认显示内容*/
    @Prop() PlaceHolderText: any;
    /**@param  MaxLength 限制最大输入长度*/
    @Prop() MaxLength: any;
    /**@param IsNumber 是否是个数字 如果为true 按照只能输入数字的正则校验 */
    @Prop() IsNumber: any;
    /**@param IsNumberAndDian 是否是个数字和“.” 如果为true 按照只能输入数字和“.”的正则校验 */
    @Prop() IsNumberAndDian: any;
    /**@param  Disabled 是否禁止输入*/
    @Prop() Disabled: any;
    /**@param Positive 是否是正整数 */
    @Prop() Positive: any;
    /**@param isNumAndEn 之恩那个输入数字和英文 不包含英文符号 */
    @Prop() isNumAndEn: any;
    private InputVal: any = ""; //当前组件绑定的值

    private InputValue() {
        if (this.Positive) {
            //如果是正整数
            if (this.InputVal == 0) {
                this.InputVal = "";
            }
        }
        if (this.isNumAndEn) {
            this.InputVal = this.isNumberAndEnglist(this.InputVal);
        }
        if (this.IsNumberAndDian) {
            this.InputVal = this.isNumberAndDian(this.InputVal);
        }
        
        this.InputVal = this.checkStr(this.InputVal);
        this.$emit("SetInputValue", this.InputVal);
    }

    @Watch("FormInput", { immediate: true, deep: true })
    private changeData(newVal: any) {
        if (newVal == undefined) return;
        this.InputVal = this.checkStr(newVal);
        this.$emit("SetInputValue", this.InputVal);
    }

    private checkStr(str: any) {
        let result: any = str;
        if (this.IsNumber) {
            result = result.replace(/[^\d]/g, "");
        }
        // utf8mb4 正则
        const utf8mb4Reg = /[^\u0000-\uFFFF]/g;
        // emoji 表情正则
        const emojiReg =
            /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g;
        // 包含 utf8mb4 的 4 字节
        const isUTF8MB4 = utf8mb4Reg.test(result);
        // 包含 emoji 表情
        const isEmoj = emojiReg.test(result);
        // 校验特殊字符 并删除
        isUTF8MB4 && (result = result.replace(utf8mb4Reg, ""));
        isEmoj && (result = result.replace(emojiReg, ""));
        return result;
    }
    private isNumberAndEnglist(str: any) {
        let result;
        result = str.replace(/[^\A-Za-z0-9]/g, "");
        return result;
    }
    private isNumberAndDian(str: any) {
        let result;
        result = str.replace(/[^\d.]/g, '');
        return result;
    }
}
