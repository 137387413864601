import { BasicsClass } from '@/common/BasicsClass';
import { fireEvent } from 'highcharts';



export class productListweb extends BasicsClass {
  //获取商品列表
  public getGoodsList(form: any, page: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/goods/system/page', { ...form, ...page }, false, false, cb);
  }
  //获取商品详情
  public getGoodsInfo(id: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/goods/system/getInfo', { id }, false, false, cb);
  }
  //查询商户所有品牌
  public getBrandList(cb: Function) {
    this.BasicGet('/animal/web/ordering/brand/listAll', {  }, false, false, cb);
  }
  //审核
  public audit(form:any,cb: Function) {
    this.BasicPost('/animal/web/ordering/goods/system/audit', { ...form }, false, false,true, cb);
  }
  


}
