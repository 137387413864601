




































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import LabelManageDetailImport from "@/views/basics/labelManage/labelManageDetail/labelManageDetailImport.vue";
import {
  LabelManage,
  LabelManageFrom,
  LabelManageTable,
  Addnumber
} from "@/common/Views/basics/nblabelManage/labelManage";
import { ComboList, Column, Result } from "@/common/Views/system/column/column";
import DeviceGroup from "@/views/basics/nblabelManage/deviceGroup/deviceGroup.vue";
import { LabelManagement } from "@/common/Views/configManage/nblabelManagement/labelManagement";
import {
  DeviceManage,
  DeviceManageForm,
  DeviceManageTable,
} from "@/common/Views/basics/deviceManage/deviceManage";
import { values } from "_xe-utils@2.8.3@xe-utils/methods";

///////
@Component({
  components: {
    DeviceGroup,
    "label-manage-detail-import": LabelManageDetailImport,
  },
})
export default class HelloWorld extends Vue {
  public column = new Column();
  private show :boolean= false
  private form: any = {
    groupCode: [],
    farmId: "",
    number: "",
    isBind: "",
    // tagType: "",//复制的时候有的注释
    time: [],
    createTimeStart: [],
    createTimeEnd: [],
    tagStatus: "",
  };
  private sendchilden = '';
  private candel = true;
  private labelNumberArr: any = [];
  private dialogupdload: boolean = false;
  private show: boolean = false;
  private elsxurl: string = "javascript:void(0)";
  private labelManage: LabelManage;

  private childComboList: ComboList[] = [];
  private localnblist=window.localStorage.getItem('nblist')

  constructor() {
    super();
    this.labelManage = new LabelManage();
  }

  private activated(): void {
    this.getTable();
    this.getTree();
    this.changeGroupCode();
    // this.column.getTissuc(undefined, (res: any) => {
    //     this.treeData = res.data
    // })
  }
  private selectionChange(data: any) {
    console.log(data.selection[0]);
    for (let i in data.selection) {
      this.labelNumberArr.push(data.selection[i].number);
    }
  }
  private selectAll(data: any) {
    console.log(data.selection);
    for (let i in data.selection) {
      this.labelNumberArr.push(data.selection[i].number);
    }
  }
  private addlabel(): void {
    this.$prompt("耳标号", "添加标签", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      //   inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
      //   inputErrorMessage: "邮箱格式不正确",
    })
      .then(({ value }) => {
        if (value != null) {
          this.labelNumberArr.push(value);
        new LabelManagement().addlabel((res: any) => {
            console.log(res);

            if (res.code == 200) {
              this.$message({
                type: "success",
                message: " 添加成功",
              });
              this.labelNumberArr = [];
              this.getTable();
            } else {
              this.$message({
                type: "warning",
                message: res.message,
              });
              this.labelNumberArr = [];
            }
          }, this.labelNumberArr);
        } else {
          this.$message({
            type: "error",
            message: " 请输入耳标号",
          });
        }
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "123",
        });
      });
  }
  private addlabels(): void {
    this.dialogupdload = true;
  }
  private dellabels(): void {
    // this.$confirm("此操作将删除耳标, 是否继续?", "提示", {
    //   confirmButtonText: "确定",
    //   cancelButtonText: "取消",
    //   type: "warning",
    // })
    //   .then(() => {
    let removeRecords: any = [];
    this.candel = true;
    removeRecords = (this.$refs.table as any).getSelectRecords();
    removeRecords.forEach((value: any) => {
      if (this.candel == false) {
        return;
      }
      if (value.isBind == false) {
        this.labelNumberArr.push(value.nbDeviceId);
      } else {
        this.labelNumberArr = [];
        this.$message({
          // message: "不能删除已绑定的耳标号",
          message: "存在已绑定耳标，不允许删除",
          type: "warning",
        });
        return (this.candel = false);
      }
    });
    if (this.labelNumberArr.length > 0 && this.candel) {
      this.$confirm("请确认是否删除耳标", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then((res) => {
          console.log(res);
          //批量删除
          new LabelManagement().dellabels((res: any) => {
            console.log(res);
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: " 删除成功",
              });
              this.labelNumberArr = [];
              this.getTable();
            } else {
              this.$message({
                type: "warning",
                message: res.message,
              });
            }
            this.labelNumberArr = [];
            this.candel = true;
            this.getTable();
          }, this.labelNumberArr);
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (this.candel) {
      this.$message({
        type: "warning",
        message: "请勾选耳标号再进行批量删除",
      });
      this.candel = true;
    }
    //   })
    //   .catch(() => {
    //     this.$message({
    //       type: "info",
    //       message: "已取消删除",
    //     });
    //   });
  }
  private beforeUpload(file: any): void {
    // this.$message({
    //   type: "success",
    //   message: " 导入成功",
    // });
    let fileList: any = [];
    fileList.push(file);
    new LabelManagement().uploadFile(fileList, "", (res: any) => {
      if (res.code == 200) {
        if(res.data.repeatNumber.length === '0' || res.data.repeatNumber.length === 0){
          this.$message({
            type: "success",
            //批量添加  上传重复提示
            // message: " 添加成功,重复的内容为" + res.data.repeatNumber,
            // message: " 导入完成",
            message: " 导入完成",
          });
        }else{
          this.$message({
          type: "success",
          //批量添加  上传重复提示
          // message: " 添加成功,重复的内容为" + res.data.repeatNumber,
          message: " 导入完成,以下耳标重复文件中耳标号重复"  + res.data.repeatNumber,
        });
        this.dialogupdload = false;
        console.log(res)
        this.getTable();
        }

        
      } 
      else {
        this.$message({
          type: "warning",
          message: res.message,
        });
      }
    });
    console.log(file);
  }
  private downmod(): void {
    new LabelManagement().down();
    this.dialogupdload = false;
  }
  private treeData: Result[] = [];
  private getTree() {
    Util.getTree((result: Result[]) => {
      this.treeData = result;
    });
  }

  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    if (this.form.time.length != 0) {
      // this.form.createTimeStart = Util.dateTimeUtil(this.form.time[0]);
      // this.form.createTimeEnd = Util.dateTimeUtil(this.form.time[1]);
    }
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private comboList: ComboList[] = [];
  private changeGroupCode(): void {
    this.comboList = [];
    // this.form.farmId = '';
    new Column().getFarm(
      this.form.groupCode[this.form.groupCode.length - 1],
      (data: AxiosData<ComboList[]>) => {
        this.comboList = data.data;
      }
    );
  }

  @Watch("form.groupCode")
  getgroupCode(newVal: Array<object>, oldVal: Array<object>) {
    if (newVal !== oldVal) {
      this.form.farmId = "";
      // this.form.typeConfId = '';
    }
  }

  private tableData: LabelManageTable[] = [];

  private tablePage: HandleTable = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: HandlePageChange) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private getTable(): void {
    if (this.$getResourceCodes("label_list")) {
      this.labelManage.getTable(
        this.form,
        this.tablePage,
        (data: AxiosData<LabelManageTable[]>) => {
          this.tablePage.totalCount = data.page.totalCount;
          this.tableData = data.data;
          console.log(tableData)
        }
      );
    }
  }

  private dialogTableVisible: boolean = false;
  private onClickDeviceGroupingVisible: boolean = false;
  private importVisible: boolean = false;

  private rowData: LabelManageTable[] = [];

  private deviceGrouping(): void {
    let removeRecords: LabelManageTable[] = (
      this.$refs.table as any
    ).getSelectRecords();
    if (removeRecords.length === 0) {
      this.$message.error("请勾选数据后再操作");
      return;
    }

    this.rowData = removeRecords;

    this.dialogTableVisible = true;
    this.$nextTick(() => {
      (<any>this.$refs.deviceGroup).getTree();
    });
  }

  private onClickDeviceGrouping(): void {
    console.log(this.comboList)
    this.rowData = [];
    this.onClickDeviceGroupingVisible = true;
    this.$nextTick(() => {
      (<any>this.$refs.onClickDevice).getTree();
    });
  }

  private importDeviceGrouping(): void {
    this.importVisible = true;
    this.$nextTick(() => {
      (<any>this.$refs.importDevice).getTree();
    });
  }

  private confirm(cb: Function) {
    this.$confirm(
      "当前选中设备已绑定养殖场，重新分组后，之前绑定的养殖场将自动解绑，确认是否继续操作？",
      "提示",
      {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }
    ).then(() => {
      cb();
    });
  }
  //添加耳标 addnumber ()
  private addnumber: Addnumber[] = [];
  private success(data: Addnumber): void {
    console.log(this.addnumber)
    //下面注释的是之前的分组的逻辑
    // let flag: boolean = false;
    // console.log(this.rowData)
    // for (const i of this.rowData) {
    //   if (i.isGroup) {
    //     flag = true;
    //     break;
    //   }
    // }

    // if (flag) {
    //   this.confirm(() => {
        // this.groupMapping(data);
    //   });
    //   return;
    // }
this.onClickDeviceGroupingVisible = false;
      this.dialogTableVisible = false;
      this.getTable();
    // this.groupMapping(data);
  }

  private groupMapping(data: LabelManageTable) {
    this.labelManage.deviceGroup(data, this.rowData, () => {
      this.onClickDeviceGroupingVisible = false;
      this.dialogTableVisible = false;
      this.getTable();
    });
  }
  mounted(){
    // this.tableData = window.localStorage.getItem('nblist');
    // console.log(window.localStorage.getItem('nblist'))
  }
}
