

























































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Result, Column } from "@/common/Views/system/column/column";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { optionSelect, Rule } from "@/common/Views/comm/formRule";
import { ConfType } from "@/common/Views/system/confType/confType";
import { SchoolList } from "@/common/Views/basics/schoolManage/schoolManage";
import { AxiosData } from "@/common/http/http";
import {
    FarmDetailForm,
    FarmDetail,
    FarmDetailDetail,
    CityList,
} from "@/common/Views/system/farm/farmDetail/farmDetail";
import { Util } from "@/common/Views/comm/util";
import TitleSolt from "@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue";
import { BasicsClass } from "../../../../common/BasicsClass";
import UploadTemp from "@/components/UploadTemp.vue";
import { getGroupCodeForUser319 } from "@/api/index";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import i18n from "@/vue-il8n";
interface IFeedingModel {
    id: string;
    name: string;
}
@Component({
    components: { TitleSolt, UploadTemp, SelectComponentsVue },
})
// @Component
export default class HelloWorld extends Vue {
    public column = new Column();
    public ImgList = [];
    private Util = new Util();
    private formLabelAlign= {
        groupCode: [],
        mobile: "",
        person: "",
        status: 1,
        name: "",
        typeIds: "",
        simpleAddress: "",
        detailAddress: "",
        licenseUrl: "",
        doorUrl: "",
        epidemicUrl: "",
        animalBusiness: "",
        pic5: "",
        pic6: "",
        pic7: "",
        pic8: "",
        pic9: "",
        pic10: "",
        pic11: "",
        auditStatus: "1",
        originDescribe: "",
        companyId: "",
        insuranceCompanyId: "",
        isInternational: "",
        feedingType: "",
        msgSwitchFlag: false,
        showHighQualityFlag: false,
    };
    private originDescribemax = 300;
    private picitem = [];
    private treeData: Result[] = [];
    private isInternationaldisabled: boolean = false;
    private msgStatusList = [
        { name: "启用", value: true },
        { name: "禁用", value: false },
    ];
    private showStatusList = [
        { name: "是", value: true },
        { name: "否", value: false },
    ];
    private ChangeIsInternational() {
        (window as any).isInternational = this.formLabelAlign.isInternational;
        this.getTree();
        this.getCompany();
    }
    private propsObj: any = {
        lazy: true,
        expandTrigger: "hover",
        value: "id",
        label: "name",
        lazyLoad(node: any, resolve: any) {
            getGroupCodeForUser319(
                node.value,
                (window as any).isInternational,
                (res: any) => {
                    try {
                        let nodes = res.data;
                        nodes.forEach((item: any) => {
                            item.leaf = item.level === 4;
                        });
                        resolve(nodes);
                    } catch (e) {
                        console.log(e);
                    }
                }
            );
        },
    };

    private getTree(cb?: Function): void {
        getGroupCodeForUser319(
            undefined,
            (window as any).isInternational,
            (data: any) => {
                this.treeData = data.data;
                cb ? cb() : "";
            }
        );
    }

    get statusOptions() {
        return [
            {
                value: 1,
                label: this.$t("system.farm.status_2") as string,
            },
            {
                value: 0,
                label: this.$t("system.farm.status_1") as string,
            },
        ];
    }

    private activated(): void {
        this.preventClick = false;
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        console.log(this.formLabelAlign,666)
        this.formLabelAlign.msgSwitchFlag = false;
        this.formLabelAlign.showHighQualityFlag = false;
        this.feedingModelList = [];
        this.getDetail();
        this.getDetail2();
        this.confType();
        this.getFeedingModelData();
    }

    private conTypeList: SchoolList<string>[] = [];

    private confType(): void {
        new ConfType().getList((data: AxiosData<SchoolList<string>[]>) => {
            this.conTypeList = data.data;
        });
    }

    get rules() {
        return {
            groupCode: [
                // @ts-ignore
                {
                    type: "array",
                    required: true,
                    message: this.$t("system.farm.tip_1") as string,
                },
            ],
            mobile: [
                {
                    required: true,
                    message: this.$t("system.farm.tip_2") as string,
                },
                // {
                //   message: "手机号码输入错误",
                //   validator: (rule: any, value: any, callback: any) => {
                //     if (!/^[0-9]{11}$/g.test(value)) {
                //       callback(new Error());
                //     } else {
                //       callback();
                //     }
                //   },
                // },
            ],
            person: [
                {
                    required: true,
                    message: this.$t("system.farm.tip_3") as string,
                    trigger: "blur",
                },
            ],
            status: [
                {
                    required: true,
                    message: this.$t("system.farm.tip_4") as string,
                    trigger: "blur",
                },
            ],
            name: [
                {
                    required: true,
                    message: this.$t("system.farm.tip_5") as string,
                },
                {
                    min: 2,
                    max: 20,
                    message: this.$t("system.farm.tip_6") as string,
                    trigger: "blur",
                },
            ],
            typeIds: [
                // @ts-ignore
                {
                    required: true,
                    message: this.$t("system.farm.tip_7") as string,
                    trigger: "blur",
                },
            ],
            // simpleAddress: [
            //     {required: true, message: "$t('common.placeholder.select')城市"}
            // ],
            detailAddress: [
                {
                    required: true,
                    message: this.$t("system.farm.tip_8") as string,
                    trigger: "blur",
                },
            ],
            doorUrl: [
                {
                    required: true,
                    message: this.$t("system.farm.tip_9") as string,
                    trigger: "blur",
                },
            ],
            companyId: [
                {
                    required: true,
                    message: this.$t("system.farm.tip_10") as string,
                    trigger: "blur",
                },
            ],
            // @ts-ignore
            feedingType: [
                {
                    required: true,
                    message: this.$t("system.farm.tip_11") as string,
                    trigger: "blur",
                },
            ],
            
        };
    }

    // private rules: FarmDetailForm<Rule[]> =

    private farmDetail: FarmDetail;

    constructor() {
        super();
        this.farmDetail = new FarmDetail();
    }

    private companyList = [];
    private getCompany(): void {
        new Column().getCorporation(
            (window as any).isInternational,
            (res: any) => {
                this.formLabelAlign.companyId = "";
                this.companyList = res.data;
            }
        );
    }

    @Watch("formLabelAlign.originDescribe")
    getPiedata(newVal: Array<object>, oldVal: Array<object>) {
        if (newVal !== oldVal) {
            // console.log(this.formLabelAlign);
            if (this.formLabelAlign.originDescribe != null) {
                if (this.formLabelAlign.originDescribe.length > 300) {
                    this.formLabelAlign.originDescribe = String(
                        this.formLabelAlign.originDescribe
                    ).slice(0, this.originDescribemax);
                    // console.log(this.formLabelAlign.originDescribe.length)
                    const vue = new Vue();
                    vue.$message.warning(
                        this.$t("system.farm.tip_12") as string
                    );
                }
            } else {
                this.formLabelAlign.originDescribe = "";
            }
        }
    }

    // 防止多次点击造成多次新增请求
    private preventClick: boolean = false;

    private save(): void {
        console.log(this.formLabelAlign, "formLabelAlign22222");
        if (this.preventClick) return;
        console.log(this.formLabelAlign, "formLabelAlign");
        if (!this.formLabelAlign.feedingType) {
            this.$message.warning(this.$t("system.farm.tip_11") as string);
        } else if (!this.formLabelAlign.person) {
            this.$message.warning(this.$t("system.farm.tip_3") as string);
        } else if (!this.formLabelAlign.mobile) {
            this.$message.warning(this.$t("system.farm.tip_2") as string);
        }
        (this.$refs.form as any).validate((valid: boolean) => {
            if (valid) {
                let id = "";
                if (this.$route.query.farmId) {
                    id = <string>this.$route.query.farmId;
                }
                try {
                    this.picitem = [];
                    var type = "";
                    for (var i = 3; i < 10; i++) {
                        var obj = {};
                        type = i;
                        if (type == 3) {
                            obj.file = this.formLabelAlign.pic5;
                            obj.type = type;
                        } else if (type == 4) {
                            obj.file = this.formLabelAlign.pic6;
                            obj.type = type;
                        } else if (type == 5) {
                            obj.file = this.formLabelAlign.pic7;
                            obj.type = type;
                        } else if (type == 6) {
                            obj.file = this.formLabelAlign.pic8;
                            obj.type = type;
                        } else if (type == 7) {
                            obj.file = this.formLabelAlign.pic9;
                            obj.type = type;
                        } else if (type == 8) {
                            obj.file = this.formLabelAlign.pic10;
                            obj.type = type;
                        } else if (type == 9) {
                            obj.file = this.formLabelAlign.pic11;
                            obj.type = type;
                        }
                        this.picitem.push(obj);
                    }
                } catch (err) {
                } finally {
                    this.preventClick = true;
                    if (this.$route.query.farmId) {
                        new FarmDetail().submit2(id, this.picitem, () => {});
                    }
                    if (this.$route.query.farmId)
                        this.formLabelAlign.isInternational ==
                        this.$t("system.farm.overseas")
                            ? (this.formLabelAlign.isInternational = 1)
                            : (this.formLabelAlign.isInternational = 0);
                    new FarmDetail().submit(
                        id,
                        this.formLabelAlign,
                        this.picitem,
                        () => {
                            this.$router.go(-1);
                        },
                        () => {
                            // console.log('请求失败回调函数, 放开$t('common.btn.save')功能')
                            this.getDetail();
                            this.preventClick = false;
                        }
                    );
                }
            }
        });
    }

    private getDetail(): void {
        // console.log(111111111111111111111111);
        // this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        this.formLabelAlign.status = 1;
        this.getTree(() => {
            if (this.$route.query.farmId) {
                this.isInternationaldisabled = true;
                this.farmDetail.getDetail(
                    <string>this.$route.query.farmId,
                    (data: AxiosData<FarmDetailDetail>) => {
                        const Data = data.data;
                        this.formLabelAlign.typeIds = Data.typeIds;
                        this.formLabelAlign.status = Number(Data.status);
                        this.formLabelAlign.mobile = Data.mobile;
                        this.formLabelAlign.person = Data.person;
                        this.formLabelAlign.name = Data.name;
                        this.formLabelAlign.auditStatus = Data.auditStatus;
                        this.formLabelAlign.originDescribe =
                            Data.originDescribe;
                        this.formLabelAlign.companyId = Data.companyId;
                        this.formLabelAlign.insuranceCompanyId =
                            Data.insuranceCompanyId;
                        this.formLabelAlign.isInternational =
                            Data.isInternational;
                        this.formLabelAlign.showHighQualityFlag =
                            Data.showHighQualityFlag;
                        this.formLabelAlign.msgSwitchFlag =
                            Data.msgSwitchFlag;
                        window.isInternational = Data.isInternational;
                        this.formLabelAlign.isInternational ==
                        this.$t("system.farm.overseas")
                            ? (this.formLabelAlign.isInternational = "1")
                            : (this.formLabelAlign.isInternational = "0");
                        new Column().getCorporation(
                            this.formLabelAlign.isInternational,
                            (res: any) => {
                                this.companyList = res.data;
                            }
                        );
                        this.formLabelAlign.feedingType = Data.feedingType;
                        // console.log(this.switchisInternational , '9999999999999999999999999999999')
                        // if (Data.isInternational == 1) {
                        //     this.formLabelAlign.isInternational =
                        //        this.$t('system.farm.overseas') as string;
                        // } else {
                        //     this.formLabelAlign.isInternational =
                        //         this.$t('system.farm.chinese') as string;
                        // }
                        this.formLabelAlign.simpleAddress = Data.simpleAdressId;
                        this.formLabelAlign.licenseUrl = Data.licenseUrl;
                        if (this.ImgList.length == 0) {
                            this.ImgList.push({
                                name: "1",
                                url: Data.licenseUrl,
                            });
                        }
                        // if(Data.simpleAddress){
                        const addressId = Data.simpleAdressId.toString();
                        this.city.province = parseInt(
                            addressId.substring(0, 2)
                        );
                        // this.getCity(this.city.province, false);
                        // this.getCity(parseInt(addressId.substring(0, 4)), false);
                        // this.city.theCity = parseInt(addressId.substring(0, 4));
                        // this.city.area = parseInt(addressId);
                        // this.city.province = Data.simpleAddress.split('-')[0]
                        // this.city.theCity = Data.simpleAddress.split('-')[1]
                        // this.city.area = Data.simpleAddress.split('-')[2]
                        // }
                        this.formLabelAlign.detailAddress = Data.detailAddress;
                        this.formLabelAlign.licenseUrl = Data.licenseUrl;
                        this.formLabelAlign.doorUrl = Data.doorUrl;
                        this.formLabelAlign.epidemicUrl = Data.epidemicUrl;
                        this.formLabelAlign.animalBusiness =
                            Data.animalBusiness;
                        if (Data.licenseUrl) {
                            this.dataImg = true;
                        }
                        // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: '', url: Data.licenseUrl}

                        this.treeData = Data.provinceList;
                        this.formLabelAlign.groupCode = [];
                        // this.formLabelAlign.groupCode.push(Data.provinceList[0].id);
                        Data.provinceList[0].name = data.data.simpleAddress;
                        this.formLabelAlign.groupCode.push(
                            Data.provinceList[0].id
                        );
                        // const arr: any = [];
                        // DeepLoop.getDataRoleForDeepLoop(this.treeData, Data.groupCode, arr);
                        // this.formLabelAlign.groupCode = arr;
                        // this.formLabelAlign.groupCode.push(Data.groupCode)
                        // this.formLabelAlign.groupCode.push(0)
                        // this.formLabelAlign.groupCode.push(13)
                    }
                );
            } else {
                this.isInternationaldisabled = false;
            }
        });
    }

    private getDetail2(): void {
        // this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        // this.formLabelAlign.status = 1;
        // this.getTree(() => {
        if (this.$route.query.farmId) {
            this.farmDetail.getDetail2(
                <string>this.$route.query.farmId,
                (data: AxiosData<FarmDetailDetail>) => {
                    const Data = data.data;
                    if (Data.length == 0) {
                        return;
                    }
                    for (var i in Data) {
                        if (Data[i].type == 3) {
                            this.formLabelAlign.pic5 = Data[i].url;
                        }
                        if (Data[i].type == 4) {
                            this.formLabelAlign.pic6 = Data[i].url;
                        }
                        if (Data[i].type == 5) {
                            this.formLabelAlign.pic7 = Data[i].url;
                        }
                        if (Data[i].type == 6) {
                            this.formLabelAlign.pic8 = Data[i].url;
                        }
                        if (Data[i].type == 7) {
                            this.formLabelAlign.pic9 = Data[i].url;
                        }
                        if (Data[i].type == 8) {
                            this.formLabelAlign.pic10 = Data[i].url;
                        }
                        if (Data[i].type == 9) {
                            this.formLabelAlign.pic11 = Data[i].url;
                        }
                    }
                }
            );
        }
        // });
    }

    private cityList0 = [];
    private cityList1 = [];
    private cityList2 = [];
    private city: any = {
        province: "",
        theCity: "",
        area: "",
    };
    private dataImg: boolean = false;

    private getCity(pid: any, flag: boolean = true): void {
        // console.log(1);

        if (!pid) {
            pid = 0;
        }
        // console.log(2);

        new FarmDetail().getCity(<any>pid, (data: CityList) => {
            if (pid == 0) {
                this.cityList0 = data.data;
            } else if (pid < 100 && pid > 10) {
                this.cityList1 = data.data;
                if (flag) {
                    this.city.theCity = "";
                    this.city.area = "";
                }
            } else if (pid > 1000 && pid < 10000) {
                this.cityList2 = data.data;
                if (flag) {
                    this.city.area = "";
                }
            }
            // console.log(this.$refs);
            // this.formLabelAlign.simpleAddress = this.$refs.province.selectedLabel + this.$refs.theCity.selectedLabel + this.$refs.area.selectedLabel
            this.formLabelAlign.simpleAddress = this.$refs.area.value;
        });
    }

    private handleCity(e: any): void {
        this.formLabelAlign.simpleAddress = e;
    }

    /*private setImg(file: any, fileList: any): void {
            var that = this
            const reader = new FileReader()
            let res = reader.readAsDataURL(file.raw)
            reader.onloadend = function() {
                that.dataImg = false
    			// var strBase64 = reader.result.substring(84);
    			var strBase64 = (<string>reader.result).substring(0);
                console.log(strBase64);
                that.formLabelAlign.licenseUrl = strBase64
            }
            console.log(that.formLabelAlign)
            reader.onload = function(e) {
    			console.log(e);
    			// simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc
    		};

        };*/

    public resGroup: Result[] = [];

    public handleCascader(start: any): void {
        if (start) {
            this.getTree();
        }
        // if (start == true) {
        //   this.treeData = []
        //   // console.log(this.resGroup.length);
        //   if (this.resGroup.length == 0) {
        //     const column: Column = new Column();
        //     this.resGroup = this.treeData;
        //     column.getTissuc(undefined, (res: any) => {
        //       // console.log(res, 11111)
        //       this.treeData = res.data;
        //       // this.formLabelAlign.groupCode = []
        //     });
        //   }
        //   if (this.treeData[0].id == this.resGroup[0].id) {
        //     const column: Column = new Column();
        //     this.resGroup = this.treeData;
        //     column.getTissuc(undefined, (res: any) => {
        //       // console.log(res, 11111)
        //       this.treeData = res.data;
        //       this.formLabelAlign.groupCode = [];
        //     });
        //   }
        // } else {
        //   // console.log(this.formLabelAlign.groupCode);
        //   if (this.formLabelAlign.groupCode[0] != "0") {
        //     // this.treeData = this.resGroup;
        //     // this.formLabelAlign.groupCode.push(this.resGroup[0].id);
        //   }
        // }
    }

    public cityChange(ids: any): void {
        // console.log(ids);

        if (ids.length === 1) {
            this.column.getTissuc(ids[ids.length - 1], (res: any) => {
                // console.log(res.data);
                var list = this.treeData;
                list[ids[0] - 11].children = res.data;
                this.treeData = [];
                this.treeData = list;
                // console.log(this.treeData);
            });
        }
    }

    private insuranceCompany: any[] = [];
    private routeform: string = "";

    @Watch("$route")
    public handleRoute(form: any, to: any): void {
        if (to.name != "farm" && form.name == "farmDetail") {
            this.resGroup = [];
            this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
            this.city = new Util().clearObject(this.city);
            this.formLabelAlign.licenseUrl = "";
            this.formLabelAlign.auditStatus = "1";
            this.$nextTick(() => {
                (<any>this.$refs.upload).clearFiles();
            });
            this.dataImg = false;
            this.getDetail();
            this.getDetail2();
            this.ImgList = [];
        }
    }

    // @Watch("formLabelAlign.groupCode")
    // $getInsurance(newVal, oldVal){
    //   // if(newVal != oldVal){
    //     this.insuranceCompany = [];
    //     this.formLabelAlign.insuranceCompanyId = "";
    //   // }

    // }
    private addressNumberArray: any = [];
    private flagBool: boolean = false;
    private testValue(val: any) {
        this.addressNumberArray = this.formLabelAlign.groupCode;
    }

    @Watch("addressNumberArray", { deep: true })
    valueChange(newValue: any, oldValue: any) {
        if (newValue.length < 5) {
            this.flagBool = true;
        } else {
            return false;
        }
    }

    // private flagState(){
    //   if(this.formLabelAlign.groupCode){
    //     this.$message.error('所属机构，必须要选择到镇/街道级别');
    //   }
    // }

    // 删除上传的图片
    removeImg(type: string) {
        this.formLabelAlign[type] = "";
    }

    // 获取上传的图片url
    getImg(url: string, type: string) {
        console.log("----------", url, type);

        this.formLabelAlign[type] = url;
    }

    inputValClear() {
        this.formLabelAlign.name = this.formLabelAlign.name.replace(
            /[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g,
            ""
        );
    }

    //$t('system.farm.feedingTypeDesc')
    private feedingModelList: Array<IFeedingModel> = [];

    //emit $t('system.farm.feedingTypeDesc')
    private EmitFeedingModel(val: string) {
        this.formLabelAlign.feedingType = val;
    }

    // api 请求$t('system.farm.feedingTypeDesc')的接口
    private getFeedingModelData() {
        // 需要补充 getFeedingModel url 如果有参数的话 补充参数
        this.farmDetail.getFeedingModel((res: AxiosData<any>) => {
            let { data } = res;
            console.log(data);
            data.forEach((item: any) => {
                this.feedingModelList.push({
                    id: item.type,
                    name: item.desc,
                });
            });
        });
        // this.feedingModelList.unshift({
        //     id: "",
        //     name: this.$t("common.placeholder.select") as string,
        // });
    }

    get feedingTypePlaceholder() {
        const selectText = this.$t("common.placeholder.select") as string;
        const feedingTypeDesc = this.$t(
            "system.farm.feedingTypeDesc"
        ) as string;
        return selectText + feedingTypeDesc;
    }

    get switchisInternational() {
        return this.formLabelAlign.isInternational == 1
            ? this.$t("system.farm.overseas")
            : this.$t("system.farm.chinese");
    }
}
