









































import { Component, Vue } from "vue-property-decorator";
import DropTree from "@/views/system/AppMenu/components/DropTree.vue";
import ColumnsAndResources from "@/views/system/AppMenu/components/ColumnsAndResources.vue";

import { getInfoByConfTypeId314 } from "@/api/index";
import { appMenu } from "../appfire/components/appAll";
import { DeepLoop, floatJson } from "@/common/deepLoop/deepLoop";
interface treetype {
    id: string;
    parentId: string;
    name: string;
    url: string;
    describe: null;
    status: boolean;
    order: number;
    type: number;
    code: null;
    requirePermission: boolean;
    requireConfig: boolean;
    icon: null;
    deleteFlag: boolean;
    platform: number;
    children: treetype[];
}
@Component({
    components: { DropTree, ColumnsAndResources }
})
export default class AppMenu extends Vue {
    private treeData: treetype[] = []; //左侧树结构
    private fatherColumn: treetype[] = []; //右侧父栏目
    private InfoByConfTypeList: any[] = []; //菜单分组数据
    private isAddNewColumn = true; //新增状态 或者修改状态
    private currentNode: any = {}; //点击当前节点的信息
    private resourceTableData: any[] = [];
    private showSourceFlag = false;
    private TabModel = "user";
    private currentid = "";

    //获取菜单数据
    private getMenuData() {
        new appMenu().getMenu((data: any) => {
            //获取完数据时判断currentNode 的id是否为真 如果为真那么就替换resourceTableData的值
            if (this.currentid) {
                this.getResourcesData(data.data);
            }
            this.treeData = data.data;
            const deepCopy: DeepLoop = new DeepLoop();
            const fatherData = deepCopy.deepCopy(this.treeData);
            fatherData.unshift({
                id: "3",
                name: "无",
                parentId: "3"
            });
            this.currentNode.fatherColumSelect = ["3"];
            this.fatherColumn = fatherData;
        });
    }
    //递归获取最新的resourceTableData
    private getResourcesData(data: any[]) {
        if (data.length) {
            for (let i = 0; i < data.length; i++) {
                if (data[i].id == this.currentid) {
                    this.resourceTableData = data[i].resources;
                } else {
                    this.getResourcesData(data[i].children);
                }
            }
        }
    }

    //新增栏目
    NewColumn() {
        this.isAddNewColumn = true; //修改为新增状
        this.showSourceFlag = false; //不显示资源
        this.TabModel = "user";
        this.currentNode = {
            //重置form
            fatherColumSelect: ["3"],
            fatherColumn: "",
            tab: "",
            name: "", //名称
            url: "", //链接
            order: "", //排序
            describe: "", //备注
            type: "1", //类型app的话永远是1
            platform: "3", //平台(1-web ,3-app平台)
            parentId: "3", //父id 一级的话 web是 ： 1 app是：3
            groupId: "" //314新增菜单属性
        };
    }

    //设置是否是新增
    private setEditAndData(obj: any) {
        console.log(obj);
        const { flag, data, arr, id } = obj;
        this.isAddNewColumn = flag; //当前状态为添加
        this.currentNode = data; //当前node
        this.currentNode.fatherColumSelect = arr; //父栏目回显数据
        this.resourceTableData = data.resources; //父栏目

        this.showSourceFlag = true; //显示资源
        this.currentid = id;
    }
    //删除后置新增
    private SendCurrentNode() {
        this.NewColumn();
    }
    //获取新的菜单数据
    private EmitGetMenuData(newVal: any) {
        this.getMenuData();
    }
    //从子组件接受tabmodel并修改
    private updateTabModel(newTabValue: any) {
        this.TabModel = newTabValue;
    }

    activated() {
        //获取菜单数据
        this.getMenuData();
        //获取菜单分组数据
        getInfoByConfTypeId314((data: any) => {
            this.InfoByConfTypeList = data.data;
        });
    }
}
