var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"merchandiseControl"},[_c('PageBox',[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"80px","inline":""}},[_c('el-form-item',{attrs:{"label":"品牌"}},[_c('SelectComponentsVue',{attrs:{"FatherSelectVal":_vm.form.brandId,"SelectList":_vm.BrandList},on:{"SetSelectListValue":_vm.SetbrandIdVal}})],1),_c('el-form-item',{attrs:{"label":"状态"}},[_c('SelectComponentsVue',{attrs:{"FatherSelectVal":_vm.form.status,"SelectList":_vm.statuList},on:{"SetSelectListValue":_vm.SetStatusVal}})],1),_c('el-form-item',{attrs:{"label":"分类"}},[_c('el-cascader',{attrs:{"options":_vm.TreeOption,"props":{
                        expandTrigger: 'hover',
                        label: 'categoryName',
                        value: 'id',
                    },"filterable":""},on:{"change":_vm.handleChange},model:{value:(_vm.treeSelect),callback:function ($$v) {_vm.treeSelect=$$v},expression:"treeSelect"}})],1),_c('InputConponentsVue',{attrs:{"width":"300px","FatherInputVal":_vm.form.keyword,"PlaceholderText":"请输入：商品编号/商品名称"},on:{"SetInputVal":_vm.SetInputVal}}),_c('el-form-item',[_c('FormBtnSearch',{on:{"handlerSearch":_vm.handlerSearch}}),_c('FormBtnReset',{on:{"handlerReset":_vm.handlerReset}})],1)],1)],1),_c('PageBox',{staticStyle:{"margin-top":"40px"}},[_c('vxe-table',{ref:"table",attrs:{"border":"","highlight-hover-row":"","header-align":"center","resizable":"","start-index":(_vm.tablePage.pageNo - 1) * _vm.tablePage.pageSize,"show-header":true,"data":_vm.tableData}},[_c('vxe-table-column',{attrs:{"align":"center","field":"goodsCode","title":"商品编号"}}),_c('vxe-table-column',{attrs:{"align":"center","field":"goodsName","title":"商品名称"}}),_c('vxe-table-column',{attrs:{"align":"center","field":"labelNumber","title":"分类"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.categoryOneName)+">"+_vm._s(row.categoryTwoName))])]}}])}),_c('vxe-table-column',{attrs:{"align":"center","field":"brandName","title":"品牌"}}),_c('vxe-table-column',{attrs:{"align":"center","field":"createTime","title":"创建时间"}}),_c('vxe-table-column',{attrs:{"align":"center","field":"updateTime","title":"更新时间"}}),_c('vxe-table-column',{attrs:{"align":"center","field":"status","title":"状态"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.status == 0 ? "未上架" : row.status == 1 ? "已上架" : row.status == 2 ? "已下架" : row.status == 3 ? "待审核" : row.status == 4 ? "审核不通过" : ""))])]}}])}),_c('vxe-table-column',{attrs:{"align":"center","field":"groupCodeName","title":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{staticStyle:{"color":"#36BE98"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.goDetail(scope.row)}}},[_vm._v("查看")])]}}])})],1)],1),_c('PageBox',{staticClass:"no-back-color"},[_c('vxe-pager',{ref:"vxePager",staticStyle:{"background-color":"#fff","position":"relative","bottom":"30px","border-radius":"15px"},attrs:{"background":"","current-page":_vm.tablePage.pageNo,"page-size":_vm.tablePage.pageSize,"total":_vm.tablePage.totalCount,"layouts":[
                'PrevPage',
                'JumpNumber',
                'NextPage',
                'Sizes',
                'Total' ]},on:{"page-change":_vm.handlePageChange}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }