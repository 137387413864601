import { BasicsClass } from "@/common/BasicsClass";
import { Util } from "@/common/Views/comm/util";
import { offset } from "highcharts";

export class appUser extends BasicsClass {

	//获取角色列表  /menu/getMenuByRoleId
	public getRoleIdMenu(roleId: any, cb: Function) {
		this.BasicGet(
			"/animal/web/menu/getMenuByRoleIdForApp",
			{ roleId, platform: 3 },
			false,
			false,
			cb
		);
	}

	//修改隶属关系树
	public editTreeUser(form: any, cb: Function) {
		this.BasicPost(
			"/animal/web/appUserManagement/editTreeUser",
			{ ...form },
			true,
			true,
			true,
			cb,
			() => { },
			true
		);
	}

	//获取隶属关系树
	public getbelowUserTree(userId: any, cb: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/belowUserTree",
			{ userId },
			false,
			false,
			cb
		);
	}

	//关联企业
	public getLinkEnterprise(cb?: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/companyList",
			{},
			false,
			false,
			cb
		);
	}

	//养殖场组织机构
	public getFarmData(cb?: Function) {
		this.BasicGet(
			"/animal/web/provinceCityDistrict/getForGroupCode",
			{},
			false,
			false,
			cb
		);
	}

	//选择养殖场
	public getNowFarm(form: any, cb: Function): void {
		this.BasicGet(
			"/animal/web/orderManage/companyList",
			{
				...form,
			},
			false,
			true,
			cb
		);
	}

	//权限角色
	public getUserName(showBoss:any,cb: Function) {
		// platform     	1：web 3:app
		this.BasicGet(
			"/animal/web/appUserManagement/roleList",
			{showBoss:showBoss, platform: 3},
			false,
			true,
			cb
		);
	}

	//关联养殖场
	public getLinkFarm(from?: any, roleId?: any, cb?: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/farmList",
			{ ...from, roleId },
			false,
			true,
			cb
		);
	}
	//关联养殖场2
	public getLinkFarmCom(userId?: any, cb?: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/farmListWithAuth",
			{ userId },
			false,
			true,
			cb
		);
	}

	//关联银行
	public getBankData(from?: any, cb?: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/bankList",
			{ ...from },
			false,
			true,
			cb
		);
	}

	//关联保险
	public getInsuranceData(from?: any, cb?: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/insuranceCompanyList",
			{ ...from },
			false,
			true,
			cb
		);
	}

	//app用户列表
	public getUserList(form: any, tablePage: any, cb: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/userList",
			{ ...form, ...tablePage },
			false,
			false,
			cb
		);
	}
}

export class appAddUser extends BasicsClass {
	public getLinkEnterprise(roleId: number | string, cb: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/companyList",
			{ roleId },
			false,
			false,
			cb
		);
	}

	//添加app用户
	public addUser(form: any, cb: Function) {
		if (form.status == "已启用") {
			form.status = 1;
		}
		if (form.status == "已停用") {
			form.status = 2;
		}
		if (form.status == "已注销") {
			form.status = 3;
		}
		if (form.companyId.length == 0) {
			form.companyId = " ";
		}
		if (form.bankId || form.insuranceCompanyId) {
			delete form.farmIds;
		}
		if (form.roleId == 10) {
			form.groupCode = "0";
		}

		this.BasicPost(
			"/animal/web/appUserManagement/addUser",
			{ ...form },
			true,
			true,
			true,
			cb
		);
	}

	//修改app用户
	public setAppUser(form: any, cb: Function) {
		if (form.status == "已启用") {
			form.status = 1;
		}
		if (form.status == "已停用") {
			form.status = 2;
		}
		if (form.status == "已注销") {
			form.status = 3;
		}
		if (form.bankId || form.insuranceCompanyId) {
			delete form.farmIds;
		}
		if (form.roleId == 10) {
			form.groupCode = "0";
		}
		this.BasicPost(
			"/animal/web/appUserManagement/updateUser",
			{ ...form },
			true,
			true,
			true,
			cb
		);
	}

	//手机号查重
	public repeatNum(mobile: any, cb: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/ifHasMobile",
			{ mobile },
			false,
			false,
			cb
		);
	}
}

export class appMenu extends BasicsClass {
	//获取菜单
	public getMenu(cb: Function, errcb?: any) {
		this.BasicGet(
			"/animal/web/menu/treeList",
			{ platform: 3 },
			false,
			false,
			cb,
			errcb,
			true
		);
	}

	//添加菜单
	public addMenu(form: any, cb: Function) {
		this.BasicPost(
			"/animal/web/menu/insert",
			{ ...form },
			true,
			true,
			true,
			cb,
			() => { },
			true
		);
	}

	//删除菜单
	public delMenu(id: string | number, cb: Function) {
		this.BasicPost("/animal/web/menu/delete", { id }, false, false, false, cb);
	}

	//拖拽菜单
	public menuDrag(form: any[], cb: Function) {
		this.BasicPost(
			"/animal/web/appUserManagement/menuDrag",
			form,
			false,
			false,
			true,
			cb,
			() => { },
			true
		);
	}

	//修改菜单
	public setMenu(form: any, cb?: Function) {
		this.BasicPost(
			"/animal/web/menu/update",
			{ ...form },
			true,
			true,
			true,
			cb
		);
	}
}

export class appJurisdiction extends BasicsClass {
	public getRole(showBoss:any,cb: Function) {
		this.BasicGet(
			"/animal/web/appUserManagement/roleList",
			{ platform: 3,showBoss:showBoss },
			false,
			false,
			cb
		);
	}

	

	//获取角色列表  /menu/getMenuByRoleId
	public getUserTree(roleId: any, cb: Function) {
		this.BasicGet(
			"/appUserManagement/belowUserTree",
			{ roleId, platform: 3 },
			false,
			false,
			cb
		);
	}

	public savePermission(form: any, cb?: Function) {
		let menuId: any = [];
		if (form.menuIds.length == 0) {
			menuId = " ";
		} else {
			menuId = form.menuIds.join(",");
		}

		this.BasicPost(
			"/animal/web/role/savePermissionNew",
			{
				roleId: form.roleId,
				menuIds: menuId,
				platform: "3",
			},
			true,
			true,
			false,
			cb
		);
	}
}
