






























































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Column, Result } from "@/common/Views/system/column/column";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { Farm, FarmForm, FarmTable } from "@/common/Views/system/farm/farm";
import { TableStudentData } from "@/common/Views/basics/studentManage/studentManage";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
import {
    getprovinceCityDistrictForGroupCode,
    getFarm_List319,
    getSystemAllFarmList,
    getSystemAllCompanyList
} from "@/api/index";

@Component
export default class HelloWorld extends Vue {
    public column = new Column();
    private dialogFormVisible: boolean = false;
    private form: FarmForm = {
        groupCode: "",
        name: "",
        platform: "",
        isInternational: ""
    };
    private forms: any = {
        id: "",
        companyType: "1"
    };
    private activated(): void {
        this.getTree(() => {
            this.getTable();
        });
        this.getSystemAllFarmListData();
        this.getSystemAllCompanyListData();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    private treeData: Result[] = [];
    private getTree(cb?: Function): void {
        getprovinceCityDistrictForGroupCode((data: any) => {
            this.treeData = new DeepLoop().deepCopy(data.data);
            this.form.isInternational = data.data[0].isInternational;
            this.form.groupCode = data.data[0].id;
            cb ? cb() : "";
        });
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.getTable();
    }

    private changemarkType(): void {
        new Farm().changemarkType(this.forms, (res: any) => {
            this.dialogFormVisible = false;
            this.forms.companyType = "1";
            this.getTable();
        });
    }
    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        this.getTable();
        this.getSystemAllFarmListData();
    }

    private tableData: FarmTable[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500]
    };

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }

    private farm: Farm;

    constructor() {
        super();
        this.farm = new Farm();
    }

    private getTable(): void {
        this.tableData = [];
        getFarm_List319(this.form, this.tablePage, (data: any) => {
            this.tablePage.totalCount = data.page.totalCount;
            this.tableData = data.data;
        });
    }

    private addFarm(): void {
        this.$router.push("/farmDetail");
    }

    private goDetail(row: FarmTable): void {
        (window as any).isInternational = (row as any).isInternational;
        this.$router.push({
            path: "/farmDetail",
            query: {
                farmId: row.id
            }
        });
    }

    private changetype(row: FarmTable): void {
        this.dialogFormVisible = true;
        this.forms.id = row.id;
    }
    private goAudit(row: FarmTable): void {
        this.$router.push({
            path: "/farmAudit",
            query: {
                farmAudit: row.id
            }
        });
    }

    private FarmList: any[] = [];
    private getSystemAllFarmListData() {
        getSystemAllFarmList((data: any) => {
            this.FarmList = data.data;
        });
    }

    private CompanyList: any[] = [];
    private getSystemAllCompanyListData() {
        getSystemAllCompanyList((data: any) => {
            this.CompanyList = data.data;
        });
    }
}
