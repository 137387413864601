import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { Util } from '@/common/Views/comm/util';
import { DeepLoop } from '@/common/deepLoop/deepLoop';

export interface TraceabilityOutManageForm {
    groupCode: string[];
    farmId: string;
    harmlessId: string;
    slaughterId: string;
    labelNumber: string;
    destinationType: string;
    createTime: string;
    verifyTime: string;
    status: string;
    checkTime: Date;
    DealWith: string;
    createStartTime: string,
    createEndTime: string,
    delStartTime: string,
    delEndTime: string,
}

export interface OutManageTable {
    count: number;
    createTime: string;
    destinationName: string;
    farmName: string;
    groupName: string;
    id: string;
    status: number;
    typeName: string;
    verifyTime: string;
    verifyUser: string;
    animalId: string;
    statusMsg: string;
}

export interface OutManageDetail {
    animalId: string;
    count: number;
    createTime: string;
    destinationName: string;
    farmName: string;
    groupName: string;
    id: string;
    pictures: string;
    status: number;
    statusMsg: string;
    typeName: string;
    verifyTime: string;
    verifyUser: string;
    animalCount: number;
    checkTime: string;
    checkUser: string;
    checkUserName: string;
    labelNos: string;
}

export class TraceabilityOutManage extends BasicsClass {

    //出栏管理

    public getTable(form: TraceabilityOutManageForm, page: HandleTable, cb: Function) {

        var path = ''
        let startTime = '';
        let endTime = '';
        if (this.$router.app.$route.name == "slaughterExamine") {
            path = '/animal/web/Traceability/inspectionList'
            if (form.checkTime) {
                console.log(form.checkTime)
                startTime = form.checkTime.getFullYear() + '-' + (form.checkTime.getMonth() + 1) + '-' + form.checkTime.getDate() + ' ' + '00:00:00'
                endTime = form.checkTime.getFullYear() + '-' + (form.checkTime.getMonth() + 1) + '-' + (form.checkTime.getDate() + 1) + ' ' + '00:00:00'
            }

        } else if (this.$router.app.$route.name == "harmlessManage") {
            path = '/animal/web/harmlessManage/harmlessList'
        } else {
            path = '/animal/web/traceability/outManageList'
        }
        // var time = Util.handleStartTimeAndEndTime(form.checkTime)
        // console.log(time)

        this.getTableList({
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime,
            endTime,
            checkTime: NaN,
            // }, page, path, cb);
            createTime: Util.dateUtil(form.createTime),
            verifyTime: Util.dateUtil(form.verifyTime),
        }, page, path, cb);
    }


    //死亡登记
    public getdeath(form: any, page: HandleTable, cb: Function) {

        var path = '/animal/web/deathAuditMark/deathAuditMarkList'
        let startTime = '';
        let endTime = '';
        if (form.checkTime.length > 0) {
            console.log(form.checkTime)
            startTime = form.checkTime[0].getFullYear() + '-' + (form.checkTime[0].getMonth() + 1) + '-' + form.checkTime[0].getDate() + ' ' + '00:00:00'
            endTime = form.checkTime[1].getFullYear() + '-' + (form.checkTime[1].getMonth() + 1) + '-' + (form.checkTime[1].getDate() + 1) + ' ' + '00:00:00'
        }
        this.getTableList({
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime,
            endTime,
            checkTime: NaN,
            // }, page, path, cb);
            createTime: Util.dateUtil(form.createTime),
            verifyTime: Util.dateUtil(form.verifyTime),
        }, page, path, cb);
    }

    public export2(form: any) {
        var path = '/animal/web/deathAuditMark/exportDeathAuditMarkList'
        let startTime = '';
        let endTime = '';
        if (form.checkTime.length > 0) {
            console.log(form.checkTime)
            startTime = form.checkTime[0].getFullYear() + '-' + (form.checkTime[0].getMonth() + 1) + '-' + form.checkTime[0].getDate() + ' ' + '00:00:00'
            endTime = form.checkTime[1].getFullYear() + '-' + (form.checkTime[1].getMonth() + 1) + '-' + (form.checkTime[1].getDate() + 1) + ' ' + '00:00:00'
        }
        // const deepForm = new DeepLoop().deepCopy(form);
        // deepForm.createTime = Util.dateUtil(form.createTime);
        // deepForm.verifyTime = Util.dateUtil(form.verifyTime);
        // form.groupCode = form.groupCode[form.groupCode.length - 1],

            this.getFile(path, form);
    }








    public export(form: TraceabilityOutManageForm) {
        var path = ''
        let startTime = '';
        let endTime = '';
        const deepForm = new DeepLoop().deepCopy(form);

        if (this.$router.app.$route.name == "slaughterExamine") {
            path = '/animal/web/Traceability/exportInspection'
            if (form.checkTime) {
                console.log(form.checkTime)
                startTime = form.checkTime.getFullYear() + '-' + (form.checkTime.getMonth() + 1) + '-' + form.checkTime.getDate() + ' ' + '00:00:00'
                endTime = form.checkTime.getFullYear() + '-' + (form.checkTime.getMonth() + 1) + '-' + (form.checkTime.getDate() + 1) + ' ' + '00:00:00'
                deepForm.startTime = startTime
                deepForm.endTime = endTime
            }

            deepForm.checkTime = NaN
        } else if (this.$router.app.$route.name == "harmlessManage") {
            path = '/animal/web/harmlessManage/exportHarmlessList'
            deepForm.createTime = Util.dateUtil(form.createTime);
            deepForm.verifyTime = Util.dateUtil(form.verifyTime);
        } else {
            path = '/animal/web/traceability/outManageListExport'
            deepForm.createTime = Util.dateUtil(form.createTime);
            deepForm.verifyTime = Util.dateUtil(form.verifyTime);
        }
        // createTime: Util.dateUtil(form.createTime),
        //     verifyTime: Util.dateUtil(form.verifyTime),


        this.getFile(path, deepForm);
    }

    public getDetail(id: string, cb: Function) {
        console.log(this.$router.app.$route)
        var path = ''
        if (this.$router.app.$route.name == "slaughterExamineDetail") {
            path = '/animal/web/Traceability/checkKillDetail'
        } else if (this.$router.app.$route.name == "harmlessManageDetail") {
            path = '/animal/web/harmlessManage/getHarmless'
        } else {
            path = '/animal/web/traceability/outManageDetail'
        }

        this.BasicGet(path, { id }, false, true, cb);
    }



    //屠宰查验

}
