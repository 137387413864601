import {BasicsClass} from '@/common/BasicsClass';
import {BenchmarkSetManageFrom} from '../benchmarkSetManage/benchmarkSetManage';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {AxiosData} from '@/common/http/http';
import {ModelArgsManage} from '@/common/Views/aiModel/modelArgsManage/modelArgsManage';

export interface ArgsBindManageForm extends BenchmarkSetManageFrom {
    typeId: string;
    modelId: string;
    groupId: string;
}

export class ArgsBindManage extends BasicsClass {

    public getTable(form: ArgsBindManageForm, tablePage: HandleTable, cb: Function) {
        this.BasicGet('/animal/web/modelBind/list', {
            ...form,
            ...tablePage,
        }, false,true, (data: AxiosData<any[]>)=>{
            cb(ModelArgsManage.handleArgsResult(data));
        });
    }

    public bind(form: ArgsBindManageForm, cb: Function) {
        this.BasicPost('/animal/web/modelBind/bind', form, true, true, true, cb);
    }

    public unBind(form: ArgsBindManageForm, cb: Function) {

        this.BasicPost('/animal/web/modelBind/unBind', form, true, true, true, cb);
    }
}
