import { render, staticRenderFns } from "./modelArgsManageDetail.vue?vue&type=template&id=29a0b76a&scoped=true&"
import script from "./modelArgsManageDetail.vue?vue&type=script&lang=ts&"
export * from "./modelArgsManageDetail.vue?vue&type=script&lang=ts&"
import style0 from "./modelArgsManageDetail.vue?vue&type=style&index=0&id=29a0b76a&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29a0b76a",
  null
  
)

export default component.exports