


















































































































import {Component, Prop, Vue} from "vue-property-decorator";
import { Column, Result } from '@/common/Views/system/column/column';
import { DeepLoop } from '@/common/deepLoop/deepLoop';
import {Harmless, HarmlessForm, HarmlessTable} from "@/common/Views/system/harmless/harmless";
import {TableStudentData} from '@/common/Views/basics/studentManage/studentManage';
import { HandleTable, HandlePageChange } from '@/common/Views/comm/handleTable';
import {AxiosData} from "@/common/http/http";
import { Util } from '@/common/Views/comm/util';

@Component
export default class HelloWorld extends Vue {

    public column = new Column()

    private form: HarmlessForm = {
        groupCode: '',
        name: '',
        platform: '',
        auditStatus: ''
    }

    private activated(): void {
        this.getTree();
        this.getTable();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    public auditStatusList: any = [
        {
            name: '待审核',
            id: '0'
        },
        {
            name: '审核通过',
            id: '1'
        },
        {
            name: '审核拒绝',
            id: '2'
        }
    ]


    private treeData: Result[] = [];
    private getTree(): void {
        if(!this.$getResourceCodes('menu_tree')) return;
        const column: Column = new Column();
        // column.getTreeData('/animal/web/group/treeList').then((result: Result[])=>{
        column.getTreeData('/animal/web/provinceCityDistrict/getForGroupCode').then((result: Result[])=>{

            this.treeData = new DeepLoop().deepCopy(result);

        }).catch(err=>{
            this.$message.error(err.message);
        });
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.getTable();
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        this.getTable();
    }

    private tableData: HarmlessTable[]=[];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }

    private handlePageChange(val: HandlePageChange){

        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }

    private harmless: Harmless;

    constructor() {
        super();
        this.harmless = new Harmless();
    }



    private getTable(): void{
        this.harmless.getTable(this.form, this.tablePage, (data: AxiosData<HarmlessTable[]>)=>{
            console.log(data)
            this.tablePage.totalCount = data.page.totalCount;
            this.tableData = data.data;
        })
    }


    private addHarmless(): void {
        this.$router.push('/harmlessDetail');
    }

    private goDetail(row: HarmlessTable): void {
        this.$router.push({
            path: '/harmlessDetail',
            query: {
                harmlessId: row.id,
            }
        })
    }

    private goAudit(row: HarmlessTable): void {
        this.$router.push({
            path: '/harmlessAudit',
            query: {
                harmlessAudit: row.id,
            }
        })
    }
}
