import {BasicsClass} from '@/common/BasicsClass';
import {Axios} from '@/common/http/http';
import {TableResult} from '@/common/Views/system/role/role';
import {Result} from '@/common/Views/system/column/column';
import {DeepLoop} from '@/common/deepLoop/deepLoop';
import {Util} from '@/common/Views/comm/util';

export interface PersonalDetail<T> {
    username: T;
    password: T;
    // identifier: T;
    name: T;
    mobile: T;
    email: T;
    // schoolName: T;
    roleList?: T[];
    // dataRole?: T[][];
    status?: number;
    typeId?:T;
    // schoolComBoxId?: T;
    // level?: T;
    // type?: T;
    roleIds?: T;
    farmIds: T[] | string;
    groupCode: T[];
    // dataComBoxList?: T[][];
}

enum SchoolGradeClassE {
    'groupId' = 1,
    'schoolIds',
    'gradeIds',
    'classIds',
}

interface SendRole<T> {
    groupId: T;
    schoolIds: T;
    gradeIds: T;
    classIds: T;
}

export interface SchoolGradeClass<T> {
    id: T;
    type: number;
    name: T;
    disabled: boolean;
}

export interface ResultSchoolGradeClass<T> extends SchoolGradeClass<T> {
    children: ResultSchoolGradeClass<T>[];
}

export class PersonalDetailC extends BasicsClass {
    private axios: Axios;

    constructor() {
        super();
        this.axios = new Axios();
    }

    public async getRoleList( platform: number = 1): Promise<Array<TableResult<string>>> {
        const axios: Axios = new Axios();
        let result: Array<TableResult<string>>;
        try {
            const data = await axios.get<Array<TableResult<string>>>('/animal/web/role/selectAll', { platform});
            console.log(data)
            result = data.data;
        } catch {
            result = [];
        }
        return result;
    }

    public async getRoleResource(roleId: string): Promise<Result[]> {
        let result: Result[];
        try {
            const data = await this.axios.get<Result[]>('/animal/web/menu/getMenuByRoleId', {
                roleId,
            });
            result = data.data;
        } catch (e) {
            this.vue.$message.error(e.message);
            result = [];
        }
        return result;
    }

    // public async getAllSchoolList() {
    //     const axios: Axios = new Axios();
    //     let result: Array<SchoolGradeClass<string>> = [];
    //     try {
    //         const data = await axios.get<Array<SchoolGradeClass<string>>>('/edu/data/eduSchool/currentCreateSchools', {});
    //         result = data.data;
    //     } catch (e) {
    //         this.vue.$message.error(e.message);
    //         result = [];
    //     }
    //     return result;
    // }

    // public async getDataPower(level: string) {
    //     // const [schoolId, type] = schoolIds.split('---');
    //
    //     let result: Array<ResultSchoolGradeClass<string>>;
    //     try {
    //         const data = await this.axios.get<Array<ResultSchoolGradeClass<string>>>('/edu/sys/user/dataPermissionTree', {
    //             level
    //         });
    //         result = data.data;
    //     } catch (e) {
    //         this.vue.$message.error(e.message);
    //         result = [];
    //     }
    //     return result;
    // }

    public submit(formData: PersonalDetail<string>, callBack: Function, errCallBack: Function, id: string = '', platform: number=1) {
        // const content: SendRole<string[]> = this.handleContent(resultIn, formData);

        // const dataRole: string[] = this.handleSubmitArgument(formData);

        this.AddOrUpdate(id, {
            ...formData,
            platform,
            roleIds: formData.roleList!.length ? formData.roleList!.join(',') : '',
            groupCode: formData.groupCode[formData.groupCode.length - 1],
            farmIds: formData.farmIds.toString(),
            provinceList: NaN
            // relativeIds: dataRole.join(','),
        }, '/animal/web/user/update', '/animal/web/user/insert', callBack, () => {
            if (id) {
                errCallBack();
            }
        });

        // const url = id ? '/edu/sys/user/update' : '/edu/sys/user/insert';
        //
        // this.axios.post<string>(url, {
        //     ...formData,
        //     schoolIds: content.schoolIds.join(','),
        //     gradeIds: content.gradeIds.join(','),
        //     groupId: content.groupId.join(','),
        //     classIds: content.classIds.join(','),
        //     roleIds: formData.roleList!.join(','),
        //     id: new Util().getId(id),
        // }, true).then((data) => {
        //     callBack(data.message);
        // }).catch((err) => {
        //     this.vue.$message.error(err.message);
        // });


    }

    // private handleSubmitArgument(result: PersonalDetail<string>): string[] {
    //     const data: PersonalDetail<string> = new DeepLoop().deepCopy(result);
    //
    //     const R: string[] = [];
    //
    //     for (const i in data.dataRole) {
    //
    //         R.push(data.dataRole[parseInt(i)][data.dataRole[parseInt(i)].length - 1]);
    //     }
    //
    //     return R;
    // }

    public async getPersonalInfo(id: string | Array<string | null>, platform: number = 1) {
        let result: PersonalDetail<string>;
        try {
            const data = await this.axios.get<PersonalDetail<string>>('/animal/web/user/get', {
                id,
                platform,
            });

            result = data.data;
        } catch (e) {
            this.vue.$message.error(e.message);
            result = {
                username: '',
                password: '',
                // identifier: '',
                name: '',
                mobile: '',
                email: '',
                // schoolName: '',
                // roleList: [],
                // dataRole: [],
                status: 1,
                groupCode: [],
                farmIds: [],
            };
        }

        return result;
    }

    // private handleContent(resultIn: Array<ResultSchoolGradeClass<string>>, formData: PersonalDetail<string>): SendRole<string[]> {
    //     const deepFlatJson: DeepLoop = new DeepLoop();
    //     const deepType = deepFlatJson.deepFlatJson(resultIn, 'id', 'type');
    //
    //     const a: Set<string> = new Set();
    //     for (let i = 0; i < formData.dataRole!.length; i++) {
    //         for (let j = 0; j < formData.dataRole![i].length; j++) {
    //             a.add(formData.dataRole![i][j]);
    //         }
    //     }
    //     const data: string[] = [...a];
    //
    //     const result: SendRole<string[]> = {
    //         gradeIds: [],
    //         schoolIds: [],
    //         groupId: [],
    //         classIds: [],
    //     };
    //
    //     for (const i in data) {
    //
    //         if (deepType[data[i]].toString() === '2') {
    //             result.schoolIds.push(data[i]);
    //         } else if (deepType[data[i]].toString() === '3') {
    //             result.gradeIds.push(data[i]);
    //         } else if (deepType[data[i]].toString() === '4') {
    //             result.classIds.push(data[i]);
    //         }
    //     }
    //
    //     const schoolId = formData.schoolName.split('---');
    //     if (schoolId[1] === '1') {
    //         result.groupId.push(schoolId[0]);
    //     } else if (schoolId[1] === '2') {
    //         result.schoolIds.push(schoolId[0]);
    //     }
    //
    //
    //     return result;
    // }

    public getLevelList(callback: Function): void {

        this.getTableList({}, {}, '/edu/sys/user/levelList ', callback);
    }

    public handleDataPower(roleList: ResultSchoolGradeClass<string>[], dataRole: string[]): string[][] {


        const result: string[][] = [];

        for (const i in dataRole) {
            const arr: string[] = [];

            DeepLoop.getDataRoleForDeepLoop(roleList, dataRole[i], arr);

            result.push(arr);
        }

        return result;
    }

    // private getDataRoleForDeepLoop(roleList: ResultSchoolGradeClass<string>[], dataRole: string, arr: string[]): any {
    //     for (const i in roleList) {
    //
    //         if (!roleList[i].disabled) {
    //
    //             if (roleList[i].children && roleList[i].children.length) {
    //                 arr.push(roleList[i].id);
    //                 let result = this.getDataRoleForDeepLoop(roleList[i].children, dataRole, arr);
    //                 if (result) {
    //                     return result;
    //                 }
    //             } else {
    //                 if (roleList[i].id === dataRole) {
    //                     arr.push(roleList[i].id);
    //                     return arr;
    //                 }
    //             }
    //         }
    //
    //     }
    //     arr.pop();
    // }

}

export class PersonalDetailAPP extends BasicsClass {
    private axios: Axios;

    constructor() {
        super();
        this.axios = new Axios();
    }

    public async getRoleList( platform: number = 1): Promise<Array<TableResult<string>>> {
        const axios: Axios = new Axios();
        let result: Array<TableResult<string>>;
        try {
            const data = await axios.get<Array<TableResult<string>>>('/animal/web/role/selectAll', { platform});
            result = data.data;
        } catch {
            result = [];
        }
        return result;
    }

    public async getRoleResource(roleId: string): Promise<Result[]> {
        let result: Result[];
        try {
            const data = await this.axios.get<Result[]>('/animal/web/menu/getMenuByRoleId', {
                roleId,
            });
            result = data.data;
        } catch (e) {
            this.vue.$message.error(e.message);
            result = [];
        }
        return result;
    }

    public submit(formData: PersonalDetail<string>, callBack: Function, errCallBack: Function, id: string = '', platform: number=1) {
        // const content: SendRole<string[]> = this.handleContent(resultIn, formData);

        // const dataRole: string[] = this.handleSubmitArgument(formData);

        this.AddOrUpdate(id, {
            ...formData,
            platform,
            roleIds: !!(formData.roleList!.length) && formData.roleList!.join(','),
            groupCode: formData.groupCode[formData.groupCode.length - 1],
            farmIds: formData.farmIds.toString(),
            provinceList: NaN
            // relativeIds: dataRole.join(','),
        }, '/animal/web/user/update', '/animal/web/user/insert', callBack, () => {
            if (id) {
                errCallBack();
            }
        });
    }

    public async getPersonalInfo(id: string | Array<string | null>, platform: number = 1) {
        let result: PersonalDetail<string>;
        try {
            const data = await this.axios.get<PersonalDetail<string>>('/animal/web/user/get', {
                id,
                platform,
            });

            result = data.data;
        } catch (e) {
            this.vue.$message.error(e.message);
            result = {
                username: '',
                password: '',
                // identifier: '',
                name: '',
                mobile: '',
                email: '',
                // schoolName: '',
                // roleList: [],
                // dataRole: [],
                status: 1,
                groupCode: [],
                farmIds: [],
            };
        }

        return result;
    }

    public getLevelList(callback: Function): void {

        this.getTableList({}, {}, '/edu/sys/user/levelList ', callback);
    }

    public handleDataPower(roleList: ResultSchoolGradeClass<string>[], dataRole: string[]): string[][] {


        const result: string[][] = [];

        for (const i in dataRole) {
            const arr: string[] = [];

            DeepLoop.getDataRoleForDeepLoop(roleList, dataRole[i], arr);

            result.push(arr);
        }

        return result;
    }

}


