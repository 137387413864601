import { BasicsClass } from '@/common/BasicsClass';

export interface merchantForm {
  merchantName: string;
}

export class merchant extends BasicsClass {
  //获取供应商列表
  public getMerchantList(form: merchantForm, page: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/merchant/page', { ...form, ...page }, false, false, cb);
  }
  //获取供应商列表
  public getMerchantListAll(cb: Function) {
    this.BasicGet('/animal/web/ordering/merchant/listAll', {}, false, false, cb);
  }
  //查询供应商信息
  public getMerchantInfo(id: any, cb: Function) {
    this.BasicGet('/animal/web/ordering/merchant/getInfo', { id }, false, false, cb);
  }
  //改变启用状态
  public changestatusBoolean(id: string, status: number, cb: Function): void {
    this.BasicGet('/animal/web/ordering/merchant/updateStatus', {
      id: id,
      status: status
    }, false, false, cb)
  }
  //新增供应商
  public addMerchant(form: any, cb: any): void {
    this.$headers['Content-Type'] = 'multipart/form-data';
    let fData = new FormData()
    let merchantReqDto = {}
    merchantReqDto = {
      merchantName: form.merchantName,
      mchNo: form.mchNo,
      apiKey: form.apiKey,
      contacts: form.contacts,
      mobile: form.mobile,
      logo: form.logo,
      description: form.description,
    }
    if (form.publicKeyFile) {
      fData.append("publicKeyFile", form.publicKeyFile);
    }
    if (form.privateKeyFile) {
      fData.append("privateKeyFile", form.privateKeyFile);
    }
    fData.append("merchantReqDto", JSON.stringify(merchantReqDto));
    console.log(this.$headers,'13222222222')
    this.vue.$axios.post("/animal/web/ordering/merchant/save",
      fData,
      {
        headers: this.$headers,
      }).then((res: any) => {
        cb(res.data)
      })
  }
  //修改供应商
  public updateMerchant(form: any, cb: any): void {
    this.$headers['Content-Type'] = 'multipart/form-data';
    let fData = new FormData()
    let merchantReqDto = {}
    merchantReqDto = {
      merchantName: form.merchantName,
      mchNo: form.mchNo,
      apiKey: form.apiKey,
      contacts: form.contacts,
      mobile: form.mobile,
      logo: form.logo,
      description: form.description,
      id: form.id,
    }
    if (form.publicKeyFile) {
      fData.append("publicKeyFile", form.publicKeyFile);
    }
    if (form.privateKeyFile) {
      fData.append("privateKeyFile", form.privateKeyFile);
    }

    fData.append("merchantReqDto", JSON.stringify(merchantReqDto));
    console.log(this.$headers,'13222222222')
    this.vue.$axios.post("/animal/web/ordering/merchant/update",
      fData,
      {
        headers: this.$headers,
      }).then((res: any) => {
        cb(res.data)
      })
  }

}
