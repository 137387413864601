












import { Component, Prop, Watch, Vue } from "vue-property-decorator";
@Component({})
export default class FarmLabelNumber extends Vue {
    @Prop() FormData: any;
    @Prop() TitleLabelNumber: any;

    private labelNumber: any = "";

    get placeholderString() {
        return this.$t("common.placeholder.enter") ;
    }
    private changeInput() {
        this.$emit("changLabelNumber", this.labelNumber);
    }

    @Watch("FormData.labelNumber")
    private changeFormData(newVal: any) {
        this.labelNumber = newVal;
    }
}
