



































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Result, Column } from "@/common/Views/system/column/column";

import { DeepLoop } from "@/common/deepLoop/deepLoop";

import { optionSelect, Rule } from "@/common/Views/comm/formRule";

import { ConfType } from "@/common/Views/system/confType/confType";

import { SchoolList } from "@/common/Views/basics/schoolManage/schoolManage";

import { AxiosData } from "@/common/http/http";

import {
    HarmlessDetailForm,
    HarmlessDetail,
    HarmlessDetailDetail,
    CityList,
} from "@/common/Views/system/harmless/harmlessDetail/harmlessDetail";

import { Util } from "@/common/Views/comm/util";

import TitleSolt from "@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue";

import { Firm } from "@/common/Views/system/firm/firm";

import { getGroupCodeForUser319 } from "@/api/index";

@Component({
    components: { TitleSolt },
})
export default class HelloWorld extends Vue {
    private column = new Column();

    private form: any = {
        contactPhone: "",

        contactPerson: "",

        enableStatus: 1,

        name: "",

        groupCode: [],

        detailAddress: "",

        companyType: "",

        companyBreedType: "1",

        auditStatus: "",

        id: "",

        newGroupCode: "",

        isInternational: "",
    };

    private IsAddShow: boolean = false;
    private isInternationaldisabled: boolean = false;

    private rules: any = {
        contactPhone: [
            { required: true, message: "请填写联系电话" },

            // {

            //     message: "手机号码输入错误", validator: (rule: any, value: any, callback: any) => {

            //         if (!/^[0-9]{11}$/g.test(value)) {

            //             callback(new Error());

            //         } else {

            //             callback();

            //         }

            //     }

            // },
        ],

        contactPerson: [{ required: true, message: "请填写联系人" }],

        enableStatus: [{ required: true, message: "请选择启用状态" }],

        name: [{ required: true, message: "请填写企业名称" }],

        // typeIds: [

        //     // @ts-ignore

        //     {type: "array", required: true, message: "请添加养殖类型"}

        // ],

        simpleAddress: [{ required: true, message: "请选择城市" }],

        detailAddress: [{ required: true, message: "请填写详细地址" }],

        companyType: [{ required: true, message: "请选择企业类型" }],
        companyBreedType: [{ required: true, message: "请选择养殖企业类型" }],

        isInternational: [{ required: true, message: "请选择企业注册地" }],

        // typeIds: [],
    };

    private propsObj: any = {
        lazy: true,
        expandTrigger: "hover",
        value: "id",
        label: "name",
        lazyLoad(node: any, resolve: any) {
            getGroupCodeForUser319(
                node.value,
                (window as any).isInternational,
                (res: any) => {
                    try {
                        let nodes = res.data;
                        nodes.forEach((item: any) => {
                            item.leaf = item.level === 4;
                        });
                        resolve(nodes);
                    } catch (e) {}
                }
            );
        },
    };

    //组织机构

    private treeData: Result[] = [];

    private props: any = {
        checkStrictly: true,

        expandTrigger: "hover",

        hoverThreshold: undefined,

        label: "name",

        value: "id",
    };

    private getTree(cb?: Function): void {
        getGroupCodeForUser319(
            undefined,
            this.form.isInternational,
            (data: any) => {
                this.treeData = data.data;
            }
        );
    }

    private changeGroupCode(val: any) {
        // this.form.newGroupCode = val[val.length-1] + ''
    }

    private statusOptions: any = [
        {
            value: 1,

            label: "启用",
        },

        {
            value: 2,

            label: "禁用",
        },
    ];

    //保存

    private firm: Firm = new Firm();

    private save() {
        (this.$refs.form as any).validate((valid: boolean) => {
            if (valid) {
                let FormDataLany = JSON.parse(JSON.stringify(this.form));

                if (FormDataLany.auditStatus == "待审核") {
                    FormDataLany.auditStatus = "0";
                } else {
                    this.writeStatus = true;
                }

                if (FormDataLany.auditStatus == "审核通过") {
                    FormDataLany.auditStatus = "1";
                }

                if (FormDataLany.auditStatus == "审核拒绝") {
                    FormDataLany.auditStatus = "2";
                }

                // console.log(this.form)

                if (this.AddOrSet) {
                    //tianjia

                    console.log(this.form);

                    this.firm.submit100(FormDataLany, () => {
                        this.$router.go(-1);
                    });
                } else {
                    //set

                    this.firm.submit101(FormDataLany, () => {
                        this.$router.go(-1);
                    });
                }
            }
        });
    }

    //watch

    private disabled: boolean = false;

    private AddOrSet: boolean = true;

    private writeStatus: boolean = false;

    @Watch("$route", { deep: true, immediate: true })
    private chageRouterAdd(newVal: any, oldVal: any) {
        if (newVal.query.item) {
            let routeQueryItem: any = JSON.parse(newVal.query.item);

            console.log(routeQueryItem);

            this.form.name = routeQueryItem.name;

            this.form.contactPerson = routeQueryItem.contactPerson;

            this.form.contactPhone = routeQueryItem.contactPhone;

            this.form.enableStatus = routeQueryItem.enableStatus;

            this.form.newGroupCode = routeQueryItem.groupCode;

            this.form.detailAddress = routeQueryItem.detailAddress;

            if (routeQueryItem.isInternational == 1) {
                this.form.isInternational = "海外";
            } else {
                this.form.isInternational = "中国";
            }

            this.$nextTick(() => {
                this.form.companyType = routeQueryItem.companyType;
                if (this.form.companyType == 1) {
                    this.form.companyType = "养殖场企业";
                }
                if (this.form.companyType == 2) {
                    this.form.companyType = "屠宰场企业";
                }
                if (this.form.companyType == 4) {
                    this.form.companyType = "牲畜买家";
                }
                if (this.form.companyType == 5) {
                    this.form.companyType = "内部角色企业";
                }
                this.form.companyBreedType = routeQueryItem.companyBreedType;
                if (this.form.companyBreedType == 1) {
                    this.form.companyBreedType = "规模养殖企业";
                }
                if (this.form.companyBreedType == 2) {
                    this.form.companyBreedType = "合作社(村集体)";
                }
                this.$forceUpdate;
            });

            this.form.id = routeQueryItem.id;

            //待审核

            this.form.auditStatus = routeQueryItem.auditStatus;

            if (this.form.auditStatus == 0) {
                this.writeStatus = false;

                this.form.auditStatus = "待审核";
            } else {
                this.writeStatus = true;
            }

            if (this.form.auditStatus == 1) {
                this.form.auditStatus = "审核通过";
            }

            if (this.form.auditStatus == 2) {
                this.form.auditStatus = "审核拒绝";
            }

            if (routeQueryItem.companyType == 1) {
                this.form.companyType = "养殖场企业";
            }

            if (routeQueryItem.companyType == 2) {
                this.form.companyType = "屠宰场企业";
            }

            if (routeQueryItem.companyType == 3) {
                this.form.companyType = "无害化企业";
            }

            if (routeQueryItem.companyType == 4) {
                this.form.companyType = "牲畜买家";
            }

            if (routeQueryItem.companyType == 5) {
                this.form.companyType = "内部角色企业";
            }
        }
    }

    mounted() {
        // this.getTree()
    }

    private groupText: any = "";

    private NoneStatus: boolean = true;

    activated() {
        this.writeStatus = false;

        this.disabled = false;

        // console.log(this.$route.query)

        console.log(this.$route.query);

        let routeAddFlag: any = this.$route.query.addFlag;

        if (routeAddFlag == true) {
            this.IsAddShow = false;
            this.isInternationaldisabled = false;
            this.NoneStatus = false;

            //初始化

            // alert(111)

            let domsVc: any = this.$refs.cascaderRef;
            domsVc.$el.children[0].children[0].value = "";

            this.form.isInternational = "";

            this.form.contactPhone = "";

            this.form.contactPerson = "";

            this.form.enableStatus = 1;

            this.form.name = "";

            (this.form.groupCode = []), (this.form.detailAddress = "");

            this.form.companyType = "";

            this.form.auditStatus = "";

            this.form.id = "";

            this.AddOrSet = true;

            this.form.auditStatus = "1";

            //添加
        } else {
            this.isInternationaldisabled = true;
            this.IsAddShow = true;
            this.NoneStatus = true;

            this.AddOrSet = false;

            this.disabled = true;

            let domsVc: any = this.$refs.cascaderRef;

            //编辑

            if (this.$route.query.item) {
                let activatedData: any = JSON.parse(this.$route.query.item);

                console.log(activatedData);

                domsVc.$el.children[0].children[0].value =
                    activatedData.groupName;
                this.groupText = activatedData.groupName;

                this.form.name = activatedData.name;

                this.form.contactPerson = activatedData.contactPerson;

                this.form.contactPhone = activatedData.contactPhone;

                this.form.enableStatus = activatedData.enableStatus;

                this.form.newGroupCode = activatedData.groupCode;

                this.form.detailAddress = activatedData.detailAddress;

                this.form.companyType = activatedData.companyType;

                this.form.id = activatedData.id;

                if (activatedData.isInternational == 1) {
                    this.form.isInternational = "海外";
                } else {
                    this.form.isInternational = "中国";
                }

                //待审核

                this.form.auditStatus = activatedData.auditStatus;

                if (this.form.auditStatus == 0) {
                    this.writeStatus = false;

                    this.form.auditStatus = "待审核";
                } else {
                    this.writeStatus = true;
                }

                if (this.form.auditStatus == 1) {
                    this.form.auditStatus = "审核通过";
                }

                if (this.form.auditStatus == 2) {
                    this.form.auditStatus = "审核拒绝";
                }

                if (activatedData.companyType == 1) {
                    this.form.companyType = "养殖场企业";
                }

                if (activatedData.companyType == 2) {
                    this.form.companyType = "屠宰场企业";
                }

                if (activatedData.companyType == 3) {
                    this.form.companyType = "无害化企业";
                }

                if (activatedData.companyType == 4) {
                    this.form.companyType = "牲畜买家";
                }

                if (activatedData.companyType == 5) {
                    this.form.companyType = "内部角色企业";
                }
            }
            console.log(this.form);
        }
    }

    private resGroup: any[] = [];
    private handleCascader(start: any): void {
        // if (start == true) {
        //   if (this.resGroup.length == 0) {
        //     getGroupCodeForUser319(undefined,this.form.isInternational,(data:any)=>{
        //         this.treeData = data.data;
        //     })
        //   }
        //   if (this.treeData[0].id == this.resGroup[0].id) {
        //     getGroupCodeForUser319(undefined,this.form.isInternational,(data:any)=>{
        //         this.treeData = data.data;
        //         this.form.groupCode = [];
        //     })
        //   }
        // } else {
        //   if (this.form.groupCode[0] != "0") {
        //     // this.treeData = this.resGroup;
        //     // this.form.groupCode.push(this.resGroup[0].id);
        //     //以上两行代码注释 原因 匹配不到 树结构中的id  后续有问题 请解开排查问题  太忙了 没空排查  应该没什么问题
        //   }
        // }
    }
    private addressNumberArray: any = [];
    private flagBool: boolean = false;

    private testValue(val: any) {
        console.log(this.form.groupCode);
        this.addressNumberArray = this.form.groupCode;
        this.form.newGroupCode = val[val.length - 1] + "";
    }
    @Watch("addressNumberArray", { deep: true })
    valueChange(newValue: any, oldValue: any) {
        console.log("addressNumberArray");
        console.log(newValue, oldValue);
        if (newValue.length < 5) {
            this.flagBool = true;
        } else {
            return false;
        }
    }

    private flagState() {
        if (this.form.groupCode) {
            this.$message.error("所属机构，必须要选择到镇/街道级别");
        }
    }
    private ChangeIsInternational() {
        (window as any).isInternational = this.form.isInternational;
        this.getTree();
    }
}
