































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {
    PersonalDetail,
    PersonalDetailC,
} from "@/common/Views/system/personal/personalDetail";
import {optionSelect, Rule} from '@/common/Views/comm/formRule';
import {TableResult} from '@/common/Views/system/role/role';
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {Util} from "@/common/Views/comm/util";
import {AxiosData} from "@/common/http/http";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import { Result, Column, ComboList } from '@/common/Views/system/column/column';


@Component
export default class HelloWorld extends Vue {


    public column = new Column()

    private personDetail: PersonalDetailC;

    constructor() {
        super();
        this.personDetail = new PersonalDetailC();
    }

    private formLabelAlign: PersonalDetail<string> = {
        username: '',
        password: '',
        // identifier: '',
        name: '',
        mobile: '',
        email: '',
        groupCode: [],
        farmIds: [''],
        roleList: [],
        status: 1,
    }

    private changeFarm(): void {
        if(this.formLabelAlign.farmIds.length > 1){
            if(this.formLabelAlign.farmIds.indexOf('') != -1 && this.formLabelAlign.farmIds.indexOf('') != this.formLabelAlign.farmIds.length-1){
                (<string[]>this.formLabelAlign.farmIds).splice(this.formLabelAlign.farmIds.indexOf(''), 1);
            } else {
                (<string[]>this.formLabelAlign.farmIds).splice(0, this.formLabelAlign.farmIds.indexOf(''))
            }
        }
    }

    private statusOptions: optionSelect<number>[] = [
        {
            value: 1,
            label: '启用',
        },
        {
            value: 2,
            label: '禁用',
        },
        {
            value: 3,
            label: '锁定',
        },
    ];


    private rules: PersonalDetail<Rule[]> = {
        username: [
            {required: true, message: '请输入用户名', trigger: 'blur'},
            {
                message: '用户名必须为字母或数字，长度最长为30', validator: (rule: any, value: any, callback: any) => {
                    if (!/^[0-9A-Za-z]{1,30}$/gi.test(value)) {
                        callback(new Error())
                    } else {
                        callback();
                    }
                }
            },
        ],
        password: [
            {required: true, message: '请输入密码', trigger: 'blur'},
            {min: 6, max: 16, message: '长度在 6 到 16 个字符', trigger: 'blur'}
        ],
        groupCode: [
            // @ts-ignore
            {type: 'array',required: true, message: '请选择所属机构', trigger: 'blur'},
        ],
        name: [
            {required: true, message: '请输入员工姓名', trigger: 'blur'},
        ],
        key: [
            {required: true, message: '请输入AppSecrec', trigger: 'blur'},
        ],
        userMchId: [
            {required: true, message: '请输入商户号', trigger: 'blur'},
        ],
        mobile: [
            {required: true, message: '请输入手机号码', trigger: 'blur'},
            {
                message: '手机号码输入错误', validator: (rule: any, value: any, callback: any) => {
                    if (!/^[0-9]{11}$/g.test(value)) {
                        callback(new Error())
                    } else {
                        callback();
                    }
                }
            },
        ],
        // email: [
        //     {required: true, message: '请输入电子邮箱', trigger: 'blur'},
        //     {
        //         message: '电子邮箱输入错误', validator: (rule: any, value: any, callback: any) => {
        //             if (!/^([0-9A-Za-z\-_\.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g.test(value)) {
        //                 callback(new Error())
        //             } else {
        //                 callback();
        //             }
        //         }
        //     },
        // ],

    }

    // private dataRoleList: ResultSchoolGradeClass<string>[] = [];

    // private schoolNameOptions: SchoolGradeClass<string>[] = [];

    private roleOptions: TableResult<string>[] = [];

    private getAllRole(): void {
        if(this.$getResourceCodes('role_all')){

            new PersonalDetailC().getRoleList(2)
                .then(data => {
                    this.roleOptions = data;
                }).catch(err => {
                this.$message.error(err.message);
            });
        }
    }

    // private getAllSchoolList(): void {
    //     new PersonalDetailC().getAllSchoolList()
    //         .then(data => {
    //             this.schoolNameOptions = data;
    //         })
    // }
    //
    // private getDataPower(callBack?: Function): void {
    //     this.dataRoleList = [];
    //     this.formLabelAlign.dataRole = [];
    //
    //     this.personDetail.getDataPower(this.formLabelAlign.level!)
    //         .then(data => {
    //             this.dataRoleList = new DeepLoop().deepCopy(data, true);
    //             if(callBack) callBack(this.dataRoleList);
    //         }).catch((err)=>{
    //
    //     });
    // }

    // private created(): void {
    //     this.getAllRole();
    //     this.getAllSchoolList();
    //
    //     this.getDetailInfo();
    //
    // }

    private isAdmin: boolean = false;

    // private changeRoleId(): void {
    //     this.isAdmin = false;
    //     label:
    //     for(const i of this.formLabelAlign.roleList!){
    //         for(const j in this.roleOptions){
    //             if( i === this.roleOptions[j].id && this.roleOptions[j].code === 1){
    //                 this.formLabelAlign.userMchId = '超管不需要商户号';
    //                 this.formLabelAlign.key = '超管不需要填写';
    //                 this.isAdmin = true;
    //                 break label;
    //             }
    //
    //         }
    //     }
    //
    //     if(!this.isAdmin && this.formLabelAlign.userMchId === '超管不需要商户号'){
    //         this.formLabelAlign.userMchId = '';
    //         this.formLabelAlign.key = '';
    //     }
    // }

    private activated(): void {
        this.getAllRole();
        // this.getAllSchoolList();

        // this.getLevelList();

        this.isAdmin = false;

        // this.getTree();
        this.changeGroupCode();
        this.getDetailInfo();
        // this.getAllSchoolList();
    }

    private treeData: Result[] = [];

    private getTree(cb?: Function): void {
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        // column.getTreeData("/animal/web/group/treeList").then((result: Result[]) => {

        //     this.treeData = new DeepLoop().deepCopy(result);

        //     if(cb) cb();
        // }).catch(err => {
        //     this.$message.error(err.message);
        // });
        this.column.getTissuc(undefined, (res: any) => {
            this.treeData = res.data
        })
    }


    private comboList: ComboList[] = [];
    private changeGroupCode(cb?: Function): void {
        this.comboList = [];
        this.formLabelAlign.farmIds = [''];
        new Column().getFarm(this.formLabelAlign.groupCode[this.formLabelAlign.groupCode.length - 1], (data: AxiosData<ComboList[]>)=>{
            this.comboList = data.data;
            if(cb) cb();
        }, '100')
    }

    private levelListData: SchoolList<string>[] = [];

    // private getLevelList(): void {
    //     new PersonalDetailC().getLevelList((data: AxiosData<SchoolList<string>[]>) => {
    //         this.levelListData = data.data;
    //     });
    // }


    private save(): void {


        (this.$refs.ruleForm as any).validate((val: boolean) => {
            if (val) {

                const readySendData: PersonalDetail<string> = new DeepLoop().deepCopy(this.formLabelAlign);

                if (this.formLabelAlign.password === '$%#^@@&*(#@#^') {
                    // this.formLabelAlign.password = this.oldPassword;
                    readySendData.password = this.oldPassword;
                }


                this.personDetail.submit(readySendData, (data: string) => {
                    // this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
                    this.$router.go(-1);
                }, (data: string) => {
                //    do not thing
                }, new Util().getId(this.$route.query.weChatPersonalDetailId.toString()), 2);
            }
        })

    }

    private oldPassword: string = '';
    private getDetailInfo(): void {
        if (this.$route.query.weChatPersonalDetailId) {
            this.personDetail.getPersonalInfo(this.$route.query.weChatPersonalDetailId, 2)
                .then(data => {
                    if(data.name){

                        const form: any = this.formLabelAlign;
                        const resultData: any = data;
                        for (const i in resultData) {

                            if (resultData[i]) form[i] = resultData[i];
                        }

                        const arr: any = [];
                        DeepLoop.getDataRoleForDeepLoop(this.treeData, resultData.groupCode, arr);

                        // this.formLabelAlign.groupCode = arr;
                        this.treeData = resultData.provinceList
                        this.formLabelAlign.groupCode = []
                        this.formLabelAlign.groupCode.push(resultData.provinceList[0].id)


                        this.oldPassword = data.password;
                        if (data.roleIds) this.formLabelAlign.roleList = data.roleIds!.split(',');
                        this.formLabelAlign.password = '$%#^@@&*(#@#^';

                        this.changeGroupCode(()=>{
                            if(data.farmIds){
                                this.formLabelAlign.farmIds = (<string>data.farmIds).split(',');
                            }else{
                                this.formLabelAlign.farmIds = [''];
                            }
                        });
                        // label:
                        // for(const i of this.formLabelAlign.roleList!) {
                        //     for (const j in this.roleOptions) {
                        //         if (i === this.roleOptions[j].id && this.roleOptions[j].code === 1 && (!this.formLabelAlign.userMchId || !this.formLabelAlign.key)) {
                        //             this.formLabelAlign.userMchId = '超管不需要商户号';
                        //             this.formLabelAlign.key = '超管不需要填写';
                        //             this.isAdmin = true;
                        //             break label;
                        //         }
                        //
                        //     }
                        // }



                        // this.getDataPower((dataRole: ResultSchoolGradeClass<string>[])=>{
                        //     this.formLabelAlign.dataRole = this.personDetail.handleDataPower(dataRole, <string[]><unknown>data.dataComBoxList);
                        // });
                    }

                });
        }
    }


    public resGroup: Result[] = []

    public handleCascader(start: any): void {
        if(start == true){
            console.log(this.resGroup.length)
            if(this.resGroup.length == 0){
                const column: Column = new Column();
                this.resGroup = this.treeData
                column.getTissuc(undefined,(res: any) => {
                    // console.log(res, 11111)
                    this.treeData = res.data
                    // this.formLabelAlign.groupCode = []
                })
            }
            if(this.treeData[0].id == this.resGroup[0].id){
                const column: Column = new Column();
                this.resGroup = this.treeData
                column.getTissuc(undefined,(res: any) => {
                    // console.log(res, 11111)
                    this.treeData = res.data
                    this.formLabelAlign.groupCode = []
                })
            }
            
        } else {
            console.log(this.formLabelAlign.groupCode)
            if(this.formLabelAlign.groupCode[0] != '0'){
                this.treeData = this.resGroup
                this.formLabelAlign.groupCode.push(this.resGroup[0].id)
            }
        }
    }


    @Watch('$route')
    private RouterChange() {
        if (this.$route.query.weChatPersonalDetailId) {
            this.getDetailInfo();
        } else {
            this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
            this.formLabelAlign.status = 1;
        }
    }

}
