import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { Util } from '@/common/Views/comm/util';
import { dateFun } from '@/common/date'
import { CountExceptionFrom } from '@/common/Views/basics/countException/countException';

// export interface InsureRecordForm {
//     groupCode: [],
//     enterpriseName: string,
//     farmName: string,
//     insuredNo: number|undefined,
//     createTime: [],
//     dealTime: [],
//     houseId: string,
//     confTypeId: string,
//     confId: string,
//     companyId: string
// }

export class InsureRecord extends BasicsClass {

    public getEnterprise(cb: Function): void {
        this.BasicGet('/animal/web/environmentalControl/enterpriseList', {}, false, true, cb)
    }

    public getInsuranceCompany(cb: Function): void {
        this.BasicGet('/animal/web/insurance/list', {}, false, true, cb)
    }

    public getTable(form: any, tablePage: HandleTable, cb: Function) {
        const createTime = Util.handleStartTimeAndEndTime(form.createTime);
        const dealTime = Util.handleStartTimeAndEndTime(form.dealTime);
        this.BasicGet('/animal/web/environmentalControl/insuranceList', {
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length - 1],
            dealStartTime: dealTime.startTime,
            dealEndTime: dealTime.endTime,
            createStartTime: createTime.startTime,
            createEndTime: createTime.endTime,
            createTime: undefined,
            dealTime: undefined
        }, false, true, cb, cb);
    }

    public export(form: any, tablePage: HandleTable): void {
        const createTime = Util.handleStartTimeAndEndTime(form.createTime);
        const dealTime = Util.handleStartTimeAndEndTime(form.dealTime);
        this.getFile('/animal/web/environmentalControl/exportInsuranceList', {
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length - 1],
            dealStartTime: dealTime.startTime,
            dealEndTime: dealTime.endTime,
            createStartTime: createTime.startTime,
            createEndTime: createTime.endTime,
            createTime: undefined,
            dealTime: undefined
        })
    }

    public getDetail(insuredId: string, cb: Function): void {
        this.BasicGet('/animal/web/insured/getInfo', { insuredId }, false, true, cb)
    }

    public getDetail2(insuredId: string, cb: Function): void {
        this.BasicGet('/animal/web/breedAndInsuredStatistics/insuredDetail', { id: insuredId }, false, true, cb)
    }

    public getDetailList(form: any, tablePage: HandleTable, cb: Function): void {
        this.BasicGet('/animal/web/insured/getInventory', {
            ...form,
            ...tablePage
        }, false, true, cb)
    }

    public getDetailList2(form: any, tablePage: HandleTable, cb: Function): void {
        this.BasicGet('/animal/web/breedAndInsuredStatistics/realTimeInventory', {
            ...form,
            ...tablePage
        }, false, true, cb)
    }

    public exportList(form: any, tablePage: HandleTable): void {
        this.getFile('/animal/web/insured/exportInventory', {
            ...form,
            ...tablePage
        })
    }

    public submit(form: any, cb: Function): void {
        // const useTime = Util.dateTimeUtil(form.useTime[0]);
        // const payTime = Util.handleStartTimeAndEndTime([form.payTime, form.payTime])
        console.log(form.useTime)
        this.BasicPost('/animal/web/insured/decisionMaking', {
            ...form,
            useTime: undefined,
            useStart: form.useTime ? Util.dateTimeUtil(form.useTime[0]) : undefined,
            useEnd: form.useTime ? Util.dateTimeUtil(form.useTime[1]) : undefined,
            // payTime: payTime.startTime
        }, true, true, true, cb)
    }


    //企业列表
    public getcompanyList(keyword: string, groupCode: string, cb: Function): void {
        this.BasicGet('/animal/web/loanAudit/companyList', { keyword, groupCode }, false, true, cb)
    }
    //企业法人
    public getlenderNameList(keyword: string, cb: Function): void {
        this.BasicGet('/animal/web/loanAudit/lenderNameList', { keyword }, false, true, cb)
    }
    //身份证号
    public getlenderIdCardList(keyword: string, cb: Function): void {
        this.BasicGet('/animal/web/loanAudit/lenderIdCardList', { keyword }, false, true, cb)
    }
    //贷款银行
    // public getbankList(keyword: string, cb: Function): void {
    //     this.BasicGet('/animal/web/loanBasicInformationConfig/list', { keyword }, false, true, cb)
    // }
    public getbankList(type: string, cb: Function): void {
        this.BasicGet('/animal/web/loanAudit/bankList', { type }, false, true, cb)
    }
    //社会信用代码
    // public gettaxNumberList(keyword: string, cb: Function): void {
    //     this.BasicGet('/animal/web/loanAudit/taxNumberList', { keyword }, false, true, cb)
    // }

    //贷款审核列表
    public getlist(form: any, tablePage: HandleTable, cb: Function): void {
        const createTime = Util.handleStartTimeAndEndTime(form.createTime);
        const dealTime = Util.handleStartTimeAndEndTime(form.dealTime);
        if (form.groupCodes.length > 0) {
            form.groupCode = form.groupCodes[form.groupCodes.length - 1]
            if (form.groupCode == 0) {
                form.groupCode = ""
            }
        }
        this.BasicGet('/animal/web/loanAudit/list', {

            ...form,
            ...tablePage,
            startApplyTime: createTime.startTime,
            endApplyTime: createTime.endTime,
            startCheckTime: dealTime.startTime,
            endCheckTime: dealTime.endTime
        }, false, true, cb)
    }
    //导出
    public export2(form: any, tablePage: HandleTable): void {
        const createTime = Util.handleStartTimeAndEndTime(form.createTime);
        const dealTime = Util.handleStartTimeAndEndTime(form.dealTime);
        this.getFile('/animal/web/loanAudit/listExport', {
            ...form,
            ...tablePage,
            startApplyTime: createTime.startTime,
            endApplyTime: createTime.endTime,
            startCheckTime: dealTime.startTime,
            endCheckTime: dealTime.endTime
        })
    }
    public export3(form: any): void {
        const Times = Util.handleStartTimeAndEndTime(form.time);
        if (form.groupCodes.length > 0) {
            form.groupCode = form.groupCodes[form.groupCodes.length - 1]
            if (form.groupCode == 0) {
                form.groupCode = ""
            }
        }
        this.getFile('/animal/web/regulatoryReport/exportList', {
            ...form,
            startTime: Times.startTime,
            endTime: Times.endTime,
        })
    }

    public export4(form: any): void {
        const Times = Util.handleStartTimeAndEndTime(form.time);
        let startTime
        let endTime
        if (form.time[0]) {
            startTime = new dateFun().returnDate(form.time[0], '-') + ' ' + new dateFun().returnHourMinuteSecend(form.time[0], ':')
            endTime = new dateFun().returnDate(form.time[1], '-') + ' ' + new dateFun().returnHourMinuteSecend(form.time[1], ':')
        }

        this.getFile('/animal/web/leaveRecord/exportList', {
            ...form,
            startTime,
            endTime
        })
    }


    //监管接收配置列表
    public sendEmailConfig(form: any, tablePage: HandleTable, cb: Function): void {
        const Times = Util.handleStartTimeAndEndTime(form.time);
        // const endTime = Util.handleStartTimeAndEndTime(form.time);
        this.BasicGet('/animal/web/sendEmailConfig/comboList', {
            ...form,
            ...tablePage,
            startTime: Times.startTime,
            endTime: Times.endTime
        }, false, true, cb)
    }

    //获取收件人
    public getrecipientName(cb: Function): void {
        this.BasicPost('/animal/web/sendEmailConfig/getRecipientNameList', {}, false, false, true, cb)
    }

    //获取收件邮箱
    public getrecipientEmail(cb: Function): void {
        this.BasicPost('/animal/web/sendEmailConfig/getRecipientEmailList', {}, false, false, true, cb)
    }

    //改变启用状态
    public changestatus(id: string, status: number): void {
        this.BasicGet('/animal/web/sendEmailConfig/updateStatus', { id, status }, true, true)
    }

    //新增配置
    public submitconfig(forms: any, cb: Function): void {
        this.BasicPost('/animal/web/sendEmailConfig/insert', forms, true, true, true, cb)
    }

    //修改配置
    public updateconfig(forms: any, cb: Function): void {
        this.BasicPost('/animal/web/sendEmailConfig/update',
            {
                ...forms,
                recipientName: forms.sendEmailList[0].recipientName,
                recipientEmail: forms.sendEmailList[0].recipientEmail
            }, true, true, true, cb)
    }


    //监管报告列表
    public getreportComboList(form: any, tablePage: HandleTable, cb: Function): void {
        const Times = Util.handleStartTimeAndEndTime(form.time);
        if (form.groupCodes.length > 0) {
            form.groupCode = form.groupCodes[form.groupCodes.length - 1]
            if (form.groupCode == 0) {
                form.groupCode = ""
            }
        }
        this.BasicGet('/animal/web/regulatoryReport/reportComboList', {
            ...form,
            ...tablePage,
            startTime: Times.startTime,
            endTime: Times.endTime,
        }, false, true, cb)
    }

    //生成报告
    public sendReport(form: any, bankId: any, cb: Function): void {
        const Times = Util.handleStartTimeAndEndTime(form.time);
        this.BasicGet('/animal/web/regulatoryReport/sendReport', {
            ...form,
            bankId,
            farmId: form.farmId,
            startTime: Times.startTime,
            endTime: Times.endTime,
        }, true, true, cb)
    }

    //短信配置列表
    public smslist(form: any, tablePage: HandleTable, cb: Function): void {
        if (form.groupCodes.length > 0) {
            form.groupCode = form.groupCodes[form.groupCodes.length - 1]
            if (form.groupCode == 0) {
                form.groupCode = ""
            }
        }
        this.BasicGet('/animal/web/sendSmsConfig/comboList', {
            ...form,
            ...tablePage,
        }, false, true, cb)
    }

    //改变启用状态
    public changestatusBoolean(id: string, statusBoolean: boolean): void {
        this.BasicGet('/animal/web/sendSmsConfig/updateStatus', {
            id: id,
            statusBoolean: statusBoolean.toString()
        }, true, true)
    }

    //新增配置
    public insertSmsConfig(forms: any, cb: Function): void {
        if (forms.groupCodes.length > 0) {
            forms.groupCode = forms.groupCodes[forms.groupCodes.length - 1]
            if (forms.groupCode == 0) {
                forms.groupCode = ""
            }
        }
        this.BasicPost('/animal/web/sendSmsConfig/insertSmsConfig', forms, true, true, true, cb)
    }

    //修改配置
    public updateSmsConfig(forms: any, cb: Function): void {
        if (forms.groupCodes.length > 0) {
            forms.groupCode = forms.groupCodes[forms.groupCodes.length - 1]
            if (forms.groupCode == 0) {
                forms.groupCode = ""
            }
        }
        this.BasicPost('/animal/web/sendSmsConfig/updateSmsConfig',
            {
                ...forms,
                recipientName: forms.sendSmsConfigList[0].recipientName,
                recipientNumber: forms.sendSmsConfigList[0].recipientNumber
            }, true, true, true, cb)
    }

    //发送短信
    public sendSms(forms: any, cb: Function): void {
        // if (forms.groupCodes.length > 0) {
        //     forms.groupCode = forms.groupCodes[forms.groupCodes.length - 1]
        //     if (forms.groupCode == 0) {
        //         forms.groupCode = ""
        //     }
        // }
        this.BasicPost('/animal/web/sendSmsConfig/sendSms', forms, true, true, true, cb)
    }


    //删除短信配置
    public deleteSmsConfig(form: any, cb: Function): void {
        // if (forms.groupCodes.length > 0) {
        //     forms.groupCode = forms.groupCodes[forms.groupCodes.length - 1]
        //     if (forms.groupCode == 0) {
        //         forms.groupCode = ""
        //     }
        // }
        this.BasicGet('/animal/web/sendSmsConfig/deleteSmsConfig', { id: form }, true, true, cb)
    }


    //删除接收项配置
    public deleteEmailConfig(form: any, cb: Function): void {
        // if (forms.groupCodes.length > 0) {
        //     forms.groupCode = forms.groupCodes[forms.groupCodes.length - 1]
        //     if (forms.groupCode == 0) {
        //         forms.groupCode = ""
        //     }
        // }
        this.BasicGet('/animal/web/sendEmailConfig/deleteEmailConfig', { id: form }, true, true, cb)
    }

    //非法离开配置
    public leavewarnconf(forms: any, farmType: number, cb: Function): void {
        // if (forms.groupCodes.length > 0) {
        //     forms.groupCode = forms.groupCodes[forms.groupCodes.length - 1]
        //     if (forms.groupCode == 0) {
        //         forms.groupCode = ""
        //     }
        // }
        this.BasicPost('/animal/web/leavewarnconf/update', {
            ...forms,
            farmType: farmType
        }, true, true, true, cb)
    }

    //获取非法离开配置
    public getinfo(form: any, cb: Function): void {
        // if (forms.groupCodes.length > 0) {
        //     forms.groupCode = forms.groupCodes[forms.groupCodes.length - 1]
        //     if (forms.groupCode == 0) {
        //         forms.groupCode = ""
        //     }
        // }
        this.BasicGet('/animal/web/leavewarnconf/info', { farmType: form }, false, false, cb)
    }
}