

































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
// import {AxiosData} from "@/common/http/http";
import {Util} from "@/common/Views/comm/util";
import {HandleTable, HandlePageChange} from "@/common/Views/comm/handleTable";
// import LabelManageDetailImport from "@/views/basics/labelManage/labelManageDetail/labelManageDetailImport.vue";
// import {LabelManage, LabelManageFrom, LabelManageTable} from "@/common/Views/basics/labelManage/labelManage";
// import { ComboList, Column, Result } from '@/common/Views/system/column/column';
// import DeviceGroup from '@/views/basics/deviceManage/deviceGroup/deviceGroup.vue';
// import {DeviceManageTable} from '@/common/Views/basics/deviceManage/deviceManage';
// import {CountException, CountExceptionFrom} from "@/common/Views/basics/countException/countException";
// import {TemperatureInfo} from '@/common/Views/basics/temperatureInfo/temperatureInfo';
// import {MedicineInfo} from '@/common/Views/basics/medicineInfo/medicineInfo';
// import {SchoolList} from '@/common/Views/basics/schoolManage/schoolManage';
// import {ConfType} from '@/common/Views/system/confType/confType';
// import { InfoRegister } from '@/common/Views/basics/infoRegister/infoRegister';
import { InsuranceForm, insuranceIdentity, InsuranceCompanyConfiguration, BusinessList } from '@/common/Views/system/insuranceCompanyConfiguration/insuranceCompanyConfiguration'
import { ConfType } from '@/common/Views/system/confType/confType';

@Component({
    // components: {DeviceGroup, "label-manage-detail-import": LabelManageDetailImport}
})
export default class HelloWorld extends Vue {
    
    // public column = new Column()

    public confType: ConfType = new ConfType()

    public typeInput: number = -1

    private form: InsuranceForm = {
        companyId: '',
        insuranceCompanyName: '',
        businessList: [],
        deleteCompany: false
    };

    public confInfoChange: number[]|string[] = []

    private Identity: insuranceIdentity = {
        companyId: '',
        insuranceCompanyName: ''
    }


    private insuranceCompanyConfiguration: InsuranceCompanyConfiguration = new InsuranceCompanyConfiguration();

    constructor() {
        super();
    }

    private activated(): void {
        // this.getDetail('');
        this.form = new Util().clearObject(this.form)
        this.getConfType()
        let id = this.$route.query.id.toString()
        this.insuranceCompanyConfiguration.getDetail({companyId: id}, (res: any) => {
            console.log(res)
            this.confType.getListDetail(id, (data: any) => {
                console.log(data)
                this.confInfoTypeList = data.data
            })
            this.form = res.data
            this.form.businessList = res.data.businessList
        })
    }

    // public created(): void {
    //     this.getConfType()
    // }

    private confTypeList: [] = []
    private confInfoTypeList: [] = []

    public getConfType(): void {
        this.confType.getList((data: any) => {
            console.log(data)
            this.confTypeList = data.data
        })
    }

    public changeConf(id: string): void {
        this.confInfoTypeList = []
        this.confType.getListDetail(id, (data: any) => {
            console.log(data)
            this.confInfoTypeList = data.data
        })
        for(let i in this.confTypeList){
            if(this.confTypeList[i].id == id){
                this.form.businessList[this.typeInput].confName = this.confTypeList[i].name
            }
        }
    }

    public nameList:string[] = []
    public changeConfInfo(idList:string[]): void {
        console.log(idList)
        // this.form.businessList[this.typeInput].confInfoName = ''
        this.nameList = []
        for(let nub in idList){
            let id = idList[nub]
            for(let i in this.confInfoTypeList){
                if(this.confInfoTypeList[i].id == id){
                    // this.form.businessList[this.typeInput].confInfoName += (this.confInfoTypeList[i].name + ',')
                    this.nameList.push(this.confInfoTypeList[i].name)
                    this.form.businessList[this.typeInput].confInfoName = this.nameList.toString()
                    this.form.businessList[this.typeInput].confInfoId = idList.toString()
                }
            }
        }
        
    }

    public clearModule(data: BusinessList, start: boolean): void {
        console.log(data)
        data.confInfoId = ''
    }

    private getDetail(id:string): void {
        this.insuranceCompanyConfiguration.getDetail(id ,(data: any) => {
            console.log(data)
        });
    }

    public handleAdd(): void {
        if(!this.verify(0)){
            return
        }
        this.confInfoChange = []
        let businessList: BusinessList = {
            id: '',
            confId: '',
            confName: '',
            confInfoId: '',
            confInfoName: '',
            startWeight: '',
            endWeight: '',
            payMoney: '',
        }
        this.form.businessList.unshift(businessList)
        this.typeInput = 0
        this.lastSingle = new Util().clearObject(this.lastSingle)
    }


    public lastSingle: BusinessList = {
        id: '',
        confId: '',
        confName: '',
        confInfoId: '',
        confInfoName: '',
        startWeight: '',
        endWeight: '',
        payMoney: '',
    }
    public compile(i: number): void {
        if(!this.verify(i)){
            return
        }
        this.form.businessList[i].confInfoId
        this.confInfoChange = this.form.businessList[i].confInfoId.split(',')
        this.lastSingle = new Util().clearObject(this.lastSingle)
        let jsonDate = JSON.stringify(this.form.businessList[i])
        this.lastSingle = JSON.parse(jsonDate)
        
        this.confType.getListDetail(this.form.businessList[i].confId, (data: any) => {
            console.log(data)
            this.confInfoTypeList = data.data
            this.typeInput = i
        })
        console.log(this.form.businessList[i])
    }

    public deleteSingle(i: number): void {
        this.form.businessList.splice(i, 1)
        console.log(i)
    }

    public cancelSingle(i: number): void {
        this.typeInput= -1
        if(this.lastSingle.confId != ''){
            let jsonDate = JSON.stringify(this.lastSingle)
            this.form.businessList.splice(i, 1, JSON.parse(jsonDate))
            this.lastSingle = new Util().clearObject(this.lastSingle)
            console.log(jsonDate)
        }else{
            this.form.businessList.splice(i, 1)
        }
        
    }

    public saveSingle(): void {
        if(!this.verify(-1)){
            return
        }
        this.typeInput = -1
    }

    public lastTypeInput: number = -1
    public verify(i: number): boolean {
        console.log(this.lastTypeInput)
        
        if(this.lastTypeInput == -1){
            this.lastTypeInput = i
        }else{
            console.log(this.form.businessList[this.lastTypeInput].confInfoId)
        console.log(this.form.businessList[this.lastTypeInput].startWeight)
        console.log(this.form.businessList[this.lastTypeInput].endWeight)
            const vue = new Vue();
            if(!this.form.businessList[this.lastTypeInput].confId){
                vue.$message.warning('请选择品种类型')
                return false
            }
            if(!this.form.businessList[this.lastTypeInput].confInfoId){
                vue.$message.warning('请选择品种分类')
                return false
            }
            if(!this.form.businessList[this.lastTypeInput].startWeight || !this.form.businessList[this.lastTypeInput].endWeight){
                vue.$message.warning('请填写完整区间体重')
                return false
            }else if(this.form.businessList[this.lastTypeInput].endWeight < this.form.businessList[this.lastTypeInput].startWeight){
                vue.$message.warning('请正确填写区间体重')
                return false
            }
            if(!this.form.businessList[this.lastTypeInput].payMoney){
                vue.$message.warning('请填写赔付金额')
                return false
            }
            this.lastTypeInput = i
        }
        return true
    }

    public save(): void {
        const vue = new Vue();
        if(!this.form.insuranceCompanyName){
            vue.$message.warning('请填写保险公司名称')
            return
        }
        if(this.typeInput != -1){
            vue.$message.warning('请先保存编辑中的内容')
            return
        }
        console.log(this.form)
        this.insuranceCompanyConfiguration.insuranceSave(this.form, (data: any) => {
            console.log(data)
            this.typeInput = -1
            this.$router.go(-1)
        })
    }

    @Watch('$route')
    private watchFunction(): void {
        console.log(this.$route.query.id)
        if(this.$route.path == "/insuranceDate"){
            let id = this.$route.query.id.toString()
            this.insuranceCompanyConfiguration.getDetail({companyId: id}, (res: any) => {
                console.log(res)
                this.confType.getListDetail(id, (data: any) => {
                    console.log(data)
                    this.confInfoTypeList = data.data
                })
                this.form = res.data
                this.form.businessList = res.data.businessList
            })
        }
        // console.log(this.$route)
    }

}
