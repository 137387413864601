




















import { Watch, Prop, Component, Vue } from "vue-property-decorator";
import { groupCodeLazy } from "@/views/system/appfire/components/groupCodeLazy";
@Component({})
export default class CascaderComponentsVue extends Vue {
    @Prop() LabelText: any;
    @Prop() FatherAdressVal: any; //父组件绑定的值
    @Prop() AdressTreeData: any;
    @Prop() PlaceholderText: any;
    @Prop() width: any;
    @Prop() IsMultiple: any; //是否多选
    @Prop() IsPid: any; //传入的prop
    // @Prop() IsDisabled:any //是否禁止选择
    private AdressChildTreeData: any[] = [];
    private ShowCascaderFlag: boolean = true;

    private PropgroupCodeLazy = new groupCodeLazy().props1; //组织机构
    private AdressVal: any[] = [];
    private propsObj: any = {
        expandTrigger: "hover",
        value: "id",
        label: "name",
        multiple: false, //默认非多选
        checkStrictly: true, //让父子节点取消关联
    };

    //处理数据
    private deleteLastChildren(data: any) {
        //删除 树结构为空的children
        if (data) {
            for (let i of data) {
                if (i.children == undefined) return;
                if (i.children) this.deleteLastChildren(i.children);
                if (i.children.length == 0)
                    Reflect.deleteProperty(i, "children");
            }
        }
    }

    private ChangeAdressValue() {
        //选择后将选择的值传给父组件
        this.$emit("SetAdressVal", this.AdressVal);
    }

    @Watch("FatherAdressVal", { immediate: true, deep: true }) //监视父组件传递过来的值 进行赋值
    private changeData(newVal: any) {
        if (newVal == [] || newVal == "") this.AdressVal = [];
        if (newVal) this.AdressVal = newVal;
    }
    @Watch("AdressTreeData", { immediate: true, deep: true }) //此组件被渲染的时候 处理传递的树结构
    private changeAressData(newVal: any) {
        if (newVal == undefined) return;
        let data: any = JSON.parse(JSON.stringify(newVal));
        this.deleteLastChildren(data); //利用递归 将空的children进行删除
        this.AdressChildTreeData = data;
    }

    @Watch("IsMultiple", { immediate: true }) //判断是否传递多选值 如果没传递 那么就是 undefined  如果传递为 true  那么表示多选
    private ChangeIsMultiple(newVal: any) {
        if (newVal) {
            this.propsObj.multiple = true;
        } else {
            this.propsObj.multiple = false;
        }
    }
    @Watch("IsPid", { immediate: true })
    private ChangeIsPid(newVal: any) {
        if (newVal) {
            this.propsObj = this.PropgroupCodeLazy;
            this.ShowCascaderFlag = false;
            this.ShowCascaderFlag = true;
        }
    }
    @Watch("IsBank", { immediate: true })
    private ChangeIsBank(newVal: any) {
        if (newVal) {
            this.propsObj.label = "bankName";
            this.ShowCascaderFlag = false;
            this.ShowCascaderFlag = true;
        }
    }

    // @Watch("IsDisabled",{immediate:true})
    // private ChangeDisabled(newVal:any){
    //     if(newVal){

    //     }
    // }
}
