















































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import { Column, ComboList, Result } from "@/common/Views/system/column/column";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
import {
    OutManageTable,
    TraceabilityOutManage,
    TraceabilityOutManageForm
} from "@/common/Views/traceabilityOutManage/traceabilityOutManage";

@Component
export default class HelloWorld extends Vue {
    public column = new Column();

    private form: any = {
        checkTime: [],
        groupCode: "",
        farmId: "",
        tagId: "",
        endTime: "",
        startTime: "",
        insuranceCompanyId: "",
        deathCode: "",
        auditStatus: "",
        prevLabelNo: ""
    };
    private langType = this.$i18n.locale;
    private infoType = false;
    public infoData = {};
    private destinationList: { id: string; destinationName: string }[] = [];

    //      private activated(): void {
    //          this.getTable();
    //          this.getTree();
    //          this.changeGroupCode();
    // this.getdeathForReason();
    //          // this.getDestination();
    //          // this.column.getTissuc(undefined, (res: any) => {
    //          //     this.treeData = res.data
    //          // })
    //      }

    // private getDestination() {
    //     new Column().getDestination((data: AxiosData<{id: string, destinationName: string}[]>)=>{
    //         this.destinationList = data.data;
    //     })
    // }
    //展示详细信息
    private ShowDetail(row:any) {
        this.infoData = row;
        this.infoType = true;
    }
    private activated(): void {
        this.getTable();
        this.getTree();
        this.changeGroupCode();
        this.getdeathForReason();
        this.getCompany();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    private companyList: any = [];

    private getCompany(): void {
        new Column().getCompany((res: any) => {
            this.companyList = res.data;
        });
    }

    private treeData: Result[] = [];

    private getTree() {
        Util.getTree((result: Result[]) => {
            this.treeData = result;
        });
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        this.tablePage.pageNo = 1;
        this.getTable();
        this.getTree();
        this.changeGroupCode();
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;
        localStorage.setItem("a", this.form);
        console.log(this.form);
        this.getTable();
        // console.log(this.form)
    }

    private comboList: ComboList[] = [];
    private deathForReason: DeathForReason[] = [];

    private changeGroupCode(): void {
        this.comboList = [];
        // this.form.farmId = "";
        new Column().getFarm(
            this.form.groupCode[this.form.groupCode.length - 1],
            (data: AxiosData<ComboList[]>) => {
                this.comboList = data.data;
            }
        );
    }

    @Watch("form.groupCode")
    getgroupCode(newVal: Array<object>, oldVal: Array<object>) {
        if (newVal !== oldVal) {
            this.form.farmId = "";
        }
    }

    private getdeathForReason(): void {
        this.deathForReason = [];
        this.form.deathCode = "";
        new Column().getdeathForReason((data: AxiosData<DeathForReason[]>) => {
            this.deathForReason = data.data;
        });
    }

    private tableData: OutManageTable[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500]
    };

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }

    private traceabilityOutManage: TraceabilityOutManage;

    constructor() {
        super();
        this.traceabilityOutManage = new TraceabilityOutManage();
    }

    private getTable(): void {
        if (this.$getResourceCodes("label_list")) {
            this.traceabilityOutManage.getdeath(
                this.form,
                this.tablePage,
                (data: AxiosData<OutManageTable[]>) => {
                    this.tableData = data.data;
                    this.tablePage.totalCount = data.page.totalCount;
                }
            );
        }
    }

    private Export() {
        this.traceabilityOutManage.export2(this.form);
        // console.log(this.form)
    }

    private goDetail(row: any) {
        this.$router.push({
            path: "/deathAnimaldetails",
            query: {
                id: row.id,
                farmName: JSON.stringify(row.farmName),
                labelNumber: JSON.stringify(row.labelNumber),
                auditStatus: row.approveStatus
                // deathTime: row.deathTime,
            }
        });
    }
}
