





































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import { Column, Result } from "@/common/Views/system/column/column";
//弹出层组件
import DeviceGroup from "@/views/basics/transaction/deviceGroup/deviceGroup.vue";
//表格数据
import { Firm } from "@/common/Views/system/firm/firm";

@Component({
    components: {
        DeviceGroup,
    },
})
export default class Usermangement extends Vue {
    private form: any = {
        name: "",
        market: "",
        status: "",
        startTime: "",
    };
    constructor() {
        super();
        this.firm = new Firm();
    }

    //props
    private props: Object = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "name",
    };

    //tableData
    private tableData: [] = [];

    //column
    public column = new Column();

    //请求列表的方法

    //查询
    private search(): void {
        this.tablePage.pageNo = 1;
        this.getTable();
    }
    //重置
    private restForm(): void {
        this.form = {
            name: "",
            market: "",
            status: "",
            startTime: "",
        };
        //请求table列表信息
        this.getTable();
    }

    //tablePage
    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    //handlePageChange
    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.getTable();
    }

    private options: any = [];
    private value: any = "";
    private tabType: number = 1;

    //表格数据请求
    private firm: Firm;

    private getTable(): void {
        // 获取列表
        if (this.tabType == 1) {
            // todo获取数据
        }
    }
    //跳转详情
    private goDetail(row: any): void {
        console.log(row);
        console.log(this.tableData);
        this.$router.push({
            path: "/appletUserDetail",
            query: {
                id: row.id,
                tabType: this.tabType,
            },
        });
    }

    // 切换列表
    private handleChangTab(type: number) {
        // 重置页码
        this.tableData = [];
        this.tabType = type;
        this.getTable();
    }

    //挂在后请求列表信息
    mounted() {
        //执行请求列表
        this.getTable();
    }
}
