



































































































import {Prop , Component , Watch , Vue} from 'vue-property-decorator'
import {Axios, AxiosData} from '@/common/http/http';
import {BasicsClass} from '@/common/BasicsClass';
@Component({})
export default class RebateDetailsConfiguration extends Vue{
    private form:any ={
        itemType:'',
        way:'',
        rebatePrice:'',
        groupCode:["0"],
    }
    private tableData:any[] = []
    private Searchform:any ={
        itemType:'',
        groupCode:["0"],
    }
    private treeData:any[] = []
    private props:Object = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "id"
    }

    private TypeList:any[] = [ //事项列表
        {name:'请选择',id:''},
        {name:'耳标佩戴',id:'1'},
        {name:'免疫登记',id:'2'},
        {name:'治疗登记',id:'3'},
        {name:'配种登记',id:'4'},
        {name:'银行线下监管',id:'5'},
    ]

    private TypeWayList:any[] =[
        {name:'请选择', id:""},
        {name:'现金', id:"1"},
        {name:'金豆', id:"2"},
    ]

    //table Page 
    private tablePage: any = {
        roleId:'',
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private EditConfig(row:any){ //编辑
        if(!this.CanAdd()) return //先判断是否能够编辑  不能编辑 直接return
        //赋值
        this.form.itemType = row.itemType.toString()
        this.form.way = row.way.toString()
        this.form.rebatePrice = row.rebatePrice.toString()
        this.form.groupCode = row.groupCode
        setTimeout(() =>{
            let domVc:any = this.$refs.CascaderRef
            domVc.$el.children[0].children[0].value = row.groupCodeName
        })
        row.flag = true //进入编辑状态
        row.type = false //变为编辑状态 保存接口
    }
    private SaveConfig(row:any){//保存
        let Saveform:any = JSON.parse(JSON.stringify(this.form))
        Array.isArray(Saveform.groupCode) ? Saveform.groupCode = Saveform.groupCode[Saveform.groupCode.length-1].toString() :Saveform.groupCode
        
        let obj:any ={
            itemType:Saveform.itemType,
            way:Saveform.way,
            rebatePrice:Saveform.rebatePrice,
            groupCode:Saveform.groupCode,
            enableFlag:row.enableFlag
        }
        if(Saveform.itemType == '' || Saveform.way == '' || Saveform.rebatePrice == '' || Saveform.groupCode == ''){
            this.$message.error('请先完整填写配置数据');
            return
        }
        //校验
        row.type ? this.AddDataReq(obj , row) : this.EditDataReq(obj,row)
    }
    private CancelConfig(row:any){ //取消
        row.flag = false//隐藏对应行的输入框
        this.getTableListDataReq(this.Searchform)
    }

    //handlePageChange
    private handlePageChange(val: any) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        //请求列表数据
        this.getTableListDataReq()
    }
    private ClickStatus(row:any){ //点击启用状态
        console.log(row.enableFlag)
        console.log(row)
        // let Saveform:any = JSON.parse(JSON.stringify(this.form))
        Array.isArray(row.groupCode) ? row.groupCode = row.groupCode[row.groupCode.length-1].toString() :row.groupCode
        
        let obj:any ={
            itemType:row.itemType,
            way:row.way,
            rebatePrice:row.rebatePrice,
            groupCode:row.groupCode,
            enableFlag:row.enableFlag
        }
        this.EditDataReq(obj,row)
    }

    //处理数据
    private deleteLastChildren(data:any){//处理组织机构树
        let treeData:any[] = data
        treeData.forEach((itemOne:any) => {
            itemOne.children.forEach((itemTwo:any) => {
                itemTwo.children.forEach((itemThree:any) => {
                    itemThree.children.forEach((itemFour:any) => {
                        itemFour.children.forEach((itemFive:any) => {
                            delete itemFive.children
                        });
                    });
                });
            });
        });
        return treeData
    }
    get ItemTypeName(){//计算属性 计算事项
        const that:any =this
        return function (type:any){
            let name:string = ''
            switch(type){
                case 1 :
                    name = '耳标佩戴' 
                    break;
                case 2 :
                    name = '免疫登记'
                    break;
                case 3 :
                    name = '治疗登记'
                    break;
                case 4 :
                    name = '配种登记'
                    break;
                case 5 :
                    name = '银行线下监管'
                    break;
            }
            return name
        }
    }
    private CanAdd(){ //是否能够新增
        let flag:boolean = false
        if(this.tableData.length === 0){
            flag = true
            return flag
        }
        for(let i:number = 0; i<this.tableData.length; i++){
            if(this.tableData[i].flag){
                flag = false
                return
            }else{
                flag = true
            }
        }
        
        return flag
    }
    private CloseEditConfig(){//全部重置为非编辑状态
        this.tableData.forEach((item:any)=>{
            item.flag = false
        })
    }
    private addItem(){  //添加配置
        this.tableData.push(
            {
                itemType: '',
                way: '',
                rebatePrice: '',
                describe:'',
                groupCode: ["0"],
                enableFlag: true,
                flag:true,
                type : true  
            }
        )
        this.form={
            itemType:'',
            way:'',
            rebatePrice:'',
            groupCode:["0"],
        }
    }
    private InputEvent(e:any){ // 返利数量 输入框处理
        this.form.rebatePrice=e.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
        // this.form.rebatePrice
        if(e > 9999.99){
            this.form.rebatePrice = 9999
        }
    }
    private search(){//搜索
        this.tablePage.pageNo = 1 
        this.tablePage.pageSize = 10
        this.getTableListDataReq(this.Searchform)
    }
    private clear(){//重置
        this.Searchform.itemType = ''
        this.Searchform.groupCode = ["0"]
        this.getTableListDataReq()
    }

    //接口请求
    private getTableListDataReq(forms?:any){ //table 数据接口
        let form:any = {}
        if(forms != undefined){
            form = JSON.parse(JSON.stringify(forms)) 
            form.groupCode = form.groupCode[form.groupCode.length-1]
        }
        this.tableData = []
        new BasicsClass().BasicGet('/animal/web/rebateItem/getRebateItemList',{...form,...this.tablePage},false,false,(data:any) =>{
            data.data.forEach((item:any)=>{
                item.describe ='' //说明
                item.flag = false//添加属性 默认非编辑状态
                item.type = true  //添加属性 默认保存接口
            })
            this.tableData = data.data
            this.tablePage.totalCount = data.page.totalCount
        })
    }
    private getGroupCodeDataReq(){//组织机构
        new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/getForGroupCode',{},false,false,(data:any)=>{
            let TreeData:any = this.deleteLastChildren(data.data)
            this.treeData = TreeData
            // this.$emit('SetTreeData' , TreeData)
        })
    }
    private EditDataReq(obj:any,row:any){ //编辑
        new BasicsClass().BasicPost1('/animal/web/rebateItem/updateRebateItem',{...obj,id:row.id},true,true,true,(data:any) =>{
            row.flag = false //隐藏输入框
            this.getTableListDataReq()
        },()=>{
            this.getTableListDataReq()
        })
    }
    private AddDataReq(obj:any,row:any){//保存
        new BasicsClass().BasicPost1('/animal/web/rebateItem/addRebateItem',{...obj},true,true,true,(data:any) =>{
            row.flag = false//关闭对应的编辑
            this.getTableListDataReq()
        })
    }
    
    changeType(row:any){
        switch(Number(this.form.itemType)){
            case 1 :
                row.describe = '成功佩戴耳标' 
                break;
            case 2 :
                row.describe = '新增一条免疫登记记录'
                break;
            case 3 :
                row.describe = '新增一条治疗登记记录'
                break;
            case 4 :
                row.describe = '新增一条配种登记记录'
                break;
            case 5 :
                row.describe = '权限范围内有活体抵押贷款，并在贷款期限内'
                break;
        }
    }

    mounted(){
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getTableListDataReq()
        this.getGroupCodeDataReq()
    }

}
