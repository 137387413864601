import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/Interface/CommonModule/TableAndPage'
import { MilkProductionStatisticsSearch } from '@/Interface/BreedingModule/MilkProductionStatistics'
import { QuarantineInspectionStatisticsInfo } from '@/Interface/BreedingModule/QuarantineInspectionStatistics'
import { MilkProduction } from '@/Interface/BreedingModule/BigScreen'


export class MilkProductionStatistics {  //大模块下面的小类  产奶量统计
    public getMilkYieldRecordList(form: MilkProductionStatisticsSearch, page: HandleTable, cb: Function) {  //产奶统计列表
        new BasicsClass().BasicGet('/animal/web/milkYield/getMilkYieldRecord', { ...form, ...page }, false, false, cb)
    }
    public exportRecordList(form: MilkProductionStatisticsSearch) {  //导出
        new BasicsClass().getFile('/animal/web/milkYield/exportRecordList', { ...form })
    }
}

export class QuarantineInspectionStatistic extends MilkProductionStatistics {  //检疫检查统计
    public getQuarantineRecorList(form: QuarantineInspectionStatisticsInfo, page: HandleTable, cb: Function) {  //获取检疫记录列表
        new BasicsClass().BasicGet('/animal/web/quarantine/getQuarantineRecorList', { ...form, ...page }, false, false, cb)
    }
    public getSampleList(cb: Function) {  //获取所有样本id和名字
        new BasicsClass().BasicGet('/animal/web/quarantine/getSampleList', {}, false, false, cb)
    }
}

export class BigScreenModule extends QuarantineInspectionStatistic { //养殖模块的大屏
    public getMilkYield(form: MilkProduction, cb: Function) {  //获取检疫记录列表
        new BasicsClass().BasicGet('/animal/web/bigScreenGovernmentCamelNew/getMilkYield', { ...form }, false, false, cb)
    }
}


export class BreedingModule extends BigScreenModule { }//最终被引用的类

