


















































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
    Personal,
    PersonalC,
    TableData,
} from "@/common/Views/system/personal/personal";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import { Util } from "@/common/Views/comm/util";
import personalIp from "@/views/system/personal/personalDetail/personalIp.vue";
import {
    PersonalDetailC,
    SchoolGradeClass,
} from "@/common/Views/system/personal/personalDetail";
import { TableResult } from "@/common/Views/system/role/role";
import { AxiosData } from "@/common/http/http";
import { Result, Column, ComboList } from "@/common/Views/system/column/column";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
const JSEncrypt = require("@/common/bin/jsencrypt.js");

enum Status {
    "启用" = 1,
    "禁用" = 2,
    "登录锁定" = 3,
    "注销" = 4,
}
enum StatusEn {
    "Enable" = 1,
    "Disable" = 2,
    "Lock" = 3,
    "Logout" = 4,
}
@Component({
    components: {
        "personal-ip": personalIp,
    },
})
export default class HelloWorld extends Vue {
    public column = new Column();

    private form: Personal<string> = {
        name: "",
        roleIds: "",
        mobile: "",
        status: "",
        groupCode: [],
        farmIds: "",
    };

    private personal: PersonalC;
    constructor() {
        super();
        this.personal = new PersonalC();
    }
    public infoData = {};
    private infoType = false;
    private langType: string = this.$i18n.locale;
    //展示详细信息
    private clickShowInfo(row: any) {
        this.infoData = row;
        this.infoType = true;
    }
    private tableData: TableData<string>[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;
        this.getPersonalList();
    }

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getPersonalList();
    }

    private activated(): void {
        this.getPersonalList();
        this.getAllRole();
        this.getTree();
        this.changeGroupCode();
    }

    private treeData: Result[] = [];

    private getTree(cb?: Function): void {
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        // column.getTreeData("/animal/web/group/treeList").then((result: Result[]) => {
        column
            .getTreeData("/animal/web/provinceCityDistrict/getForGroupCode")
            .then((result: Result[]) => {
                this.treeData = new DeepLoop().deepCopy(result);

                if (cb) cb();
            })
            .catch((err) => {
                this.$message.error(err.message);
            });
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    private comboList: ComboList[] = [];
    private changeGroupCode(): void {
        this.comboList = [];
        // this.form.farmIds = '';
        new Column().getFarm(
            this.form.groupCode[this.form.groupCode.length - 1],
            (data: AxiosData<ComboList[]>) => {
                this.comboList = data.data;
            },
            "100"
        );
    }

    @Watch("form.groupCode")
    getgroupCode(newVal: Array<object>, oldVal: Array<object>) {
        if (newVal !== oldVal) {
            this.form.farmIds = "";
            // this.form.typeConfId = '';
        }
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        this.tablePage.pageNo = 1;
        this.getTree();
        this.changeGroupCode();
        this.getAllRole();
        this.getPersonalList();
    }

    private getPersonalList(): void {
        if (this.$getResourceCodes("user_list")) {
            this.personal
                .getPersonalList(this.form, this.tablePage)
                .then((data) => {
                    for (const i in data.result) {
                        if (this.langType === "zh") {
                            data.result[i]["status"] =
                                Status[data.result[i]["status"]];
                        } else {
                            data.result[i]["status"] =
                                StatusEn[data.result[i]["status"]];
                        }
                    }
                    this.tableData = data.result;
                    this.$set(
                        this.tablePage,
                        "totalCount",
                        data.pageInfo.totalCount
                    );
                });
        }
    }

    private removePerson(): void {
        // this.personal.removePersonal()
        let removeRecords: TableData<string>[] = (
            this.$refs.table as any
        ).getSelectRecords();

        if (removeRecords.length === 0) {
            this.$message.error(this.$i18n.t('system.web_user.loginUnlock').toString());
        } else {
            this.$confirm("是否确认删除？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    const ids = removeRecords.map(
                        (value: TableData<string>) => value.id
                    );

                    this.personal.removeRole(
                        ids.join(","),
                        this.getPersonalList
                    );
                })
                .catch(() => {
                    //   do nothing
                });
        }
    }

    private handlePerson(id: string = ""): void {
        this.$router.push({
            path: "/personalDetail",
            query: {
                personId: id,
            },
        });
    }

    private restPassword(row: TableData<string>): void {
        this.$prompt(row.name, this.$i18n.t('system.web_user.resetPassword') as string, {
            closeOnClickModal: false,
        }).then((data: any) => {
            if (data.value.length < 6 || data.value.length > 16) {
                this.$message.error(this.$i18n.t('system.web_user.length_not_match').toString());
            } else {
                // const param = new DeepLoop().deepCopy(this.param);
                console.log(data.value);
                const encrypt = new JSEncrypt.JSEncrypt();
                encrypt.setPublicKey(
                    "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDAfA20cRn7tWmU3R9rEA6UnH+1KzqoZAu0JJdDis+wgZTp8JKnkFBBMa8TsaI0muA0/J0dsY2wC6m3d5sT6lwXeLk9KOxasjtM3Y2lISLks6VRG6qE9CYKEynbMTf2WjygI08pxjiCDLTC5RRJ53fomE9JE0iXMFyYy9kKi1JcxwIDAQAB/8914nmEgq6wbH5Iuw5Ra6805doP14SEMbKEITlyloyc6b3l1EjRWe1HuwlGaHwlW2vBAOAKbu1EeBDaLjehmmH7gOB3Tr0OvR7smQIDAQAB"
                );
                let password = encrypt.encrypt(data.value);
                this.personal.restPassword(row.id, password);
            }
        });
    }

    private loginIPDialog: boolean = false;
    private handleIp(id: string): void {
        this.loginIPDialog = true;

        this.getIpWithId = id;
    }

    private getIpWithId: string = "";

    private schoolNameOptions: SchoolGradeClass<string>[] = [];

    // private getAllSchoolList(): void {
    //     new PersonalDetailC().getAllSchoolList()
    //         .then(data=> {
    //
    //             this.schoolNameOptions = data;
    //         })
    // }

    private roleOptions: TableResult<string>[] = [];

    private getAllRole(): void {
        new PersonalDetailC()
            .getRoleList()
            .then((data) => {
                this.roleOptions = data;
            })
            .catch((err) => {
                this.$message.error(err.message);
            });
    }

    private loginUnLock(row: TableData<string>): void {
        this.$confirm(this.$i18n.t('system.web_user.loginUnlock').toString(),this.$i18n.t('system.web_user.Tips').toString(), {
            confirmButtonText: this.$i18n.t('common.confirm').toString(),
            cancelButtonText: this.$i18n.t('common.cencel').toString(),
            type: "warning",
        }).then(() => {
            this.personal.loginUnLock(row.id, () => {
                this.getPersonalList();
            });
        });
    }
}
