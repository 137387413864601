

















































import {Watch , Component , Vue} from 'vue-property-decorator'

import ForageAndDrugFormSearch from '@/views/basics/typeConfig/components/ForageAndDrugFormSearch.vue'

import {BasicsClass} from '@/common/BasicsClass';
@Component({
    components:{
        ForageAndDrugFormSearch
    }
})
export default class ForageRackConfiguration extends Vue{

    private tableData:any[] = [] //table 数据
    private tablePage:any ={  //分页数据
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }
    private ShowAddForagFlag:boolean = false //是否显示弹出层
    private dialogInput:string = '' //弹出层输入框绑定的值
    private dialogTableList:any[] = []

    //事件
    private handlePageChange(val: any) { //分页 事件
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        let domVc:any = this.$refs.SearchItemRef
        //请求列表数据
        this.getTableListReq(domVc.form)
    }
    private EditData(row:any){//编辑
        this.$router.push({
            path:"/EditForageRackConfiguration",
            query:{
                rowData:row
            }
        })
    }
    private RemoveData(row:any){//删除
        //删除提示框
        this.$confirm('是否确认删除？' , '操作确认' , {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() =>{
            this.DeleteItemDataReq(row)
        }).catch(() =>{
            this.$message({
                type: 'info',
                message: '已取消删除'
            })
            this.getTableListReq()
        })
    }
    private AddForageItemType(){ //新增草料类别
        this.AddForageTypeItemReq()
    }



    //emit事件
    private AddForagTypeEmit(flag:boolean){  //新增草料类别
        this.getDialogTableListReq() //获取列表数据
        this.ShowAddForagFlag = flag
    }
    private SearchList(form:any){ //搜索
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getTableListReq(form)
    }
    private ResetList(form:object){ //重置
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getTableListReq(form)
    }



    //请求数据
    private getTableListReq(form?:object){//请求table数据
        let formData:any ={}
        if(form !=undefined){
            formData = JSON.parse(JSON.stringify(form))
            formData.groupCode = formData.groupCode[formData.groupCode.length-1]
            if(formData.enableFlag == 1){
                formData.enableFlag = true
            }else if(formData.enableFlag == 2){
                formData.enableFlag = false
            }else{
                formData.enableFlag = ""
            }
        }
        
        new BasicsClass().BasicGet1('/animal/web/forage/getForage',{...formData , ...this.tablePage},false,false,(data:any)=>{
            this.tableData = data.data
            this.tablePage.totalCount = data.page.totalCount
        })
    }
    private AddForageTypeItemReq(){ //新增草料料类别
        let obj:any = {name:this.dialogInput}
        new BasicsClass().BasicPost('/animal/web/forage/addConfType',{...obj},false,false,false,(data:any)=>{
            this.getDialogTableListReq() //添加成功后获取最新列表
        })
    }
    private getDialogTableListReq(){//弹出层table列表请求 获取草药类型
        new BasicsClass().BasicGet('/animal/web/forage/getConfTypeId',{},false,false,(data:any)=>{
            this.dialogTableList = data.data
        })
    }
    private DeleteItemDataReq(row:any){ //删除
        new BasicsClass().BasicPost('/animal/web/forage/deleteForage',{id:row.id},false,false,true,(data:any)=>{
            this.$message({
                type: 'success',
                message: '删除成功!'
            }),
            this.tablePage.pageNo = 1
            this.tablePage.pageSize = 10
            this.getTableListReq() //获取最新数据
        })
    }

    activated(){
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        let domVc:any = this.$refs.SearchItemRef
        this.getTableListReq(domVc.form)
    }

}
