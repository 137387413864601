









































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Result, ComboList, Column } from "@/common/Views/system/column/column";
import { Util } from "@/common/Views/comm/util";
import { AxiosData } from "@/common/http/http";
//import { DeviceGroupingDetail } from "@/common/Views/configManage/deviceGrouping/deviceGrouping";
import  {
  LabelManage,
  LabelManageFrom,
  LabelManageTable,
  Addnumber,
  Focu
} from "@/common/Views/basics/nblabelManage/labelManage";
import { Rule } from "@/common/Views/comm/formRule";
import {
  DeviceManage,
  DeviceManageTable,
} from "@/common/Views/basics/deviceManage/deviceManage";

@Component
export default class HelloWorld extends Vue {
  private addfrom:Focu ={
    number:'',
    ccid:'',
    imei:'',
    imsi:''
  }
  private labelManage: LabelManage;
  constructor() {
    super();
    this.labelManage = new LabelManage();
  }

  private addnum:Addnumber=new Addnumber();

  private aa():void{
    this.addnum.addnumber(this.addfrom,()=>{
      // aa()
      // console.log('12')
      this.$emit("success", this.form);
    })
  }
  // @Prop({default: false})show!: boolean;
  // @Prop({default: false})comboList!: object;
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: false }) device!: boolean;
  @Prop() sendform! :object;
  @Prop() tablePage! :object; 
  @Prop() sendchilden! :any;
  // show: { 
  //     type: Boolean
  // },
  // comboList: {
  //     type: Array
  public column = new Column();

  private form: DeviceGroupingDetail = {
    groupCode: [],
    farmId: "",
    destinationTypeId: "",
    locationType: "",
    isOutType: "",

  };

  private treeData: Result[] = [];

  private formRule: Focu<Rule[]> = {
    // groupCode: [
    //   // @ts-ignore
    //   {
    //     type: "string",
    //     required: true,
    //     message: "请输入耳标号",
    //     trigger: "blur",
    //   },
    // ],
    // farmId: [{ required: true, message: "请选择养殖场" }],
    number:[{ type:'string',required: true, message: "请完整填写耳标号",trigger: "blur"}],
    ccid:[{  type:'string',required: true, message: "请完整填写CCID号总长度为1~20位",trigger: "blur"}],
    imei:[{  type:'number',required: true, message: "IMEI由15位数字组成",trigger: "blur"}],
    imsi:[{  type:'number',required: true, message: "设置IMSI总长度不可超过15位，使用0~9的数字",trigger: "blur"}]
  };

  private getTree() {
    this.form = new Util().clearObject(this.form);
    Util.getTree((result: Result[]) => {
      this.treeData = result;
    });
  }

  private comboList: ComboList[] = [];

  private changeGroupCode(): void {
    this.comboList = [];
    this.form.farmId = "";
    new Column().getFarm(
      this.form.groupCode[this.form.groupCode.length - 1],
      (data: AxiosData<ComboList[]>) => {
        this.comboList = data.data;
      },
      this.form.destinationTypeId
    );
  }

  @Watch("form.destinationTypeId")
  private handleDestinationTypeId() {
    const vue = new Vue();
    if (this.form.groupCode.length == 0) {
      vue.$message.warning("请选择组织机构");
      this.form.destinationTypeId = "";
      return;
    } else {
      // this.submitFunction()
      this.changeGroupCode();
    }

    // this.getTree()
    // this.column.getTissuc(undefined, (res: any) => {
    //     this.treeData = res.data
    // })
  }

  // private setGroupCode(data: String): void {
  //   console.log(data);
  //   for (const i of this.comboList) {
  //     console.log(i);
  //     if (i.id == data) {
  //       this.form.groupCode.splice(0, 1);
  //       this.form.groupCode.push(i.groupCode);
  //     }
  //   }
  // }
  // Content-Type 不正确, [Content type 'multipart/form-data;boundary=----WebKitFormBoundaryvZz3aWbpYZEcF31g;charset=UTF-8' not supported]

    private setGroupCode(data: String): void {
    console.log(data);
    for (const i of this.comboList) {
      console.log(i);
      if (i.id == data) {
        this.form.groupCode.splice(0, 1);
        this.form.groupCode.push(i.groupCode);
      }
    }
  }

  private deviceManage: DeviceManage;

  constructor() {
    super();
    this.deviceManage = new DeviceManage();
  }

  public activeted() {
    this.form.groupCode = [];
  }
  private childcomboList = '';
  private tableData: LabelManageTable[] = [];
  private submit(): void {
    (this.$refs.addfrom as any).validate((valid: boolean) => {
      // switch(this.addfrom.imei){
      //   case 
      // }
        console.log(this.addfrom)
		console.log(valid)
		// this.checkCCID()
      if (valid) {
		  this.aa();
        
        // console.log(this.addfrom.imei='')
		// let ccid = this.$refs.ccid.value.length != 0
		// let imei = (this.$refs.imei.value + '').length == 15
		// let imsi = (this.$refs.imsi.value + '').length == 15
		// console.log(ccid , imei , imsi)
		// if(ccid == true && imei == true && imsi == true){
		// 	//请求
		// 	this.aa();
		// }
        
        
      }
      //清除input
        this.addfrom.imei=''
        this.addfrom.imsi=''
        this.addfrom.number=''
        this.addfrom.ccid=''
    });
    // console.log(this.comboList)

    // this.labelManage.getTable(
    //   this.sendform,
    //   this.tablePage,
    //   (data: AxiosData<LabelManageTable[]>) => {
    //       this.tablePage.totalCount = data.page.totalCount;
    //       this.sendtableData = data.data;
    //       this.tableData = this.sendtableData;
    //       console.log(this.sendtableData)
    //       window.localStorage.setItem('nblist',JSON.stringify(this.sendtableData));
    //     }
    // )
  }
    // private checkCCID(){
	// 	// if(this.$refs.ccid.value.length == 20)
		
	// }
}
