
























































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import LabelManageDetailImport from "@/views/basics/labelManage/labelManageDetail/labelManageDetailImport.vue";
import {
    LabelManage,
    LabelManageFrom,
    LabelManageTable,
} from "@/common/Views/basics/labelManage/labelManage";
import { ComboList, Column, Result } from "@/common/Views/system/column/column";
import DeviceGroup from "@/views/basics/deviceManage/deviceGroup/deviceGroup.vue";
import { LabelManagement } from "@/common/Views/configManage/labelManagement/labelManagement";
import {
    DeviceManage,
    DeviceManageForm,
    DeviceManageTable,
} from "@/common/Views/basics/deviceManage/deviceManage";
import { values } from "_xe-utils@2.8.3@xe-utils/methods";

import { BasicsClass } from "@/common/BasicsClass";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import { getJDAnimalFarmList316, getSystemAllCompanyList } from "@/api/index";
import XLSX from "xlsx";
import InputConponentsVue from "@/components/BasicConponents/InputConponentsVue.vue";

@Component({
    components: {
        DeviceGroup,
        SelectComponentsVue,
        "label-manage-detail-import": LabelManageDetailImport,
        InputConponentsVue,
    },
})
export default class HelloWorld extends Vue {
    public column = new Column();
    private showLabelSelectInputFlag: boolean = false;
    private form: any = {
        groupCode: [],
        farmId: "",
        number: "",
        isBind: "",
        tagType: "",
        time: [],
        startTime: [],
        endTime: [],
        tagStatus: "",
        companyId: "",
    };
    private singleForm = {
        labelNumber: "",
        companyId: "",
        cause: "",
    };
    private batchForm = {
        companyId: "",
        cause: "",
    };
    get singleRulus() {
        return {
            labelNumber: [
                {
                    required: true,
                    message: this.$t(
                        "hardware_module.labelnumber.enterTagNumber"
                    ) as string,
                },
            ],
        };
    }
    get batchRulus() {
        return {};
    }
    private candel = true;
    private labelNumberArr: any = [];
    private dialogupdload: boolean = false;
    private show: boolean = false;
    private elsxurl: string = "javascript:void(0)";
    private labelManage: LabelManage;
    private langType = this.$store.state.lanuage;
    private radio: string = "1";
    private NoFile: boolean = false;
    private FarmListData: any[] = [];
    private SetFarmVal(val: string) {
        this.DialogForm.farmId = val;
    }

    private getFarmListData() {
        this.FarmListData = [];
        getJDAnimalFarmList316((res: any) => {
            res.data.forEach((item: any) => {
                this.FarmListData.push({
                    id: item.farmId,
                    name: item.farmName,
                });
            });
        });
    }

    private childComboList: ComboList[] = [];

    constructor() {
        super();
        this.labelManage = new LabelManage();
    }
    private companyList: Array<{ [key: any]: string }> = [];
    private companyListForForm: Array<{ [key: any]: string }> = [];
    private activated(): void {
        this.getTree(() => {
            this.getTable();
        });
        this.changeGroupCode();
        this.getFarmListData();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
        getSystemAllCompanyList((res: any) => {
            this.companyList = res.data;
            this.companyListForForm = [...res.data];
            this.companyList.unshift({
                id: "",
                name: this.$t("common.placeholder.select") as string,
            });
        });
    }

    @Watch("$store.state.lanuage", { immediate: true })
    private changeLanuage(newVal: any) {
        if (this.companyList.length)
            this.companyList[0].name = this.$t(
                "common.placeholder.select"
            ) as string;
    }
    private selectionChange(data: any) {
        console.log(data.selection[0]);
        for (let i in data.selection) {
            this.labelNumberArr.push(data.selection[i].number);
        }
    }
    private selectAll(data: any) {
        console.log(data.selection);
        for (let i in data.selection) {
            this.labelNumberArr.push(data.selection[i].number);
        }
    }
    private showSingleFlag = false;
    private handleClose(done: Function) {
        done();
    }
    //event 确认添加单个耳标按钮
    private enterSingle() {
        (this.$refs.singleForm as any).validate((valid: boolean) => {
            if (valid) {
                if (
                    this.singleForm.companyId == "" &&
                    this.singleForm.cause == ""
                ) {
                    this.$message({
                        type: "warning",
                        message: this.$t(
                            "hardware_module.labelnumber.submitTip_20"
                        ) as string,
                    });
                    return;
                }
                console.log(this.singleForm, "单个耳标817");
                new LabelManagement().addlabel(
                    (res: any) => {
                        console.log(res);
                        if (res.code == 200) {
                            this.singleForm.labelNumber = "";
                            this.singleForm.companyId = "";
                            this.singleForm.cause = "";
                            console.log(this.singleForm.companyId);
                            this.showSingleFlag = false;
                            this.getTable();
                            this.$message({
                                type: "success",
                                message: this.$t(
                                    "hardware_module.labelnumber.submitTip_6"
                                ) as string,
                            });
                        } else {
                            this.$message({
                                type: "warning",
                                message: res.message,
                            });
                        }
                    },
                    this.singleForm.labelNumber,
                    this.singleForm.companyId,
                    this.singleForm.cause
                );
            }
        });
    }
    private addlabel(): void {
        this.showSingleFlag = true;
        // this.$prompt("$t('hardware_module.tagNumber.')", "$t('hardware_module.labelnumber.addTag')", {
        //     confirmButtonText: "确定",
        //     cancelButtonText: "取消"
        //     //   inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        //     //   inputErrorMessage: "邮箱格式不正确",
        // })
        //     .then(({ value }) => {
        //         if (value != null) {
        //             this.labelNumberArr.push(value);
        //             new LabelManagement().addlabel((res: any) => {
        //                 console.log(res);

        //                 if (res.code == 200) {
        //                     this.$message({
        //                         type: "success",
        //                         message: " 添加成功"
        //                     });
        //                     this.labelNumberArr = [];
        //                     this.getTable();
        //                 } else {
        //                     this.$message({
        //                         type: "warning",
        //                         message: res.message
        //                     });
        //                     this.labelNumberArr = [];
        //                 }
        //             }, this.labelNumberArr);
        //         } else {
        //             this.$message({
        //                 type: "error",
        //                 message: " $t('hardware_module.enterTagNumber.')"
        //             });
        //         }
        //     })
        //     .catch(() => {
        //         // this.$message({
        //         //   type: "info",
        //         //   message: "123",
        //         // });
        //     });
    }
    private addlabels(): void {
        this.dialogupdload = true;
    }
    private dellabels(): void {
        // this.$confirm("此操作将删除耳标, $t('hardware_module.labelnumber.yes')$t('hardware_module.labelnumber.no')继续?", "提示", {
        //   confirmButtonText: "确定",
        //   cancelButtonText: "取消",
        //   type: "warning",
        // })
        //   .then(() => {
        let removeRecords: any = [];
        this.candel = true;
        removeRecords = (this.$refs.table as any).getSelectRecords();
        removeRecords.forEach((value: any) => {
            if (this.candel == false) {
                return;
            }
            if (value.isBind == false) {
                this.labelNumberArr.push(value.number);
            } else {
                this.labelNumberArr = [];
                this.$message({
                    message: this.$t(
                        "hardware_module.labelnumber.submitTip_1"
                    ) as string,
                    type: "warning",
                });
                return (this.candel = false);
            }
        });
        if (this.labelNumberArr.length > 0 && this.candel) {
            this.$confirm(
                this.$t("hardware_module.labelnumber.submitTip_2") as string,
                this.$t("hardware_module.labelnumber.submitTip_3") as string,
                {
                    confirmButtonText: this.$t("common.confirm") as string,
                    cancelButtonText: this.$t("common.cencel") as string,
                    type: "warning",
                }
            )
                .then((res) => {
                    console.log(res);
                    new LabelManagement().dellabels((res: any) => {
                        console.log(res);
                        if (res.code == 200) {
                            this.$message({
                                type: "success",
                                message: this.$t(
                                    "hardware_module.labelnumber.submitTip_4"
                                ) as string,
                            });
                            this.labelNumberArr = [];
                            this.getTable();
                        } else {
                            this.$message({
                                type: "warning",
                                message: res.message,
                            });
                        }
                        this.labelNumberArr = [];
                        this.candel = true;
                        this.getTable();
                    }, this.labelNumberArr);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (this.candel) {
            this.$message({
                type: "warning",
                message: this.$t(
                    "hardware_module.labelnumber.submitTip_5"
                ) as string,
            });
            this.candel = true;
        }
        //   })
        //   .catch(() => {
        //     this.$message({
        //       type: "info",
        //       message: "已取消删除",
        //     });
        //   });
    }

    private uploadFileList: any = [];
    private beforeUpload(file: any): void {
        let fileList: any = [];
        fileList.push(file);
        this.uploadFileList = fileList;
    }
    private downmod(): void {
        new LabelManagement().down();
        this.dialogupdload = false;
    }
    private treeData: Result[] = [];
    private getTree(cb: Function) {
        Util.getTree((result: Result[]) => {
            this.treeData = result;
            cb ? cb() : "";
        });
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);

        this.getTree(() => {
            this.getTable();
        });
        this.getFarmListData();
        this.changeGroupCode();
    }

    private search(): void {
        if (this.form.time.length != 0) {
            this.form.startTime = Util.dateTimeUtil(this.form.time[0]);
            this.form.endTime = Util.dateTimeUtil(this.form.time[1]);
        }
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;

        this.getTable();
    }

    private comboList: ComboList[] = [];
    private changeGroupCode(): void {
        this.comboList = [];
        // this.form.farmId = '';
        new Column().getFarm(
            this.form.groupCode[this.form.groupCode.length - 1],
            (data: AxiosData<ComboList[]>) => {
                this.comboList = data.data;
            }
        );
    }

    @Watch("form.groupCode")
    getgroupCode(newVal: Array<object>, oldVal: Array<object>) {
        if (newVal !== oldVal) {
            this.form.farmId = "";
            // this.form.typeConfId = '';
        }
    }

    private tableData: LabelManageTable[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }

    private getTable(): void {
        if (this.$getResourceCodes("label_list")) {
            this.labelManage.getTable(
                this.form,
                this.tablePage,
                (data: AxiosData<LabelManageTable[]>) => {
                    this.tablePage.totalCount = data.page.totalCount;
                    this.tableData = data.data;
                }
            );
        }
    }

    private dialogTableVisible: boolean = false;
    private onClickDeviceGroupingVisible: boolean = false;
    private importVisible: boolean = false;

    private rowData: LabelManageTable[] = [];

    private deviceGrouping(): void {
        let removeRecords: LabelManageTable[] = (this.$refs
            .table as any).getSelectRecords();
        if (removeRecords.length === 0) {
            this.$message.error(
                this.$t("hardware_module.labelnumber.submitTip_7") as string
            );
            return;
        }

        this.rowData = removeRecords;

        this.dialogTableVisible = true;
        this.$nextTick(() => {
            (<any>this.$refs.deviceGroup).getTree();
        });
    }

    private onClickDeviceGrouping(): void {
        this.rowData = [];
        this.onClickDeviceGroupingVisible = true;
        this.$nextTick(() => {
            (<any>this.$refs.onClickDevice).getTree();
        });
    }

    private importDeviceGrouping(): void {
        this.importVisible = true;
        this.$nextTick(() => {
            (<any>this.$refs.importDevice).getTree();
        });
    }

    private confirm(cb: Function) {
        this.$confirm(
            this.$t("hardware_module.labelnumber.submitTip_8") as string,
            this.$t("hardware_module.labelnumber.submitTip_3") as string,
            {
                confirmButtonText: this.$t("common.confirm") as string,
                cancelButtonText: this.$t("common.cencel") as string,
                type: "warning",
            }
        ).then(() => {
            cb();
        });
    }

    private success(data: LabelManageTable): void {
        let flag: boolean = false;
        for (const i of this.rowData) {
            if (i.isGroup) {
                flag = true;
                break;
            }
        }

        if (flag) {
            this.confirm(() => {
                this.groupMapping(data);
            });
            return;
        }

        this.groupMapping(data);
    }

    private groupMapping(data: LabelManageTable) {
        this.labelManage.deviceGroup(data, this.rowData, () => {
            this.onClickDeviceGroupingVisible = false;
            this.dialogTableVisible = false;
            this.getTable();
        });
    }

    private DialogForm: any = {
        custNo: "",
        custName: "",
        farmId: "",
    };
    get DialogRules() {
        return {
            custNo: [
                {
                    required: true,
                    message: this.$t(
                        "hardware_module.labelnumber.submitTip_9"
                    ) as string,
                    trigger: "change",
                },
            ],
            custName: [
                {
                    required: true,
                    message: this.$t(
                        "hardware_module.labelnumber.submitTip_9"
                    ) as string,
                    trigger: "change",
                },
            ],
            farmId: [
                {
                    required: true,
                    message: this.$t(
                        "hardware_module.labelnumber.submitTip_9"
                    ) as string,
                    trigger: "change",
                },
            ],
        };
    }
    //导入
    private readFile(file: any) {
        //文件读取
        return new Promise((resolve) => {
            let reader = new FileReader();
            reader.readAsBinaryString(file); //以二进制的方式读取
            reader.onload = (ev: any) => {
                resolve(ev.target.result);
            };
        });
    }
    private extendsFileLabel: any[] = [];
    private async handle(ev: any) {
        //文件导入
        let file = ev.raw;
        if (!file) {
            this.$message.error(
                this.$t("hardware_module.labelnumber.submitTip_10") as string
            );
            return;
        } else {
            let fileTypeName: any = file.name.split(".");
            if (
                fileTypeName[fileTypeName.length - 1] == "xlsx" ||
                fileTypeName[fileTypeName.length - 1] == "xls"
            ) {
                let data = await this.readFile(file);
                let workbook: any = XLSX.read(data, { type: "binary" }); //解析二进制格式数据
                // console.log('二进制数据的解析:' , workbook)
                console.log(workbook.Strings);
                if (!workbook.Strings[0]) {
                    this.$message.error("文件标题栏为空,解析失败");
                    return;
                }
                if (
                    workbook.Strings[0].h &&
                    workbook.Strings[0].h.length == 3
                ) {
                    if (workbook.Strings[0].h != "耳标号") {
                        this.$message.error(
                            this.$t(
                                "hardware_module.labelnumber.submitTip_12"
                            ) as string
                        );
                        return;
                    }
                }
                if (
                    workbook.Strings[0].r &&
                    workbook.Strings[0].r.length == 3
                ) {
                    if (workbook.Strings[0].r != "耳标号") {
                        this.$message.error(
                            this.$t(
                                "hardware_module.labelnumber.submitTip_12"
                            ) as string
                        );
                        return;
                    }
                }
                if (
                    workbook.Strings[0].t &&
                    workbook.Strings[0].t.length == 3
                ) {
                    if (workbook.Strings[0].r != "耳标号") {
                        this.$message.error(
                            this.$t(
                                "hardware_module.labelnumber.submitTip_12"
                            ) as string
                        );
                        return;
                    }
                }
                if (workbook.Strings[0].h && workbook.Strings[0].h == "") {
                    this.$message.error(
                        this.$t(
                            "hardware_module.labelnumber.submitTip_13"
                        ) as string
                    );
                    return;
                }
                if (workbook.Strings[0].r && workbook.Strings[0].r == "") {
                    this.$message.error(
                        this.$t(
                            "hardware_module.labelnumber.submitTip_13"
                        ) as string
                    );
                    return;
                }
                if (workbook.Strings[0].t && workbook.Strings[0].t == "") {
                    this.$message.error(
                        this.$t(
                            "hardware_module.labelnumber.submitTip_13"
                        ) as string
                    );
                    return;
                }
                let worksheet = workbook.Sheets[workbook.SheetNames[0]]; //获取第一个Sheet
                let result = XLSX.utils.sheet_to_json(worksheet); //json数据格式
                console.log(result);
                result.forEach((item: any) => {
                    if (!item["耳标号"]) {
                        Util.showToast(
                            this.$t(
                                "hardware_module.labelnumber.submitTip_12"
                            ) as string
                        );
                        throw this.$t(
                            "hardware_module.labelnumber.submitTip_14"
                        ) as string;
                    }
                });
                let firstLabel: any = result[0];
                let setLable: any = new Set();
                this.extendsFileLabel = [];
                result.forEach((item: any) => {
                    if (item["耳标号"]) {
                        setLable.add(item["耳标号"].toString());
                    }
                });
                setLable.forEach((item: any) => {
                    console.log(item);
                    this.extendsFileLabel.push(item);
                });
                this.$message({
                    message: this.$t(
                        "hardware_module.labelnumber.submitTip_15"
                    ) as string,
                    type: "success",
                });
            } else {
                Util.showToast(
                    this.$t(
                        "hardware_module.labelnumber.submitTip_16"
                    ) as string
                );
                return;
            }
        }
    }

    private SelectListValue: any[] = [];
    private selectChangeEvent(data: any) {
        this.ErrorMessageFlag = false;
        this.SelectListValue = data.selection;
        this.HandlerSelectData();
    }
    private selectAllEvent(data: any) {
        this.ErrorMessageFlag = false;
        this.SelectListValue = data.selection;
        this.HandlerSelectData();
    }

    private LabelPushArr: any[] = [];
    private ErrorMessageFlag: boolean = false;
    private HandlerSelectData() {
        if (this.SelectListValue.length < 0) return;
        this.LabelPushArr = [];

        for (let i: number = 0; i < this.SelectListValue.length; i++) {
            this.LabelPushArr.push(this.SelectListValue[i].number);
            if (this.SelectListValue[i].isBind) {
                this.ErrorMessageFlag = true;
                this.$message({
                    message: this.$t(
                        "hardware_module.labelnumber.submitTip_17"
                    ) as string,
                    type: "warning",
                });
                this.LabelPushArr = [];
                return;
            }
        }
    }

    private AddDialodData() {
        (this.$refs["DialogRef"] as any).validate((flag: boolean) => {
            if (flag) {
                let obj: any = {
                    ...this.DialogForm,
                    labelNumberList: this.LabelPushArr,
                };
                if (this.radio == "2") {
                    if (this.extendsFileLabel.length != 0) {
                        this.NoFile = false;
                        obj.labelNumberList = this.extendsFileLabel;
                        new BasicsClass().BasicPost(
                            "/animal/web/JDAnimal/labelBind",
                            { ...obj },
                            true,
                            true,
                            true,
                            (data: any) => {
                                this.showLabelSelectInputFlag = false;
                                this.getTable();
                            }
                        );
                    } else {
                        this.NoFile = true; //没有值就显示提示语
                        return;
                    }
                } else {
                    if (this.ErrorMessageFlag) {
                        this.$message({
                            message: this.$t(
                                "hardware_module.labelnumber.submitTip_18"
                            ) as string,
                            type: "warning",
                        });
                        return;
                    } else {
                        if (
                            this.LabelPushArr.length === 0 &&
                            this.radio != "2"
                        ) {
                            this.$message({
                                message: this.$t(
                                    "hardware_module.labelnumber.submitTip_19"
                                ) as string,
                                type: "warning",
                            });
                        } else {
                            new BasicsClass().BasicPost(
                                "/animal/web/JDAnimal/labelBind",
                                { ...obj },
                                true,
                                true,
                                true,
                                (data: any) => {
                                    this.showLabelSelectInputFlag = false;
                                    this.ClearDialogObj();
                                    this.getTable();
                                }
                            );
                        }
                    }
                }

                // new BasicsClass().BasicPost('/animal/web/JDAnimal/labelBind',{...obj},true,true,true,(data:any)=>{
                //   this.showLabelSelectInputFlag = false
                //   this.getTable();
                // })
            }
        });
    }

    private ClearDialogObj() {
        this.LabelPushArr = [];
        this.SelectListValue = [];
    }
    private CloseDialog() {
        this.showLabelSelectInputFlag = false;
    }

    // emit single labelNumber
    private EmitLabelNumber(labelNumber: string) {
        this.singleForm.labelNumber = labelNumber;
    }
    //emit single compony
    private EmitCompany(companyId: string) {
        this.singleForm.companyId = companyId;
    }

    private EmitBatchCompany(companyId: string) {
        this.batchForm.companyId = companyId;
    }
    private AddTagBindCompany() {}

    private importExcelAndBindCompany() {
        (this.$refs["dialogBatchform"] as any).validate((flag: boolean) => {
            if (flag) {
                if (
                    this.batchForm.companyId == "" &&
                    this.batchForm.cause == ""
                ) {
                    this.$message({
                        type: "warning",
                        message: this.$t(
                            "hardware_module.labelnumber.submitTip_20"
                        ) as string,
                    });
                    return;
                }
                if (this.uploadFileList.length == 0) {
                    this.$message({
                        type: "warning",
                        message: this.$t(
                            "hardware_module.labelnumber.noFileTip"
                        ) as string,
                    });
                    return;
                }
                const loading = this.$loading({
                    lock: true,
                    text: this.$t(
                        "hardware_module.labelnumber.submitTip_22"
                    ) as string,
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                new LabelManagement().uploadFile(
                    this.uploadFileList,
                    this.batchForm,
                    (res: any) => {
                        if (res.code == 200) {
                            this.$message({
                                type: "success",
                                message: this.$t(
                                    "hardware_module.labelnumber.submitTip_6"
                                ) as string,
                            });
                        } else {
                            this.$message({
                                type: "warning",
                                message: res.message,
                            });
                        }
                        this.uploadFileList = [];
                        this.batchForm.companyId = "";
                        this.batchForm.cause = "";
                        this.dialogupdload = false;
                        loading.close();
                        this.getTable();
                    }
                );
            }
        });
    }
}
