















































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {AxiosData} from "@/common/http/http";
import {Util} from "@/common/Views/comm/util";
import {HandleTable, HandlePageChange} from "@/common/Views/comm/handleTable";
import LabelManageDetailImport from "@/views/basics/labelManage/labelManageDetail/labelManageDetailImport.vue";
import {LabelManage, LabelManageFrom, LabelManageTable} from "@/common/Views/basics/labelManage/labelManage";
import { ComboList, Column, Result } from '@/common/Views/system/column/column';
import DeviceGroup from '@/views/basics/deviceManage/deviceGroup/deviceGroup.vue';
import {DeviceManageTable} from '@/common/Views/basics/deviceManage/deviceManage';
import {CountException, CountExceptionFrom} from "@/common/Views/basics/countException/countException";
import {TemperatureInfo} from '@/common/Views/basics/temperatureInfo/temperatureInfo';
import {MedicineInfo} from '@/common/Views/basics/medicineInfo/medicineInfo';
import {
    HandleTemperatureException,
    HandleTemperatureReason
} from "@/common/Views/basics/handleTemperatureException/handleTemperatureException";

@Component({
    components: {DeviceGroup, "label-manage-detail-import": LabelManageDetailImport}
})
export default class HelloWorld extends Vue {

    public column = new Column()

    private form: CountExceptionFrom = {
        groupCode: [],
        farmId: '',
        labelNumber: '',
        warnTime: [],
        handleTime: [],
        type: '',
        time: []
    };

    private show: boolean = false;

    private handleTemperatureException: HandleTemperatureException;

    constructor() {
        super();
        this.handleTemperatureException = new HandleTemperatureException();
    }

    private Export() {
        new HandleTemperatureException().Export(this.form);

    }

    private activated(): void {
        this.getTable();
        this.getTree();
        this.changeGroupCode();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })

        this.getHandleTemperatureReason();
    }

    private handleList: HandleTemperatureReason[] = [];

    private getHandleTemperatureReason() {
        this.handleList = [];
        new HandleTemperatureException().getHandleTemperatureReason((data: AxiosData<HandleTemperatureReason[]>)=>{
            data.data.forEach(value => {
                if (value.pid === 12) {
                    this.handleList.push(value)
                }
            })
        })
    }

    private treeData: Result[] = [];
    private getTree() {
        Util.getTree((result: Result[])=>{
            this.treeData = result
        })
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);

        this.getTable();
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;

        this.getTable();
    }


    private comboList: ComboList[] = [];
    private changeGroupCode(): void {
        this.comboList = [];
        // this.form.farmId = '';
        new Column().getFarm(this.form.groupCode[this.form.groupCode.length - 1], (data: AxiosData<ComboList[]>)=>{
            this.comboList = data.data;
        })
    }

    @Watch("form.groupCode")
    getGroupCode(newVal:Array<object>,oldVal:Array<object>) {
        if (newVal !== oldVal) {
            this.form.farmId = ''; 
        }
    }


    private tableData: LabelManageTable[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private handlePageChange(val: HandlePageChange) {

        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }


    private getTable(): void {
        if(this.$getResourceCodes('label_list')){
            this.handleTemperatureException.getTable(this.form, this.tablePage, (data: AxiosData<LabelManageTable[]>) => {
                this.tablePage.totalCount = data.page.totalCount;
                this.tableData = data.data;
            });

        }
    }



    private rowData: LabelManageTable[] = [];
}
