






















































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Util } from "@/common/Views/comm/util";
import { ComboList, Column, Result } from "@/common/Views/system/column/column";

//page
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";

// import {appUser} from './components/appAll'
import { appUser } from "./components/appAll";
import i18n from "@/vue-il8n";

@Component({})
export default class AppUser extends Vue {
    private form: any = {
        username: "",
        mobile: "",
        roleId: "",
        companyId: "",
        groupCode: "",
        linkEnter: "",
        role: [this.$t("system.app_user.Aquaculture_manager")],
        status: "0",
        farmData: "",
        farmId: "",
    };

    public infoData = {};
    private infoType = false;
    private langType: string = this.$i18n.locale;
    //展示详细信息
    private clickShowInfo(row: any) {
        this.infoData = row;
        this.infoType = true;
    }

    //关联企业数组
    private enterpriseData: any = [];
    //关联企业 || 养殖场组织机构 树形联级菜单配置props
    private props: Object = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "id",
    };
    //关联企业 change
    private enterpriseHandleChange(value: any) {
        // console.log(value);
        //获取最后一项
        // console.log(value[value.length-1])
    }
    //请求关联企业
    private getLinkEnterprise() {
        //请求体
        new appUser().getLinkEnterprise((data: any) => {
            // console.log(data)
            this.enterpriseData = data.data;
        });
    }

    //养殖场组织机构
    private farmData: any = [];

    //养殖场组织机构 change
    private farmHandleChange(value: any) {
        // console.log(value);
        //获取最后一项
        // console.log(value[value.length-1])
    }
    //请求养殖场组织机构
    private getFarmData() {
        //请求体
        new appUser().getFarmData((data: any) => {
            // console.log(data)
            this.farmData = data.data;
        });
    }

    //table 数据
    private tableData: any = [];

    //权限角色
    private userName: any = [];
    //请求权限角色接口
    private getUserName() {
        this.userName = [];
        new appUser().getUserName(true,(data: any) => {
            // console.log(data)
            this.userName = data.data;
        });
    }

    //关联养殖场
    private linkFarm: any = [];
    //请求关联养殖场
    private getLinkFarm() {
        this.linkFarm = [];
        new appUser().getLinkFarm({},"", (data: any) => {
            this.linkFarm = data.data;
        });
    }

    //table Page
    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    //handlePageChange
    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        //请求列表数据
        this.getList();
    }

    //养殖场组织机构的treeData
    private treeData: Result[] = [];

    //请求列表数据
    private getList() {
        if (this.form.status == "0") {
            this.form.status = "";
        }
        if (this.form.groupCode[this.form.groupCode.length - 1]) {
            this.form.groupCode = this.form.groupCode[
                this.form.groupCode.length - 1
            ];
        }
        // console.log('------ 表单查询数据', this.form)

        // 如果选择全国，回事一个 [0] 的数组，需要处理一下，0 或者 '' 都行
        if (Array.isArray(this.form.groupCode))
            this.form.groupCode = this.form.groupCode[0];
        //整理数据
        new appUser().getUserList(this.form, this.tablePage, (data: any) => {
            this.tableData = data.data;

            this.tablePage.totalCount = data.page.totalCount;
        });
    }

    //新增用户
    private handlePerson() {
        let clearAll: boolean = true;
        //跳转
        this.$router.push({
            path: "/appAddUser",
            query: {
                clearAll: true,
            },
        });
    }

    //编辑修改
    private setPerson(scope: any) {
        window.AppSetData = scope.row;
        // 跳转
        this.$router.push({
            path: "/appSetUser",
            query: {
                rowData: JSON.stringify(scope.row),
            },
        });
    }

    //查询
    private search() {
        //设置page 为第一页
        this.tablePage.pageNo = 1;
        //请求列表数据
        this.getList();
    }

    //重置
    private restForm() {
        this.clearInput();
        //请求列表数据
        this.getList();
    }

    private clearInput() {
        this.form.username = "";
        this.form.mobile = "";
        this.form.roleId = "";
        this.form.companyId = "";
        this.form.groupCode = "";
        this.form.linkEnter = "";
        this.form.role = [];
        this.form.status = "";
        this.form.farmData = "";
        this.form.farmId = "";
    }

    activated() {
        //请求列表数据
        this.getList();
    }

    //进入后
    mounted() {
        // 请求关联企业
        this.getLinkEnterprise();

        //请求养殖场组织机构
        this.getFarmData();

        //权限角色
        this.getUserName();

        //请求关联养殖场
        this.getLinkFarm();
    }
    //修改隶属关系
    //隶属关系树配置项
    private roleGroupDialogVisible: boolean = false;
    private treeDateCopy: any = {};
    private treeConfig: any = {
        treeDate: {},
        horizontal: false, //横向展示
        collapsable: true, //是否可折叠
        onlyOneNode: false, //仅拖动当前节点
        cloneNodeDrag: false, //是否拷贝节点拖拽 false仅拖拽  true拷贝+保留原位
        disaled: true, //是否禁用编辑
        style: {
            color: "#fff",
            background: "#36be98",
            border: "1px solid",
            scle: "0.7",
        },
        toolBar: {
            scale: false,
        },
    };
    //拖拽节点后触发
    private moveTreeDateCopy: any = {};
    private errType = false;
    private nodeDragEnd(data: any, isSelf: any) {
        if (data.pid == isSelf.id) {
            return;
        }
        console.log(data, "被拖拽的数据");
        console.log(this.treeConfig.treeDate, "拖拽后总数据111");
        let nodettt = this.deepClone(this.treeConfig.treeDate);
        //if (!this.checkTreeInit(nodettt)) {
        //    this.$message.error("树效验失败");
        //return;
        //}
        //this.setDragFlags(this.treeConfig.treeDate,data);
        let nodeCount = this.countNodes(this.treeConfig.treeDate);
        if (nodeCount !== this.nodeCount) {
            this.$message.error(
                this.$t("system.app_user.Darg_error") as string
            );
            this.errType = true;
            console.log(
                this.treeConfig.treeDate,
                "拖拽失败，拖拽后节点数量不一致"
            );
            console.log(
                this.countNodes(this.treeConfig.treeDate),
                "拖拽失败，拖拽后节点数量"
            );
            return;
        }
        this.moveTreeDateCopy = JSON.parse(
            JSON.stringify(this.treeConfig.treeDate)
        );

        this.setDragFlags(this.treeConfig.treeDate, data);
    }
    //添加拖拽节点
    private addDragNode(node: any) {
        if (node.children && node.children.length) {
            for (let i = 0; i < node.children.length; i++) {
                if (node.children[i].id === this.targetNode.id) {
                    this.dragNode.pid = this.targetNode.id;
                    node.children[i].children.push(this.dragNode);
                    return;
                } else {
                    this.addDragNode(node.children[i]);
                }
            }
        }
    }
    private deleteNode = (node: any) => {
        if (node.children && node.children.length) {
            for (let i = 0; i < node.children.length; i++) {
                if (
                    node.children[i].id === this.dragNode.id &&
                    node.children[i].pid !== this.targetNode.id
                ) {
                    node.children.splice(i, 1);
                    return;
                } else {
                    this.deleteNode(node.children[i]);
                }
            }
        }
    };

    private deepClone(obj: any) {
        if (obj === null || typeof obj !== "object") {
            return obj;
        }

        let temp = obj.constructor(); // 给出了正确的原型
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                temp[key] = this.deepClone(obj[key]);
            }
        }

        return temp;
    }

    //拖拽结束之前触发
    private dragNode: any = {};
    private targetNode: any = {};
    private beforeDragEnd(node: any, targetNode: any) {
        console.log(targetNode, "targetNode");
        this.dragNode = this.deepClone(node);
        this.targetNode = this.deepClone(targetNode);
        return new Promise((resolve, reject) => {
            if (!targetNode) {
                reject(); // 提供错误信息
            } else if (node.id === targetNode.id) {
                reject(); // 提供错误信息
            } else if (targetNode.roleType === 3 && targetNode.roleGroup == 1) {
                reject();
            } else if (targetNode.roleType === 2 && targetNode.roleGroup != 1) {
                reject();
            } else {
                resolve("v"); // 允许拖拽
            }
        });
    }
    //获取节点总数量
    private countNodes(node: any) {
        // 开始计数，当前节点算一个
        let count = 1;

        // 如果当前节点有子节点
        if (node.children && node.children.length) {
            // 遍历子节点，递归计算每个子节点的数量并累加到count上
            node.children.forEach((childNode: any) => {
                count += this.countNodes(childNode);
            });
        }

        // 返回当前节点及其所有子节点的总数
        return count;
    }
    //设置拖动状态dragFlag
    private mapTreeNodes(tree: any, map = new Map()) {
        map.set(tree.id, tree);
        if (tree.children) {
            tree.children.forEach((child: any) =>
                this.mapTreeNodes(child, map)
            );
        }
        return map;
    }
    private setDragFlags(tree1: any, tree2: any) {
        const map1 = this.mapTreeNodes(tree1);
        const map2 = this.mapTreeNodes(tree2);

        for (let [id, node] of map1) {
            if (map2.has(id)) {
                node.dragFlag = 1;
                map2.get(id).dragFlag = 1;
            }
        }
    }
    //获取隶属关系树
    private nodeCount: any = 0;
    private getTreeData(userId: any) {
        new appUser().getbelowUserTree(userId, (data: any) => {
            let datat = this.addLabelToNodes(data.data);
            console.log(datat, "修改前的树");
            this.treeConfig.treeDate = JSON.parse(JSON.stringify(datat));
            this.treeDateCopy = { ...datat };
            this.nodeCount = this.countNodes(datat);
        });
    }

    //递归添加关系树label
    private addLabelToNodes(data: any) {
        // 检查当前节点的username和roleNick，并创建label
        if (data.username && data.roleNick) {
            data.label = `${data.username}(${data.roleNick})`;
        } else if (data.username) {
            // 如果没有roleNick，只使用username
            data.label = data.username;
        }

        // 如果存在children，则递归处理每个子节点
        if (data.children && data.children.length > 0) {
            data.children.forEach((child: any) => this.addLabelToNodes(child));
        }

        return data;
    }
    //点击修改隶属关系
    private clickUpdateRoleGroup(row: any) {
        this.roleGroupDialogVisible = true;
        this.treeConfig.treeDate = {};
        this.nodeCount = 0;
        this.moveTreeDateCopy = {};
        this.treeDateCopy = {};
        this.getTreeData(row.id);
    }
    //关闭rolegroupdialog
    private CloseDialog() {
        this.errType = false;
        this.roleGroupDialogVisible = false;
        //this.treeConfig.treeDate = JSON.parse(
        //    JSON.stringify(this.treeDateCopy)
        //);
    }
    //保存roleGroup选择后的树
    private AddDialodData() {
        if (this.errType) {
            this.$message.error(
                this.$t("system.app_user.Darg_error") as string
            );
            return;
        }
        console.log(this.treeConfig.treeDate, "保存后的树");
        //this.roleGroupDialogVisible = false;
        //调用修改隶属关系接口
        new appUser().editTreeUser(this.treeConfig.treeDate, (data: any) => {
            console.log(data, "修改后隶属关系树");
            //this.$message.success(this.$t("system.app_user.Modified_successfully")  as string);
            this.getList();
        });
    }
}
