






































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
// import {Result, Column} from "@/common/Views/system/column/column";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {optionSelect, Rule} from "@/common/Views/comm/formRule";
import {ConfType} from "@/common/Views/system/confType/confType";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {AxiosData} from "@/common/http/http";
import {SlaughterDetailForm, SlaughterDetail, SlaughterDetailDetail, CityList} from "@/common/Views/system/slaughter/slaughterDetail/slaughterDetail";
import {Util} from "@/common/Views/comm/util";
import TitleSolt from '@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue';
import { BasicsClass } from "../../../../common/BasicsClass";
import { Column, ComboList, Result } from "@/common/Views/system/column/column";


@Component({
    components: {TitleSolt}
})

export default class HelloWorld extends Vue {

    private form:any ={
        parentId:'',
        name:"",
        groupCode:["0"],
        person:"",
        mobile:"",
        detailAddress:"",
        licenseUrl:"",
        doorUrl:"",
        status:"",
        companyId:"",
        groupCodeStr:'',
        id:''
    }

    private rules: SlaughterDetailForm<Rule[]> = {
        groupCode: [
            // @ts-ignore
            // {type: "array", required: true, message: "请选择组织机构"}
            {required: true, message: "请选择组织机构"}
        ],
        mobile: [
            {required: true, message: "请填写联系电话"},
            {
                message: "手机号码输入错误", validator: (rule: any, value: any, callback: any) => {
                    if (!/^[0-9]{11}$/g.test(value)) {
                        callback(new Error());
                    } else {
                        callback();
                    }
                }
            },
        ],
        person: [
            {required: true, message: "请填写联系人"}
        ],
        status: [
            {required: true, message: "请选择启用状态"}
        ],
        name: [
            {required: true, message: "请填写屠宰场名称"}
        ],
        detailAddress: [
            {required: true, message: "请填写详细地址"}
        ],
        doorUrl: [
            {required: true, message: "请上传照片"}
        ],
        parentId: [
            {required: true, message: "请填写所属企业"}
        ],
    };

    private BlurEnter(val:any){
        // console.log(val)
        this.form.companyId = val
    }

    //所属企业
    private firmList:any = []
    private getLinkEnter(){
        new SlaughterDetail().getLinkFarm((data:any) => {
            this.firmList = data.data
        })
    }

    //所属机构
    private farmData:any = []
    private props:Object = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "id",
        emitPath:false
    }
    private getFarmData(){
        new SlaughterDetail().getFarmData((data:any) => {
            data.data.forEach((item:any) => {
                item.children.forEach((TowItem:any) => {
                    TowItem.children.forEach((ThreeItem:any) => {
                        ThreeItem.children.forEach((FourItem:any) => {
                            FourItem.children.forEach((FiveItem:any) => {
                                console.log(FiveItem)
                                if(FiveItem.children.length === 0){
                                    delete FiveItem.children
                                    console.log(data.data)
                                }
                            });
                        });
                    });
                });
            });
                this.farmData = data.data
            }
        )
    }
    private farmHandleChange(value:any) {
        this.form.groupCode
    }


    //上传
    private showMark1: boolean =  false;
    private showMark2: boolean =  false;
    public dialogVisible = false
    private url: string =  '';
    public ImgList = []
    private dataImg: boolean = false
    private ossconfigdata:any ={}

    private handlePictureCardPreview(file: any): void {
        console.log(file.url)
        this.form.licenseUrl = file.url;
        this.dialogVisible = true;
    }
    private handleRemove(file: any): void {
        // console.log(file);
        this.form.licenseUrl = ''
        this.ImgList = []
    };
    private setImg(file: any, fileList: any): void {
        this.readImg(file, fileList, "licenseUrl");
        if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
        this.putObject(file);
        } else {
        Util.showToast("请上传图片文件", "warnning");
        }

        // console.log(this.form)
    }
    private setImg1(file: any, fileList: any): void {
        this.readImg(file, fileList, "doorUrl");
        if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
        this.putObject1(file);
        } else {
        Util.showToast("请上传图片文件", "warnning");
        }

        // console.log(this.form)
    }
    private showDio(url: string) {
        this.dialogVisible = true;
        this.url = url;
    }
    private del(url: keyof SlaughterDetail) {
        (<string>this.form[url]) = '';
        this.showMark1 = false;
        this.showMark2 = false;
    }
    private readImg(file: any, fileList: any, url: any) {
        const reader = new FileReader();
        let res = reader.readAsDataURL(file.raw);
        reader.onloadend = () => {
            this.dataImg = false;
            // var strBase64 = reader.result.substring(84);
            const strBase64 = (<string>reader.result).substring(0);
            // this.form.licensePicture = {code: "license", base64ImgData: strBase64, url: ""};
            (<string>this.form[url]) = strBase64;
            // console.log(this.form);
        };
        reader.onload = function(e) {
            // console.log(e);
            // simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc
        };

    }
    //获取oss参数
    private ossconfig(): void {
        new Column().ossconfig((res: any) => {
        this.ossconfigdata = res.data;
        });
    }

    private async putObject1(file: any) {
        let OSS = require("ali-oss");
        // const data = new OSS.Buffer(file);
        let client = new OSS({
        region: "oss-cn-hangzhou",
        accessKeyId: this.ossconfigdata.accessKeyId,
        accessKeySecret: this.ossconfigdata.accessKeySecret,
        stsToken: this.ossconfigdata.securityToken,
        bucket: this.ossconfigdata.bucketName,
        });
        try {
        const result = await client.put(
            this.ossconfigdata.bucketName +
            "/" +
            this.ossconfigdata.directory +
            this.ossconfigdata.userId +
            "/" +
            file.name,
            file.raw
        );
        console.log(result);
        this.form.doorUrl = result.url
        // let quill = this.$refs.myQuillEditor.quill;
        // let length = quill.getSelection().index;
        // quill.insertEmbed(length, "image", result.url);
        // quill.setSelection(length + 1);
        } catch (e) {
        // console.log(e);
        }
    }

    private async putObject(file: any) {
        let OSS = require("ali-oss");
        // const data = new OSS.Buffer(file);
        let client = new OSS({
        region: "oss-cn-hangzhou",
        accessKeyId: this.ossconfigdata.accessKeyId,
        accessKeySecret: this.ossconfigdata.accessKeySecret,
        stsToken: this.ossconfigdata.securityToken,
        bucket: this.ossconfigdata.bucketName,
        });
        try {
        const result = await client.put(
            this.ossconfigdata.bucketName +
            "/" +
            this.ossconfigdata.directory +
            this.ossconfigdata.userId +
            "/" +
            file.name,
            file.raw
        );
        console.log(result);
        this.form.licenseUrl = result.url
        // let quill = this.$refs.myQuillEditor.quill;
        // let length = quill.getSelection().index;
        // quill.insertEmbed(length, "image", result.url);
        // quill.setSelection(length + 1);
        } catch (e) {
        // console.log(e);
        }
    }


    //启用状态
    private statusOptions: optionSelect<number>[] = [
        {
            value: 1,
            label: "启用",
        },
        {
            value: 0,
            label: "禁用",
        },

    ];

    private toFather(){
        this.$router.push({
            path:'/slaughter'
        })
    }

    //save
    private save(){
        (this.$refs.form as any).validate((valid: boolean) => {
            if (valid) {
                console.log(this.form)
                
                if(this.isSet){
                    new SlaughterDetail().setFarm(this.form ,(data:any) => {
                        this.toFather()
                    })
                }else{
                    new SlaughterDetail().addFarm(this.form,(data:any) => {
                        this.toFather()
                    })
                }
            }
        });
    }

    private simpleAddress:string = ''
    private showText:boolean = true
    activated(){
        this.getLinkEnter()
        this.getFarmData()
        this.ossconfig()

        if(this.isSet){
            new SlaughterDetail().resetData(this.FarmId , (data:any) => {
                console.log(data)
                let datas:any = data.data
                
                this.form.parentId = datas.companyId
                this.form.name = datas.name
                this.form.detailAddress = datas.detailAddress
                this.form.groupCode = datas.groupCode
                this.form.person = datas.person
                this.form.mobile = datas.mobile
                this.form.licenseUrl = datas.licenseUrl
                this.form.doorUrl = datas.doorUrl
                this.form.id = datas.id
                this.form.status = datas.status

                // let adressText:any[] = datas.simpleAddress.split('-')
                // this.simpleAddress = adressText[adressText.length-1]
                
                let doms:any = this.$refs.cascaderCode
                doms.$el.children[0].children[0].value = datas.simpleAddress
                if(datas.status){
                    this.form.status = "启用"
                }else{
                    this.form.status = "禁用"
                }
            })
        }
        if(this.$route.query.setFlag == 'false'){
            this.resetForm()
        }
    }

    private resetForm(){
        this.form.parentId=''
        this.form.name=""
        this.form.groupCode=["0"]
        this.form.person=""
        this.form.mobile=""
        this.form.detailAddress=""
        this.form.licenseUrl=""
        this.form.doorUrl=""
        this.form.status=""
        this.form.companyId=""
        this.form.groupCodeStr=''
        this.form.id=''
    }


    private isSet:boolean = false
    private FarmId:any = ''
    @Watch('$route',{immediate:true,deep:true})
    private changeRoute(newVal:any){
        if(newVal.query){
            if(newVal.query.rowData){
                let queryData:any = newVal.query.rowData
                this.FarmId = queryData.farmId
            }
            if(newVal.query.setFlag == 'true'){
                this.isSet = true
            }
            if(newVal.query.setFlag == 'false'){
                this.isSet = false
            }
        }
    }

    @Watch('form.groupCode')
    changecode(newVal:any){
        console.log(newVal)
    }
}
