import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { Util } from '@/common/Views/comm/util';
import { CountExceptionFrom } from '@/common/Views/basics/countException/countException';

export interface InfoRegisterDetail {
    ageDays: number | '';
    createTime: string;
    createUserName: string;
    farmId: string;
    farmName: string;
    gender: number | '';
    groupCode: string;
    groupName: string;
    id: string;
    labelNumber: string;
    pictureIds: string;
    pictures: { id: string; url: string }[];
    typeConfId: string;
    typeConfName: string;
    vaccineNames: string;
    weight: number | '';

}

export class InfoRegister extends BasicsClass {

    public getTable(form: CountExceptionFrom, tablePage: HandleTable, cb: Function) {
        const time = Util.handleStartTimeAndEndTime(form.time);
        this.BasicGet('/animal/web/animalMark/animalList', {
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length - 1],
            ...time,
        }, false, true, cb, cb);
    }

    public getTable2(form: CountExceptionFrom, tablePage: HandleTable, cb: Function) {
        const time = Util.handleStartTimeAndEndTime(form.time);
        this.BasicGet('/animal/web/thirdMonitor/getList', {
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length - 1],
        }, false, true, cb, cb);
    }

    public getTable3(cb: Function) {
        this.BasicGet('/animal/web/thirdMonitorConfig/getConfigList', {

        }, false, true, cb, cb);
    }

    public associatedFarm(form: any, cb: Function) {
        this.BasicPost('/animal/web/thirdMonitor/associatedFarm', {
            ...form,
            associationStatus: form.associationStatus.toString(),
            groupCode: form.groupCode[form.groupCode.length - 1],
        }, true, true, true, cb)
    }

    public addConfig(form: any, cb: Function) {
        this.BasicPost('/animal/web/thirdMonitorConfig/addConfig', {
            ...form,
        }, true, true, true, cb)
    }

    public editConfig(form: any, cb: Function) {
        this.BasicPost('/animal/web/thirdMonitorConfig/editConfig', {
            ...form,
        }, true, true, true, cb)
    }

    public getList(cb: Function) {
        this.BasicGet('/animal/web/thirdMonitorChack/getList', {

        }, false, true, cb, cb);
    }


    public getPreviewURL(form: any, cb: Function) {
        this.BasicGet('/animal/web/thirdMonitorChack/getPreviewURL', {
            ...form,
            time: false,
        }, false, true, cb, cb);
    }

    public getPlaybackURL(form: any, cb: Function) {
        // const time = Util.handleStartTimeAndEndTimevideo(form.time);
        this.BasicGet('/animal/web/thirdMonitorChack/getPlaybackURL', {
            ...form,
            // time: Util.dateUtil(form.time)
        }, false, true, cb, cb);
    }



    public deleteConfig(form: any, cb: Function) {
        this.BasicPost('/animal/web/thirdMonitorConfig/deleteConfig', {
            ...form,
        }, true, true, true, cb)
    }

    public statusConfig(form: any, cb: Function) {
        this.BasicPost('/animal/web/thirdMonitorConfig/statusConfig', {
            ...form,
            enableStatus: form.enableStatus.toString()
        }, true, true, true, cb)
    }

    public getbreedList(form: CountExceptionFrom, tablePage: HandleTable, cb: Function) {
        const time = Util.handleStartTimeAndEndTimenohms(form.time);
        this.BasicGet('/animal/web/breedAndInsuredStatistics/breedList', {
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length - 1],
            ...time,
        }, false, true, cb, cb);
    }

    public getDetail(id: string, cb: Function) {
        this.BasicGet('/animal/web/animalMark/get', { id }, false, true, cb);
    }


    public getTablelist(form: any, tablePage: HandleTable, cb: Function) {
        const time1 = Util.handleStartTimeAndEndTime(form.time1);
        const time2 = Util.handleStartTimeAndEndTime(form.time2);
        form.applyStartTime = time1.startTime
        form.applyEndTime = time1.endTime
        form.outStartTime = time2.startTime
        form.outEndTime = time2.endTime
        this.BasicGet('/animal/web/releaseFromCustody/list', {
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length - 1],
            destinationGroupCode: form.destinationGroupCode[form.destinationGroupCode.length - 1]
        }, false, true, cb, cb);
    }

    public farstCheck(form: any, cb: Function) {
        if (form.checkStatus == '1') {
            this.BasicPost('/animal/web/animalApplyOutColumn/farstCheck', {
                id: form.id,
                checkStatus: form.checkStatus,
                refuseMsg: ''
            }, true, true, true, cb)
        }
        else if (form.checkStatus == '2') {
            this.BasicPost('/animal/web/animalApplyOutColumn/farstCheck', form, true, true, true, cb)
        }
    }

    public secondCheck(form: any, cb: Function) {
        this.BasicPost('/animal/web/animalApplyOutColumn/secondCheck', form, true, true, true, cb)
    }

    public farstInfo(id: any, cb: Function) {
        this.BasicGet('/animal/web/animalApplyOutColumn/farstInfo', { id }, false, true, cb);
    }

    public noCheckInfo(id: any, cb: Function) {
        this.BasicGet('/animal/web/animalApplyOutColumn/noCheckInfo', { id }, false, true, cb);
    }

    public secondInfo(id: any, cb: Function) {
        this.BasicGet('/animal/web/animalApplyOutColumn/secondInfo', { id }, false, true, cb);
    }
}
