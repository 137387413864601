



















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
//HandlePageChange
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import { ComboList, Column, Result } from "@/common/Views/system/column/column";
//弹出层组件
import DeviceGroup from "@/views/basics/transaction/deviceGroup/deviceGroup.vue";
//表格数据
import { Firm } from "@/common/Views/system/firm/firm";
//请求
import { LabelManagement } from "@/common/Views/configManage/transaction/labelManagement";
import {
  Usermanagement,
  LabelManageTable,
} from "@/common/Views/basics/transaction/order/labelManage";
@Component({
  components: {
    DeviceGroup,
  },
})
export default class Usermangement extends Vue {
  private form: any = {
    groupCode: [],
    name: this.value,
    tel: "",
    contacts: "",

    username: "",
    mobile: "",
    simpleAddress: "",
  };
  constructor() {
    super();
    this.firm = new Firm();
    this.searchlist = new Usermanagement();
  }

  //props
  private props: Object = {
    checkStrictly: true,
    expandTrigger: "hover",
    hoverThreshold: undefined,
    label: "name",
    value: "name",
  };

  //tableData
  private tableData: LabelManageTable[] = [];

  //column
  public column = new Column();
  private changeGroupCode(): void {
    this.comboList = [];
    // this.form.farmId = '';
    new Column().getFarm(
      this.form.groupCode[this.form.groupCode.length - 1],
      (data: AxiosData<ComboList[]>) => {
        this.comboList = data.data;
      }
    );
  }

  //校验手机号
  private checkTel() {
    this.form.tel.length === 10 || this.form.tel.length === "10";
  }
  //请求列表的方法

  //查询
  private search(): void {
    this.tablePage.pageNo = 1;
    this.getTable();
  }
  //重置
  private restForm(): void {
    //清空所属机构
    this.form.simpleAddress = [];
    //清空企业名称
    this.form.name = "";
    //清空联系人
    this.form.username = "";
    //清空联系电话
    this.form.mobile = "";
    // this.form = new Util().clearObject(this.form);
    //请求table列表信息
    this.getTable();
  }

  //tablePage
  private tablePage: HandleTable = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };
  //handlePageChange
  private handlePageChange(val: HandlePageChange) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  //弹出层
  private onClickDeviceGroupingVisible: boolean = false;
  private childComboList: ComboList[] = [];
  private onClickDeviceGrouping(): void {
    console.log(this.comboList);
    this.rowData = [];
    this.onClickDeviceGroupingVisible = true;
  }

  //添加企业
  private success(data: Addnumber): void {
    // console.log(this.addnumber)
    this.onClickDeviceGroupingVisible = false;
    this.dialogTableVisible = false;
    //   this.getTable();
  }
  //企业名称
  private options: any = [];
  private value: any = "";
  //请求企业名称
  private searchlist: Usermanagement;

  private getBuyerNameList() {
    this.searchlist.getBuyerNameList(
      this.form,
      (data: AxiosData<ComboList[]>) => {
        this.options = data.data;
        console.log(options);
      }
    );
  }

  //表格数据请求
  private firm: Firm;

  private getTable(): void {
    this.tableData = [];
    this.firm.getTable1(this.form, this.tablePage, (data: AxiosData<any[]>) => {
      this.tablePage.totalCount = data.page.totalCount;
      this.tableData = data.data;
    });
  }
  // 删除摊位信息
  private handlDelete(row: any): void {
    this.$confirm("此项数据将被永久删除, 是否继续?", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        this.$message({
          type: "success",
          message: "删除成功!",
        });
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      });
  }
  //编辑跳转
  private goDetail(row: any): void {
    console.log(row);
    console.log(this.tableData);
    this.$router.push({
      path: "/firmDetail1",
      query: {
        item: JSON.stringify(row),
      },
    });
  }

  //挂在后请求列表信息
  mounted() {
    //执行请求列表
    this.getTable();
    //买家企业列表
    this.getBuyerNameList();

    console.log(this.form);
  }
}
