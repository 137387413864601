


















































































































import { Component, Watch, Vue } from "vue-property-decorator";
import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";
import InputComonentVue from "@/views/basics/typeConfig/components/components/InputComonentVue.vue";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";

import { GroupCodeApi, FarmApi } from "@/api/CommonModule/index";
import { dateFun } from "@/common/date";

import { cameraUpgrade } from "@/api/HardwareModule/index";
import { FileApi } from "@/api/CommonModule/index";
import {
    SensorForm,
    addSensorForm,
    upSensorForm,
    AddList,
} from "@/Interface/HardwareModule/index";

@Component({
    components: { SelectComponentVue, InputComonentVue, SelectComponentsVue },
})
export default class upPackageManagement extends Vue {
    private form = {
        progressStatus: "",
        deviceCode: "",
        batchId: this.$route.query.id,
    };
    private ruleForm = {
        sensorNo: "",
        confTypeId: "",
    };
    private rules: any = {
        sensorNo: [
            {
                required: true,
                message: "请输入正确的序列号",
                trigger: ["blur", "change"],
            },
            {
                min: 15,
                max: 15,
                message: "请输入正确的序列号",
                trigger: ["blur", "change"],
            },
        ],
        confTypeId: [
            {
                required: true,
                message: "请选择传感器类型",
                trigger: ["blur", "change"],
            },
        ],
    };
    //填写序列号
    private SetNumberValue(val: any) {
        this.form.deviceCode = val;
    }
    //选择状态
    private SetZTVal(val: any) {
        console.log(val, "val5465");
        this.form.progressStatus = val;
    }
    private selectZT = "";
    //状态列表
    private ZTList: any[] = [
        {
            id: "0",
            name: "传输中",
        },
        {
            id: "1",
            name: "传输完成",
        },
    ];
    // 查询
    private search() {
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getListData();
    }
    //重置
    private resetForm() {
        this.form = {
            progressStatus: "",
            deviceCode: "",
            batchId: this.$route.query.id,
        };
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.selectZT = "";
        this.SetZTVal("");
        this.getListData();
    }
    private handlePageChange(val: any) {
        // 分页
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.getListData();
    }
    private SelectData = "";
    private tableData: any[] = []; //table 数据
    private tablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    /**获取列表数据 */
    private getListData() {
        new cameraUpgrade().getdeList(this.form, this.tablePage, (res: any) => {
            res.data.forEach((item: any, index: number) => {
                item.upTarget = "AI摄像头";
            });
            this.tableData = res.data;
            this.tableData = this.tableData.map((item) => {
                // 将字符串转换为浮点数，并取上整
                const progress = Math.ceil(parseFloat(item.progress));
                // 将整数转换为字符串，并设置回 item.progress
                item.progress = progress.toString()+'%';
                return item;
            });
            this.tablePage = { ...res.page };
        });
    }

    activated() {
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.form.batchId = this.$route.query.id;
        this.getListData();
    }
}
