import {Util} from '@/common/Views/comm/util';

export class DateUtil {
    private now: Date;
    private nowDayOfWeek: number;
    private nowDay: number;
    private nowYear: number;
    private nowMonth: number;

    constructor() {
        this.now = new Date(); // 当前日期
        this.nowDayOfWeek = this.now.getDay(); // 今天本周的第几天
        this.nowDay = this.now.getDate(); // 当前日
        this.nowMonth = this.now.getMonth(); // 当前月
        this.nowYear = this.now.getFullYear(); // 当前年
        this.nowYear += (this.nowYear < 2000) ? 1900 : 0;
    }


    public getStartDayOfWeek() {
        const day = this.nowDayOfWeek || 7;
        return this.formatDate(new Date(this.now.getFullYear(), this.nowMonth, this.nowDay + 1 - day));
    }

    public getEndDayOfWeek() {
        const day = this.nowDayOfWeek || 7;
        return this.formatDate(new Date(this.now.getFullYear(), this.nowMonth, this.nowDay + 7 - day));
    }


    public getStartDayOfMonth() {
        const monthStartDate = new Date(this.nowYear, this.nowMonth, 1);
        return this.formatDate(monthStartDate);
    }

    public getEndDayOfMonth() {
        const monthEndDate = new Date(this.nowYear, this.nowMonth, this.getMonthDays());
        return this.formatDate(monthEndDate);
    }


    public getMonthDays() {
        const monthStartDate = new Date(this.nowYear, this.nowMonth, 1).getTime();
        const monthEndDate = new Date(this.nowYear, this.nowMonth + 1, 1).getTime();
        return (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
    }

    public formatDate(date: Date): string {
        const myYear = date.getFullYear();
        const myMonth = date.getMonth() + 1;
        const myWeekDay = date.getDate();

        return (myYear + '-' + Util.fillZero(myMonth) + '-' + Util.fillZero(myWeekDay));
    }
}


// var DateUtil = {
//     /**
//      * 获得当前日期
//      *
//      * @returns
//      */
//     getNowDay() {
//         return this.formatDate(new Date());
//     },
//     /**
//      * 获得本周的开始时间
//      *
//      * @returns
//      */
//     getStartDayOfWeek() {
//         var day = nowDayOfWeek || 7;
//         return this.formatDate(new Date(now.getFullYear(), nowMonth, nowDay + 1 - day));
//     },
//     /**
//      * 获得本周的结束时间
//      *
//      * @returns
//      */
//     getEndDayOfWeek() {
//         var day = nowDayOfWeek || 7;
//         return this.formatDate(new Date(now.getFullYear(), nowMonth, nowDay + 7 - day));
//     },
//     /**
//      * 获得本月的开始时间
//      *
//      * @returns
//      */
//     getStartDayOfMonth() {
//         var monthStartDate = new Date(nowYear, nowMonth, 1);
//         return this.formatDate(monthStartDate);
//     },
//     /**
//      * 获得本月的结束时间
//      *
//      * @returns
//      */
//     getEndDayOfMonth() {
//         var monthEndDate = new Date(nowYear, nowMonth, this.getMonthDays());
//         return this.formatDate(monthEndDate);
//     },
//     /**
//      * 获得本月天数
//      *
//      * @returns
//      */
//     getMonthDays() {
//         var monthStartDate = new Date(nowYear, nowMonth, 1);
//         var monthEndDate = new Date(nowYear, nowMonth + 1, 1);
//         var days = (monthEndDate - monthStartDate) / (1000 * 60 * 60 * 24);
//         return days;
//     },
//     /**
//      * @param 日期格式化
//      * @returns {String}
//      */
//     formatDate(date) {
//         var myyear = date.getFullYear();
//         var mymonth = date.getMonth() + 1;
//         var myweekday = date.getDate();
//
//         if (mymonth < 10) {
//             mymonth = "0" + mymonth;
//         }
//         if (myweekday < 10) {
//             myweekday = "0" + myweekday;
//         }
//         return (myyear + "-" + mymonth + "-" + myweekday);
//     }
// };
//
// export default{
//     DateUtil
// }
