





















































































































































































import { Component, Vue } from "vue-property-decorator";
import LivePlayer from "@liveqing/liveplayer";

import { CommodityManagementInfoForm } from "@/Interface/WeChatApplet/AboutShop/index";
import { AboutShop } from "@/api/WeChatApplet/index";
@Component({
    components: { LivePlayer },
})
export default class CommodityManagementInfo extends Vue {
    private form: CommodityManagementInfoForm = {
        id: "",
        userName: "",
        mobile: "",
        registerSubject: "",
        labelNumber: "",
        breedName: "",
        typeName: "",
        day: null,
        minWeight: "",
        maxWeight: "",
        unitPrice: "",
        cureCount: 0,
        immuneCount: 0,
        hybridizationCount: 0,
        calvingCount: "",
        aiLeftImg:
            "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
        aiRightImg:
            "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        aiBackImg:
            "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        aiFleshUpPercentage: 0,
        video: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4",
        more: "fawefaefalkegjlkaneflka翻个激发了i金发科技发拉开i为分厘卡文件夹分厘卡机五年分厘卡嗯法拉可耐福扣篮俄方金卡为你方便金卡为吧放假咯文本绿卡恶化狂乱金额吧饭卡为部分科比饭卡为呢吧饭卡为被疯狂加我",
        isPutaway: false,
        checkState: 0,
    };
    private rules: any = {
        name: [
            { required: true, message: "请填写交易场名称", trigger: "change" },
        ],
        groupCode: [
            { required: true, message: "请选择所在区域", trigger: "change" },
        ],
        addressDetail: [
            { required: true, message: "请填写详细地址", trigger: "change" },
        ],
        transactionType: [
            { required: true, message: "请选择交易类型", trigger: "change" },
        ],
        proceedsType: [
            { required: true, message: "请选择收款类型", trigger: "change" },
        ],
    };
    private srcList: any = [
        //图片发大列表 请按照顺序
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
    ];
    private showControl: boolean = false; // 控制是否显示 视频控制条

    //请求接口
    private getDetailData() {
        let id: string = this.$route.query.id as string;
        new AboutShop().getDetail(id, (data: any) => {
            data.data.headImgList == null ? (data.data.headImgList = []) : "";
            this.form = { ...data.data };
            this.srcList = [
                data.data.aiLeftImg,
                data.data.aiRightImg,
                data.data.aiBackImg,
            ];
        });
    }

    activated() {
        this.getDetailData();
    }
}
