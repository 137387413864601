












































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {HandleTable, HandlePageChange} from '@/common/Views/comm/handleTable';
import {Column, ComboList, Result} from "@/common/Views/system/column/column";
import {AxiosData} from '@/common/http/http';
import { Util } from '@/common/Views/comm/util';
import {ConfType} from '@/common/Views/system/confType/confType';
import {
    PreventRecord,
    PreventRecordForm
} from "@/common/Views/traceabilityOutManage/preventRecord";

@Component
export default class HelloWorld extends Vue {
    private preventRecord: PreventRecord = new PreventRecord()

    public column = new Column()

    private form: PreventRecordForm = {
        groupCode: [],
        farmId: '',
        labelNumber: '',
        time: [],
        diseaseName: '',
        medicationId: '',
    }

    private activated(): void {
        // this.getTable();
        this.getTree();
        this.confType();
        this.changeGroupCode();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    private treeData: Result[] = [];

    private getTree() {
        Util.getTree((result: Result[])=>{
            this.treeData = result
        })
    }

    private comboList: ComboList[] = [];

    private changeGroupCode(): void {
        this.comboList = [];
        // this.form.farmId = '';
        this.form.typeConfId = '';
        new Column().getFarm(this.form.groupCode[this.form.groupCode.length - 1], (data: AxiosData<ComboList[]>)=>{
            this.comboList = data.data;
        })
        // this.confType();
    }

    @Watch("form.groupCode")
    getgroupCode(newVal:Array<object>,oldVal:Array<object>) {
        if (newVal !== oldVal) {
            this.form.farmId = '';
            // this.form.typeConfId = ''; 
        }
    }

    private conTypeList: [] = [];

    private confType(): void {

        this.conTypeList = [];
        this.form.typeConfId = '';
        // new ConfType().getSelectAll(this.form.farmId, (data: any) => {
        //     this.conTypeList = data.data;
        //     console.log(data)
        // });
        this.preventRecord.getMedication((res) => {
            this.conTypeList = res.data
            this.getTable()
        })
    }

    private tableData: any = [];

    private getTable(): void {
        this.preventRecord.getList(this.form, this.tablePage, (res) => {
            this.tableData = res.data
            this.tablePage.totalCount = res.page.totalCount
        })
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        // this.form.time = [];
        this.tablePage.pageNo = 1
        this.getTable();
        this.getTree();
        this.confType();
        this.changeGroupCode();
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;

        this.getTable();
    }

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.tableData = []
        this.getTable();
    }

    private Export(): void {
        this.preventRecord.exportList(this.form, (res) => {
            console.log(res)
        })
    }
}
