import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';

export class Firm extends BasicsClass {

    public queryCompany(groupCode:any ,isInternational:any, cb:Function): void {
        // let address = ''
        // if(simpleAddress){
        //     address = simpleAddress.join('-')
        // }
        this.BasicGet('/animal/web/user/appNameList', {groupCode,isInternational}, false, true, cb)
    }

    public getTable(form: any, tablePage: HandleTable, cb: Function): void {
        if(!form.groupCode || !form.newGroupCode){
            form.newGroupCode = '0'
        }
        // console.log(form)
        let address = ''
        // if(form.simpleAddress.length > 1){
        //     address = form.simpleAddress.slice(1, form.simpleAddress.length).join('-')
        // }
        this.getTableList({
            groupCode:form.newGroupCode,
            name:form.name,
            contactPerson:form.contactPerson,
            contactPhone:form.contactPhone,
            // simpleAddress: address
        }, tablePage, '/animal/web/company/list', cb);
    }
    public getTable1(form: any, tablePage: HandleTable, cb: Function): void {
        let address = ''
        if(form.simpleAddress.length > 1){
            address = form.simpleAddress.splice(1, form.simpleAddress.length-1).join('-')
            // console.log(form.simpleAddress.splice(1, form.simpleAddress.length-1))
        }
        console.log(this)
        this.getTableList({
            ...form,
            simpleAddress: address
        }, tablePage, '/animal/web/buyerUser/buyerList', cb);
    }

    public submit(type: string, form: any, cb: Function): void {
        console.log(form)
        if(type === '1'){
            this.BasicPost('/animal/web/company/add', {
                name:form.name,
                groupCode:form.groupCode,
                detailAddress:form.detailAddress,
                contactPerson:form.contactPerson,
                contactPhone:form.contactPhone,
                enableStatus:form.enableStatus,
                companyType:form.companyType ,
            }, true, true,true, cb)
        }else if(type === '2'){
            this.BasicPost('/animal/web/company/update', form, true, true,true, cb)
        }
    }
    //add
    public submit100(form: any, cb: Function): void {
        // form.newGroupCode = form.groupCode[form.groupCode.length-1]
        console.log(form)
        delete form.auditStatus
        let newObj = JSON.parse(JSON.stringify(form))
        form.groupCode = newObj.newGroupCode
        delete form.newGroupCode

        if(form.companyType =='养殖场企业'){form.companyType = 1}
        if(form.companyType =='屠宰场企业'){form.companyType = 2}
        if(form.companyType =='无害化企业'){form.companyType = 3}
        if(form.companyType =='牲畜买家'){form.companyType = 4}
        if(form.companyType =='内部角色企业'){form.companyType = 5}
        form.companyBreedType = Number(form.companyBreedType)
        form.companyType = Number(form.companyType)
        this.BasicPost('/animal/web/company/add', {
            ...form,
            name:form.name,
            detailAddress:form.detailAddress,
            contactPerson:form.contactPerson,
            contactPhone:form.contactPhone,
            enableStatus:form.enableStatus,
            companyType:form.companyType ,
        }, true, true,true, cb)
    }
    //set
    public submit101(form: any, cb: Function): void {
        // form.newGroupCode = form.groupCode[form.groupCode.length-1]
        console.log(form)
        let newObj = JSON.parse(JSON.stringify(form))
        form.groupCode = newObj.newGroupCode
        form.isInternational == '海外' ? form.isInternational =1 : form.isInternational =0
        delete form.newGroupCode
        if(form.companyType =='养殖场企业'){form.companyType = 1}
        if(form.companyType =='屠宰场企业'){form.companyType = 2}
        if(form.companyType =='无害化企业'){form.companyType = 3}
        if(form.companyType =='牲畜买家'){form.companyType = 4}
        if(form.companyType =='内部角色企业'){form.companyType = 5}
        if(form.companyBreedType == '规模养殖企业'){form.companyBreedType = 1}
        if(form.companyBreedType == '合作社(村集体)'){form.companyBreedType = 2}
        form.companyBreedType = Number(form.companyBreedType)
        form.companyType = Number(form.companyType)
        this.BasicPost('/animal/web/company/update', {
            ...form,
            id:form.id,
            name:form.name,
            detailAddress:form.detailAddress,
            contactPerson:form.contactPerson,
            contactPhone:form.contactPhone,
            auditStatus:form.auditStatus,
            enableStatus:form.enableStatus ,
            companyType:form.companyType,
        }, true, true,true, cb)
    }


    //买卖
    public submit1(type: string, form: any, cb: Function): void {
        if(type === '1'){
            this.BasicGet('/animal/web/buyerUser/addBuyer', form, true, true, cb)
        }else if(type === '2'){
            this.BasicGet('/animal/web/buyerUser/editBuyer', form, true, true, cb)
        }
    }
		
	// public submit2(id: string, form, cb: Function): void {
	//     this.BasicPost('/animal/web/farm/updatePicture', {
	//         farmId:id,
	//         items:form,
	//     }, true, true, true, cb);
	// }

    // public getDetail(id: string, cb: Function): void {
    //     this.BasicGet('/animal/web/farm/get', {id}, false, true, cb);
    // }

    public audit(form: any, cb: Function): void {
        this.BasicGet('/animal/web/enterprise/auditEnterprise', form, true, true, cb);
    }
    //买卖
    public audit1(form: any, cb: Function): void {
        this.BasicGet('/animal/web/buyerUser/auditBuyer', form, true, true, cb);
    }
}
