

























































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Personal, PersonalApp, TableData} from "@/common/Views/system/personal/personal";
import {HandleTable, HandlePageChange} from "@/common/Views/comm/handleTable";
import {Util} from "@/common/Views/comm/util";
import personalIp from '@/views/system/personal/personalDetail/personalIp.vue';
import { PersonalDetailC, SchoolGradeClass } from '@/common/Views/system/personal/personalDetail';
import { TableResult } from '@/common/Views/system/role/role';
import {AxiosData} from "@/common/http/http";
import { Result, Column, ComboList } from '@/common/Views/system/column/column';
import { DeepLoop } from '@/common/deepLoop/deepLoop';

enum Status {
    '启用' = 1,
    '禁用' = 2,
    '锁定' = 3
}
@Component({
    components: {
        'personal-ip': personalIp,
    }
})
export default class HelloWorld extends Vue {

    public column = new Column()

    private form: Personal<string> = {
        name: '',
        mobile: '',
        groupCode: [],
        companyId: '',
        level: '',
        status: '',
    }

    private props:Object = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "name"
    }

    private personal: PersonalApp;
    constructor() {
        super();
        this.personal = new PersonalApp();
    }

    private tableData: TableData<string>[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;
        this.getPersonalList();
    }

    private handlePageChange(val: HandlePageChange){

        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getPersonalList();
    }

    private activated(): void {
        this.getPersonalList();
        // this.getAllRole();
        this.getTree();
        this.changeGroupCode();
    }

    private treeData: Result[] = [];

    private getTree(cb?: Function): void {
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        // column.getTreeData("/animal/web/group/treeList").then((result: Result[]) => {
        column.getTreeData("/animal/web/provinceCityDistrict/getForGroupCode").then((result: Result[]) => {

            this.treeData = new DeepLoop().deepCopy(result);

            if(cb) cb();
        }).catch(err => {
            this.$message.error(err.message);
        });
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    private getFirmList(): void {
        let simpleAddress = ''
        if(this.form.groupCode.length >= 2){
            let simp:any = JSON.stringify(this.form.groupCode)
            simp = JSON.parse(simp)
            simp.splice(0, 1)
            simpleAddress = simp.join('-')
        }
        this.personal.getCompanyList(simpleAddress, (res: any) => {
            // console.log(res)
            this.comboList = res.data;
        })
    }

    private comboList: ComboList[] = [];
    private changeGroupCode(): void {
        this.comboList = [];
        this.form.companyId = ''
        // this.form.farmIds = '';
        // new Column().getFarm(this.form.groupCode[this.form.groupCode.length - 1], (data: AxiosData<ComboList[]>)=>{
        //     this.comboList = data.data;
        // }, '100')
        this.getFirmList()
    }

    @Watch("form.groupCode")
    getgroupCode(newVal:Array<object>,oldVal:Array<object>) {
        if (newVal !== oldVal) {
            this.form.farmIds = '';
            // this.form.typeConfId = ''; 
        }
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        this.tablePage.pageNo = 1;

        this.getPersonalList();
    }

    private getPersonalList(): void {
        if(this.$getResourceCodes('user_list')){
            this.personal.getPersonalList(this.form, this.tablePage)
                .then(data=>{
                    for(const i in data.result) {
                        data.result[i]['status'] = Status[data.result[i]['status']];
                    }
                    this.tableData = data.result;
                    this.$set(this.tablePage, 'totalCount', data.pageInfo.totalCount);
                })

        }

    }

    private handlePerson(id: string=''): void {
        this.$router.push({
            path: '/appPersonalDetail',
            query: {
                id: id,
            }
        });
    }

    private loginIPDialog: boolean = false;
    private handleIp(id: string): void {
        this.loginIPDialog = true;

        this.getIpWithId = id;
    }

    private getIpWithId: string = '';

    private schoolNameOptions: SchoolGradeClass<string>[] = [];

    // private getAllSchoolList(): void {
    //     new PersonalDetailC().getAllSchoolList()
    //         .then(data=> {
    //
    //             this.schoolNameOptions = data;
    //         })
    // }


    private roleOptions: any[] = [
        {name: '企业管理员', id: '1'},
        {name: '企业员工', id: '2'}
    ];

    // private getAllRole(): void {
    //     new PersonalDetailC().getRoleList()
    //         .then(data=>{

    //             this.roleOptions = data;
    //         }).catch(err=>{
    //         this.$message.error(err.message);
    //     });
    // }


    private loginUnLock(row: TableData<string>): void {

        this.$confirm('是否确认解锁？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {

            this.personal.loginUnLock(row.id, ()=>{
                this.getPersonalList();
            });

        });


    }
}
