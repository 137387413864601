import { BasicsClass } from '@/common/BasicsClass';

export interface FarmDetailForm<T = string> {
    groupCode: T[];
    mobile: T;
    person: T;
    status: T | number;
    name: T;
    typeIds: T;
    simpleAddress: T;
    detailAddress: T;
    licenseUrl: T;
    doorUrl: T;
    auditStatus: T | number;
    // auditStatus: any;
    createUserId?: T | number;
    destinationTypeId: T;
    companyId: any,
    feedingModel: string,
    msgSwitchFlag: boolean,
    showHighQualityFlag: boolean,
}

export interface Url {
    code: string;
    base64ImgData: string;
    url: string;
}

export interface FarmDetailDetail {
    createTime: string;
    createUserId: string;
    groupCode: string;
    id: string;
    mobile: string;
    doorUrl: string;
    name: string;
    permissionFarmIdList: string[];
    permissionFarmIds: string;
    permissionGroupCode: string;
    person: string;
    status: boolean;
    typeIds: string;
    updateTime: string;
    auditStatus: number;
    simpleAddress: string;
    detailAddress: string;
    licenseUrl: string;
    simpleAdressId: string;
    provinceList: [];
    companyId: any
}

export interface Pictures {
    code: string;
    base64ImgData: string;
    url: string;
}

export interface CityList {
    data: []
}

export class FarmDetail extends BasicsClass {
    public submit(id: string, form: FarmDetailForm, items: any, cb: Function, cb2: Function): void {

        this.AddOrUpdate(id, {
            ...form,
            typeIds: form.typeIds.toString(),
            groupCode: form.groupCode[form.groupCode.length - 1],
            items
        }, '/animal/web/farm/update', '/animal/web/farm/insert', cb, cb2);
    }

    public submit2(id: string, form: any, cb: Function): void {
        this.BasicPost('/animal/web/farm/updatePicture', {
            farmId: id,
            items: form,
        }, true, true, true, cb);
    }

    public getDetail(id: string, cb: Function): void {
        this.BasicGet('/animal/web/farm/get', {
            id,
            companyType: '1'
        }, false, true, cb);
    }

    // 获取饲养方式数据 3.3.5新增
    public getFeedingModel(cb: Function) {
        this.BasicGet("/animal/web/farm/getFeedingTypeEnum", {}, false, true, cb)
    }

    public getDetail2(id: string, cb: Function): void {
        this.BasicGet('/animal/web/farm/getPicture', { id }, false, true, cb);
    }

    public getAudit(id: string, cb: Function): void {
        this.BasicGet('/animal/web/farm/getaudit', { id }, false, true, cb);
    }

    public getCity(pid: string, roleId?: any, cb?: Function): void {
        this.BasicGet('/animal/web/provinceCityDistrict/get', { pid, roleId }, false, true, cb);
    }

    public getCity1(pid: string, cb: Function): void {
        this.BasicGet('/animal/web/provinceCityDistrict/getGroupCodeForUser', { pid, flag: "0" }, false, true, cb);
    }

    public audit(id: string, form: FarmDetailForm, cb: Function): void {
        this.BasicPost('/animal/web/audit/auditFarm', {
            id,
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            typeIds: form.typeIds.toString(),
        }, true, true, true, cb);
    }
}
