
























































import {Component , Prop , Watch , Vue} from 'vue-property-decorator'
import {appJurisdiction , appMenu ,appUser} from './components/appAll' 
@Component({})
export default class AppJurisdiction extends Vue{
    private data:any = []
    private form :any = {
        roleId:'',
        menuIds:[]
    }

    private powerData:any = []

    private selectData:any = []

    //设置角色id
    private roleId:any = ''

    //当前角色的菜单
    private roleMenu:any = []


    //请求select接口
    private getSelectData(){
        new appJurisdiction().getRole(true,(data:any) => {
            this.selectData = data.data
        })
    }

    //请求用户列表
    private getRoleIdMenu(){
        
        new appUser().getRoleIdMenu(this.form.roleId,(data:any) => {
            console.log(data.data)
            this.$refs.tree.setCheckedNodes(data.data);
        })

         
    }


    //菜单props
    private prop:any ={
        label:'name',
        children:'children'
    }
    //请求树形结构菜单
    private getTreeData(){
        new appMenu().getMenu((data:any) => {
            this.data = data.data
        })
    }

    //
    private checkData(data:any){
    }


    private selectAll(){}

    private showData(data:any){
        console.log(data.resources)
        this.powerData = data.resources
    }

    private allResource: Set<string> = new Set<string>();
    private checkbox(row:any){
        if(row.checked){
                this.allResource.add(row.row.id);
            }else{
                this.allResource.delete(row.row.id);
            }
    }

    
    private checkArr:any[] =[]
    //check-change节点选中状态发生变化时的回调共三个参数，依次为：
    //传递给 data 属性的数组中该节点所对应的对象、节点本身是否被选中、节点的子树中是否有被选中的节点
    private NodeIdArr:any[] = []
    private checkChange(data:any,checkFlag:any){
        // console.log(data)
        // console.log(checkFlag)
        if(checkFlag){
            this.NodeIdArr.push(
                data.id
            )
        }else{
            this.NodeIdArr.forEach((val:any) => {
                if(data.id == val){
                    let arrLength = this.NodeIdArr.indexOf(val)
                    this.NodeIdArr.splice(arrLength,1)
                    // console.log(this.NodeIdArr)
                }
            });
        }


    }


    //保存
    private save(){
        if(this.form.roleId){
            //菜单名称
            this.form.menuIds = this.NodeIdArr
            
            console.log(this.form)
            this.saveData()

        }else{
            this.$message.error('请先选择角色');
            return;
        }
    }

    //保存权限
    private saveData(){
        new appJurisdiction().savePermission(this.form,(data:any) => {
            //请求重新渲染
            this.selectRole()
        })

    }

    //选择
    private selectRole(){
        this.getRoleIdMenu()
    }


    mounted(){
        this.getSelectData() 

        this.getTreeData()
    }
}
