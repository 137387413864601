import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {AxiosData} from '@/common/http/http';
import {Time} from '@/common/Views/basics/summaryAlarm/summaryAlarm';
import {Util} from '@/common/Views/comm/util';
import {LabelType} from '@/common/Views/basics/assetRegistration/assetRegistration';
import { DeviceGroupingDetail } from '../../configManage/deviceGrouping/deviceGrouping';
import {DeviceManageTable} from '@/common/Views/basics/deviceManage/deviceManage';

export interface LabelManageTable {
    createTime: string;
    createUserName: string;
    farmId: string;
    farmName: string;
    groupCode: string;
    groupName: string;
    id: string;
    isBind: boolean;
    isGroup: boolean;
    number: string;
    registerTime: string;
    registerUserName: string;
    typeName: string;
    warnType: number;
}

export interface Focu{
    number:number | string ,
    ccid:number | string,
    imei:number | string,
    imsi:number | string
}

export interface LabelManageFrom extends DeviceGroupingDetail {
    number: string;
    isBind: ''|number;
}

export interface LabelManageDetailForm<T = string> {
    type: T;
    number: T;
}

export class LabelManage extends BasicsClass {
    public getTable(form: LabelManageFrom, tablePage: HandleTable, cb: Function): void {
        // delete form.time
        form.createTimeStart = Util.dateTimeUtil(form.time[0]);
        form.createTimeEnd = Util.dateTimeUtil(form.time[1]);
        // delete form.time
        this.BasicGet('/animal/web/tagNb/list', { //列表
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length - 1],
        }, false, true, (data: AxiosData<LabelManageTable[]>) => {

            cb(data);
        });
    }

    public deviceGroup(form: LabelManageTable, deviceCode: LabelManageTable[], cb: Function) {
        const numbers: string[] = [];
        for (const i of deviceCode) {
            numbers.push(i.number);
        }

        this.BasicPost('/animal/web/tag/groupTags', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            numbers: numbers.toString()
        }, true, true, true, cb);
    }

    // public del(id: string, cb: Function): void {
    //     this.BasicPost('/asset/assetLabel/delete', {id}, true, true, false, cb);
    // }
    //
    // public addLabel(form: LabelManageDetailForm, cb: Function): void {
    //     this.BasicPost('/asset/assetLabel/save', form, true, true, true, cb);
    // }
    //
    public addMoreLabel(file: FileList, form: DeviceGroupingDetail, cb: Function): void {
        this.ImportFile('/animal/web/tag/groupTagsByExport', file, 'file', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length-1]
        }, cb);
    }

    public getTemplate(): void {
        this.getFile('/animal/web/tag/exportLabelTemplate', {});
    }
}
                //类名          
export class Addnumber extends BasicsClass{
            //方法名  //传的数据  //cb回调
    public addnumber(form : any,cb:Function) {
            //请求方式 BasicPost post  BasicGet get       //请求地址
        this.BasicPost('/animal/web/tagNb/saveNb',{
            ...form,//数据
            //...tablePage //页码
            // groupCode: form.groupCode[form.groupCode.length - 1],
        },true, true, true, cb)
    }
}
