import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/common/Views/comm/handleTable';

export interface FarmForm {
    groupCode: string;
    name: string;
    platform: string;
    isInternational:any;

}

export interface FarmTable {
    createTime: string;
    createUserName: string;
    groupCode: string;
    groupName: string;
    id: string;
    mobile: string;
    name: string;
    person: string;
    status: boolean;
    typeIds: string;


}

export class Farm extends BasicsClass {

    public getTable(form: FarmForm, tablePage: HandleTable, cb: Function): void {
        this.getTableList({
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1]
        }, tablePage, '/animal/web/farm/list', cb);
    }

    public changemarkType(form: any, cb: Function): void {
        this.BasicPost('/animal/web/farm/markType', form, true, true, true, cb)
    }
}
