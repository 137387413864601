
















































































































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";
import InputComonentVue from "@/views/basics/typeConfig/components/components/InputComonentVue.vue";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import { Column } from "@/common/Views/system/column/column";
import { GroupCodeApi, FarmApi } from "@/api/CommonModule/index";
import { dateFun } from "@/common/date";

import { pkgManagement } from "@/api/HardwareModule/index";
import { FileApi } from "@/api/CommonModule/index";
import {
    SensorForm,
    addSensorForm,
    upSensorForm,
    AddList,
} from "@/Interface/HardwareModule/index";

@Component({
    components: { SelectComponentVue, InputComonentVue, SelectComponentsVue },
})
export default class upPackageManagement extends Vue {
    private form = {
        version: "",
        createtimeEnd: "",
        createtimeStart: "",
    };
    private addForm = {
        id: "",
        version: "",
        url: "",
        deviceModel: "",
        remark: "",
        fileName: "",
    };
    private upTarget = "AI摄像头";

    private SelectData = "";
    private tableData: any[] = []; //table 数据
    private tablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private handleExceed(files: any, fileList: any) {
        this.$message.warning(`只能上传单个文件！`);
    }
    private fileList: any[] = [];
    get DialogRules() {
        return {
            version: [
                {
                    required: true,
                    message: "请输入版本号",
                    trigger: "change",
                },
            ],
            upTarget: [
                {
                    required: true,
                    message: "请输入upTarget",
                    trigger: "change",
                },
            ],
            fileName: [
                {
                    required: true,
                    message: "请上传升级包",
                    trigger: "change",
                },
            ],
            deviceModel: [
                {
                    required: true,
                    message: "请选择设备型号",
                    trigger: "change",
                },
            ],
        };
    }
    private showLabelSelectInputFlag = false;
    private addPkg() {
        this.showLabelSelectInputFlag = true;
        this.SetEquVal("ZR2798");
    }
    private clearAddForm() {
        this.addForm = {
            id: "",
            version: "",
            url: "",
            deviceModel: "",
            remark: "",
            fileName: "",
        };
    }
    private CloseDialog() {
        this.clearAddForm();
        this.$refs.upload.clearFiles();
        this.showLabelSelectInputFlag = false;
    }
    //设备型号选择
    private EquList: any[] = [{ name: "ZR2798", id: "ZR2798" }];
    private SetEquVal(val: string) {

        this.addForm.deviceModel = val;
    }

    /**获取列表数据 */
    private getListData() {
        new pkgManagement().getList(this.form, this.tablePage, (res: any) => {
            res.data.forEach((item: any, index: number) => {
                item.upTarget = "AI摄像头";
            });
            this.tableData = res.data;
            this.tablePage = { ...res.page };
        });
    }
    //提交升级包
    private AddDialodData() {
        this.addForm.version = this.addForm.version.trim();
        if (this.addForm.version == "") {
            this.$message({
                type: "warning",
                message: "版本号不能为空",
            });
            return;
        } else if (!this.isValidVersion(this.addForm.version)) {
            this.$message({
                type: "warning",
                message: "版本号格式不正确",
            });
            return;
        } else if (this.addForm.url == "") {
            this.$message({
                type: "warning",
                message: "请上传升级包",
            });
            return;
        }
        if (this.addForm.id) {
            new pkgManagement().editPkg(this.addForm, (res: any) => {
                this.$message({
                    type: "success",
                    message: "修改升级包成功",
                });
                this.clearAddForm();
                this.CloseDialog();
                this.getListData();
                this.fileList = [];
                this.$refs.upload.clearFiles();
            });
        } else {
            new pkgManagement().addPkg(this.addForm, (res: any) => {
                this.$message({
                    type: "success",
                    message: "新增升级包成功",
                });
                this.clearAddForm();
                this.CloseDialog();
                this.getListData();
                this.fileList = [];
                this.$refs.upload.clearFiles();
            });
        }
    }
    //校验版本号
    private isValidVersion(version: string) {
        const pattern = /^[0-9]+(\.[0-9]+){2}$/;
        return pattern.test(version);
    }
    // 查询
    private search() {
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getListData();
    }
    //重置
    private resetForm() {
        this.tableData = [];
        this.SelectData = "";
        this.form = {
            version: "",
            createtimeEnd: "",
            createtimeStart: "",
        };
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getListData();
    }
    private handlePageChange(val: any) {
        // 分页
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.getListData();
    }
    //查询填写版本号
    private SetVersionValue(val: any) {
        this.form.version = val;
    }
    //新增填写版本号
    private SetAddVersionValue(val: any) {
        this.addForm.version = val;
    }

    //点击列表修改
    // private EditData(row: any) {
    //     this.showLabelSelectInputFlag = true;
    //     this.addForm = {
    //         id: row.id,
    //         version: row.version,
    //         url: row.url,
    //         deviceModel: row.deviceModel,
    //         remark: row.remark,
    //         fileName: row.fileName,
    //     };
    // }

    //获取oss参数
    private ossconfigdata: any = {};
    ossconfig() {
        new Column().ossconfig((res: any) => {
            this.ossconfigdata = res.data;
        });
    }
    //上传文件
    setFile(file: any, fileList: any): void {
        this.addForm.fileName = file.name;
        this.putObject(file);
    }
    //上传到oss
    async putObject(file: any) {
        const loading = this.$loading({
            lock: true,
            text: "请稍后...",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
        });
        let OSS = require("ali-oss");
        // const data = new OSS.Buffer(file);
        let client = new OSS({
            region: "oss-cn-hangzhou",
            accessKeyId: this.ossconfigdata.accessKeyId,
            accessKeySecret: this.ossconfigdata.accessKeySecret,
            stsToken: this.ossconfigdata.securityToken,
            bucket: this.ossconfigdata.bucketName,
        });
        try {
            const result = await client.put(
                this.ossconfigdata.bucketName +
                    "/" +
                    this.ossconfigdata.directory +
                    this.ossconfigdata.userId +
                    "/" +
                    file.name,
                file.raw
            );
            this.addForm.url = result.url;
            loading.close();
        } catch (e) {
            loading.close();
        }
    }
    @Watch("SelectData", { deep: true }) //处理选择日期
    private handlerSelectData(newVal: any) {
        if (newVal) {
            this.form.createtimeStart = new dateFun().returnAllDate(newVal[0], "-");
            this.form.createtimeEnd = new dateFun().returnAllDate(newVal[1], "-");
        } else {
            this.SelectData = "";
            this.form.createtimeEnd = "";
            this.form.createtimeStart = "";
        }
    }
    //删除升级包
    private delPkg(row: any) {
        this.$confirm("确认删除此升级包吗", "删除", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        })
            .then(() => {
                new pkgManagement().delPkg(row.id, (res: any) => {
                    this.$message({
                        type: "success",
                        message: "删除成功",
                    });
                    this.getListData();
                });
            })
            
    }
    //下载升级包
    private downloadPkg(row: any) {
        window.open(row.url);
    }
    activated() {
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };

        this.ossconfig();
        this.getListData();
    }
}
