<template>
  <div></div>
</template>

<script>
import {Prop , Component , Vue} from 'vue-property-decorator'
@Component({})
export default class StatisticsPage extends Vue{}
</script>

<style>

</style>