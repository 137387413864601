

























































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {HandleTable, HandlePageChange} from '@/common/Views/comm/handleTable';
import {Column, ComboList, Result} from "@/common/Views/system/column/column";
import {AxiosData} from '@/common/http/http';
import { Util } from '@/common/Views/comm/util';
import {
    OutManageTable,
    TraceabilityOutManage,
    TraceabilityOutManageForm
} from "@/common/Views/traceabilityOutManage/traceabilityOutManage";

@Component
export default class HelloWorld extends Vue {
    public column = new Column();

    private form: TraceabilityOutManageForm = {
        groupCode: [],
        farmId: "",
        labelNos: "",
        checkTime: '',
        status: '',
    };

    private destinationList: {id: string, destinationName: string}[] = [];

    private activated(): void {
        this.getTable();
        this.getTree();
        this.changeGroupCode();
        // this.getDestination();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    // private getDestination() {
    //     new Column().getDestination((data: AxiosData<{id: string, destinationName: string}[]>)=>{
    //         this.destinationList = data.data;
    //     })
    // }

    private created(): void {
        this.getTable();
        this.getTree();
        this.changeGroupCode();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    private treeData: Result[] = [];

    private getTree() {
        Util.getTree((result: Result[]) => {
            this.treeData = result;
        });
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);

        this.getTable();
        this.getTree();
        this.changeGroupCode();
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;

        this.getTable();
        // console.log(this.form)
    }


    private comboList: ComboList[] = [];

    private changeGroupCode(): void {
        this.comboList = [];
        // this.form.farmId = "";
        new Column().getFarm(this.form.groupCode[this.form.groupCode.length - 1], (data: AxiosData<ComboList[]>) => {
            this.comboList = data.data;
        },"2");
    }

    @Watch("form.groupCode")
    getgroupCode(newVal:Array<object>,oldVal:Array<object>) {
        if (newVal !== oldVal) {
            this.form.farmId = '';
            // this.form.typeConfId = ''; 
        }
    }



    private tableData: OutManageTable[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private handlePageChange(val: HandlePageChange) {

        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }

    private traceabilityOutManage: TraceabilityOutManage;

    constructor() {
        super();
        this.traceabilityOutManage = new TraceabilityOutManage();
    }

    private getTable(): void {
        if (this.$getResourceCodes("label_list")) {
            this.traceabilityOutManage.getTable(this.form, this.tablePage, (data: AxiosData<OutManageTable[]>)=>{

                this.tableData = data.data;
                this.tablePage.totalCount = data.page.totalCount;
            })
        }
    }


    private Export() {
        this.traceabilityOutManage.export(this.form);
        // console.log(this.form)
    }

    private goDetail(row: OutManageTable) {
        this.$router.push({
            path: '/slaughterExamineDetail',
            query: {
                slaughterExamineDetailId: row.id
            }
        })
    }

}
