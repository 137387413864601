


















































































import {Watch , Component , Vue} from 'vue-property-decorator'
import {BasicsClass} from '@/common/BasicsClass';
import {dateFun} from '@/common/date'
import { SimManage } from "@/common/Views/basics/simManage/simManage";
@Component({})
export default class RebateDetails extends Vue{

    private form:any ={
        userName:'',
        mobile:'',
        itemType:'',
        startTime:'',
        endTime:'',
        labelNumber:'',
        oldLabelNumber:'',
        farmId:'',
    }
    private SelectTime:any[] = [] 
    private tableData:any[] = []
    private tablePage:any ={
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }
    private TypeWayList:any[] =[//返利类型 数组
        {name:'请选择',id:''},
        {name:'耳标佩戴',id:'1'},
        {name:'免疫登记',id:'2'},
        {name:'治疗登记',id:'3'},
        {name:'配种登记',id:'4'},
        {name:'银行线下监管',id:'5'},
    ]
    private FarmList:any[] = []
    private moneyCount:any = 0
    private jdMoneyCount:any = 0

    //事件
    private search(){//搜索查询
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getList()
        this.AllCount()
    }
    private restForm(){//重置
        this.ClearForm()
        this.ClearSelectTime()
        this.getFarmList()
        this.getList()
        this.AllCount()
    }
    private handlePageChange(val: any) { //分页 事件
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        //请求列表数据
        this.getList()
    }
    private Export(){ //导出
        this.exportList()
    }
    private ToNewPage(row:any){//跳转对应页面
        let labelNumber:string = row.labelNumber
        switch(row.itemType){
            case 1 :
                this.RouterPushFun('/infoRegister' , labelNumber)
                break;
            case 2 :
                this.RouterPushFun('/newImmuneRecord' , labelNumber)
                break;
            case 3 :
                this.RouterPushFun('/newPreventRecord' , labelNumber)
                break;
            case 4 :
                this.RouterPushFun('/newbreedingHistory' , labelNumber)
                break;
            case 5 :
                this.RouterPushFun('/loanapproval' , labelNumber)
                break;
        }
    }

    private RouterPushFun(path:string , labelNumber:string){
        this.$router.push({
            path:path,
            query:{
                labelNumber:labelNumber
            }
        })
    }


    //接口
    private getList(){ //table 列表
        new BasicsClass().BasicGet('/animal/web/rebate/list',{...this.form,...this.tablePage},false,false,(data:any) =>{
            this.tableData = data.data
            this.tablePage.totalCount = data.page.totalCount
        })
    }
    private getFarmList(){//获取养殖场列表
        new BasicsClass().BasicGet('/animal/web/rebate/farmList',{},false,false,(data:any)=>{
            this.FarmList = data.data
        })
    }
    private exportList(){//导出
        new SimManage().exportList1(this.form)
    }
    private AllCount(){//统计
        new BasicsClass().BasicGet('/animal/web/rebate/total' , {...this.form} , false, false , (data:any)=>{
            this.jdMoneyCount = data.data.jdMoney
            this.moneyCount = data.data.money
        })
    }


    //逻辑处理
    private ClearForm(){ //重置form
        this.form ={
            userName:'',
            mobile:'',
            itemType:'',
            startTime:'',
            endTime:'',
            labelNumber:'',
            oldLabelNumber:'',
            farmId:'',
        }
    }
    private ClearSelectTime(){
        this.SelectTime = []
    }


    //监视
    @Watch('SelectTime')
    private changeSelectTime(newVal:any[]){
        if(newVal.length == 0) return
        let oneTime =  new dateFun().returnDate(newVal[0] , '-') + ' ' + new dateFun().returnHourMinuteSecend(newVal[0], ':')
        let twoTime =  new dateFun().returnDate(newVal[1] , '-') + ' ' + new dateFun().returnHourMinuteSecend(newVal[1], ':')
        this.form.startTime=oneTime
        this.form.endTime=twoTime
    }

    activated(){
        this.getFarmList()
        this.getList()
        this.AllCount()
    }
}
