import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';

export interface ModelTypeManageTableData<T = string> {
    id: T;
    name: T;
    desc: T;
    status: number | T;
}


export class ModelTypeManage extends BasicsClass {

    public getTable(name: string, page: HandleTable, cb: Function) {
        this.getTableList({name}, page, '/animal/web/modelType/list', cb);
    }

    public getDetail(id: string, cb: Function) {
        this.BasicGet('/animal/web/modelType/detail', {id}, false, true, cb);
    }

    public save(id: string | null, from: ModelTypeManageTableData, cb: Function) {
        this.AddOrUpdate(id, from, '/animal/web/modelType/update', '/animal/web/modelType/add', cb);
    }

}
