import { BasicsClass } from '@/common/BasicsClass';

export class GroupCodeApi {
    /**@param getForGroupCode 获取当前账号所有养殖场的区域 */
    public getForGroupCode(cb: Function) {  //获取当前账号所有养殖场的区域
        new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/getForGroupCode', {}, false, false, cb)
    }
    /**@param getGroupCodeForUser 当前登录者可选择的组织机构 */
    public getGroupCodeForUser(pid: any, isInternational: string, cb: Function) {//获取组织机构  PID  当前登录者可选择的组织机构   ---> 3.1.9海外版变更
        if (pid === undefined) pid = -1
        new BasicsClass().BasicGet('/animal/web/provinceCityDistrict/getGroupCodeForUser', { pid, isInternational, flag: "0" }, false, false, cb)
    }
}

class CompanyApi extends GroupCodeApi { //获取当前账号所有养殖场所属的企业并可通过groupCode进一步筛选
    public getCompanyList(groupCode: string, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/farm/getCompanyList', { groupCode }, false, false, cb)
    }
}

export class FarmApi extends CompanyApi {
    public getFarmList(companyId: string, cb: Function) {  //获取养殖场
        new BasicsClass().BasicGet('/animal/web/farm/getFarmList', { companyId }, false, false, cb)
    }
    public getPlaceList(farmId: string, cb: Function) {  //获取养殖舍
        new BasicsClass().BasicGet('/animal/web/transferRecord/getThisAnimalFarmPlaceList', { farmId, parentId: 0 }, false, false, cb)
    }
    public getSmallPlace(placeId: string, cb: Function) {  //获取养殖栏
        new BasicsClass().BasicGet('/animal/web/animalFarmPlace/getSmallPlace', { placeId }, false, false, cb)
    }
    public getSelectAll(farmId: string, cb: Function): void { //获取品种类别
        new BasicsClass().BasicGet1('/animal/web/confTypeInfo/selectAll', { farmId }, false, false, cb)
    }


    /**@param getJDFarmList 获取京东养殖场 */
    public getJDFarmList(cb: Function) {
        new BasicsClass().BasicGet('/animal/web/JDAnimal/farmList', {}, false, true, cb)
    }
    /**@param getHouseList 获取养殖舍 */
    public getHouseList(farmId: string, cb: Function) {
        new BasicsClass().BasicGet("/animal/web/animalMark/houseList", { farmId }, false, false, cb)
    }

    /**@param getFarmComboList 获取养殖场 */
    public getFarmComboList(groupCode: string, cb: Function) {
        new BasicsClass().BasicGet("/animal/web/farm/comboList", { groupCode }, false, false, cb)
    }
}

export class FileApi {
    public getExcel(url: string, form?: any, cb?: Function) {
        new BasicsClass().getFile(url, { ...form }, cb)
    }
}

export class CommonModule extends FarmApi { }//互相继承 最后引用这个