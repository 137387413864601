var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('el-card',{staticClass:"box-card"},[_c('div',{staticClass:"clearfloat",staticStyle:{"padding-left":"20px"},attrs:{"slot":"header"},slot:"header"},[_c('el-form',{ref:"form",staticClass:"el-f",attrs:{"label-position":"left","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"保险公司名称 :","prop":"mobile"}},[_c('el-col',{attrs:{"span":11}},[_c('el-input',{staticStyle:{"width":"300px"},model:{value:(_vm.form.insuranceCompanyName),callback:function ($$v) {_vm.$set(_vm.form, "insuranceCompanyName", $$v)},expression:"form.insuranceCompanyName"}})],1)],1)],1)],1),_c('div',{staticStyle:{"width":"100%","background-color":"#fff","position":"relative","bottom":"40px","border-radius":".2rem"}},[_c('div',{staticStyle:{"padding-top":"20px"}},[_c('vxe-button',{on:{"click":_vm.handleAdd}},[_vm._v("新增配置")])],1),_c('div',{staticStyle:{"width":"930px","padding":"20px 20px"}},[_c('vxe-table',{ref:"table",staticStyle:{"top":"0","border-radius":"0"},attrs:{"border":"","highlight-hover-row":"","header-align":"center","show-header":true,"data":_vm.form.businessList}},[_c('vxe-table-column',{attrs:{"align":"center","field":"confName","title":"品种类型","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(_vm.typeInput == rowIndex)?_c('div',[_c('el-select',{staticStyle:{"width":"120px!important"},attrs:{"placeholder":"请选择"},on:{"visible-change":function($event){return _vm.clearModule(row)},"change":_vm.changeConf},model:{value:(row.confId),callback:function ($$v) {_vm.$set(row, "confId", $$v)},expression:"row.confId"}},_vm._l((_vm.confTypeList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1):_c('p',[_vm._v(_vm._s(row.confName))])]}}])}),_c('vxe-table-column',{attrs:{"align":"center","field":"confInfoName","title":"品种分类","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(_vm.typeInput == rowIndex)?_c('div',[_c('el-select',{staticStyle:{"width":"120px!important"},attrs:{"multiple":"","placeholder":"请选择"},on:{"change":_vm.changeConfInfo},model:{value:(_vm.confInfoChange),callback:function ($$v) {_vm.confInfoChange=$$v},expression:"confInfoChange"}},_vm._l((_vm.confInfoTypeList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}})}),1)],1):_c('p',[_vm._v(_vm._s(row.confInfoName))])]}}])}),_c('vxe-table-column',{attrs:{"align":"center","field":"","title":"区间体重","width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(_vm.typeInput == rowIndex)?_c('div',[_c('p',{staticStyle:{"float":"left","margin":"0 10px 0 0"}},[_c('el-input',{staticStyle:{"width":"150px"},model:{value:(row.startWeight),callback:function ($$v) {_vm.$set(row, "startWeight", $$v)},expression:"row.startWeight"}}),_vm._v(" 斤 ")],1),_c('p',{staticStyle:{"float":"left","line-height":"35px"}},[_vm._v("~")]),_c('p',{staticStyle:{"float":"left","margin":"0 0 0 10px"}},[_c('el-input',{staticStyle:{"width":"150px"},model:{value:(row.endWeight),callback:function ($$v) {_vm.$set(row, "endWeight", $$v)},expression:"row.endWeight"}}),_vm._v(" 斤 ")],1)]):_c('div',{staticStyle:{"width":"160px","overflow":"hidden","margin":"0 auto","display":"flex","justify-content":"space-around"}},[_c('p',{},[_vm._v(" "+_vm._s(row.startWeight)+" 斤 ")]),_c('p',{staticStyle:{"line-height":"20px"}},[_vm._v("~")]),_c('p',{},[_vm._v(" "+_vm._s(row.endWeight)+" 斤 ")])])]}}])}),_c('vxe-table-column',{attrs:{"align":"center","field":"payMoney","title":"赔付金额","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(_vm.typeInput == rowIndex)?_c('p',{},[_c('el-input',{staticStyle:{"width":"80px"},model:{value:(row.payMoney),callback:function ($$v) {_vm.$set(row, "payMoney", $$v)},expression:"row.payMoney"}}),_vm._v(" 元 ")],1):_c('p',{},[_vm._v(" "+_vm._s(row.payMoney)+" 元 ")])]}}])}),_c('vxe-table-column',{attrs:{"align":"center","width":"100","title":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var rowIndex = ref.rowIndex;
return [(_vm.typeInput == rowIndex)?_c('div',[_c('el-button',{staticStyle:{"color":"#36BE98"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.saveSingle()}}},[_vm._v("保存")]),_c('el-button',{staticStyle:{"color":"#36BE98"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.cancelSingle(rowIndex)}}},[_vm._v("取消")])],1):_c('div',[_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.compile(rowIndex)}}},[_vm._v("编辑")]),_c('el-button',{staticStyle:{"color":"red"},attrs:{"type":"text","size":"small"},on:{"click":function($event){return _vm.deleteSingle(rowIndex)}}},[_vm._v("删除")])],1)]}}])})],1)],1),_c('div',{staticStyle:{"padding":"20px"}},[_c('el-button',{staticStyle:{"background-color":"#36BE98","border-color":"#36BE98"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("保存")]),_c('el-button',{staticStyle:{"background-color":"#EAECF2","border-color":"#EAECF2","color":"#343434"},attrs:{"type":"primary"},on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v("取消")])],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }