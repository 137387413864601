




























import {Watch , Prop , Component , Vue} from 'vue-property-decorator'
import {Util} from "@/common/Views/comm/util";
import { Column, ComboList, Result } from "@/common/Views/system/column/column";
@Component({})
export default class UpLoadImgComponentVue extends Vue{
    @Prop() ShowImgUrl:any
    private imgurl:any="" //显示的图片数据

    private ShowImgFlag:boolean = false //是否显示图片

    private showIconFlag:boolean = false  //鼠标移动是否显示icon

    private dialogVisible:boolean = false
    
    private handleChangeOne(file:any,fileList:any){ //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        let nameArr:any[] =  file.name.split('.')
        if (nameArr[nameArr.length-1] === "jpg" || nameArr[nameArr.length-1] === "png") {
            fileList.pop()
            this.putObject1(file); //如果等于了 那么就 执行添加操作
        } else {
            Util.showToast("请上传jpg或png格式图片", "warnning");
        }
    }
    private showBigImg(){ //放大图片
        this.dialogVisible = true;
        return
    }
    private delImg(){ //删除图片
        this.imgurl = ""
        this.showIconFlag = false
        this.ShowImgFlag = false
    }


    private ossconfigdata:any ={} //oss 参数对象
    private ossconfig(): void {//获取oss参数
        new Column().ossconfig((res: any) => {
        this.ossconfigdata = res.data;
        });
    }
    private async putObject1(file: any) {
        let OSS = require("ali-oss");
        let client = new OSS({
            region: "oss-cn-hangzhou",
            accessKeyId: this.ossconfigdata.accessKeyId,
            accessKeySecret: this.ossconfigdata.accessKeySecret,
            stsToken: this.ossconfigdata.securityToken,
            bucket: this.ossconfigdata.bucketName,
        });
        try {
        const result = await client.put(
            this.ossconfigdata.bucketName +
            "/" +
            this.ossconfigdata.directory +
            this.ossconfigdata.userId +
            "/" +
            file.name,
            file.raw
        );
        // console.log(result);
        this.ShowImgFlag = true
        this.imgurl = result.url
        } catch (e) {
            console.log(e);
        }
    }

    @Watch('imgurl',{immediate:true})
    private changeImgUrl(newVal:string){
        if(newVal == ''){
            this.ShowImgFlag = false
        }else{
            this.ShowImgFlag = true
        }
        this.$emit('SetNewImgUrl' , newVal)  //当上传的值 或 修改的值 改变 通知父组件更改
    }

    @Watch('ShowImgUrl',{immediate:true})
    private ChangeShowImgUrl(newVal:string){
        if(newVal == '')this.imgurl = newVal
        if(newVal == undefined || newVal == '') return
        this.imgurl = newVal
        console.log(this.imgurl)
        this.ShowImgFlag = true
    }
    activated(){
        this.ossconfig() 
    }
}
