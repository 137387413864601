import {BasicsClass} from '@/common/BasicsClass';
import {sForm, TableSchoolAttendanceData} from '@/common/Views/attendance/schoolAttendance/schoolAttendance';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {AxiosData} from '@/common/http/http';
import {DeepLoop} from '@/common/deepLoop/deepLoop';
import {Util} from '@/common/Views/comm/util';

export interface SchoolAttendanceDetailTable<T> extends TableSchoolAttendanceData<T> {
    studentId: T;
    position: T;
    ioType: T;
    ioTime: T;
    status: T;
    studentName: T;
    statusType: T;
    ioTypeFont: T;
    temperatureStatus: T;
    temperature: T;
}

export interface Form<T> extends sForm<T>{
    ioType: T;
    status: T;
    time: T[];
    temperatureStatus: T;
    studentName:T;
}

export enum Status {
    '正常' = 1,
    '迟到',
    '早退',
    '缺勤',
    '异常',
}

export enum IoType {
    '进' = 1,
    '出'
}

enum TemperatureStatus {
    '正常' = 1,
    '异常'
}

export interface CopyWithSendData extends Form<string> {
    studentId: string;
    startTime: string;
    endTime: string;

    number?: string;
    buildingNumber?: string;
}

export class SchoolAttendanceDetail extends BasicsClass {

    public handleArguments(form: any, id?: string): CopyWithSendData {
        let readySendData: CopyWithSendData = new DeepLoop().deepCopy(form, false);
        if(id) readySendData.studentId = id;

        try {
            readySendData.startTime = new Util().dateTimeUtil(form.time[0]);
            readySendData.endTime = new Util().dateTimeUtil(form.time[1]);
        } catch {
            readySendData.startTime = '';
            readySendData.endTime = '';
        }

        delete readySendData.time;

        return readySendData;
    }

    public handleResultDataWithIoTypeAndStatus(data: any): void {
        for (const i in data) {
            data[i].statusType = Status[parseInt(data[i].status)];

            data[i].ioTypeFont = IoType[parseInt(data[i].ioType)];
        }
    }

    public getSchoolAttendanceDetailTable(id: string, form: Form<string>, tablePage: HandleTable, callback: Function) {
        const readySendData = this.handleArguments(form, id);

        this.getTableList(readySendData, tablePage, '/edu/biz/eduAttend/attendList', (data: AxiosData<SchoolAttendanceDetailTable<string>[]>) => {
            this.handleResultDataWithIoTypeAndStatus(data.data);
            for(const i in data.data){
                data.data[i].temperatureStatus = TemperatureStatus[parseInt(data.data[i].temperatureStatus)];
                if(data.data[i].temperature) data.data[i].temperature += '℃';
            }
            callback(data);
        });
    }

    public Export(form: Form<string>, id: string): void {
        const readySendData = this.handleArguments(form, id);

        this.getFile('/edu/biz/eduAttend/attendForExport', readySendData);
    }
}
