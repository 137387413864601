























































interface FormInfo{
    id:string,
    bankName:string,
    groupCode:Array<number>,
    bankLevel:string,
    parentId:Array<number>
    enableFlag:boolean
}
interface FormUpDataInfo{
    id:string,
    bankName:string,
    groupCode:Array<number>,
    bankLevel:string,
    parentId:Array<number>,
    enableFlag:boolean
}
import {Component , Vue, Watch} from 'vue-property-decorator'
import {getBankTree315 , addBank315,getGroupCodeForUser,updateBank315} from '@/api/index'
import {BankConfigTs} from './components/BankConfig'
import InputConponentsVue from '@/components/BasicConponents/InputConponentsVue.vue'
import CascaderComponentsVue from '@/components/BasicConponents/CascaderComponentsVue.vue'
import SelectComponentsVue from '@/components/BasicConponents/SelectComponentsVue.vue'
@Component({
    components:{InputConponentsVue,CascaderComponentsVue,SelectComponentsVue}
})

export default class BankConfig extends Vue{
    private TreeDataList:any[] = [] //树结构变量
    private ParentList:any[] = []
    private AdressDataList:any[] = []
    private PropsObj:any = new BankConfigTs().defaultProps  //级联prop对象
    private activeName:string ="user"  //tab
    private form:FormInfo =new BankConfigTs().form  //form
    private updataForm:FormUpDataInfo = new BankConfigTs().UpdataFrom
    private LevelListData:any[] = new BankConfigTs().Levellist //机构分析数组变量
    private AddRulerObj:any = new BankConfigTs().Addrules
    private LevelName:string = ""
    private IsAdd:boolean = true
    private ShowCascader:boolean = true
    private CascaderPlaceHolder:string =''

    //事件处理
    private handleNodeClick(traget:any,node:any,vc:any){  //点击左侧树结构的某一项
        console.log(traget)
        this.IsAdd = false //当点击 树结构的时候那么表示 不再是添加 而是修改
        this.form.groupCode = traget.groupCode //3.1.5 测试临时新增需求 要求点击之后显示对应的地区
        this.CascaderPlaceHolder = traget.groupName //3.1.5 测试临时新增需求 要求点击之后显示对应的地区
        this.ChangeShowCas()
        this.form.parentId =[9999999999999]  //编辑的时候将所属上级的值变为空数组 
        this.form.bankLevel = node.data.bankLevel.toString()//赋值机构分支  
        this.form.bankName = node.data.bankName  //赋值给输入框点击的名称
        this.form.enableFlag = traget.enableFlag //业务状态
        this.form.id = traget.id  //赋值id
        //赋值所属上级
        console.log(traget.bankLevel)
        if(traget.bankLevel == 1){  //是总行
            this.updataForm.parentId = traget.id //父节点的id
            this.LevelName = '无'
        }else{
            this.LevelName = node.parent.data.bankName  //父节点的银行名字
            this.updataForm.parentId = node.parent.data.id  //父节点的id
        }
    }
    private addBank(){  //新增银行
        this.LevelName = ""
        this.CascaderPlaceHolder = ''
        this.IsAdd = true  //点击新增的时候将表示改为新增 
        this.ChangeShowCas()
        this.ClearForm()  //重置form
        this.ResetForm()    //重置表单校验
    }
    private CancelData(){
        if(this.IsAdd){
            this.ClearForm()  //重置form
            this.ResetForm() //重置表单校验
            this.ChangeShowCas()
        }
        
    }
    private SaveData(){
        if(this.IsAdd){
            (this.$refs['formRef'] as any).validate((flag:Boolean)=>{
                if(flag){
                    //发送请求  新增银行接口
                    Reflect.deleteProperty(this.form,'enableFlag')
                    Reflect.deleteProperty(this.form,'id')
                    addBank315(this.HandlerFormData(this.form),()=>{
                        //重置form  重置校验
                        this.ClearForm()  //重置form
                        this.ResetForm() //重置表单校验
                        this.getTreeData()
                        this.ChangeShowCas()
                    },()=>{
                        this.ClearForm()  //重置form
                        this.ResetForm() //重置表单校验
                        this.ChangeShowCas()
                    })
                }
            })
        }else{
            (this.$refs['formRef'] as any).validate((flag:Boolean)=>{
                if(flag){
                    //发送请求  更新银行接口
                    let data:any = JSON.parse(JSON.stringify(this.form))
                    Reflect.deleteProperty(data,'parentId')
                    Reflect.deleteProperty(data,'bankLevel')
                    updateBank315(this.HandlerFormData(data),()=>{
                        //重置form  重置校验
                        this.ClearForm()  //重置form
                        this.ResetForm() //重置表单校验
                        this.getTreeData()
                        this.ChangeShowCas()
                    },()=>{
                        this.ClearForm()  //重置form
                        this.ResetForm() //重置表单校验
                        this.ChangeShowCas()
                    })
                }
            })
        }
        
    }

    private ClearForm(){  //重置 form
        this.form = new BankConfigTs().form 
    }
    private ResetForm(){  //移除表单校验
        (this.$refs['formRef'] as any).resetFields();
    }

    //emit赋值
    private SetBankNameVal(val:string){
        this.form.bankName = val
    }
    private SelectLevel(val:string){
        this.form.bankLevel = val
    }
    private SetLoanAdressVal(val:Array<number>){
        this.form.groupCode = val
    }

    private ChangeShowCas(){
        this.ShowCascader = false
        let timer = setTimeout(()=>{
            clearTimeout(timer)
            this.ShowCascader = true
        })
    }


    //数据处理
    private HandlerFormData(data:any){
        let res:any = JSON.parse(JSON.stringify(data))
        if(res.groupCode)Array.isArray(res.groupCode) ? res.groupCode = res.groupCode.slice(-1).toString() : res.groupCode //将grounpcode变为 字符串
        if(res.parentId)Array.isArray(res.parentId) ? res.parentId = res.parentId.slice(-1).toString() : res.parentId //将grounpcode变为 字符串
        
        return res
    }

    private getTreeData(){
        getBankTree315((data:any)=>{
            this.TreeDataList = data.data
            let res:any = JSON.parse(JSON.stringify(data.data))
            res.unshift({bankName:"无",id:"0",children:[]})
            this.ParentList = res
        })
    }

    activated(){
        this.getTreeData()
        // 
        getGroupCodeForUser((data:any)=>{
            this.AdressDataList = data.data
        })
    }



}
