










































































































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import { FarmData } from "@/views/components/FarmGetData";
import InputConponentsVue from "@/components/BasicConponents/InputConponentsVue.vue";
import { CattleEarlyWarningConfigurationApi } from "@/api/ConfigModule/index";
@Component({
    components: { PageBox, SelectComponentsVue, InputConponentsVue },
})
export default class cattleEarlyWarningConfigurationAdd extends Vue {
    private form: { [key: string]: string } = {
        breedId: "",
        firstBreedDays: "",
        afterBirthBreedDays: "",
        pregnancyWarningDays: "",
        birthWarningDays: "",
    };
    private rulus: any = {
        breedId: [{ required: true, message: "  " }],
        firstBreedDays: [{ required: true, message: "  " }],
        afterBirthBreedDays: [{ required: true, message: "  " }],
        pregnancyWarningDays: [{ required: true, message: "  " }],
        birthWarningDays: [{ required: true, message: "  " }],
    };
    private breedList: Array<any> = [];
    //emit品种选择
    private EmitBreedId(breedId: string) {
        this.form.breedId = breedId;
    }
    //emit 发情
    private EmitHeat(val: string) {
        this.form.firstBreedDays = val;
    }
    //emit 配种
    private EmitHybridization(val: string) {
        this.form.afterBirthBreedDays = val;
    }
    //emit 孕检
    private EmitpregnancyTest(val: string) {
        this.form.pregnancyWarningDays = val;
    }
    //emit 分娩
    private EmitChildbirth(val: string) {
        this.form.birthWarningDays = val;
    }
    //品种
    private getAllBreed(farmId: any) {
        this.breedList = [];
        new FarmData().getAllBreedAdd(farmId, (data: any) => {
            this.breedList = data.data;
        });
    }
    //event 保存数据
    private saveData() {
        if (this.form.breedId === "") {
            this.$message({
                message: this.$t("config.beef_config.warningOne") as string,
                type: "warning",
            });
            return;
        } else if (this.form.firstBreedDays === "") {
            this.$message({
                message: this.$t("config.beef_config.warningTwo") as string,
                type: "warning",
            });
            return;
        } else if (this.form.afterBirthBreedDays === "") {
            this.$message({
                message: this.$t("config.beef_config.warningThree") as string,
                type: "warning",
            });
            return;
        } else if (this.form.pregnancyWarningDays === "") {
            this.$message({
                message: this.$t("config.beef_config.warningFour") as string,
                type: "warning",
            });
            return;
        } else if (this.form.birthWarningDays === "") {
            this.$message({
                message: this.$t("config.beef_config.warningFive") as string,
                type: "warning",
            });
            return;
        }
        (this.$refs.form as any).validate((flag: boolean) => {
            new CattleEarlyWarningConfigurationApi().addCattleEarlyWarning(
                this.form,
                () => {
                    this.form = {
                        breedId: "",
                        firstBreedDays: "",
                        afterBirthBreedDays: "",
                        pregnancyWarningDays: "",
                        birthWarningDays: "",
                    };
                    this.$router.push({ path: "/beefconfig" });
                }
            );
        });
    }
    private activated(){
        this.form = {
            breedId: "",
            firstBreedDays: "",
            afterBirthBreedDays: "",
            pregnancyWarningDays: "",
            birthWarningDays: "",
        };
        console.log(this.form, "this.form");

        this.getAllBreed("");
    }
}
