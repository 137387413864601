











































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Util } from "@/common/Views/comm/util";
import {
    LabelManage,
    LabelManageDetailForm
} from "@/common/Views/basics/labelManage/labelManage";
import { Rule } from "@/common/Views/comm/formRule";
import { TemperatureInfo } from "@/common/Views/basics/temperatureInfo/temperatureInfo";
import { DateUtil } from "@/common/DateUtil";
import { DataECharts } from "@/common/echarts/DataECharts";
import echarts from "echarts";
import NewEchartsVue from "@/views/basics/temperatureInfo/temperatureInfoDetail/NewEchartsVue.vue";
import { dateFun } from "@/common/date";
//loading
import { Loading } from "element-ui";
import { BasicsClass } from "@/common/BasicsClass";

@Component({
    components: { NewEchartsVue }
})
export default class HelloWorld extends Vue {
    @(Prop()!) deathtime: any;
    private created(): void {
        console.log(this.btnActive);
        // this.day();
        // this.getLineF(this.nameList, this.dataList);
    }

    private pickerMinDate: any = null;

    private pickerOptions = {
        onPick: (obj: any) => {
            if (this.pickerMinDate) {
                this.pickerMinDate = null;
            } else {
                this.pickerMinDate = new Date(obj.minDate).getTime();
            }
        },
        disabledDate: (time: any) => {
            if (this.pickerMinDate) {
                const day1 = 7 * 24 * 3600 * 1000;
                let maxTime = this.pickerMinDate + day1;
                let minTime = this.pickerMinDate - day1;
                return time.getTime() > maxTime || time.getTime() < minTime;
            } else {
                return false;
            }
        }
    };
    //loading
    private options: any = {
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
    };
    // private serviceLoading:any = Loading.service(this.options);

    // @Watch('value')
    // $resetTime(): void {
    //     console.log(this.value)
    //     if(!this.value){
    //         this.pickerMinDate = null
    //         this.pickerOptions.disabledDate(new Date())
    //     }
    // }

    private value: Date[] = [];

    private dateUtil: DateUtil;
    private temperatureInfo: TemperatureInfo;

    constructor() {
        super();
        this.dateUtil = new DateUtil();
        this.temperatureInfo = new TemperatureInfo();
    }

    private btnActive: number = 1;
    private typeActive: number = 1;

    private week(): void {
        this.btnActive = 1;
        this.typeActive = 1;
        this.value = [
            new Date(this.dateUtil.getStartDayOfWeek()),
            new Date(this.dateUtil.getEndDayOfWeek())
        ];
        // this.getLineF(this.nameList, this.dataList);
        // this.getDot(this.nameList, this.dataObject)
        this.setAll(this.nameList, this.dataList, this.dataObject);
    }

    @Watch("deathtime")
    private threeday(): void {
        //字符串转日期格式，strDate要转为日期格式的字符串
        let now = new Date(Date.parse(this.deathtime.replace(/-/g, "/")));
        // let now = new Date(); //当前日期
        let nowDay = now.getDate(); //当前日
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        this.value = [
            new Date(nowYear, nowMonth, nowDay - 1),
            new Date(nowYear, nowMonth, nowDay + 1, 23, 59, 59)
        ];
        console.log(this.value);

        this.getLine();

        // this.serviceLoading;
    }
    private month(): void {
        this.btnActive = 2;
        let now = new Date(); //当前日期
        let nowDayOfWeek = now.getDay(); //今天本周的第几天
        let nowDay = now.getDate(); //当前日
        let nowMonth = now.getMonth(); //当前月
        let nowYear = now.getFullYear(); //当前年
        this.value = [
            new Date(nowYear, nowMonth, nowDay - nowDayOfWeek + 1),
            new Date(nowYear, nowMonth, nowDay + (7 - nowDayOfWeek), 23, 59, 59)
        ];
        this.getLine();
        this.getThreeData();
        // this.serviceLoading;
    }

    private day(): void {
        this.btnActive = 0;
        console.log(new Date().toLocaleDateString().split("/"));
        let n = parseFloat(new Date().toLocaleDateString().split("/")[0]);
        let y = parseFloat(new Date().toLocaleDateString().split("/")[1]) - 1;
        let r = parseFloat(new Date().toLocaleDateString().split("/")[2]);
        this.value = [new Date(n, y, r, 0, 0, 0), new Date()];
        console.log(this.value);

        // this.getLineF(this.nameList, this.dataList);
        this.getLine();
        
        this.getThreeData();
        // this.serviceLoading;
    }

    private serviceLoading: any = "";

    private parseData: any = {
        farmName: "",
        labelNumber: "",
        markId: ""
    };

    private setAll(
        nameList: string[],
        dataList: number[],
        dataObject: any
    ): void {
        // this.serviceLoading()
        this.serviceLoading = Loading.service(this.options);
        // console.log(this.serviceLoading)
        // if(nameList && dataList){
        this.getLineF(nameList, dataList);
        this.getDot(nameList, dataObject);
        this.myChart.on("click", (params: any) => {
            // console.log(params)
            this.myChart2.dispatchAction({
                type: "showTip",
                // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
                seriesIndex: params.seriesIndex,
                // 数据的 index，如果不指定也可以通过 name 属性根据名称指定数据
                dataIndex: params.dataIndex,
                // 可选，数据名称，在有 dataIndex 的时候忽略
                name: params.name
                // 本次显示 tooltip 的位置。只在本次 action 中生效。
                // 缺省则使用 option 中定义的 tooltip 位置。
                // position: Array.<number>|string|Function,
            });
        });
        this.myChart2.on("click", (params: any) => {
            // console.log(params)
            this.myChart.dispatchAction({
                type: "showTip",
                // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
                seriesIndex: params.seriesIndex,
                // 数据的 index，如果不指定也可以通过 name 属性根据名称指定数据
                dataIndex: params.dataIndex,
                // 可选，数据名称，在有 dataIndex 的时候忽略
                name: params.name
                // 本次显示 tooltip 的位置。只在本次 action 中生效。
                // 缺省则使用 option 中定义的 tooltip 位置。
                // position: Array.<number>|string|Function,
            });
        });
        // }
    }

    private getLineAndTree() {
        // 时间查询确认回调
        this.getLine();
        this.getThreeData();
    }

    private getLine(): void {
        console.log(this.value);
        // this.pickerMinDate = null
        this.nameList = [];
        this.dataList = [];
        if (this.$route.query.temperatureInfoDetail) {
            this.parseData = JSON.parse(
                <string>this.$route.query.temperatureInfoDetail
            );
        } else {
            this.parseData.markId = this.$route.query.id;
            console.log(this.parseData.markId);
            this.parseData.farmName = this.$route.query.farmName
                ? JSON.parse(<string>this.$route.query.farmName)
                : "";
            this.parseData.labelNumber = JSON.parse(
                <string>this.$route.query.labelNumber
            );
        }
        this.nameList = [];
        this.dataList = [];
        this.dataObject.xList = [];
        this.dataObject.yList = [];
        this.dataObject.zList = [];
        this.dataObject.allList = [];
        this.temperatureInfo.getLine(
            this.parseData.markId,
            this.value,
            (data: any) => {
                console.log(data);
                if (data.code === 200) {
                    this.dataObject.xLists = [];
                    this.dataObject.times = [];
                    setTimeout(() => {
                        this.$nextTick(() => {
                            // 以服务的方式调用的 Loading 需要异步关闭
                            this.serviceLoading.close();
                            console.log("close service");
                        });
                    }, 1000);
                }
                for (var i in data.data) {
                    this.dataList.push(data.data[i].temperature);
                    this.nameList.push(data.data[i].createTime);
                    this.dataObject.xLists.push(Math.abs(data.data[i].xaxis));
                    this.dataObject.xList.push(data.data[i].xaxis);
                    this.dataObject.yList.push(data.data[i].yaxis);
                    this.dataObject.zList.push(data.data[i].zaxis);
                    this.dataObject.allList.push(data.data[i].countXyz);
                    this.dataObject.times.push(data.data[i].createTime);
                }
                this.setAll(this.nameList, this.dataList, this.dataObject);
                // this.getLineF(this.nameList, this.dataList);
                // this.getDot(this.nameList, this.dataObject)
            }
        );
    }

    private createDot: boolean = false;
    private nameList: string[] = ["1", "2", "3", "4", "5"];
    private dataList: number[] = [0, 0, 0, 0, 0];
    private dataObject: any = {
        xLists: [],
        yList: [],
        zList: [],
        allList: []
        // xList: this.dataList,
        // yList: this.dataList,
        // zList: this.dataList,
        // allList: this.dataList
    };

    private myChart: any;

    private myChart2: any;

    private getLineF(nameList: string[], dataList: number[]) {
        if (document.getElementById("line")) {
            this.myChart = echarts.init(
                <HTMLDivElement>document.getElementById("line")
            );
            DataECharts.warningLine2(
                this.myChart,
                this.myChart2,
                nameList,
                dataList
            );
        }
    }

    private getDot(nameList: string[], dataObject: any): void {
        // console.log(document.getElementById("dot"))
        if (document.getElementById("dot")) {
            this.myChart2 = echarts.init(
                <HTMLDivElement>document.getElementById("dot")
            );
            DataECharts.warningDot(
                this.myChart,
                this.myChart2,
                nameList,
                dataObject
            );
        }
    }

    private activated() {
        this.day();
        // this.getThreeData();
        // this.getLine();

        // this.getLineF(this.nameList, this.dataList);
    }

    @Watch("$route")
    private handleRouter(): void {
        this.getLine();
        // this.getThreeData();
        // this.getLineF(this.nameList, this.dataList);
    }

    private ThreeDataList: any[] = [];
    private getThreeData() {
        let stDate =
            new dateFun().returnDate(this.value[0], "-") +
            " " +
            new dateFun().returnHourMinuteSecend(this.value[0], ":");
        let edDate =
            new dateFun().returnDate(this.value[1], "-") +
            " " +
            new dateFun().returnHourMinuteSecend(this.value[1], ":");
        // this.parseData.markId = '777964699554676736'
        // stDate = '2022-10-25 00:00:00'
        // edDate = '2022-10-25 23:00:00'
        new BasicsClass().BasicGet(
            "/animal/web/temperatureHistory/queryFeedingV3085",
            { markId: this.parseData.markId, stDate, edDate },
            false,
            false,
            (data: any) => {
                this.ThreeDataList = data.data;
            }
        );
    }
}
