

























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {handleRole, TableResult} from "@/common/Views/system/role/role";
import {Rule} from "@/common/Views/comm/formRule";
import {Axios} from "@/common/http/http";

@Component
export default class HelloWorld extends Vue {
    @Prop({default: {id: '', name: '', describe: ''}})row!: TableResult<string>;

    private rule: handleRole<Rule[]> = {
        name: [
            {required: true, message: '请输入角色名称', trigger:'blur'},
        ],
        describe: [
            {required: true, message: '请输入描述名称', trigger:'blur'},
        ],
    }

    private axios: Axios;
    constructor() {
        super();
        this.axios = new Axios();
    }

    private submit(): void {
        (this.$refs.form as any).validate((valid: boolean)=>{
            if(valid){
                this.handleRole();
            }
        })
    }

    private handleRole(): void {
        this.axios.post(this.getUrl(this.row.id),{

            ...this.row,

        }, true).then(data=>{
            this.$emit('success');
            this.$emit('exit');
            this.$message.success(data.message);

            (this.$refs.form as any).resetFields();
        }).catch(err=>{
            this.$message.error(err.message);
        })
    }

    private getUrl(id: string): string {
        return id ? '/animal/web/role/update' : '/animal/web/role/insert';
    }

}
