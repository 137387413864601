


















import { Component, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FarmLogData extends Vue {
    private Logtime: any[] = [];

    private changeLoginDate() {
        this.$emit("changeLoginDate", this.Logtime);
    }
}
