























































































































































































import { Component, Vue } from "vue-property-decorator";
import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";
import SelectDateComponetnsVue from "@/components/BasicConponents/SelectDateComponetnsVue.vue";

import { ProductListInfo } from "@/Interface/WeChatApplet/AboutShop/index";
import {
    HandleTable,
    HandlePageChange,
} from "@/Interface/CommonModule/TableAndPage";
import { AboutShop } from "@/api/WeChatApplet/index";
@Component({
    components: { SelectComponentVue, SelectDateComponetnsVue },
})
export default class CommodityManagementList extends Vue {
    private form: ProductListInfo = {
        breedId: "",
        typeConfId: "",
        checkState: "",
        createTimeStart: "",
        createTimeEnd: "",
    };

    private tableData: any[] = []; //table 表格 数据
    private tablePage: HandleTable = {
        //分页数据
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private DateSelectVal: any = new Date();
    private breedList: any[] = []; //品种列表
    private typeConfList: any[] = []; //类型列表
    private checkStateList: any[] = [
        //状态列表
        {
            id: "",
            name: "全部",
        },
        {
            id: "0",
            name: "待审核",
        },
        {
            id: "1",
            name: "通过",
        },
        {
            id: "2",
            name: "拒绝",
        },
    ];

    //事件
    private SearchList() {
        //请求列表数据
        this.getProductListData();
    }
    private ResetList() {
        //重置
        this.form = {
            breedId: "",
            typeConfId: "",
            checkState: "",
            createTimeStart: "",
            createTimeEnd: "",
        };
        this.tablePage.pageNo = 1;
        this.getAllData();
    }
    private handlePageChange(val: any) {
        //分页 事件
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        //请求列表数据
        this.getProductListData();
    }

    private ToShopInfo(row: any) {
        if (row.checkState === 0) {
            this.$router.push({
                path: "/CommodityManagementEditInfo",
                query: {
                    id: row.id,
                },
            });
        } else {
            this.$router.push({
                path: "/CommodityManagementInfo",
                query: {
                    id: row.id,
                },
            });
        }
    }

    //emit
    private SetBreedVal(val: string) {
        //品种
        this.form.breedId = val;
    }
    private SettypeConfVal(val: string) {
        //类别
        this.form.typeConfId = val;
    }
    private SetcheckStateVal(val: string) {
        //审核
        this.form.checkState = val;
    }
    private SetSelecDateValue(val: string[]) {
        //创建时间
        console.log(val);
        this.form.createTimeStart = val[0];
        this.form.createTimeEnd = val[1];
    }

    //接口请求
    private getAppletBreedData() {
        //获取品种
        new AboutShop().getAppletBreed((data: any) => {
            this.breedList = data.data;
            this.breedList.unshift({
                id: "",
                name: "请选择",
            });
        });
    }
    private getAppletTypeData() {
        //获取类型
        new AboutShop().getAppletType((data: any) => {
            this.typeConfList = data.data;
            this.typeConfList.unshift({
                id: "",
                name: "请选择",
            });
        });
    }
    private getProductListData() {
        //获取列表
        this.tableData = [];
        new AboutShop().getProductList(
            this.form,
            this.tablePage,
            (data: any) => {
                this.tableData = data.data;
                this.tablePage.totalCount = data.page.totalCount;
            }
        );
    }

    private getAllData() {
        this.getAppletBreedData();
        this.getAppletTypeData();
        this.getProductListData();
    }

    activated() {
        this.tablePage.pageNo = 1;
        this.getAllData();
    }
}
