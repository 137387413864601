
































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Result, Column} from "@/common/Views/system/column/column";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {optionSelect, Rule} from "@/common/Views/comm/formRule";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {AxiosData} from "@/common/http/http";
import {
    FarmDetailForm,
    FarmDetail,
    FarmDetailDetail,
    CityList, Url
} from "@/common/Views/system/farm/farmDetail/farmDetail";
import {Util} from "@/common/Views/comm/util";
import TitleSolt from '@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue';
import {Firm} from "@/common/Views/system/firm/firm";
import { getSellerDetail,getBuyerDetail,getMarketDetail } from "@/api/index";

@Component({
    components: {TitleSolt}
})
export default class HelloWorld extends Vue {

    public column = new Column();

    private firm: Firm = new Firm()

    public dialogVisible = false;
    public ImgList: { name: string, url: string }[] = [];

    private formLabelAlign: any = {
        checkState: '',
        id: ""
    };

    private showMark1: boolean =  false;
    private showMark2: boolean =  false;
    private url: string =  '';

    public props: any = {
        expandTrigger: 'hover',
        value: 'name',
        label: 'name',
        lazy: true,
        lazyLoad(node: any, resolve: any) {
            const column: Column = new Column();
            if (!node.data) {
                return
            }
            column.getTissuc2(node.data.id,1, (res: any) => {
                const nodes = res.data
                nodes.forEach((item: any) => {
                    item.leaf = (item.level === 4)
                })    
                resolve(nodes)
            })
        }
    }


    private id:string |number = "";
    private tabType:string |number = "";
    private activated(): void {
        this.id = this.$route.query.id
        this.tabType = this.$route.query.tabType
        this.getSellerDetail();
    }
    // 详情数据
    private detailData:any = {};

    // 获取详情
    private getSellerDetail():void {
        if( this.tabType == 1){
            getSellerDetail({id:this.id},(res:any)=>{
                if(res.code == 200){
                    this.detailData = res.data;
                }
            })
        }
        if( this.tabType == 2){
            getBuyerDetail({id:this.id},(res:any)=>{
                debugger
                if(res.code == 200){
                    this.detailData = res.data;
                }
            })
        }
        if( this.tabType == 3){
            getMarketDetail({id:this.id},(res:any)=>{
                if(res.code == 200){
                    this.detailData = res.data;
                }
            })
        }
    }   

    private treeData: Result[] = [];


    private conTypeList: SchoolList<string>[] = [];


    private farmDetail: FarmDetail;

    constructor() {
        super();
        this.farmDetail = new FarmDetail();
    }


    private getDetail(): void {
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        this.formLabelAlign.auditStatus = '1';
        this.formLabelAlign.status = 1;
    }


    private cityList0 = [];
    private cityList1 = [];
    private cityList2 = [];
    private cityList3 = [];
    private city: any = {
        province: "",
        theCity: "",
        area: "",
        street: "",
    };
    private dataImg: boolean = false;

    private audits: any[] = [
        {id: '1', name: "审核通过"},
        {id: '2', name: "审核拒绝"}
    ];


    public resGroup: Result[] = [];


    private handleAudit(str: string): void {
        this.formLabelAlign.auditStatus = str
    }


    private setImg(file: any, fileList: any): void {
        this.readImg(file, fileList, "licenseUrl");
    }

    private setImg1(file: any, fileList: any): void {
        this.readImg(file, fileList, "doorUrl");
    }

    private readImg(file: any, fileList: any, url: keyof FarmDetailForm) {
        const reader = new FileReader();
        let res = reader.readAsDataURL(file.raw);
        reader.onloadend = () => {
            this.dataImg = false;
            // var strBase64 = reader.result.substring(84);
            const strBase64 = (<string>reader.result).substring(0);
            // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: strBase64, url: ""};
            (<string>this.formLabelAlign[url]) = strBase64;
            console.log(this.formLabelAlign);
        };
        reader.onload = function(e) {
            console.log(e);
            // simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc
        };

    }

    public handleCascader(start: any): void {
        console.log(start)
        if((start == false) && (this.formLabelAlign.groupCode.length != 0)){
            this.formLabelAlign.simpleAddress = this.formLabelAlign.groupCode.join('-')
            
        }
    }

    private handleRemove(file: any): void {
        /*console.log(file);
        this.formLabelAlign.licenseUrl.base64ImgData = '';
        this.formLabelAlign.licenseUrl.url = '';
        this.ImgList = [];*/
    };

    private handlePictureCardPreview(file: any): void {
        this.formLabelAlign.licenseUrl = file.url;
        this.dialogVisible = true;
    }


    @Watch("$route")
    public handleRoute(): void {
        if(this.$route.name == 'firmAudit'){
            this.formLabelAlign.auditStatus == '1'
        }
        this.getDetail();
        this.ImgList = [];
        this.city = {
            province: "",
            theCity: "",
            area: "",
            street: "",
        };
    }
}
