























































import {Component, Prop, Vue} from "vue-property-decorator";
import {TypeConfig, TypeConfigTable} from "@/common/Views/basics/typeConfig/typeConfig";
import {AxiosData} from "@/common/http/http";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {MedicineConfig, MedicineConfigTable} from "@/common/Views/basics/medicineConfig/medicineConfig";

@Component
export default class HelloWorld extends Vue {

    private medicineConfig: MedicineConfig;

    constructor() {
        super();
        this.medicineConfig = new MedicineConfig();
    }

    private tableData: any[] = [];
    private activated() {
        this.tableData = [];
        this.medicineConfig.getMedicineConf((data: AxiosData<MedicineConfigTable>) => {
            this.tableData.push(data.data.medicines);
            this.tableData.push(data.data.vaccines);
        });
    }

    private append(rowIndex: number) {
        this.tableData[rowIndex].push({
            id: '',
            name: ''
        })
    }

    private remove(rowIndex: number, index: number) {
        this.tableData[rowIndex].splice(index, 1);
    }

    private cancel() {
        this.$router.go(0)
    }

    private submit() {
        this.medicineConfig.save(this.tableData, ()=>{
            this.medicineConfig.getMedicineConf((data: AxiosData<MedicineConfigTable>) => {
                this.tableData = []
                this.tableData.push(data.data.medicines);
                this.tableData.push(data.data.vaccines);
            });
        })
    }

}
