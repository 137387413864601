


























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cameraManagement } from "@/api/HardwareModule/index";
import { dateFun } from "@/common/date";
@Component({})
export default class configFile extends Vue {
    @Prop() cameraDetail: any;
    //获取配置文件列表
    private configForm = {
        deviceCode: "",
        stTime: "",
        edTime: "",
    }
    private SelectData = '';
    private configTableDate: any = [];
    private getConfigFileList(): void {
        this.configForm.deviceCode = this.cameraDetail.deviceCode;
        console.log(this.configForm, 'this.configForm');
        new cameraManagement().gecfgList(this.configForm, (res: any) => {
            if (res.code == 200) {
                this.configTableDate = res.data;
                console.log(this.configTableDate, 'this.configTableDate');
            } else {
                this.$message.error(res.msg);
            }
        });
    }
    @Watch("SelectData", { deep: true }) //处理选择日期
    private handlerSelectData(newVal: any) {
        console.log(newVal);
        if (newVal) {
            this.configForm.stTime = new dateFun().returnDate(newVal[0], "-");
            this.configForm.edTime = new dateFun().returnDate(newVal[1], "-");
        } else {
            this.SelectData = "";
            this.configForm.edTime = "";
            this.configForm.stTime = "";
        }
    }
    //查询
    private search(): void {
        this.configForm.stTime = this.SelectData[0];
        this.configForm.edTime = this.SelectData[1];
        this.getConfigFileList();
    }
    private activated(): void {
        this.getConfigFileList();
    }
}
