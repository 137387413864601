




















































































































































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import FormBtnSearch from "@/components/FormBtnSearch/FormBtnSearch.vue";
import FormBtnReset from "@/components/FormBtnReset/FormBtnReset.vue";
import InputConponentsVue from "@/components/BasicConponents/InputConponentsVue.vue";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";

import { Commodity } from "@/common/Views/BeefOrdering/CommodityClassification/CommodityClassification";
@Component({
    components: {
        PageBox,
        InputConponentsVue,
        SelectComponentsVue,
        FormBtnSearch,
        FormBtnReset,
    },
})
export default class CommodityClassification extends Vue {
    private form: any = {
        categoryName: "",
    };
    private tableData = [];
    private tablePage = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    //emit
    private SetInputVal(val: string) {
        this.form.categoryName = val;
    }
    private handlePageChange(val: any) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getList();
    }
    private handlerSearch() {
        this.getList();
    }
    private handlerReset() {
        this.form = {
            categoryName: "",
        };
        this.tablePage.pageNo = 1;
        this.getList();
    }
    private toEdit(row: any) {
        this.$router.push({
            path: "/AddClassification",
            query: { row, type: "edit" },
        });
    }
    private goAdd(row: any) {
        this.$router.push({
            path: "/AddClassification",
        });
    }

    private getList() {
        new Commodity().getCategoryList(
            this.form,
            this.tablePage,
            (res: any) => {
                this.tablePage.totalCount = res.page.totalCount;
                this.tableData = res.data;
                this.tableData.forEach((item: any) => {
                    item.status = item.status == 0 ? true : false;
                    if (item.children) {
                        item.children.forEach((itemc: any) => {
                            itemc.status = itemc.status == 0 ? true : false;
                        });
                    }
                });
                console.log(this.tableData, "tableData");
            }
        );
    }
    private changestatusBoolean(row: any): void {
        if (row.status == true) {
            row.status = 0;
        } else {
            row.status = 1;
        }
        new Commodity().updateCategory(row, (res: any) => {
            if (row.status == 0) {
                this.$message({
                    type: "success",
                    message: "开启成功!",
                });
            } else if (row.status == 1) {
                this.$message({
                    type: "info",
                    message: "关闭成功!",
                });
            }
            this.getList();
        });
    }
    activated() {
        this.form = {
            merchantName: "",
        };
        this.tablePage.pageNo = 1;
        this.getList();
    }
    private toAddChild(row: any) {
        console.log(row, 238);
        this.$router.push({
            path: "/AddClassificationChild",
            query: { row, type: "addChild" },
        });
    }
    private toDelete(row: any) {
        if (row.parentId != null) {
            this.$confirm(
                "若删除该分类，会连同该分类下的商品一起删除，确定要删除吗？",
                "提示",
                {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    confirmButtonClass: "confirm__btn",
                }
            )
                .then(() => {
                    this.deleteCategory(row.id);
                })
                .catch(() => {
                    console.log("err");
                });
        } else if (row.parentId == null && row.children == null) {
            this.$confirm("确定要删除该分类吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                confirmButtonClass: "confirm__btn",
            })
                .then(() => {
                    this.deleteCategory(row.id);
                })
                .catch(() => {
                    console.log("err");
                });
        } else if (row.parentId == null && row.children.length > 0) {
            this.$confirm("请删除该分类下所有子级后，再来尝试", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                confirmButtonClass: "confirm__btn",
            })
                .then((res) => {
                    console.log(res);
                })
                .catch(() => {
                    console.log("err");
                });
        }
    }
    private deleteCategory(id: any) {
        new Commodity().deleteCategory(id, (res: any) => {
            this.$message({
                type: "success",
                message: "删除成功!",
            });

            this.getList();
        });
    }
    private updateSort(val: any) {
        console.log(val, 261);
        let form = {};
        form = {
            categoryName: val.categoryName, //分类名称
            parentId: val.parentId == null ? "" : val.parentId, //所属上级分类
            sort: val.sort, //排序
            status: val.status ? 0 : 1, //状态
            categoryIcon: val.categoryIcon, //图标
            id: val.id,
        };
        new Commodity().updateCategory(form, (res: any) => {
            this.$message({
                type: "success",
                message: "修改排序成功",
            });
            this.getList();
        });
    }
}
