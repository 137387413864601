





































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  AssetRegistration,
  AssetTypeList,
  ImportAsset,
} from "@/common/Views/basics/assetRegistration/assetRegistration";
import upload2 from "@/components/upload2.vue";
import { Util } from "@/common/Views/comm/util";

@Component({
  components: {
    upload2: upload2,
  },
})
export default class HelloWorld extends Vue {
  @Prop() appform!: any;
  private form: any = {
    fileUrl: "",
    id: "",
    isVersion: "",
    isUpdate: "",
    upgradeContent: "",
  };

  @Watch("appform", { immediate: true, deep: true })
  private getappform(): void {
    console.log(this.appform);
    this.form.isVersion = this.appform.isVersion.toString();
    this.form.isUpdate = this.appform.isUpdate.toString();
    this.form.upgradeContent = this.appform.upgradeContent;
    this.form.fileUrl = this.appform.url;

    this.$forceUpdate();
  }

  private file: any = "";
  private downLoad(): void {
    new AssetRegistration().downLoadTemplate();
  }
  private Import(file: FileList): void {
    if (this.appform.appType == "Android") {
      this.file = file;
    }
  }
  private num:number = 1;
  private onSubmit(): void {
    this.num ++;
    this.form.id = this.appform.id;
    if (this.form.id && this.form.isVersion && this.form.isUpdate) {
      new AssetRegistration().getAddAppVer4(this.form, () => {
        this.$emit("versionsMessages", false);
      });
    } else {
      Util.showToast("请填写完整", "warning");
    }
  }

  private close(): void {
    this.$emit("versionsMessages", false);
  }
}
