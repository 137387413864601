




















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FarmCheckType extends Vue {
    @Prop() FarmHouse: any;

    @Prop() wayListData: any;

    @Prop() Title: any;

    private way: any = "";

    private changWay() {
        this.$emit("changWay", this.way);
    }
}
