
















































import {Component, Vue, Watch} from "vue-property-decorator";
import {
    BenchmarkSetManage,
    BenchmarkSetManageTableData
} from "@/common/Views/aiModel/benchmarkSetManage/benchmarkSetManage";
import {DataECharts} from "@/common/echarts/DataECharts";

@Component
export default class HelloWorld extends Vue {
    private detailInfo: BenchmarkSetManageTableData = {
        columnName: "",
        farmName: "",
        h0: 0,
        h1: 0,
        h2: 0,
        h3: 0,
        h4: 0,
        h5: 0,
        h6: 0,
        h7: 0,
        h8: 0,
        h9: 0,
        h10: 0,
        h11: 0,
        h12: 0,
        h13: 0,
        h14: 0,
        h15: 0,
        h16: 0,
        h17: 0,
        h18: 0,
        h19: 0,
        h20: 0,
        h21: 0,
        h22: 0,
        h23: 0,
        houseName: "",
        id: "",
        labelNumber: "",
        markId: "",
    };

    private benchmarkSetManage: BenchmarkSetManage = new BenchmarkSetManage();

    private time: string = "";
    private temperature: number = 0;

    private dataList: number[] = [];
    private nameList: string[] = [];

    @Watch("$route", {immediate: true})
    public handleRoute() {
        if (this.$route.query.benchmarkSetManageId) {
            this.detailInfo = JSON.parse(<string> this.$route.query.benchmarkSetManageInfo);

            this.dataList = [];
            this.nameList = [];

            const data: any = this.detailInfo;
            for (const key in data) {
                if (/^h\d+$/.test(key)) {
                    this.nameList.push(key);
                    this.dataList.push(data[key]);
                }
            }

            this.$nextTick(() => {
                this.draw();
            });
        }
    }

    private draw() {
        DataECharts.aiModelBaseCharts(<HTMLDivElement> document.querySelector("#charts"), this.nameList, this.dataList, (index: number) => {
            this.time = this.nameList[index];
            this.temperature = this.dataList[index];
        });
    }

    private update() {
        this.benchmarkSetManage.update(<string> this.detailInfo.markId, this.time, this.temperature, ()=>{
            const number = this.nameList.indexOf(this.time);
            if(number === -1) return;
            this.dataList[number] = this.temperature;
            const data: any = this.detailInfo;
            data[this.time] = this.temperature;
            this.draw();

            // 处理页面的hash传参;
            const urlParam = location.hash.split("&")[1].split("=");
            for (let i=0;i<urlParam.length;i++) {
                if (urlParam[i] === "benchmarkSetManageInfo") {
                    urlParam[i + 1] = JSON.stringify(data);
                    break;
                }
            }
            location.hash = location.hash.split("&")[0] + "&" + urlParam.join("=");
        });
    }
}

