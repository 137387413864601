import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/Interface/CommonModule/TableAndPage'
import { IUpdateForm, WeightInfo } from '@/Interface/BasicInfoModule/WeightForm'
export class BasicInfoModule {
    public getRegisterList(cb: Function) {  //获取当前账号所有养殖场的区域
        new BasicsClass().BasicGet('/animal/web/animalMark/registerList', {}, false, false, cb)
    }

    public getWeightList(form: WeightInfo, page: HandleTable, cb: Function) {  //体重列表
        new BasicsClass().BasicGet1('/animal/web/weight/list', { ...form, ...page }, false, false, cb)
    }

    // 计算
    public updateWeightInfo(form: IUpdateForm, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/weight/calcBodySize", { ...form }, false, true, true, cb)
    }
    //获取详情
    public getWeightInfo(id: string, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/weight/detail', { id }, false, false, cb)
    }
    // 保存
    public saveWeightInfo(form: IUpdateForm, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/weight/save", { ...form }, true, true, true, cb)
    }
}