



















































import {Watch , Component , Vue} from 'vue-property-decorator'

import {BasicsClass} from '@/common/BasicsClass';

import SelectComponentVue from '@/views/basics/typeConfig/components/components/SelectComponentVue.vue'
import InputComonentVue from './components/InputComonentVue.vue'
import UpLoadImgComponentVue from '@/views/basics/typeConfig/components/components/UpLoadImgComponentVue.vue'
import NewPidAdressVue from '@/views/basics/typeConfig/components/components/NewPidAdressVue.vue'
import UpLoadImgComponentListVue from '@/views/basics/typeConfig/components/components/UpLoadImgComponentListVue.vue'

import {FarmDetail} from '@/common/Views/system/farm/farmDetail/farmDetail'
@Component({
    components:{
        SelectComponentVue,
        InputComonentVue,
        UpLoadImgComponentVue,
        NewPidAdressVue,
        UpLoadImgComponentListVue
    }
})
export default class AddDrug extends Vue{
    private form:any = {
        drugTenant:"", //合作商家
        drug:"", //药品名称
        introduction:"",//药品介绍
        groupCode:["0"], //区域
        amount:'',//价格
        unit:'1',//单位中文
        coverImage:'',//封面图地址
        detailImageList:[], //详情图
        enableFlag:"1" , //是否上架
    }
    private rules:any ={
        drugTenant:[
            { required: true, message: '请填写合作商家', trigger: 'change' }
        ],
        drug:[
            { required: true, message: '请填写药品名称', trigger: ['change','blur'] }
        ],
        introduction:[
            { required: true, message: '请填写药品介绍', trigger: 'change' }
        ],
        groupCode:[
            { required: true, message: '请选择售卖区域', trigger: 'change' }
        ],
        amount:[
            { required: true, message: '请填写价格', trigger: ['change' , 'blur']  }
        ],
        coverImage:[
            { required: true, message: '请上传封面图', trigger: 'change' }
        ],
        detailImageList:[
            { required: true, message: '请上传详情图', trigger: 'change' }
        ],
        enableFlag:[
            { required: true, message: '请选择上架状态', trigger: 'change' }
        ]
    }

    private ForageTypeList:any[] = [] //草料类型 数据
    private AdressTreeList:any[] = [] //售卖区域
    private ShelfStatusList:any[] = [//上架状态
        {id:"1",name:"上架"},
        {id:"2",name:"下架"},
    ] 



    //事件
    private SaveData(){ //保存
        this.$refs.form.validate((flag:boolean)=>{
            if(flag){
                let form:any = this.handleFormData()
                this.AddDrugReq(form)
            }
        })
    }
    private InputEvent(e:any){ //价格输入框 输入事件
        this.form.amount = e
        if(e > 999999999){
            this.form.amount = 999999999
        }
    }
    @Watch('form.amount',{deep:true})
    private changeAmount(newVal:string){
        this.form.amount=newVal.replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
    }


    //emit事件
    private SetDrugTenantValue(val:string){ //合作商家
        this.form.drugTenant = val
    }
    private SetDrugValue(val:string){ //药品名称
        this.form.drug = val
    }
    private SetIntroductionValue(val:string){ //药品介绍
        this.form.introduction = val
    }
    private SetAdressVal(val:any[]){ //售卖区域
        this.form.groupCode = val
    }
    private SetEnableFlagValue(val:string){ //是否上架
        this.form.enableFlag = val
    }
    private SetNewImgUrl(val:string){ //封面图地址
        this.form.coverImage = val
    }
    private SetDetailImageListNewImgUrlList(val:any[]){ //详情图
        this.form.detailImageList = val
    }


    //获取数据 request
    private getAdressListReq(){ //获取地址数据
        new FarmDetail().getCity1('-1',(data:any)=>{
            this.AdressTreeList = data.data
        })
    }
    private AddDrugReq(form:any){
        new BasicsClass().BasicPost1('/animal/web/drug/addDrug',{...form},true,true,true,(data:any)=>{
            this.$router.push({
                path:"/DrugShelfConfiguration"
            })
        })
    }



    //处理数据
    private handleFormData(){ //处理数据
        let obj:any = JSON.parse(JSON.stringify(this.form))
        //处理组织机构
        Array.isArray(this.form.groupCode) ? obj.groupCode = this.form.groupCode.slice(-1).toString() : this.form.groupCode //将grounpcode变为 字符串
        
        //处理单位
        if(this.form.unit == 1){ 
            obj.unit = '盒'
        }else if(this.form.unit == 2){
            obj.unit = '瓶'
        }else if(this.form.unit == 3) obj.unit = '包'

        //处理上架下架
        obj.enableFlag == "1" ? obj.enableFlag = true : obj.enableFlag  = false
        
        return obj
    }
    private ClearFormData(){ //清楚Form数据
        this.form = {
            drugTenant:"", //合作商家
            drug:"", //药品名称
            introduction:"",//药品介绍
            groupCode:["0"], //区域
            amount:'',//价格
            unit:'1',//单位中文
            coverImage:'',//封面图地址
            detailImageList:[], //详情图
            enableFlag:"1" , //是否上架
        }
    }
    private ClearCheck(){ //清除校验
        this.$refs.form.clearValidate()
    }


    activated(){
        this.ClearFormData()
        this.ClearCheck()
        this.getAdressListReq()
    }

}
