import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { AxiosData } from '@/common/http/http';

export interface ModelArgsManageForm {
    typeId: string;
    modelId: string;
    status: string;
    desc: string;
    isBind: string;
}


export interface ModelArgsManageDetailForm<T = string> {
    typeId: T;
    version: T;
    name: T;
    defaultParamGroup: T | number;
    isEnable: T | number;
    modelDesc: T;
}

export interface ModelArgsManageDetailFormList {
    columnName: string;
    columnValue: string;
    description: string;
    id: string;
}


export interface ModelArgsManageTableData {
    id: string;
    typeId: string;
    columnNames: string;
    columnValues: string;
    defaultParamGroup: boolean;
    descriptions: string;
    groupId: string;
    version: string;
    isBind: number;
    isEnable: number;
    modelDesc: string;
    modelName: string;
    modelType: string;
    paramIds: string;
}

export interface ArgsResult {
    maxCount: number;
    tableData: any[];
    page: HandleTable;
}

export class ModelArgsManage extends BasicsClass {


    public getModelNameList(typeId: string, cb: Function) {
        this.getTableList({ typeId }, {}, '/animal/web/modelBean/comboList', cb);
    }

    public getTable(form: ModelArgsManageForm, tablePage: HandleTable, cb: Function) {
        this.getTableList({
            typeId: form.typeId,
            modelId: form.modelId,
            isEnable: form.status,
            isBind: form.isBind,
            modelDesc: form.desc,
        }, tablePage, '/animal/web/modelBean/list', (data: AxiosData<ModelArgsManageTableData[]>) => {
            cb(ModelArgsManage.handleArgsResult(data));
        });
    }

    public static handleArgsResult(data: AxiosData<any[]>) {
        const tableData: any[] = [];
        let maxCount: number = 0;
        for (const i in data.data) {

            tableData.push(data.data[i]);
            try {

                const columnNames = data.data[i].columnNames.split(',');
                const columnValues = data.data[i].columnValues.split(',');
                const descriptions = data.data[i].descriptions.split(',');
                const params = data.data[i].paramIds.split(',');

                if (columnNames.length > maxCount) {
                    maxCount = columnNames.length;
                }

                for (const j in columnNames) {
                    tableData[i]['columnNames' + j] = columnNames[j];
                    tableData[i]['columnValues' + j] = columnValues[j];
                    tableData[i]['descriptions' + j] = descriptions[j];
                    tableData[i]['params' + j] = params[j];
                }
            } catch (e) {
                // do no thing
            }
        }

        return { maxCount, tableData, page: data.page };
    }

    public getTypeTable(typeId: string, tablePage: HandleTable, cb: Function) {
        this.getTableList({
            typeId,
            isEnable: 1,
        }, tablePage, '/animal/web/modelBean/list', cb);
    }

    public canDelete(groupId: string, cb: Function) {
        this.BasicGet('/animal/web/modelBean/queryParamDeleteStatus', {
            groupId,
        }, false, true, cb);
    }

    public submit(modelArgsManagegroupId: string, modelArgsManageId: string, form: ModelArgsManageDetailForm, formList: ModelArgsManageDetailFormList[], cb: Function) {
        this.AddOrUpdate2(modelArgsManagegroupId, modelArgsManageId, {
            ...form,
            id: modelArgsManagegroupId,
            modelId: modelArgsManageId,
            params: formList,
        }, '/animal/web/modelBean/update', '/animal/web/modelBean/insert', cb);
    }
}
