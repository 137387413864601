












import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class FarmOldLabelNumber extends Vue {
    @Prop() TitleldLabelNumber: any;

    @Prop() clearAllData: any;

    private oldLabelNumber: any = "";

    // private oldLabelNumberStr: any =
    //     this.$t("common.placeholder.enter") + this.TitleldLabelNumber;

    get oldLabelNumberStr(){
        return this.$t("common.placeholder.enter") ;
    }

    private changeOldLabelNumber() {
        this.$emit("changeOldLabelNumber", this.oldLabelNumber);
    }
}
