import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { Util } from '@/common/Views/comm/util';
import { DeepLoop } from '@/common/deepLoop/deepLoop';

export interface TransferRecordForm {
    groupCode: [],
    farmId: string,
    labelNumber: string,
    time: [],
    confTypeId: string,
    oldHouse: string,
    oldColumn: string,
    newHouse: string,
    newColumn: string,
    houseId: string
}

export class TransferRecord extends BasicsClass {
    public getList(form: TransferRecordForm, page: any, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.BasicGet('/animal/web/transferRecord/list', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN,
            ...page
        }, false, true, cb)
    }


    public getenvironmentalControlList(form: TransferRecordForm, page: any, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.BasicGet('/animal/web/environmentalControl/list2', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN,
            ...page
        }, false, true, cb)
    }

    public getdeviceAlarmList(form: TransferRecordForm, page: any, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.BasicGet('/animal/web/deviceAlarm/list', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN,
            ...page
        }, false, false, cb)
    }



    public exportList(form: TransferRecordForm, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.getFile('/animal/web/transferRecord/exportList', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN,
        })
    }

    public exportList2(form: TransferRecordForm, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.getFile('/animal/web/deviceAlarm/exportList', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN,
        })
    }
    public exportList3(form: TransferRecordForm, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.getFile('/animal/web/environmentalControl/exportList2', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN,
        })
    }

    public getChilder(farmId: string, parentId: string, cb: Function): void {
        this.BasicGet('/animal/web/transferRecord/getThisAnimalFarmPlaceList', {
            farmId,
            parentId,
        }, false, true, cb)
    }

    public getChilder2(placeId: string, cb: Function): void {
        this.BasicGet('/animal/web/animalFarmPlace/getSmallPlace', {
            placeId,
        }, false, true, cb)
    }

}