























































































































import { Prop, Component, Vue } from "vue-property-decorator";
import { Type } from "./data";
@Component({})
export default class type extends Vue {
    private pigData: any = "";
    private sheepData: any = "";
    private beefData: any = "";
    private camelData: any = "";

    private getList() {
        new Type().getTypeList((data: any) => {
            console.log(data.data);
            this.pigData = data.data[1];
            this.beefData = data.data[2];
            this.sheepData = data.data[3];
            this.camelData = data.data[4];
        });
    }

    activated() {
        this.getList();
    }
}
