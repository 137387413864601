























































































































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import FormBtnSearch from "@/components/FormBtnSearch/FormBtnSearch.vue";
import FormBtnReset from "@/components/FormBtnReset/FormBtnReset.vue";
import InputConponentsVue from "@/components/BasicConponents/InputConponentsVue.vue";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";

import {
    merchantForm,
    merchant,
} from "@/common/Views/BeefOrdering/SupplierManagement/SupplierManagement";
@Component({
    components: {
        PageBox,
        InputConponentsVue,
        SelectComponentsVue,
        FormBtnSearch,
        FormBtnReset,
    },
})
export default class SupplierManagement extends Vue {
    private form: merchantForm = {
        merchantName: "",
    };
    private tableData = [];
    private tablePage = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    //emit
    private SetInputVal(val: string) {
        this.form.merchantName = val;
    }
    private handlePageChange(val: any) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getList();
    }
    private handlerSearch() {
        this.tablePage.pageNo = 1;
        this.getList();
    }
    private handlerReset() {
        this.form = {
            merchantName: "",
        };
        this.tablePage.pageNo = 1;
        this.getList();
    }
    private toEdit(row: any) {
        this.$router.push({
            path: "/EditMerchant",
            query: { id:row.id},
        });
    }
    private goAdd(row: any) {
        this.$router.push({
            path: "/AddMerchant",
        });
    }

    private getList() {
        new merchant().getMerchantList(
            this.form,
            this.tablePage,
            (res: any) => {
                this.tablePage.totalCount = res.page.totalCount;
                this.tableData = res.data;
                this.tableData.forEach((item: any) => {
                    item.status = item.status == 0 ? true : false;
                });
            }
        );
    }
    private changestatusBoolean(row: any): void {
        if (row.status == true) {
            row.status = 0;
        } else {
            row.status = 1;
        }
        new merchant().changestatusBoolean(row.id, row.status, (res: any) => {
            if (row.status == 0) {
                this.$message({
                    type: "success",
                    message: "开启成功!",
                });
            } else if (row.status == 1) {
                this.$message({
                    type: "info",
                    message: "关闭成功!",
                });
            }
            this.getList();
        });
    }
    activated() {
        this.form = {
            merchantName: "",
        };
        this.tablePage.pageNo = 1;
        this.getList();
    }
}
