











































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  AssetRegistration,
  AssetTypeList,
  ImportAsset,
} from "@/common/Views/basics/assetRegistration/assetRegistration";
import upload2 from "@/components/upload2.vue";
import { Util } from "@/common/Views/comm/util";

@Component({
  components: {
    upload2: upload2,
  },
})
export default class HelloWorld extends Vue {
  private locationList: any = [
    {
      name: "养殖场app",
      id: "养殖场",
    },
    {
      name: "屠宰场app",
      id: "屠宰场",
    },
    {
      name: "无害化处理厂app",
      id: "无害化",
    },
  ];
  private form: any = {
     fileUrl: "",
    appName: "",
    appType: "",
    isVersion: "",
    isUpdate: "",
    versionType: "",
    upgradeContent: "",
  };
  private title1: any = "如版本号为：V1.0.0,";
  private title2: any = "升级小版本则为V1.0.1,";
  private title3: any = "中版本则为V1.1.0,";
  private title4: any = "大版本则为V2.0.0,";
  private title5: any = "小版本升级到1.0.9,";
  private title6: any = "后继续小版本升级则为V1.0.10";
  private file: any = "";
  private downLoad(): void {
    new AssetRegistration().downLoadTemplate();
  }
  private Import(file: FileList): void {
    // (this.$refs.form as any).validate((valid: boolean) => {
    //   if (valid) {
    //     new AssetRegistration().uploadFile(file, this.form, () => {
    //       this.$emit("exit");
    //       this.$emit("success");
    //     });
    //   }
    // });

    if (this.form.appType == "Android") {
      this.file = file;
    } 
  }
  private onSubmit(): void {
    console.log(this.form);
    if (
      this.form.appName &&
      this.form.appType &&
      this.form.isVersion &&
      this.form.isUpdate &&
      this.form.versionType
    ) {
     
        new AssetRegistration().getAddAppVer3(this.form, () => {
          this.$emit("versionsMessages", false);
        });
       
    } else {
      Util.showToast("请填写完整", "warning");
    }
  }

  private close(): void {
    this.$emit("versionsMessages", false);
  }
}
