












































































import {Component, Vue} from "vue-property-decorator";
import {HandlePageChange, HandleTable} from "@/common/Views/comm/handleTable";
import {ModelTypeManage, ModelTypeManageTableData} from "@/common/Views/aiModel/modelTypeManage/modelTypeManage";
import {AxiosData} from "@/common/http/http";
import {ComboList, Column} from '@/common/Views/system/column/column';
import { ConfType } from '@/common/Views/system/confType/confType';
import {TransferRecord} from '@/common/Views/traceabilityOutManage/transferRecord';
import {
    BenchmarkSetManage,
    BenchmarkSetManageFrom,
    BenchmarkSetManageTableData
} from "@/common/Views/aiModel/benchmarkSetManage/benchmarkSetManage";
import {Util} from "@/common/Views/comm/util";
import {Rule} from "@/common/Views/comm/formRule";

@Component
export default class HelloWorld extends Vue {

    private form: BenchmarkSetManageFrom = {
        farmId: '',
        houseId: '',
        columnId: '',
        labelNumber: '',
        markId: '',
    };


    private restForm() {
        this.form = new Util().clearObject(this.form);
        this.search();
    }

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private tableData: BenchmarkSetManageTableData[] = [];

    private benchmarkSetManage: BenchmarkSetManage = new BenchmarkSetManage();
    private activated() {
        this.search();
        this.changeGroupCode();
    }

    private confType(): void {
        this.houseList = [];
        this.columnList = [];
        this.form.houseId = '';
        this.form.columnId = '';

        new ConfType().getSelectAll2(this.form.farmId,"0", (data: AxiosData<ComboList[]>) => {
            this.houseList = data.data;
        });

    }
    private transferRecord: TransferRecord = new TransferRecord();
    private confHouse(): void {
        this.columnList = [];
        this.form.columnId = '';

        this.transferRecord.getChilder2(this.form.houseId, (res: AxiosData<ComboList[]>) => {
            this.columnList = res.data
        })
    }

    private comboList: ComboList[] = [];
    private houseList: ComboList[] = [];
    private columnList: ComboList[] = [];

    private changeGroupCode(): void {
        this.comboList = [];
        // this.form.farmId = "";
        new Column().getFarm('0', (data: AxiosData<ComboList[]>) => {
            this.comboList = data.data;
        });
    }

    private search() {
        this.tablePage.pageNo = 1;
        this.tablePage.totalCount = 0;
        this.tableData = [];
        this.getTable();
    }

    private handlePageChange(val: HandlePageChange) {

        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }


    private getTable() {
        this.benchmarkSetManage.getTable(this.form, this.tablePage, (data: AxiosData<BenchmarkSetManageTableData[]>)=>{

            this.tableData = data.data;

            this.tablePage.totalCount = data.page.totalCount;
        })
    }

    private goDetail(row: ModelTypeManageTableData) {
        this.$router.push({
            path: "/benchmarkSetManageDetail",
            query: {
                benchmarkSetManageId: row.id,
                benchmarkSetManageInfo: JSON.stringify(row),
            }
        })
    }
}
