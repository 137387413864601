































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import { Column, ComboList, Result } from "@/common/Views/system/column/column";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
import { ConfType } from "@/common/Views/system/confType/confType";
import {
  ImmuneRecord,
  ImmuneRecordForm,
} from "@/common/Views/traceabilityOutManage/immuneRecord";

@Component
export default class HelloWorld extends Vue {
  private immuneRecord: ImmuneRecord = new ImmuneRecord();
  private tableData: any = [];
  public column = new Column();

  private forms: any = {
    groupCodeLists: "",
    insuranceCompanyId: "",
    groupCode: "",
    confId: "",
    typeInfoId: "",
    status: "",
    pageNo: "1",
    pageSize: "10",
  };
  private data: any = {};
  private typeList: any = [];
  // private activated(): void {
  // this.getTree();
  // this.getCompany();
  // }

  private activated(): void {
    this.getTree();
    this.getCompany();
    this.getcomboList();
    this.gettypeList();
  }
  private gettypeList(): void {
    this.column.typeList((res: any) => {
      this.typeList = res.data;
    });
  }
  private treeData: Result[] = [];

  private changesta(data: any): void {
    this.forms.id = data.id;
    if (data.status == true) {
      // data.status = 1;
      this.column.setStatus((res: any) => {}, {
        ...data,
        status: 1,
      });
    } else {
      // data.status = 0;
      this.column.setStatus((res: any) => {}, {
        ...data,
        status: 0,
      });
    }
  }
  private getTree() {
    Util.getTree3((result: Result[]) => {
      this.treeData = result;
    });
  }

  private updata(id: any): void {
    this.$router.push({
      path: "/editinsuranceConfig",
      query: {
        pid: id,
      },
    });
  }
  private companyList: any = [];

  private getCompany(): void {
    new Column().getCompany2((res: any) => {
      this.companyList = res.data;
    });
  }
  private comboList: ComboList[] = [];

  private getcomboList(): void {
    new Column().getcomboList((res: any) => {
      this.tableData = res.data;
      this.tablePage.totalCount = res.page.totalCount;
    }, this.forms);
  }

  private vaccineList: [] = [];

  private restForm(): void {
    this.forms = {
      groupCodeLists: [],
      id: "",
      groupCode: "",
      confId: "",
      typeInfoId: "",
      status: "",
      insuranceCompanyId: "",
    };
  }

  private search(): void {
    if (this.forms.groupCodeLists.length > 0) {
      this.forms.groupCode =
        this.forms.groupCodeLists[this.forms.groupCodeLists.length - 1];
    }

    console.log(typeof this.forms.groupCode);
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
    this.getcomboList();
  }

  private tablePage: HandleTable = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: HandlePageChange) {
    this.forms.pageNo = val.currentPage;
    this.forms.pageSize = val.pageSize;
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;
    this.tableData = [];
    this.getcomboList();
  }
}
