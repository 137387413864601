



























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {ModelTypeManage, ModelTypeManageTableData} from "@/common/Views/aiModel/modelTypeManage/modelTypeManage";
import {AxiosData} from "@/common/http/http";
import {Util} from "@/common/Views/comm/util";
import {Rule} from "@/common/Views/comm/formRule";

@Component
export default class HelloWorld extends Vue {
    private form: ModelTypeManageTableData = {
        id: "",
        desc: "",
        name: "",
        status: 1,
    };

    private rules: ModelTypeManageTableData<Rule[]> = {
        id: [{required: true, message: "请选择模板类型"}],
        desc: [{required: true, message: "请填写类型"}],
        name: [{required: true, message: "请填写名称"}],
        status: [{type: "number", required: true, message: "请选择状态"}],

    };


    @Watch("$route", {immediate: true})
    public handleRoute() {
        if (this.$route.query.modelTypeManageId) {
            this.form = new Util().clearObject(this.form);
            this.form.status = 1;
            this.getDetail();
        }
    }

    private modelTypeManage: ModelTypeManage = new ModelTypeManage();

    private getDetail() {
        const id: string = <string> this.$route.query.modelTypeManageId;
        this.modelTypeManage.getDetail(id, (data: AxiosData<ModelTypeManageTableData>) => {
            this.form.status = data.data.status;
            this.form.desc = data.data.desc;
            this.form.name = data.data.name;
        });
    }

    private onSubmit() {
        (this.$refs.form as any).validate((valid: boolean) => {

            if (!valid) {
                return;
            }
            this.modelTypeManage.save(<string> this.$route.query.modelTypeManageId, this.form, (data: AxiosData<string>) => {
                this.$router.go(-1);
            });
        });
    }

}
