
































































































































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";
import { BasicInfoModule } from "@/api/BasicInfoModule";
import {
    IUpdateForm,
    IUpdateFormItem,
} from "@/Interface/BasicInfoModule/WeightForm";

@Component({
    components: { PageBox, SelectComponentVue },
})
export default class WeightManagementUpdate extends Vue {
    private updateForm: IUpdateForm = {
        id: "",
        coordinateJson: [
            {
                bodyPart: "bodyLength",
                bodyCoordinate: {
                    //
                    xaxisStart: "",
                    xaxisEnd: "",
                    yaxisStart: "",
                    yaxisEnd: "",
                },
                name: "体长",
            },
            {
                bodyPart: "bodyHeight",
                bodyCoordinate: {
                    //体宽
                    xaxisStart: "",
                    xaxisEnd: "",
                    yaxisStart: "",
                    yaxisEnd: "",
                },
                name: "体高",
            },
            {
                bodyPart: "chestWeight",
                bodyCoordinate: {
                    //体长
                    xaxisStart: "",
                    xaxisEnd: "",
                    yaxisStart: "",
                    yaxisEnd: "",
                },
                name: "胸宽",
            },
            {
                bodyPart: "forefootLength",
                bodyCoordinate: {
                    //：前脚长
                    xaxisStart: "",
                    xaxisEnd: "",
                    yaxisStart: "",
                    yaxisEnd: "",
                },
                name: "前脚长",
            },
            {
                bodyPart: "forelegWidth",
                bodyCoordinate: {
                    //：前腿宽
                    xaxisStart: "",
                    xaxisEnd: "",
                    yaxisStart: "",
                    yaxisEnd: "",
                },
                name: "前腿宽",
            },
            {
                bodyPart: "neckWeight",
                bodyCoordinate: {
                    //：脖宽
                    xaxisStart: "",
                    xaxisEnd: "",
                    yaxisStart: "",
                    yaxisEnd: "",
                },
                name: "脖宽",
            },
        ],
    };
    private bodyPartMap = {
        bodyLength: "体长",
        bodyHeight: "体高",
        chestWeight: "胸宽",
        forefootLength: "前脚长",
        forelegWidth: "前腿宽",
        neckWeight: "脖宽",
    };
    //@ts-ignore
    private canvas: HTMLCanvasElement;
    private showSelectPoint = false;
    private showCompleteResult = false;
    private SelectTypeList = [
        { id: "", name: "请选择标记类型" },
        { id: 0, name: "体长" },
        { id: 1, name: "体高" },
        { id: 2, name: "胸宽" },
        { id: 3, name: "前脚长" },
        { id: 4, name: "前脚宽" },
        { id: 5, name: "脖宽" },
    ];
    private SelectPointList = [
        { id: "", name: "请选择始/终点" },
        { id: "start", name: "始点" },
        { id: "end", name: "终点" },
    ];
    private TypeChecked: number | string = "";
    private PointChecked = "";
    private xPoint: string | number = "";
    private yPoint: string | number = "";
    private weightInfo = {};
    private calcBodySizeInfo = [];

    //初始化canvas
    private initCanvas(imageUrl: string) {
        this.canvas = this.$refs.canvas_Ref as HTMLCanvasElement;
        const ctx: CanvasRenderingContext2D = this.canvas.getContext(
            "2d"
        ) as CanvasRenderingContext2D;
        const imgageScoped = new Image();
        imgageScoped.src = imageUrl;
        //@ts-ignore
        imgageScoped.onload = () => {
            const width = imgageScoped.width;
            const height = imgageScoped.height;
            this.canvas.width = width;
            this.canvas.height = height;
            ctx.drawImage(imgageScoped, 0, 0, width, height);
        };
    }

    // 点击canvas 的事件
    private handlerCanvasClick(e: MouseEvent) {
        const rect: DOMRect = this.canvas.getBoundingClientRect() as DOMRect; // 获取canvas在页面上的位置和大小
        this.xPoint = Math.floor(e.clientX - rect.left); // 计算鼠标点击位置相对于canvas的x坐标
        this.yPoint = Math.floor(e.clientY - rect.top); // 计算鼠标点击位置相对于canvas的y坐标
        this.showSelectPoint = true;
        // const ctx = this.canvas.getContext("2d");
        // if (ctx) {
        //     // 设置绘制颜色为红色
        //     ctx.fillStyle = "red";

        //     // 绘制一个小圆来标记点击位置
        //     ctx.beginPath(); // 开始路径
        //     ctx.arc(this.xPoint, this.yPoint, 5, 0, Math.PI * 2, true); // 绘制圆：中心(x, y)，半径5，从0度开始到360度
        //     ctx.fill(); // 填充圆
        // }
    }
    //设置点位信息
    private setPointInfo() {
        this.showSelectPoint = false;
        let { xPoint, yPoint, PointChecked, TypeChecked } = this;
        if (TypeChecked === "") {
            this.$message.error(`请选择标记类型`);
            return;
        }
        if (PointChecked === "") {
            this.$message.error(`请选择始终点`);
            return;
        }
        if (PointChecked === "start") {
            this.updateForm.coordinateJson[
                TypeChecked
            ].bodyCoordinate.xaxisStart = xPoint;
            this.updateForm.coordinateJson[
                TypeChecked
            ].bodyCoordinate.yaxisStart = yPoint;
        } else if (PointChecked === "end") {
            this.updateForm.coordinateJson[
                TypeChecked
            ].bodyCoordinate.xaxisEnd = xPoint;
            this.updateForm.coordinateJson[
                TypeChecked
            ].bodyCoordinate.yaxisEnd = yPoint;
        } else {
            new Error("空的类型");
        }
    }

    /**
     * 注释说明 摄像机可能无法拍全 所以不在校验所有字段是否必填
     */
    //校验器
    // private validateForm(item: any, fieldName: any): boolean {
    //     if (
    //         !item.xaxisStart ||
    //         !item.xaxisEnd ||
    //         !item.yaxisStart ||
    //         !item.yaxisEnd
    //     ) {
    //         this.$message.error(`请在图上标记获取${fieldName}的起终点坐标数据`);
    //         return false;
    //     }
    //     return true;
    // }

    //通过 map 处理 updateForm name 字段
    private MapUpdataKeyName() {
        this.updateForm.coordinateJson.forEach((item) => {
            // @ts-ignore
            item.name = this.bodyPartMap[item.bodyPart];
        });
    }

    //去计算
    private toComplete() {
        // const { coordinateJson } = this.updateForm;
        // for (const key in this.bodyPartMap) {
        //     const field = coordinateJson.find(item => item.bodyPart === key);
        //     //@ts-ignore
        //     console.log(this.bodyPartMap[key]);
        //     if (
        //         !this.validateForm.call(
        //             this,
        //             //@ts-ignore
        //             field.bodyCoordinate,
        //             //@ts-ignore
        //             this.bodyPartMap[key]
        //         )
        //     ) {
        //         return;
        //     }
        // }

        this.showCompleteResult = true;
        // 计算
        new BasicInfoModule().updateWeightInfo(this.updateForm, (res: any) => {
            let { data } = res;
            data.forEach((item: any) => {
                //@ts-ignore
                item.name = this.bodyPartMap[item.bodyPart];
            });
            console.log(data);
            this.calcBodySizeInfo = data;
        });
    }
    //保存数据
    private savePointData() {
        this.showCompleteResult = false;
        new BasicInfoModule().saveWeightInfo(this.updateForm, () => {
            this.clearUpdateForm();
            this.$router.push({
                path: "/weightManagement",
            });
        });
    }

    //emit
    private setTypeVal(type: number) {
        this.TypeChecked = type;
    }
    private setPointVal(point: string) {
        this.PointChecked = point;
    }

    //数据请求 获取详情
    private getInfo(id: string) {
        new BasicInfoModule().getWeightInfo(id, (res: any) => {
            const { data } = res;
            console.log(data.bodyCoordinate);
            if (data.bodyCoordinate != null) {
                let bodyCoordinate: Array<IUpdateFormItem> = JSON.parse(
                    data.bodyCoordinate
                );
                this.updateForm.coordinateJson = bodyCoordinate;
                this.MapUpdataKeyName();
            }
            this.weightInfo = data;
            this.initCanvas(data.weighingSnap);
        });
    }

    private clearUpdateForm() {
        this.updateForm.coordinateJson.forEach((item) => {
            item.bodyCoordinate.xaxisStart = "";
            item.bodyCoordinate.xaxisEnd = "";
            item.bodyCoordinate.yaxisStart = "";
            item.bodyCoordinate.yaxisEnd = "";
        });
    }
    activated() {
        this.MapUpdataKeyName();
        const { id } = this.$route.query;
        this.updateForm.id = id;
        this.clearUpdateForm();
        this.getInfo(id);

        // this.initCanvas(
        //     "https://p9.itc.cn/c_fit,w_1668,h_1200,q_70/images03/20210209/269ec6f4108e45a3ab300d8c265ebf14.jpeg"
        // );
    }
}
