


















































































































































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import { FarmData } from "@/views/components/FarmGetData";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import { CattleEarlyWarningConfigurationApi } from "@/api/ConfigModule/index";
@Component({
    components: { PageBox, SelectComponentsVue },
})
export default class CattleEarlyWarningConfiguration extends Vue {
    private form: { [key: string]: string } = {
        breedId: "",
    };
    private tableData = [];
    private tablePage = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 100,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private breedList: Array<any> = [];

    //emit品种选择
    private EmitBreedId(breedId: string) {
        this.form.breedId = breedId;
    }

    private handlePageChange(val: any) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        // this.getCommodityList();
    }
    //品种

    private getAllBreed(typeId: any) {
        this.breedList = [];
        new FarmData().getAllBreedNew(typeId, (data: any) => {
            this.breedList = data.data;
        });
    }
    // event
    //新增预警配置 点击跳转
    private addNewConfig() {
        this.$router.push({
            path: "/addbeefconfig",
        });
    }
    //查询
    private search() {
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 100,
            pageSizes: [100, 200, 500, 1000, 2000, 5000],
        };
        this.getcattleEarlyWarningConfigurationList();
        console.log(this.form);
    }
    //重置
    private resetForm() {
        this.form = {
            breedId: "",
        };
        this.search();
    }
    private deleteC(row: any) {
        //删除提示框
        this.$confirm(
            this.$t("config.beef_config.confirm_deletion") as string,
            this.$t("config.beef_config.Operation_validation") as string,
            {
                confirmButtonText: this.$t("common.confirm") as string,
                cancelButtonText: this.$t("common.cencel") as string,
                type: "warning",
            }
        )
            .then(() => {
                this.$message({
                    type: "success",
                    message: this.$t(
                        "config.beef_config.Successfully_deleted"
                    ) as string,
                }),
                    this.DeleteItemDataReq(row.id);
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: this.$t("config.beef_config.undeleted") as string,
                });
            });
    }
    private DeleteItemDataReq(id: string) {
        console.log(id, "id");

        new CattleEarlyWarningConfigurationApi().deleteCattleEarlyWarning(
            id,
            (res: any) => {
                this.search();
            }
        );
    }
    private edit(row: any) {
        this.$router.push({
            path: "/editbeefconfig",
            query: row,
        });
    }
    activated() {
        // 初始化page相关内容
        // 请求最新列表数据
        this.getAllBreed(102);
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 100,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getcattleEarlyWarningConfigurationList();
        console.log(this.tablePage);
    }
    //获取数据
    private getcattleEarlyWarningConfigurationList() {
        new CattleEarlyWarningConfigurationApi().getCattleEarlyWarningList(
            this.form,
            this.tablePage,
            (res: any) => {
                this.tableData = res.data;
                this.tablePage.totalCount = this.tableData.length;
            }
        );
    }
}
