import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {Util} from '@/common/Views/comm/util';
import {DeepLoop} from '@/common/deepLoop/deepLoop';

export interface PregnancyRecordForm {
    groupCode: [],
    farmId: string,
    checkId: string,
    labelNumber: string,
    time: [],
}

export class PregnancyRecord extends BasicsClass {
    public getList(form: PregnancyRecordForm, page: any, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.BasicGet('/animal/web/pregnancyRecord/list', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN,
            ...page
        }, false, true, cb)
    }

    public exportList(form: PregnancyRecordForm, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.getFile('/animal/web/pregnancyRecord/exportList', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN
        })
    }

    public getResultList(cb: Function): void {
        this.BasicGet('/animal/web/pregnancyRecord/resultList', {}, false, true, cb)
    }
}