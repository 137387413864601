












































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Result, Column} from "@/common/Views/system/column/column";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {optionSelect, Rule} from "@/common/Views/comm/formRule";
import {ConfType} from "@/common/Views/system/confType/confType";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {AxiosData} from "@/common/http/http";
import {HarmlessDetailForm, HarmlessDetail, HarmlessDetailDetail, CityList} from "@/common/Views/system/harmless/harmlessDetail/harmlessDetail";
import {Util} from "@/common/Views/comm/util";
import TitleSolt from '@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue';
import {Firm} from "@/common/Views/system/firm/firm";
import {Personal, PersonalApp, TableData} from "@/common/Views/system/personal/personal";


@Component({
    components: {TitleSolt}
})

export default class HelloWorld extends Vue {

    public column = new Column()

    private firm: Firm = new Firm()

    private personal: PersonalApp = new PersonalApp()

    public dialogVisible = false
    public ImgList = []

    private groupCodeFirm = []
    // private groupCodeFarm = []

    private formLabelAlign: any = {
        mobile: "",
        userName: "",
        status: 1,
        name: "",
        detailAddress: "",
        simpleAddress: "",
        id: "",
        companyId: '',
        farmIdList: [],
        groupCode: [],
        level: '',
        type: '',
    };

    private oldAddress = ''

    // private props:Object = {
    //     checkStrictly: true,
    //     expandTrigger: "hover",
    //     hoverThreshold: undefined,
    //     label: "name",
    //     value: "name"
    // }

    public props: any = {
        expandTrigger: 'hover',
        value: 'name',
        label: 'name',
        lazy: true,
        lazyLoad(node: any, resolve: any) {
            console.log(node)
            // console.log(resolve(123))
            const column: Column = new Column();
            // if (node.level === 5) {
            //     return
            // }
            column.getTissuc2(node.data.id,1, (res: any) => {
                // if(res.data[0].level == 4){
                //     console.log(1)
                //     for(var i in res.data){
                //     }
                // }else{
                //     resolve(res.data)
                // }
                // console.log(res.data)
                // resolve(res.data)
                const nodes = res.data
                // if (node.level === 4) {
                nodes.forEach((item: any) => {
                    item.leaf = (item.level === 4)
                })    
                // }
                resolve(nodes)
            })
        }
    }

    private getFirmList(): void {
        let simpleAddress = ''
        console.log(this.formLabelAlign.simpleAddress)
        if(this.groupCodeFirm.length != 0){
            let simp:any = JSON.stringify(this.groupCodeFirm)
            simp = JSON.parse(simp)
            this.formLabelAlign.simpleAddress = simp.join('-')
        }
        this.personal.getCompanyList({
            simpleAddress: this.formLabelAlign.simpleAddress,
            type: this.formLabelAlign.type
        }, (res: any) => {
            // console.log(res)
            this.firmList = res.data;
            this.httpData.companyId?this.formLabelAlign.companyId = this.httpData.companyId:this.formLabelAlign.companyId = ''
            this.getFarmList()
        })
    }

    @Watch('formLabelAlign.simpleAddress')
    $alterSimpleAddress(){
        this.firmList = []
        this.formLabelAlign.companyId = ''
        this.formLabelAlign.farmIdList = []
        if(this.formLabelAlign.type){
            this.getFirmList()
        }
    }

    @Watch('formLabelAlign.type')
    $alterType(){
        this.firmList = []
        this.formLabelAlign.companyId = ''
        this.formLabelAlign.farmIdList = []
        if(this.formLabelAlign.type){
            this.getFirmList()
        }
    }

    private firmList = []

    private changeCity(a, b, c): void {
        // this.formLabelAlign.simpleAddress = this.groupCodeFirm.join('-')
        this.firmList = []
        this.formLabelAlign.companyId = ''
        this.getFirmList()
    }

    private getFarmList(): void {
        console.log(this.formLabelAlign.groupCode)
        new PersonalApp().getFarmList({
            groupCode: this.formLabelAlign.groupCode[this.formLabelAlign.groupCode.length - 1], 
            companyId: this.formLabelAlign.companyId,
            type: this.formLabelAlign.type
        }, (res: any) => {
            // console.log(res)
            this.farmList = res.data;
        })
    }

    private farmList = []

    private changeCity1(a, b, c): void {
        // this.formLabelAlign.simpleAddress = this.groupCodeFirm.join('-')
        this.farmList = []
        this.formLabelAlign.farmIdList = []
        this.getFarmList()
    }

    private changeFarm(): void {
        if (this.formLabelAlign.farmIdList.length > 1) {
            if (this.formLabelAlign.farmIdList.indexOf("") != -1) {
                (<string[]>this.formLabelAlign.farmList).splice(
                this.formLabelAlign.farmIdList.indexOf(""),
                1
                );
            }
        }
    }


    private treeData: Result[] = [];
    private treeData1: Result[] = [];

    private showMark1: boolean =  false;
    private showMark2: boolean =  false;
    private url: string =  '';

    private getTree(cb?: Function): void {
        
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        column.getTreeData().then((result: Result[]) => {

            if(cb) cb();
        }).catch(err => {
            this.$message.error(err.message);
        });
    }

    private statusOptions: optionSelect<number>[] = [
        {
            value: 1,
            label: "启用",
        },
        {
            value: 2,
            label: "禁用",
        },
        {
            value: 3,
            label: "锁定",
        },

    ];

    // private column: Column = new Column();


    private activated(): void {
        this.getTree();

        this.getDetail();
        this.confType();
        if(!this.$route.query.id){
            // this.getFirmList()
            // this.getFarmList()
        }
        
        // console.log(12132131);
        
        this.column.getTissuc(0, (res: any) => {
            this.treeData = res.data
        })
        new Column().getTissuc(undefined, (res: any) => {
            this.treeData1 = res.data
        })
    }


    private conTypeList: SchoolList<string>[] = [];

    private confType(): void {
        new ConfType().getList((data: AxiosData<SchoolList<string>[]>) => {
            this.conTypeList = data.data;
        });
    }

    private rules: HarmlessDetailForm<Rule[]> = {
        mobile: [
            {required: true, message: "请填写联系电话"},
            {
                message: "手机号码输入错误", validator: (rule: any, value: any, callback: any) => {
                    if (!/^[0-9]{11}$/g.test(value)) {
                        callback(new Error());
                    } else {
                        callback();
                    }
                }
            },
        ],
        userName: [
            {required: true, message: "请填写联系人"}
        ],
        status: [
            {required: true, message: "请选择启用状态"}
        ],
        name: [
            {required: true, message: "请填写姓名"}
        ],
        companyId: [
            {required: true, message: "请选择所属企业"}
        ],
        type: [
            {required: true, message: "请选择企业类型"}
        ]
        // typeIds: [
        //     // @ts-ignore
        //     {type: "array", required: true, message: "请添加养殖类型"}
        // ],
        // typeIds: [],
    };

    private harmlessDetail: HarmlessDetail;

    constructor() {
        super();
        this.harmlessDetail = new HarmlessDetail();
    }

    private showDio(url: string) {
        this.dialogVisible = true;
        this.url = url;
    }

    private del(url: keyof FarmDetailForm) {
        (<string>this.formLabelAlign[url]) = '';
        this.showMark1 = false;
        this.showMark2 = false;
    }

    private save(): void {
        // console.log(this.ImgList);
        
        (this.$refs.form as any).validate((valid: boolean) => {
            if (valid) {
                let id = "";
                if (this.$route.query.id) {
                    id = this.$route.query.id
                }
                this.personal.submit(this.formLabelAlign, () => {
                    this.$router.go(-1);

                }, id);
                // console.log(this.formLabelAlign)
            }

        });

    }

    private httpData: any = ''


    private getDetail(): void {
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        this.formLabelAlign.status = 1;
        // console.log(this.$route.query.item)
        // if(this.$route.query.item){
            
        //     let item = JSON.parse(this.$route.query.item)
        //     this.formLabelAlign.name = item.name
        //     this.formLabelAlign.userName = item.username
        //     this.formLabelAlign.mobile = item.mobile
        //     this.formLabelAlign.detailAddress = item.detailAddress
        //     this.formLabelAlign.simpleAddress = item.simpleAddress.split('/').join('-')
        //     this.formLabelAlign.id = item.id
        //     // console.log(item.simpleAddress)
        // }
        if(this.$route.query.id){
            new PersonalApp().getDetail(this.$route.query.id, (res: any) => {
                console.log(res)
                this.httpData = res.data
                this.formLabelAlign.name = res.data.name
                this.formLabelAlign.mobile = res.data.mobile
                this.formLabelAlign.level = res.data.level
                this.formLabelAlign.status = res.data.status
                this.formLabelAlign.type = res.data.type.toString()
                
                if(this.formLabelAlign.type){
                    // this.getFirmList()
                }
                if(res.data.simpleAddress){
                    this.formLabelAlign.simpleAddress = res.data.simpleAddress.split('/').join('-')
                }
                if(res.data.companyId){
                    this.formLabelAlign.companyId = res.data.companyId
                    console.log(res.data.companyId)
                }
                if (res.data.farmIds) {
                    this.formLabelAlign.farmIdList = (<string>res.data.farmIds).split(",");
                } else {
                    this.formLabelAlign.farmIds = [""];
                }
                
                // this.getFarmList()
            })
        }
    }

    private cityList0 = []
    private cityList1 = []
    private cityList2 = []
    private city: any = {
        province: '',
        theCity: '',
        area: '',
    }
    private dataImg: boolean = false


    public resGroup: Result[] = []
    // public handleCascader(start: any): void {
    //     console.log(start)
    //     if((start == false) && (this.groupCodeFirm.length != 0)){
    //         this.formLabelAlign.simpleAddress = this.groupCodeFirm.join('-')
            
    //     }
    // }

    private getCity(pid: any, flag: boolean = true): void {
        console.log(1);
        
        if (!pid) {
            pid = 0
        }
        console.log(2);
        
        new HarmlessDetail().getCity(<any>pid, (data: CityList) => {
            if (pid == 0) {
                this.cityList0 = data.data
            } else if (pid < 100 && pid > 10) {
                this.cityList1 = data.data;
                if (flag) {

                    this.city.theCity = '';
                    this.city.area = ''
                }
            } else if (pid > 1000 && pid < 10000){
                this.cityList2 = data.data;
                if (flag) {

                    this.city.area = '';
                }
            }
            console.log(this.$refs)
            // this.formLabelAlign.simpleAddress = this.$refs.province.selectedLabel + this.$refs.theCity.selectedLabel + this.$refs.area.selectedLabel
            this.formLabelAlign.simpleAddress = this.$refs.area.value
        })
    }

    private handleCity(e: any): void {
        this.formLabelAlign.simpleAddress = e
    }

    private setImg(file: any, fileList: any): void {
        this.readImg(file, fileList, "licenseUrl");
    }

    private setImg1(file: any, fileList: any): void {
        this.readImg(file, fileList, "doorUrl");
    }

    private readImg(file: any, fileList: any, url: keyof FarmDetailForm) {
        const reader = new FileReader();
        let res = reader.readAsDataURL(file.raw);
        reader.onloadend = () => {
            this.dataImg = false;
            // var strBase64 = reader.result.substring(84);
            const strBase64 = (<string>reader.result).substring(0);
            // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: strBase64, url: ""};
            (<string>this.formLabelAlign[url]) = strBase64;
            console.log(this.formLabelAlign);
        };
        reader.onload = function(e) {
            console.log(e);
            // simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc
        };

    }

    // public changeCity = []

    
    public cityChange(ids: any): void {
        
    }

    private handleRemove(file: any): void {
        console.log(file);
        this.formLabelAlign.licenseUrl = ''
        this.ImgList = []
    };

    private handlePictureCardPreview(file: any): void {
        this.formLabelAlign.licenseUrl = file.url;
        this.dialogVisible = true;
    }
    
      

    @Watch("$route")
    public handleRoute(): void {
        this.resGroup = []
        // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: "", url: ""},
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        this.city = new Util().clearObject(this.city);
        // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: "", url: ""};
        this.formLabelAlign.auditStatus = "1";
        // this.$nextTick(()=>{
        //     (<any>this.$refs.upload).clearFiles();
        // })
        this.dataImg = false;
        this.getDetail();
        this.ImgList = []
    }
}
