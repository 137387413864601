




















import {Watch , Component , Vue} from 'vue-property-decorator'

import RebateDetailsConfiguration from './components/RebateDetailsConfiguration.vue'
import DrugAllocation from './components/DrugAllocation.vue'

import {BasicsClass} from '@/common/BasicsClass';
@Component({
    components:{
        RebateDetailsConfiguration,
        DrugAllocation
    }
})
export default class RebateConfiguration extends Vue{

    private checkType:boolean = true //切换的type

    private showType(flag:boolean){
        this.checkType = flag
    }

    private AddItem(){//点击添加的逻辑
        if(this.checkType){
            let domVc:any = this.$refs.OneType
            if(domVc.CanAdd()){
                domVc.CloseEditConfig()
                domVc.addItem()
            }
        }else{
            let domVc:any = this.$refs.TwoType
            if(domVc.CanAdd()){
                domVc.CloseEditConfig()
                domVc.addItem()
            }else{
                //提示
            }
            
            
        }
    }


    activated(){
    }
    

}
