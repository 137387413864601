import {Axios, AxiosData} from '@/common/http/http';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {BasicsClass} from '@/common/BasicsClass';

export interface Personal<T> {
    name: T;
    mobile: T;
    groupCode: T[];
    farmIds: T;
    roleIds: T;
    status: any;
}

export interface TableData<T> extends Personal<T> {
    id: T;
    username: T;
    identifier: T;
    email: T;
    groupId: T;
    gradeIds: T;
    schoolName: T;
    createTime: T;
    updateTime: T;
    stopTime: T;
    createUserId: T;
    updateUserId: T;
    deleteFlag: T;
    classIds: T;
    roleNames: T;
    roles: T;
    farmNames: T;
}


export class PersonalC extends BasicsClass {

    public async getPersonalList(form: Personal<string>, tablePage: HandleTable, platform: number = 1) {
        let result: Array<TableData<string>>;
        let pageInfo: HandleTable;
        try {
            const axios: Axios = new Axios();
            const data = await axios.get<Array<TableData<string>>>('/animal/web/user/list', {
                ...form,
                groupCode: form.groupCode[form.groupCode.length - 1],
                ...tablePage,
                platform
            });
            result = data.data;
            pageInfo = data.page;
        } catch (e) {
            this.vue.$message.error(e.message);
            result = [];
            pageInfo = tablePage;
        }

        return {result, pageInfo};

    }

    public removeRole(ids: string, callback: Function,  platform: number = 1): void {
        this.$axios.post('/animal/web/user/delete', {
            ids,
            platform
        }).then((data) => {
            this.vue.$message.success(data.message);
            callback();
        }).catch((err) => {
            this.vue.$message.error(err.message);
        });
    }

    public restPassword(id: string, password: string,  platform: number = 1): void {
        this.$axios.post('/animal/web/user/resetPassword', {
            id,
            password,
            platform
        }, true).then((data) => {
            this.vue.$message.success(data.message);
        }).catch((err) => {
            this.vue.$message.error(err.message);
        });
    }

    public loginUnLock(id: string, callBack: Function,  platform: number = 1): void {
        this.$axios.post<AxiosData<string>>('/animal/web/user/unlock', {id, platform}, false).then((data)=>{
            this.vue.$message.success(data.message);
            callBack(data);
        }).catch(err=>{
            this.vue.$message.error(err.message);
        });
    }

    public getCompanyList(cb: Function): void {
        this.BasicGet('/animal/web/user/companies', {}, false, true, cb);
    }

    public audit(form: any, cb: Function): void {
        this.BasicGet('/animal/web/user/audit', form, false, true, cb);
    }
}

export class PersonalApp extends BasicsClass {

    public async getPersonalList(form: Personal<string>, tablePage: HandleTable, platform: number = 1) {
        let result: Array<TableData<string>>;
        let pageInfo: HandleTable;
        let simpleAddress
        if(form.groupCode.length >= 2){
            let simp:any = JSON.stringify(form.groupCode)
            simp = JSON.parse(simp)
            simp.splice(0, 1)
            simpleAddress = simp.join('-')
        }
        try {
            const axios: Axios = new Axios();
            const data = await axios.get<Array<TableData<string>>>('/animal/web/appUser/userList', {
                ...form,
                groupCode: form.groupCode[form.groupCode.length - 1],
                ...tablePage,
                platform,
                simpleAddress
            });
            result = data.data;
            pageInfo = data.page;
        } catch (e) {
            this.vue.$message.error(e.message);
            result = [];
            pageInfo = tablePage;
        }

        return {result, pageInfo};

    }

    public loginUnLock(id: string, callBack: Function,  platform: number = 1): void {
        this.$axios.post<AxiosData<string>>('/animal/web/user/unlock', {id, platform}, false).then((data)=>{
            this.vue.$message.success(data.message);
            callBack(data);
        }).catch(err=>{
            this.vue.$message.error(err.message);
        });
    }

    public getCompanyList(form: any, cb: Function): void {
        this.BasicGet('/animal/web/appUser/companyList', form, false, true, cb);
    }

    public getFarmList(form: any, cb: Function): void {
        this.BasicGet('/animal/web/appUser/companyFarmList', form, false, true, cb);
    }

    public submit(form: any, cb: Function, id: string): void {
        if(id){
            this.BasicPost('/animal/web/appUser/updateAppUser', {
                ...form, 
                id,
                groupCode: form.groupCode[form.groupCode.length - 1],
                farmIds: undefined
            }, true, true, true, cb)
        }else {
            this.BasicPost('/animal/web/appUser/insert', {
                ...form,
                groupCode: form.groupCode[form.groupCode.length - 1],
                farmIds: undefined
            }, true, true, true, cb)
        }
    }

    public getDetail( id: string, cb: Function): void {
        this.BasicGet('/animal/web/appUser/get', {id}, false, true, cb)
    }
}
