















































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {Column} from '@/common/Views/system/column/column';
import {FarmDetailForm} from '@/common/Views/system/farm/farmDetail/farmDetail';

@Component({
  name: 'UploadTemp'
})
export default class UploadTemp extends Vue {
  @Prop({ default: '' }) title?: string // 图片标题
  @Prop({ default: '' }) type?: string // 图片对应的参数名
  @Prop({ default: '' }) url?: string // 图片路径

  @Watch('url')
  changeUrl(val: string) {
    this.iconUrl = val

  }

  iconUrl = ''
  dataImg = false
  showMark = false
  dialogVisible = false
  ossconfigdata: any = {}

  created() {
    this.iconUrl = this.url
    this.ossconfig()
  }

  //获取oss参数
  ossconfig() {
    new Column().ossconfig((res: any) => {
      this.ossconfigdata = res.data
    })
  }

  async putObject(file: any) {
    const loading = this.$loading({
      lock: true,
      text: '请稍后...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    let OSS = require("ali-oss");
    // const data = new OSS.Buffer(file);
    let client = new OSS({
      region: "oss-cn-hangzhou",
      accessKeyId: this.ossconfigdata.accessKeyId,
      accessKeySecret: this.ossconfigdata.accessKeySecret,
      stsToken: this.ossconfigdata.securityToken,
      bucket: this.ossconfigdata.bucketName,
    });
    try {
      const result = await client.put(
          this.ossconfigdata.bucketName +
          "/" +
          this.ossconfigdata.directory +
          this.ossconfigdata.userId +
          "/" +
          file.name,
          file.raw
      );
      this.iconUrl = result.url;
      this.$emit('getUrl', this.iconUrl, this.type)
      loading.close();
    } catch (e) {
      console.log(e);
      loading.close();
    }
  }

  setImg(file: any, fileList: any): void {
    const types = file.name.split(".")[1];
    const fileType = ["jpg", "png", "jpeg"].some((item) => {
      return item === types;
    });
    if (!fileType) {
      this.$message.error("文件格式错误，请重新选择文件！");
      return;
    }
    this.putObject(file);
    this.readImg(file, fileList, "pic");
  }

  readImg(file: any, fileList: any, url: keyof FarmDetailForm) {
    const reader = new FileReader();
    let res = reader.readAsDataURL(file.raw);
    reader.onloadend = () => {
      this.dataImg = false;
      // var strBase64 = reader.result.substring(84);
      const strBase64 = (<string>reader.result).substring(0);
    };
  }

  handleRemove() {
    this.$emit('removeUrl', this.type)
  }

  del() {
    this.iconUrl = "";
    this.$emit('removeUrl', this.type)
    this.showMark = false;
  }

  showDio() {
    this.dialogVisible = true
  }

}
