import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';

export interface SlaughterForm {
    groupCode: string;
    name: string;
    platform: string;

}

export interface SlaughterTable {
    createTime: string;
    createUserName: string;
    groupCode: string;
    groupName: string;
    id: string;
    mobile: string;
    name: string;
    person: string;
    status: boolean;
    typeIds: string;

    
}

export class Slaughter extends BasicsClass {

    public getTable(form: SlaughterForm, tablePage: HandleTable, cb: Function): void {
        this.getTableList({
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1]
        }, tablePage, '/animal/web/slaughter/list', cb);
    }

    //请求所属企业
    public getLinkFarm(cb:Function){
        this.BasicGet('/animal/web/appUserManagement/companyList',{platform:3},false,false,cb)
    }
}