
























































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { TypeConfig } from "@/common/Views/basics/typeConfig/typeConfig";
import { AxiosData } from "@/common/http/http";
import { SchoolList } from "@/common/Views/basics/schoolManage/schoolManage";
import TypeChildVue from "./components/type.vue";

@Component({
    components: {
        TypeChildVue,
    },
})
export default class HelloWorld extends Vue {
    private form: any = {};

    private pigData: any = [];
    private sheepData: any = [];
    private beefData: any = [];
    private camelData: any[] = [];
    private ShowAddFlag: boolean = false;
    private AddInputVal: string = "";
    private IsType = 1;

    private pig: any = [];
    private cow: any = [];
    private sheep: any = [];
    private camel: any[] = [];

    private ShowType: boolean = true;

    private activated() {
        new TypeConfig().getMedicineConf((data: any) => {
            this.pigData = data.data[101];
            this.beefData = data.data[102];
            this.sheepData = data.data[103];
            this.camelData = data.data[104] ? data.data[104] : [];
            console.log(this.pigData);
        });
    }

    private pigAdd(val: number) {
        // this.pigData.push(
        //     {
        //         id: '',
        //         name: '',
        //         typeId: '101'
        //     }
        // )

        this.ShowAddFlag = true;
        this.IsType = 1;
        this.AddInputVal = "";
    }
    private pigRemove(val: string | number) {
        this.pigData.splice(val, 1);
        console.log(this.pigData);
    }
    private beefAdd(val: number) {
        // this.beefData.push(
        //     {
        //         id: '',
        //         name: '',
        //         typeId: '102'
        //     }
        // )

        this.ShowAddFlag = true;
        this.IsType = 2;
        this.AddInputVal = "";
    }
    private beefRemove(val: string | number) {
        this.beefData.splice(val, 1);
    }
    private sheepAdd(val: number) {
        // this.sheepData.push(
        //     {
        //         id: '',
        //         name: '',
        //         typeId: '103'
        //     }
        // )

        this.ShowAddFlag = true;
        this.IsType = 3;
        this.AddInputVal = "";
    }
    private sheepRemove(val: string | number) {
        this.sheepData.splice(val, 1);
    }
    private camelAdd(val: number) {
        this.ShowAddFlag = true;
        this.IsType = 4;
        this.AddInputVal = "";
    }
    // private camelRemove(val:string | number){
    //     this.camelData.splice(val , 1)
    // }

    private cancel() {
        this.$router.go(0);
    }

    // private datas(arr:any , str:any){
    //     arr.forEach((val:any) => {
    //         // if(val.name != ''){
    //             str.push(
    //                 val.name
    //             )
    //         // }

    //     });
    //     str = str.toString()
    //     console.log(arr)
    // }

    private submit() {
        this.pig = [];

        this.pigData.forEach((val: any) => {
            this.pig.push(val.name);
        });

        this.beefData.forEach((val: any) => {
            this.cow.push(val.name);
        });

        this.sheepData.forEach((val: any) => {
            this.sheep.push(val.name);
        });
        this.camelData.forEach((val: any) => {
            this.camel.push(val.name);
        });
        console.log(this.pig);

        new TypeConfig().save(
            this.pig,
            this.cow,
            this.sheep,
            this.camel,
            () => {
                new TypeConfig().getMedicineConf((data: any) => {
                    this.ShowType = false;
                    this.ShowType = true;
                    this.pigData = data.data[101];
                    this.beefData = data.data[102];
                    this.sheepData = data.data[103];
                    this.camelData = data.data[104];
                    console.log(this.pigData);
                });
            }
        );
    }

    private CheckType(data: any) {
        let result: boolean = false;
        console.log(data);
        data.forEach((item: any) => {
            if (item.name == this.AddInputVal) {
                this.$message({
                    message: "品种类别已存在",
                    type: "warning",
                });
                result = true;
                throw "";
            }
        });
        this.ShowAddFlag = false;
        return result;
    }

    private AddForageItemType(val: number) {
        if (this.AddInputVal == "") {
            this.$message({
                message: "品种类别不能为空",
                type: "warning",
            });
            return;
        }
        if (this.IsType == 1) {
            if (this.CheckType(this.pigData)) {
                return;
            } else {
                this.pigData.push({
                    id: "",
                    name: this.AddInputVal,
                    typeId: "101",
                });
            }
        }
        if (this.IsType == 2) {
            if (this.CheckType(this.beefData)) {
                return;
            } else {
                this.beefData.push({
                    id: "",
                    name: this.AddInputVal,
                    typeId: "102",
                });
            }
        }
        if (this.IsType == 3) {
            if (this.CheckType(this.sheepData)) {
                return;
            } else {
                this.sheepData.push({
                    id: "",
                    name: this.AddInputVal,
                    typeId: "103",
                });
            }
        }

        if (this.IsType == 4) {
            console.log(this.IsType);
            if (this.CheckType(this.camelData)) {
                return;
            } else {
                this.camelData.push({
                    id: "",
                    name: this.AddInputVal,
                    typeId: "104",
                });
            }
        }
    }
}
