

















import {Watch , Prop , Component , Vue} from 'vue-property-decorator'
import {Util} from "@/common/Views/comm/util";
import { Column, ComboList, Result } from "@/common/Views/system/column/column";
@Component({})
export default class UpLoadImgComponentListVue extends Vue{
    @Prop() ShowImgUrlList:any
    private imgurl:any[]=[] //显示的图片数据
    private fileList:any =  [] //回显图片列表
    
    private handleChangeOne(file:any,fileList:any[]){ //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        let nameArr:any[] =  file.name.split('.')
        if(file.raw.type.split('/')[0] =='image' && file.size / 1024 / 1024 > 30){
            this.$message('请上传低于30MB大小的图片');
            fileList.pop()
            return
        }
        if (nameArr[nameArr.length-1] === "jpg" || nameArr[nameArr.length-1] === "png") {
            fileList.pop()
            this.putObject1(file,fileList);
        } else {
            fileList.pop()
            Util.showToast("请上传jpg或png格式图片", "warnning");
            return
        }
    }
    private beforeRemove(file:any , fileList:any){ //删除
        this.imgurl = []
        setTimeout(() =>{
            fileList.forEach((item:any) => {
                this.imgurl.push(item.url)
            });
        })
    }


    

    private ossconfigdata:any ={} //oss 参数对象
    private ossconfig(): void {//获取oss参数
        new Column().ossconfig((res: any) => {
            this.ossconfigdata = res.data;
        });
    }
    private async putObject1(file: any,fileList:any) {
        fileList.pop() //删除最后一位
        let OSS = require("ali-oss");
        let client = new OSS({
            region: "oss-cn-hangzhou",
            accessKeyId: this.ossconfigdata.accessKeyId,
            accessKeySecret: this.ossconfigdata.accessKeySecret,
            stsToken: this.ossconfigdata.securityToken,
            bucket: this.ossconfigdata.bucketName,
        });
        try {
            const result = await client.put(
                this.ossconfigdata.bucketName +
                "/" +
                this.ossconfigdata.directory +
                this.ossconfigdata.userId +
                "/" +
                file.name,
                file.raw
            );
            this.imgurl.push(result.url)
            fileList.push({url:result.url,uid: Math.random()*1000})
        } catch (e) {
            console.log(e);
        }
    }

    @Watch('imgurl',{deep:true})
    private ChangeImgUrlList(newVal:any[]){
        let dom:any= null
        if(this.$route.path == '/AddForageRackConfiguration'){
            dom = document.getElementsByClassName('el-upload--picture-card')[1]
        }else{
            dom = document.getElementsByClassName('el-upload--picture-card')[0]
        }
        if(newVal.length == 5){
            dom.style.display = 'none'
        }
        if(newVal.length < 5){
            dom.style.display = 'block'
        }
        this.$emit('SetNewImgUrlList' , newVal)
    }
    @Watch('ShowImgUrlList',{immediate:true})
    private ChangeShowImgUrlList(newVal:any){
        this.fileList = []
        if(newVal == undefined) return
        this.imgurl = newVal
        newVal.forEach((item:any) => {
            this.fileList.push({
                url:item,
                uid: Math.random()*1000
            })
        });
    }
    activated(){
        this.ossconfig() 
    }
}
