




















import { Watch, Prop, Component, Vue } from "vue-property-decorator";
@Component({})
export default class SelectComponentVue extends Vue {
    @Prop() LabelText: any;
    @Prop() FatherSelectVal: any;
    @Prop() SelectList: any;
    @Prop() width: any;
    @Prop() PlaceholderText: any;
    @Prop() IsDisabled: any; //是否禁止选择
    /**@param multiple 是否开启多选 */
    @Prop() multiple: any;
    @Prop() emitCb: any;

    private SelectVal: any = ""; //当前组件所绑定的值

    get placeholder(){
        console.log(this.PlaceholderText, 'this.PlaceholderText')
        // return this.PlaceholderText;
        return this.PlaceholderText ? this.PlaceholderText : this.$t('common.placeholder.select');
    }
    
    @Watch("SelectVal")
    private cjamgeSelectVal(newVal: string) {
        console.log(newVal, '45newVal');
        
        this.$emit("SetSelectListValue", newVal); //设置下拉框选择的值
        if(this.emitCb){
            this.emitCb(newVal);
        }
    }

    @Watch("FatherSelectVal", { immediate: true, deep: true }) //监视form传递过来的绑定的值  如果不为假赋值  编辑使用
    private changeSelectData(newVal: any) {
        console.log(newVal, 'newVal');
        
        if (newVal == "") this.SelectVal = "";
        if (newVal) this.SelectVal = newVal;
    }
    //此组件只支持 id name 类型的数组
}
