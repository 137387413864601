











































import { Row } from 'element-ui'
import {Prop , Component , Vue} from 'vue-property-decorator'
import {BasicsClass} from '@/common/BasicsClass';
@Component({})
export default class DrugAllocation extends Vue{
    private tableData:any[] = [] // table数据

    private InputVal:any = '' //生产厂家
    private InputVal2:any = ''//药品名称

    private tablePage: any = {//table Page 
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private EditConfig(row:any){//编辑
        if(!this.CanAdd()) return //先判断是否能够编辑  不能编辑 直接return
        row.flag = true //显示对应行的输入框
        row.type = false //变为编辑状态 保存接口
        this.InputVal = row.manufacturer //赋值对应的
        this.InputVal2 = row.medicine
    }

    private deleteRow(row:any){//删除
        this.$confirm('是否确认删除' , '操作确认' , {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() =>{
            this.DeleteDataReq(row)
        }).catch(() =>{
            this.$message({type: 'info',message: '已取消删除'})
            this.getList()
        })
    }

    private saveRowData(row:any){//保存
        if(row.manufacturer =='' || row.medicine == ''){
            this.$message.error('请先完整填写配置数据');
            return
        }  //判断 任意值是否为空 如果为空 return
        console.log(row)
        row.type ? this.SaveDataReq(row) : this.EditDataReq(row)
    }


    //函数处理
    private clearData(row:any){//取消
        row.flag = false//隐藏对应行的输入框
        this.getList()
    }
    
    private handlePageChange(val: any) {//handlePageChange 分页
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.getList()//请求列表数据
    }
    private addItem(){  //添加配置
        this.tableData.push(
            {
               manufacturer:'',
                medicine:'' ,
                flag:true,
                type:true
            }
        )
    }

    private CloseEditConfig(){//全部重置为非编辑状态
        this.tableData.forEach((item:any)=>{
            item.flag = false
        })
    }

    private CanAdd(){ //是否能够新增
        let flag:boolean = false
        if(this.tableData.length === 0){
            flag = true
            return flag
        }
        for(let i:number = 0; i<this.tableData.length; i++){
            if(this.tableData[i].flag){
                flag = false
                return
            }else{
                flag = true
            }
        }
        return flag
    }

    //接口
    private getList(){// 获取列表数据接口
        this.tableData = []
        new BasicsClass().BasicGet('/animal/web/rebateMedicine/getRebateMedicineList',{...this.tablePage},false,false,(data:any)=>{
            data.data.forEach((item:any) => {
                item.flag = false //添加属性 默认非编辑状态
                item.type = true  //添加属性 默认保存接口
            });
            this.tableData= data.data
            this.tablePage.totalCount = data.page.totalCount
        })
        
    }
    private SaveDataReq(row:any){ //新增保存接口
        new BasicsClass().BasicPost('/animal/web/rebateMedicine/addRebateMedicine',{manufacturer:row.manufacturer,medicine:row.medicine} , true ,true , true , ()=>{
            row.flag = false//关闭对应的编辑装填
            this.getList()//获取最新数据
        })
    }
    private EditDataReq(row:any){ //修改接口
        let obj:any = {
            id:row.id,
            manufacturer:row.manufacturer,
            medicine:row.medicine
        }
        new BasicsClass().BasicPost('/animal/web/rebateMedicine/updateRebateMedicine',{...obj},true,true,true,()=>{
            row.flag = false //隐藏输入框
            this.getList()//获取最新数据
        })
    }
    private DeleteDataReq(row:any){
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        new BasicsClass().BasicGet('/animal/web/rebateMedicine/deleteRebateMedicine',{id:row.id} , true , true , (daga:any)=>{
            this.getList()//获取最新数据
        })
    }

    mounted(){
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getList()
    }
}
