

























































import {Component , Watch , Vue} from 'vue-property-decorator'
import InputComonentVue from '@/views/basics/typeConfig/components/components/InputComonentVue.vue'
import NewPidAdressVue from '@/views/basics/typeConfig/components/components/NewPidAdressVue.vue'
import {FarmDetail} from '@/common/Views/system/farm/farmDetail/farmDetail'
import {SaveUpdateMarket , getMarketBaseInfo} from '@/api/index'
import {dateFun} from '@/common/date'
interface MarkInfoData extends InfoData {
    id:string
    name:string, //交易场名称
    groupCode:string,//所在区域
    addressDetail:string,//详细地址
    transactionType:string//交易类型 1线下 2线上 3线上+线下
    proceedsType:string //收款类型 1服务费 2服务费+购牛款
    openMarketDay:string //开市时间 每逢几开市 逗号分割(1,2,3)
    openMarketTime:string //开市具体时间 时分 （HH:mm）
}
interface InfoData {
    groupCodeName:string
}
@Component({
    components:{
        InputComonentVue,NewPidAdressVue
    }
})
export default class AddMarket extends Vue{
    private form :MarkInfoData ={
        name: "",
        groupCode: '',
        addressDetail: '',
        transactionType: "1",
        proceedsType: "1",
        id: '',
        groupCodeName: '',
        openMarketDay: '',
        openMarketTime: ''
    }
    private openMarketDay:string[] = []
    private inputVisible:boolean = false
    private inputValue:string = ''
    private timeval:Date = new Date()
    private rules:any ={
        name:[
            { required: true, message: '请填写交易场名称', trigger: 'change' }
        ],
        groupCode:[
            { required: true, message: '请选择所在区域', trigger: 'change' }
        ],
        addressDetail:[
            { required: true, message: '请填写详细地址', trigger: 'change' }
        ],
        transactionType:[
            { required: true, message: '请选择交易类型', trigger: 'change' }
        ],
        proceedsType:[
            { required: true, message: '请选择收款类型', trigger: 'change' }
        ],
    }
    private AdressTreeList:any[] = []


    //emit
    private SetUserNameValue(val:string){//交易场名称
        this.form.name = val
    }
    private SetSelectListValue(val:any[]){//所在区域
        this.form.groupCode = val.at(-1).toString()
    }


    //事件
    private SaveData(){ //提交
        (this.$refs.form as any).validate((flag:boolean)=>{
            if(flag){
                SaveUpdateMarket(this.form,(data:any)=>{
                    this.form = {
                        id:'',
                        name: "",
                        groupCode: '',
                        addressDetail: '',
                        transactionType: "1",
                        proceedsType: "1",
                        groupCodeName:""
                    }
                    this.$router.push({
                        path: "/MarkList"
                    });
                })
            }
        })
    }
    //tag
    private handleClose(tag:string) {  //关闭标签
        this.openMarketDay.splice(this.openMarketDay.indexOf(tag), 1);
        console.log(this.openMarketDay)
    }
    private handleInputConfirm() {
        let inputValue = this.inputValue;
        if (inputValue) {
          this.openMarketDay.push(inputValue);
          this.form.openMarketDay = this.openMarketDay.toString()  //转为字符串
        }
        this.inputVisible = false;
        this.inputValue = '';
    }
    private showInput() {
        this.inputVisible = true;
        this.$nextTick(() => {
          this.$refs.saveTagInput.$refs.input.focus();
        });
    }

    //接口请求


    @Watch('timeval')
    private Changetimeval(newVal:any){
        console.log(newVal)
        this.form.openMarketTime = new dateFun().returnHourMinuteSecend(newVal,':')
        
    }
    

    activated(){
        this.form = {
            id:'',
            name: "",
            groupCode: '',
            addressDetail: '',
            transactionType: "1",
            proceedsType: "1",
            groupCodeName:"",
            openMarketDay:"",
            openMarketTime:""
        }
        let id = this.$route.query.id
        new FarmDetail().getCity1('-1',(data:any)=>{
            this.AdressTreeList = data.data
        })
        getMarketBaseInfo((id as string),(data:any)=>{
            this.form = {...data.data}
            this.form.proceedsType = data.data.proceedsType.toString()
            this.form.transactionType = data.data.transactionType.toString()
            document.getElementsByClassName('one_width')[0].children[0].children[0].value = this.form.groupCodeName
            this.openMarketDay = data.data.openMarketDay.split(',')
            console.log(this.openMarketDay)
            this.timeval = new Date('Thu May 11 2023' + ' ' + data.data.openMarketTime)
        })
    }
}
