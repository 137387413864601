
export class BankConfigTs{
    
    public form:any = {
        id:"",
        bankName:"", //银行名称
        parentId:"0", // 所属上级id
        groupCode:[], //组织机构
        bankLevel:"",  // 机构分析1,2，3
        enableFlag:true,// 是否允许
    }

    public UpdataFrom:any = {
        id:"",
        bankName:"", //银行名称
        parentId:"", // 所属上级id
        groupCode:[], //组织机构
        bankLevel:"",  // 机构分析1,2，3
        enableFlag:true,// 是否允许
    }

    public TreeDataList:any[] = [
        {
            "id": "698616113201676288",
            "parentId": "3",
            "name": "首页",
            "url": "parenting_main",
            "describe": null,
            "status": true,
            "order": 0,
            "type": 1,
            "code": null,
            "requirePermission": true,
            "requireConfig": true,
            "icon": null,
            "deleteFlag": false,
            "platform": 3,
            "children": [
                {
                    "id": "698616322388393984",
                    "parentId": "698616113201676288",
                    "name": "首页_存盘数量div",
                    "url": "main_amount_of_inventory_div",
                    "describe": null,
                    "status": true,
                    "order": 0,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [
                        {
                            "id": "698624994535538800",
                            "parentId": "698616322388393984",
                            "name": "详情页tab“总览”",
                            "url": "archives_details_overview_tab",
                            "describe": null,
                            "status": true,
                            "order": 0,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538801",
                            "parentId": "698616322388393984",
                            "name": "详情页tab“体温记录”",
                            "url": "archives_details_temp_tab",
                            "describe": null,
                            "status": true,
                            "order": 1,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538802",
                            "parentId": "698616322388393984",
                            "name": "详情页tab“免疫记录”",
                            "url": "archives_details_immune_tab",
                            "describe": null,
                            "status": true,
                            "order": 2,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538815",
                            "parentId": "698616322388393984",
                            "name": "总览页发情按钮",
                            "url": "archives_details_overview_rutting_btn",
                            "describe": null,
                            "status": true,
                            "order": 3,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538803",
                            "parentId": "698616322388393984",
                            "name": "详情页tab“防治记录”",
                            "url": "archives_details_treatment_tab",
                            "describe": null,
                            "status": true,
                            "order": 4,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538804",
                            "parentId": "698616322388393984",
                            "name": "详情页tab“配种记录”",
                            "url": "archives_details_breeding_tab",
                            "describe": null,
                            "status": true,
                            "order": 5,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538805",
                            "parentId": "698616322388393984",
                            "name": "详情页tab“分娩记录”",
                            "url": "archives_details_childbirth_tab",
                            "describe": null,
                            "status": true,
                            "order": 6,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538806",
                            "parentId": "698616322388393984",
                            "name": "总览页免疫按钮",
                            "url": "archives_details_overview_immune_btn",
                            "describe": null,
                            "status": true,
                            "order": 7,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538816",
                            "parentId": "698616322388393984",
                            "name": "总览页流产按钮",
                            "url": "archives_details_overview_abortion_btn",
                            "describe": null,
                            "status": true,
                            "order": 8,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538807",
                            "parentId": "698616322388393984",
                            "name": "总览页防治按钮",
                            "url": "archives_details_overview_treatment_btn",
                            "describe": null,
                            "status": true,
                            "order": 9,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538808",
                            "parentId": "698616322388393984",
                            "name": "总览页配种按钮",
                            "url": "archives_details_overview_breeding_btn",
                            "describe": null,
                            "status": true,
                            "order": 10,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538809",
                            "parentId": "698616322388393984",
                            "name": "总览页孕检按钮",
                            "url": "archives_details_overview_pregnancy_test_btn",
                            "describe": null,
                            "status": true,
                            "order": 11,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538810",
                            "parentId": "698616322388393984",
                            "name": "总览页分娩按钮",
                            "url": "archives_details_overview_childbirth_btn",
                            "describe": null,
                            "status": true,
                            "order": 12,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538811",
                            "parentId": "698616322388393984",
                            "name": "总览页淘汰按钮",
                            "url": "archives_details_overview_eliminate_btn",
                            "describe": null,
                            "status": true,
                            "order": 13,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538812",
                            "parentId": "698616322388393984",
                            "name": "总览页死亡按钮",
                            "url": "archives_details_overview_death_btn",
                            "describe": null,
                            "status": true,
                            "order": 14,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538813",
                            "parentId": "698616322388393984",
                            "name": "总览页转舍按钮",
                            "url": "archives_details_overview_switch_column_btn",
                            "describe": null,
                            "status": true,
                            "order": 15,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "698624994535538814",
                            "parentId": "698616322388393984",
                            "name": "总览页基础信息变更按钮",
                            "url": "archives_details_overview_change_info_btn",
                            "describe": null,
                            "status": true,
                            "order": 16,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        }
                    ],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                },
                {
                    "id": "737713158289883136",
                    "parentId": "698616113201676288",
                    "name": "首页_保险盘点div",
                    "url": "main_insurance_inventory_div",
                    "describe": null,
                    "status": true,
                    "order": 1,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                },
                {
                    "id": "737713335541174272",
                    "parentId": "698616113201676288",
                    "name": "首页_投保汇总div",
                    "url": "main_insurance_summary_div",
                    "describe": null,
                    "status": true,
                    "order": 2,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                },
                {
                    "id": "737712925539565568",
                    "parentId": "698616113201676288",
                    "name": "首页_银行盘点div",
                    "url": "main_bank_inventory_div",
                    "describe": null,
                    "status": true,
                    "order": 3,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                },
                {
                    "id": "737713275122225152",
                    "parentId": "698616113201676288",
                    "name": "首页_贷款汇总div",
                    "url": "main_loan_summary_div",
                    "describe": null,
                    "status": true,
                    "order": 4,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                },
                {
                    "id": "698616459626020864",
                    "parentId": "698616113201676288",
                    "name": "首页_健康预警div",
                    "url": "main_health_warning_div",
                    "describe": null,
                    "status": true,
                    "order": 5,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [
                        {
                            "id": "714226947013476352",
                            "parentId": "698616459626020864",
                            "name": " 健康预警列表页",
                            "url": "warning_health_list_page",
                            "describe": null,
                            "status": true,
                            "order": 0,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "714227044585570304",
                            "parentId": "698616459626020864",
                            "name": "健康预警处理页",
                            "url": "warning_health_deal_page",
                            "describe": null,
                            "status": true,
                            "order": 1,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "714227128391958528",
                            "parentId": "698616459626020864",
                            "name": "健康预警处理页_右上角防治记录",
                            "url": "warning_health_treatment_btn",
                            "describe": null,
                            "status": true,
                            "order": 2,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        }
                    ],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                },
                {
                    "id": "714221653306900480",
                    "parentId": "698616113201676288",
                    "name": "首页_养殖课堂",
                    "url": "main_media_div",
                    "describe": null,
                    "status": true,
                    "order": 6,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [
                        {
                            "id": "714223286392717312",
                            "parentId": "714221653306900480",
                            "name": "视频列表",
                            "url": "media_list_page",
                            "describe": null,
                            "status": true,
                            "order": 0,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        }
                    ],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                },
                {
                    "id": "737711716325916672",
                    "parentId": "698616113201676288",
                    "name": "首页_待办事项",
                    "url": "main_todo_div",
                    "describe": null,
                    "status": true,
                    "order": 7,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [
                        {
                            "id": "737711800253939712",
                            "parentId": "737711716325916672",
                            "name": "发情提醒",
                            "url": "main_todo_oestrus_remind_div",
                            "describe": null,
                            "status": true,
                            "order": 0,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737711888099442688",
                            "parentId": "737711716325916672",
                            "name": "配种提醒",
                            "url": "main_todo_breeding_remind_div",
                            "describe": null,
                            "status": true,
                            "order": 1,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737711956030394368",
                            "parentId": "737711716325916672",
                            "name": "孕检提醒",
                            "url": "main_todo_pregnancy_remind_div",
                            "describe": null,
                            "status": true,
                            "order": 2,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737712016336097280",
                            "parentId": "737711716325916672",
                            "name": "分娩提醒",
                            "url": "main_todo_birth_remind_div",
                            "describe": null,
                            "status": true,
                            "order": 3,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737712277670596608",
                            "parentId": "737711716325916672",
                            "name": "出栏审核",
                            "url": "main_todo_out_column_audit_div",
                            "describe": null,
                            "status": true,
                            "order": 4,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737712340740345856",
                            "parentId": "737711716325916672",
                            "name": "设备异常",
                            "url": "main_todo_device_err_div",
                            "describe": null,
                            "status": true,
                            "order": 5,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737712416061657088",
                            "parentId": "737711716325916672",
                            "name": "耳标异常",
                            "url": "main_todo_label_err_div",
                            "describe": null,
                            "status": true,
                            "order": 6,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737712481857703936",
                            "parentId": "737711716325916672",
                            "name": "非法离栏",
                            "url": "main_todo_leave_err_div",
                            "describe": null,
                            "status": true,
                            "order": 7,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737712542444425216",
                            "parentId": "737711716325916672",
                            "name": "死亡判别",
                            "url": "main_todo_death_err_div",
                            "describe": null,
                            "status": true,
                            "order": 8,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737712612262809600",
                            "parentId": "737711716325916672",
                            "name": "贷款需求",
                            "url": "main_todo_loan_demand_div",
                            "describe": null,
                            "status": true,
                            "order": 9,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737712662359576576",
                            "parentId": "737711716325916672",
                            "name": "出栏解押",
                            "url": "main_todo_out_column_sign_div",
                            "describe": null,
                            "status": true,
                            "order": 10,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        },
                        {
                            "id": "737712808216494080",
                            "parentId": "737711716325916672",
                            "name": "承保",
                            "url": "main_todo_insured_div",
                            "describe": null,
                            "status": true,
                            "order": 11,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        }
                    ],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                },
                {
                    "id": "732756092085338112",
                    "parentId": "698616113201676288",
                    "name": "首页_新视频监控div",
                    "url": "main_iot_monitor_div",
                    "describe": null,
                    "status": true,
                    "order": 8,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [
                        {
                            "id": "732756674154074112",
                            "parentId": "732756092085338112",
                            "name": "监控列表页",
                            "url": "iot_monitor_details_page",
                            "describe": null,
                            "status": true,
                            "order": 0,
                            "type": 1,
                            "code": null,
                            "requirePermission": true,
                            "requireConfig": true,
                            "icon": null,
                            "deleteFlag": false,
                            "platform": 3,
                            "children": [],
                            "resources": [],
                            "resourceCodes": null,
                            "fatherColumSelect": null
                        }
                    ],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                }
            ],
            "resources": [],
            "resourceCodes": null,
            "fatherColumSelect": null
        },
        {
            "id": "704841066255220736",
            "parentId": "3",
            "name": "首页",
            "url": "slaughter_main",
            "describe": "屠宰场首页",
            "status": true,
            "order": 1,
            "type": 1,
            "code": null,
            "requirePermission": true,
            "requireConfig": true,
            "icon": null,
            "deleteFlag": false,
            "platform": 3,
            "children": [
                {
                    "id": "704841182735237120",
                    "parentId": "704841066255220736",
                    "name": "首页_屠宰明细页",
                    "url": "slaughter_main_details_div",
                    "describe": null,
                    "status": true,
                    "order": 0,
                    "type": 1,
                    "code": null,
                    "requirePermission": true,
                    "requireConfig": true,
                    "icon": null,
                    "deleteFlag": false,
                    "platform": 3,
                    "children": [],
                    "resources": [],
                    "resourceCodes": null,
                    "fatherColumSelect": null
                }
            ],
            "resources": [],
            "resourceCodes": null,
            "fatherColumSelect": null
        }
    ]

    public defaultProps:any = {
        expandTrigger:"hover",
        children: 'children',
        label: 'bankName',
    }

    public Levellist:any[] = [
        {
            id:"",
            name:"请选择",
        },
        {
            id:"1",
            name:"总行",
        },
        {
            id:"2",
            name:"分行",
        },
        {
            id:"3",
            name:"支行",
        },
    ]

    //ruler
    public Addrules:any = {
        bankName: [
            { required: true, message: '请输入银行名称', trigger: ['change' , 'blur'] }
        ],
        parentId:[
            { required: true, message: '请选择所属上级', trigger: ['change' , 'blur'] }
        ],
        groupCode:[
            { required: true, message: '请选择区域', trigger: ['change' , 'blur'] }
        ],
        bankLevel:[
            { required: true, message: '请选择机构分支', trigger: ['change' , 'blur'] }
        ],
        enableFlag:[
            { required: true, message: '请选择业务状态', trigger: ['change' , 'blur'] }
        ]
    }

}