import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';

export interface BenchmarkSetManageFrom<T=string> {
    farmId: T;
    houseId: T;
    columnId: T;
    labelNumber: T;
    markId: T;
}

export interface BenchmarkSetManageTableData {
    columnName: string;
    farmName: string;
    h0: number;
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    h5: number;
    h6: number;
    h7: number;
    h8: number;
    h9: number;
    h10: number;
    h11: number;
    h12: number;
    h13: number;
    h14: number;
    h15: number;
    h16: number;
    h17: number;
    h18: number;
    h19: number;
    h20: number;
    h21: number;
    h22: number;
    h23: number;
    houseName: string;
    id: string;
    labelNumber: string;
    markId: string;
}

export class BenchmarkSetManage extends BasicsClass{
    public getTable(from: BenchmarkSetManageFrom, page: HandleTable, cb: Function) {
        this.getTableList(from, page, '/animal/web/modelBase/list', cb);
    }

    public update(markId: string, time: string, temperature: number, cb: Function) {
        this.BasicPost('/animal/web/modelBase/updateSingle', {
            [time]: temperature,
            markId,
        }, true, true, true, cb);
    }
}
