

























































import {Component, Prop, Vue} from "vue-property-decorator";
import {TypeConfig, TypeConfigTable} from "@/common/Views/basics/typeConfig/typeConfig";
import {AxiosData} from "@/common/http/http";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {MedicineConfig, MedicineConfigTable} from "@/common/Views/basics/medicineConfig/medicineConfig";

@Component
export default class HelloWorld extends Vue {

    private medicineConfig: MedicineConfig;

    constructor() {
        super();
        this.medicineConfig = new MedicineConfig();
    }

    private tableData: any[] = [];
    private activated() {
        this.tableData = [];
        this.getDisease()
        
    }


    private getDisease(){
        let pig:any = []
        let beef:any = []
        let sheep:any = []
        this.tableData = []
        new MedicineConfig().getDisease((data:any) => {
            function arrPush(data:any , arr:any){
                data.forEach((val:any) => {
                    arr.push(val)
                });
            }
            arrPush(data.data[1] , pig)
            arrPush(data.data[2] , beef)
            arrPush(data.data[3] , sheep)


        })
        const that = this
        function tableDataPush(arr:any){
            that.tableData.push(arr)
        }
        tableDataPush(pig)
        tableDataPush(beef)
        tableDataPush(sheep)
        console.log(this.tableData)
        
        
    }

    private append(rowIndex: number) {
        this.tableData[rowIndex].push({
            id: '',
            name: ''
        })
    }

    private remove(rowIndex: number, index: number) {
        this.tableData[rowIndex].splice(index, 1);
    }

    private cancel() {
        this.$router.go(0)
    }

    private submit() {
        this.medicineConfig.saveDisease(this.tableData, ()=>{
            this.getDisease()
        })
        
    }

}
