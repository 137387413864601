


















import {Watch , Prop , Component , Vue} from 'vue-property-decorator'
import {Util} from "@/common/Views/comm/util";
import { Column, ComboList, Result } from "@/common/Views/system/column/column";
import { List } from 'vxe-table';
@Component({})
export default class UpLoadImgComponentListVue extends Vue{
    @Prop() ShowImgUrlList:any
    private imgurl:any[]=[] //显示的图片数据
    private fileList:any =  [] //回显图片列表
    private IsVideo:boolean =false
    private handleChangeOne(file:any,fileList:any){ //文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
        let nameArr:any[] =  file.name.split('.')
        
        if(file.raw.type.split('/')[0] =='video' && file.size / 1024 / 1024 > 500){
            this.$message('请上传低于500MB大小的或视频');
            fileList.pop()
            return
        }
        if(file.raw.type.split('/')[0] =='image' && file.size / 1024 / 1024 > 30){
            this.$message('请上传低于30MB大小的图片');
            fileList.pop()
            return
        }
        //以上两个判断大小

        if (nameArr[nameArr.length-1] === "jpg" || nameArr[nameArr.length-1] === "png" || nameArr[nameArr.length-1] === "mp4" || nameArr[nameArr.length-1] === "avi" || nameArr[nameArr.length-1] === "flv"){
            //判断  是否是指定格式的图片或者视频 如果是  那么执行
            fileList.pop()
            this.IsVideo = false
        }else{
            Util.showToast("请上传jpg或png格式图片或者mp4,avi,flv格式视频", "warnning");
            fileList.pop()
            return
        }
        //以上判断判断 格式

        this.putObject1(file , fileList);

        // if (file.raw.type.split('/')[0] == 'image' || 'video') {
        //     if(file.raw.type.split('/')[0] == 'video'){
        //         let nameArr:any[] = file.name.split('.')
        //         if(nameArr[nameArr.length-1] === "mp4" || nameArr[nameArr.length-1] === "avi" || nameArr[nameArr.length-1] === "flv"){
        //             this.IsVideo = true
        //             Util.showToast("请上传mp4,avi,flv格式视频", "warnning");
        //             return
        //         }
                
        //     }else{
        //         if (nameArr[nameArr.length-1] === "jpg" || nameArr[nameArr.length-1] === "png"){
        //             this.IsVideo = false
        //         }else{
        //             Util.showToast("请上传jpg或png格式图片", "warnning");
        //             return
        //         }
        //     }
        //     this.putObject1(file , fileList);
        // } else {
        //     Util.showToast("请上传图片或视频文件", "warnning");
        //     return
        // }
    }

    private beforeRemove = (file:any , fileList:any)=>{ //删除
        this.imgurl = []
        setTimeout(() =>{
            fileList.forEach((item:any) => {
                this.imgurl.push(item.url)
            });
            this.$emit('SetNewImgVideoUrlList' , this.imgurl)
        })
    }


    

    private ossconfigdata:any ={} //oss 参数对象
    private ossconfig(): void {//获取oss参数
        new Column().ossconfig((res: any) => {
            this.ossconfigdata = res.data;
        });
    }
    private async putObject1(file: any,fileList:any[]) {
        fileList.pop() //删除最后一位
        const loading = this.$loading({
            lock: true,
            text: '请稍后...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
        let OSS = require("ali-oss");
        let client = new OSS({
            region: "oss-cn-hangzhou",
            accessKeyId: this.ossconfigdata.accessKeyId,
            accessKeySecret: this.ossconfigdata.accessKeySecret,
            stsToken: this.ossconfigdata.securityToken,
            bucket: this.ossconfigdata.bucketName,
            timeout: 1000 * 60 * 10,      // 请求超时取消时间
        });
        try {
        const result = await client.put(
            this.ossconfigdata.bucketName +
            "/" +
            this.ossconfigdata.directory +
            this.ossconfigdata.userId +
            "/" +
            file.name,
            file.raw
        );
        if(this.IsVideo){
            this.imgurl.push(result.url)
            fileList.push({url:result.url + '?x-oss-process=video/snapshot,t_1000,f_png,w_0,h_0',uid: Math.random()*1000})
        }else{
            this.imgurl.push(result.url)
            fileList.push({url:result.url,uid: Math.random()*1000})
        }
        
        loading.close();
        } catch (e) {
            console.log(e);
            loading.close();
        }
    }

    @Watch('imgurl',{deep:true})
    private changeImgUrlList(newVal:any[]){
        if(newVal.length == 5){
            document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'none'
        }
        if(newVal.length < 5){
            document.getElementsByClassName('el-upload--picture-card')[0].style.display = 'block'
        }
        this.$emit('SetNewImgVideoUrlList' , newVal)
    }
    @Watch('ShowImgUrlList',{immediate:true,deep:true})
    private changeShowImgUrlList(newVal:any[]){
        this.fileList = []
        if(newVal == undefined) return
        this.imgurl = newVal
        //判断是视频还是图片 如果是视频  那么就加上 
        for(let i:number = 0; i<newVal.length; i++){
            console.log()
            let urlArr:any[] = newVal[i].split('.')
            if(urlArr[urlArr.length-1] == 'png' || urlArr[urlArr.length-1] == 'jpg'){
                this.fileList.push({
                    url:newVal[i],
                    uid: Math.random()*1000
                })
            }else{
                this.fileList.push({
                    url:newVal[i] + '?x-oss-process=video/snapshot,t_1000,f_png,w_0,h_0',
                    uid: Math.random()*1000
                })
            }
        };
    }
    
    activated(){
        this.ossconfig()
        console.log(this.$route.path == '/AddForageRackConfiguration') 
    }
}
