

















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Column, Result } from "@/common/Views/system/column/column";
import {Axios} from "@/common/http/http";
import {DeepLoop, floatJson} from "@/common/deepLoop/deepLoop";
import {Rule} from "@/common/Views/comm/formRule";
import {Util} from "@/common/Views/comm/util";

interface form<T> {
    name: T;
    url: T;
    order: T;
    fatherColumSelect?: string[];
    icon: T;
}


interface resource {
    id: string;
    name: string;
    url: string;
    describe: string;
    // status: string;
    _XID?: string;
}

@Component
export default class HelloWorld extends Vue {

    private defaultParenId = '2';
    private showMain: boolean = false;
    private addColum(): void {
        this.imgList = []
        this.form.icon = ''
        this.activeName = 'user';
        this.showMain = true;

        this.resetForm();
        this.form.fatherColumSelect = this.getParentId();

        this.id = '';
    }

    private resetForm(): void {
        // 重置
        this.form = new Util().clearObject(this.form);
        this.form.fatherColumSelect = [this.defaultParenId];
        this.$forceUpdate();

    }
    
    private axios: Axios;
    constructor() {
        super();
        this.axios = new Axios();
    }

    private treeData: Result[] = [];

    private form: form<string> = {
        name: '',
        url: '',
        order: '',
        fatherColumSelect:[this.defaultParenId],
        icon: '',
    };

    private fatherColumn: Result[] = [];
    private activated(): void {
        this.getTree();
        this.imgList = []
        this.form.icon = ''
    }

    private flatJson: floatJson = {};

    private getTree(): void {
        if(!this.$getResourceCodes('menu_tree')) return;
        const column: Column = new Column();
        column.getTreeData('/animal/web/menu/treeList', 2, 2).then((result: Result[])=>{
            this.treeData = result;

            const deepCopy: DeepLoop = new DeepLoop();
            const fatherData = deepCopy.deepCopy(this.treeData);
            fatherData.unshift({
                id: this.defaultParenId,
                name: '无',
                parentId: this.defaultParenId
            });

            this.fatherColumn = fatherData;

            this.flatJson = deepCopy.deepFlatJson(this.fatherColumn, 'id', 'parentId');

        }).catch(err=>{
            this.$message.error(err.message);
        });
    }



    private imgList: any = []
    private dialogVisible: boolean = false
    private handlePictureCardPreview(file: any): void {
        this.form.icon = file.url;
        this.dialogVisible = true;
    }
    private setImg(file: any, fileList: any): void {
        var that = this
        const reader = new FileReader()
        let res = reader.readAsDataURL(file.raw)
        reader.onloadend = function() {
            // var strBase64 = reader.result.substring(84);
            var strBase64 = (<string>reader.result).substring(0);
            console.log(strBase64);
            that.form.icon = strBase64
        }
        reader.onload = function(e) {
            console.log(e);
            // simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc   
        };

    };
    private handleRemove(file: any): void {
        console.log(file);
        this.form.icon = ''
        this.imgList = []
        this.form.icon = ''
    };





    private rules: form<Rule[]> = {

        name: [
            {required: true, message: '请输入栏目名称', trigger:'blur'},
        ],
        url: [
            {message: '请输入栏目路径', trigger:'blur'},
        ],
        order: [
            {required: true, message: '请输入栏目排列序号', trigger:'blur'},
            { type: 'number', message: '栏目排列序号必须为数字'}
        ],

        // icon: [
        //     {required: true, message: '上传ICON', trigger:'blur'}
        // ],
    }

    private activeName: string = 'user';

    private getData(data: Result): void {
        this.showMain = true;
        this.imgList = []
        this.form.icon = ''
        // this.form.name = data.name;
        // this.form.url = data.url;
        // this.form.order = data.order;
        this.form = {
            ...data
        }
        if(data.icon){
            this.imgList.push({name: "1", url: data.icon})
            this.form.icon = data.icon
        }
        
        console.log(data)

        this.id = data.id;

        this.form.fatherColumSelect = this.getParentId(true);

        this.resourceTableData = data.resources;


    }

    private getParentId(isPop: boolean = false): string[] {

        let fatherColumSelect = [this.defaultParenId];

        if(this.id !== ''){
            const column: Column = new Column();
            fatherColumSelect = column.getParentId(this.flatJson, this.id, this.defaultParenId);

            if(isPop) fatherColumSelect.pop();
        }

        if(fatherColumSelect.length === 0) fatherColumSelect = [this.defaultParenId];

        return  fatherColumSelect;
    }


    // 栏目管理
    private save(): void {
        (this.$refs.ruleForm as any).validate((valid: any)=>{
            if(valid){

                this.handleColumn();

            }
        })
        console.log(this.imgList)
    }

    private handleColumn(): void {
        const url = this.getUrl(this.id);
        this.axios.post<Result>(url, {
            ...this.form,
            type: this.form.fatherColumSelect!.toString() === this.defaultParenId ? 1 : 2,
            parentId: this.form.fatherColumSelect![this.form.fatherColumSelect!.length - 1],
            id: new Util().getId(this.id),
            platform: 2,
        }, true).then(data=>{
            this.$message.success(data.message);

            this.showMain = false;
            this.getTree();

        }).catch(err=>{
            this.$message.error(err.message);
        })
    }

    private remove(): void {

        this.delResource(this.id);

    }

    private delResource(id: string): void {

        this.$confirm('删除栏目将无法恢复，相关子栏目、权限配置也将同步删除！是否确定删除该栏目？','提示',{
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(()=>{
            this.axios.post<Result>('/animal/web/menu/delete',{
                id: id,
                platform: 2,
            }).then((data)=>{

                this.$message.success(data.message);
                this.resourceTableData = data.data? data.data.resources : [];
                this.getTree();

                this.showMain = false;

            }).catch(err=>{
                this.$message.error(err.message);
            })
        })

    }

    // 栏目管理end


    // 资源控制

    private resourceTableData: Result[] = [];


    private insertEvent(): void {
        (this.$refs.xTable as any).insert()
            .then(({
                       row
                   }: any) => {
                (this.$refs.xTable as any).setActiveRow(row, 'resourceName');
                (this.resourceTableData as any).unshift(row);
            })

    }

    private alterRow(row: resource) {
        (this.$refs.xTable as any).setActiveRow(row);
        // this.on = true;
    }

    private handleData(row: resource): void {
        
        (this.$refs.xTable as any).clearActived().then(() => {
            this.handleResource(row);
        })


    }

    private handleResource(row: resource): void {
        const url = this.getUrl(row.id);

        this.axios.post<Result>(url, {
            id: new Util().getId(this.id),
            ...row,
            type: 3,
            platform: 2,
            parentId: this.getParentId(false)[this.getParentId(false).length - 1],
        }, true).then(data=>{

            this.$message.success(data.message);
            this.resourceTableData = data.data? data.data.resources : [];
            this.getTree();

        }).catch(err=>{
            this.$message.error(err.message);
        })
    }

    private removeResource(row: resource, rowIndex: number): void {

        if (!row.id) {
            this.resourceTableData.splice(rowIndex, 1);
        } else {

            this.delResource(row.id);

        }
    }

    //end

    private id: string = '';

    private getUrl(data: string): string {
        return data ? '/animal/web/menu/update' : '/animal/web/menu/insert';
    }


}
