import {Axios, AxiosData} from '@/common/http/http';
import {BasicsClass} from '@/common/BasicsClass';

export class Power extends BasicsClass{
    private axios: Axios;
    constructor(){
        super();
        this.axios = new Axios();
    }

    public save(rolesId: string, resourceIds: Array<string>, platform: number = 1) {
        this.axios.post('/animal/web/role/savePermission', {
                roleId: rolesId,
                menuIds: resourceIds.join(','),
                platform
            }).then(data=>{
                this.vue.$message.success(data.message);
        }).catch(err=>{
            this.vue.$message.error(err.message);
        });

    }

    public save2(rolesId: string, resourceIds: Array<string>, platform: number = 3) {
        this.axios.post('/animal/web/role/savePermission', {
                roleId: rolesId,
                menuIds: resourceIds.join(','),
                platform
            }).then(data=>{
                this.vue.$message.success(data.message);
        }).catch(err=>{
            this.vue.$message.error(err.message);
        });

    }
}
