

























































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import { Column, ComboList, Result } from "@/common/Views/system/column/column";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
import { ConfType } from "@/common/Views/system/confType/confType";
import {
  ImmuneRecord,
  ImmuneRecordForm,
} from "@/common/Views/traceabilityOutManage/immuneRecord";
import { lastEachIterate } from "node_modules/_xe-utils@2.8.3@xe-utils/methods";

import { quillEditor } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Oss from "ali-oss"
@Component({
  quillEditor,
})
export default class HelloWorld extends Vue {
  private form: any = {
    id: "",
    insuranceBusinessName: "",
    insuranceCompanyId: "",
    insuranceName: "",
    confId: 0,
    groupCode: "",
    groupCodeList: [],
    govPayment: "",
    individualPayment: "",
    productDetails: "",
    introduction: "",
    businessInfoList: [
      // {
      //   // typeInfoId: "",
      //   // typeInfoName: "",
      //   // weightStart: "",
      //   // weightEnd: "",
      //   // insuredAmount: "",
      //   // payAmount: "",
      //   // type: "",
      // },
    ],
  };
  private index2: number = -1;
  private upone: number = 0;
  private uptwo: number = 0;
  private toolbarOptions: any = [
    ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
    // ["blockquote", "code-block"], //引用，代码块
    [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
    [{ list: "ordered" }, { list: "bullet" }], //列表
    [{ script: "sub" }, { script: "super" }], // 上下标
    // [{ indent: "-1" }, { indent: "+1" }], // 缩进
    // [{ direction: "rtl" }], // 文本方向
    [
      {
        size: [
          "12px",
          "14px",
          false,
          "18px",
          "22px",
          "26px",
          "30px",
          "36px",
          "42px",
        ],
      },
    ],
    // [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
    [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
    [{ font: [] }], //字体
    [{ align: [] }], //对齐方式
    // ["clean"], //清除字体样式
    ["image", "video"], //上传图片、上传视频
  ];
  private editorOption: any = {
    placeholder: "请在这里输入",
    modules: {
      toolbar: {
        container: this.toolbarOptions,
        handlers: {
          image: function (value) {
            if (value) {
              document.querySelector("input[name='file']").click();
            } else {
              this.quill.format("image", false);
            }
          },
        },
      },
    },
  };

  private editorOption2: any = {
    placeholder: "请在这里输入",
    modules: {
      toolbar: {
        container: this.toolbarOptions,
        handlers: {
          image: function (value) {
            if (value) {
              document.querySelector("input[name='file2']").click();
            } else {
              this.quill.format("image", false);
            }
          },
        },
      },
    },
  };
  private weightStart: any = "";
  private weightEnd: any = "";
  private name: any = "";
  private input: any = "";
  private input1: any = "";
  private input2: any = "";
  private value: any = "";
  private value1: any = "";
  // private type: any = "0";
  private companyList: any = [];
  private conTypeList: any = [];
  private farmId: string = "";
  private treeData: Result[] = [];
  public column = new Column();
  private flag: number = 1;

  private rules: any = {
    confId: [{ required: true, message: "请选择险种" }],
    insuranceCompanyId: [{ required: true, message: "请选择保险公司" }],
    insuranceName: [{ required: true, message: "请输入保险产品名称" }],
    groupCodeList: [{ required: true, message: "请选择承保范围" }],
    govPayment: [{ required: true, message: "请输入缴费比例" }],
    individualPayment: [{ required: true, message: "请输入缴费比例" }],
  };
  private getCompany(): void {
    new Column().getCompany((res: any) => {
      this.companyList = res.data;
    });
  }
  private confType(): void {
    this.conTypeList = [];
    new ConfType().getSelectAll(this.farmId, (data: any) => {
      this.conTypeList = data.data;
      console.log(data);
    });
  }
  private getTree(start: any) {
    console.log(start);
    this.column.getTissuc2(undefined, this.flag, (res: any) => {
      // console.log(res, 11111)
      this.treeData = res.data;
    });
  }

  private ossconfigdata: any = {};
  //获取oss参数
  private ossconfig(): void {
    new Column().ossconfig((res: any) => {
      this.ossconfigdata = res.data;
    });
  }

  public resGroup: Result[] = [];

  public handleCascader(start: any): void {
    if (start == true) {
      console.log(this.resGroup.length);
      if (this.resGroup.length == 0) {
        const column: Column = new Column();
        this.resGroup = this.treeData;
        this.column.getTissuc2(undefined, this.flag, (res: any) => {
          // console.log(res, 11111)
          this.treeData = res.data;
        });
      }
      if (this.treeData[0].id == this.resGroup[0].id) {
        const column: Column = new Column();
        this.resGroup = this.treeData;
        this.column.getTissuc2(undefined, this.flag, (res: any) => {
          // console.log(res, 11111)
          this.treeData = res.data;
        });
      }
    } else {
      console.log(this.form.groupCodeList);
      if (this.form.groupCodeList[0] != "0") {
        this.treeData = this.resGroup;
        this.form.groupCodeList.push(this.resGroup[0].id);
      }
    }
  }

  private created(): void {
    // this.getTree();
    this.getCompany();
    this.confType();
  }
  @Watch("$route", { immediate: true })
  public handleRoute() {
    if (this.$route.query.pid) {
      this.form.groupCodeList.length = 0;
      new Column().getDetail((res: any) => {
        let str = JSON.stringify(res.data.groupCodeList);
        this.treeData = JSON.parse(str);
        console.log(this.treeData);

        this.form.groupCodeList.push(this.treeData[0].id);
        console.log(this.form.groupCodeList);
        this.form.id = res.data.id;
        this.form.insuranceBusinessName = res.data.insuranceBusinessName;
        this.form.insuranceCompanyId = res.data.insuranceCompanyId;
        this.form.insuranceName = res.data.insuranceName;
        // this.form.confId = res.data.,
        // this.form.groupCode = res.data.groupCode;
        (this.form.individualPayment = res.data.individualPayment),
          (this.form.govPayment = res.data.govPayment);
        this.form.productDetails = res.data.productDetails;
        this.form.introduction = res.data.introduction;
        this.form.businessInfoList = res.data.businessInfoList;

        this.form.confId = res.data.confId.toString();
        console.log(this.form);
      }, this.$route.query.pid);
    } else {
      console.log(1);
      this.form = {
        id: "",
        insuranceBusinessName: "",
        insuranceCompanyId: "",
        insuranceName: "",
        confId: "0",
        groupCode: "",
        groupCodeList: [],
        govPayment: "",
        individualPayment: "",
        productDetails: "",
        introduction: "",
        businessInfoList: [
          // {
          //   // typeInfoId: "",
          //   // typeInfoName: "",
          //   // weightStart: "",
          //   // weightEnd: "",
          //   // insuredAmount: "",
          //   // payAmount: "",
          //   // type: "",
          // },
        ],
      };
    }
  }
  private insurancename(data: any): void {
    console.log(data);
    for (let i in data) {
      if (data[i].id == this.form.insuranceCompanyId) {
        this.form.insuranceBusinessName = data[i].insuranceCompanyName;
      }
    }
  }

  private onSubmit(): void {
    this.$refs.form.validate((valid) => {
      if (valid) {
        if (this.uptwo == 1) {
          Util.showToast("请填写完整", "warning");
          return;
        }
        if (this.form.productDetails == "" && this.form.introduction == "") {
          Util.showToast("请填写投保介绍,请填写赔付说明", "warnning");
          return;
        }
        if (this.form.productDetails == "") {
          Util.showToast("请填写赔付说明", "warnning");
          return;
        }
        if (this.form.introduction == "") {
          Util.showToast("请填写投保介绍", "warnning");
          return;
        }
        this.form.groupCode =
          this.form.groupCodeList[this.form.groupCodeList.length - 1];
        console.log(this.form);
        if (this.form.id == "") {
          new Column().addConfig(
            (res: any) => {
              console.log(res);
              this.$router.go(-1);
            },
            { ...this.form, groupCodeList: undefined }
          );
        } else {
          new Column().editConfig(
            (res: any) => {
              console.log(res);
              this.$router.go(-1);
            },
            { ...this.form, groupCodeList: undefined }
          );
        }
      }
    });
  }

  private updata(data: any): void {
    // console.log(data.seq)
    // if(this.index==data.seq){

    // }

    this.value = data.row.typeInfoId;
    this.input = data.row.startNum;
    this.upone = 1;
  }

  private addconfig2(): void {
    if (this.form.businessInfoList.length > 0) {
      if (this.form.businessInfoList[0].typeInfoId != "") {
        if (this.uptwo == 1) {
          Util.showToast("请填写完整", "warning");
          return;
        }
        this.uptwo = 1;
        this.form.businessInfoList.unshift({
          typeInfoId: "",
          weightStart: "",
          weightEnd: "",
          insuredAmount: "",
          payAmount: "",
        });
        this.index2 = 1;
      } else {
        Util.showToast("请填写完整", "warning");
        return;
      }
    } else {
      this.uptwo = 1;
      this.index2 = 1;
      this.form.businessInfoList.unshift({
        typeInfoId: "",
        weightStart: "",
        weightEnd: "",
        insuredAmount: "",
        payAmount: "",
      });
    }
  }
  private save2(data: any): void {
    console.log(this.input,this.input1,this.input2)
    if (
      this.value1 != "" &&
      this.input != "" &&
      this.input1 != "" &&
      this.input2 != ""
    ) {
      this.index2 = -1;
      this.uptwo = 0;
      this.form.businessInfoList[data.seq - 1].typeInfoId = this.value1;
      this.form.businessInfoList[data.seq - 1].startNum = this.input;

      this.form.businessInfoList[data.seq - 1].insuredAmount = this.input1;
      this.form.businessInfoList[data.seq - 1].payAmount = this.input2;
      // this.form.businessInfoList[data.seq - 1].type = this.type;
      for (let i in this.conTypeList) {
        if (this.conTypeList[i].id == this.value1) {
          this.form.businessInfoList[data.seq - 1].typeInfoName =
            this.conTypeList[i].name;
        }
      }
      this.input = "";
      this.input1 = "";
      this.input2 = "";
      this.value1 = "";
      this.weightStart = "";
      this.weightEnd = "";
    } else {
      Util.showToast("请完成编辑", "warning");
      return;
    }
  }

  private updata2(data: any): void {
    console.log(data);
    this.uptwo = 1;
    this.index2 = data.seq;
    this.input = data.row.startNum;
    this.input1 = data.row.insuredAmount;
    this.input2 = data.row.payAmount;
    this.value1 = data.row.typeInfoId;
    this.weightStart = data.row.weightStart;
    this.weightEnd = data.row.weightEnd;
    // this.type = data.row.type.toString();
  }

  private deldata2(data: any): void {
    console.log(data);
    // if (this.uptwo == 1) {
    //   Util.showToast("请完成编辑", "warning");
    //   return;
    // }
    this.input = "";
    this.input1 = "";
    this.input2 = "";
    this.value1 = "";
    this.weightStart = "";
    this.weightEnd = "";
    this.form.businessInfoList.splice(data.seq - 1, 1);
    this.uptwo = 0;
  }
  private mounted(): void {
    document.getElementsByClassName("ql-container")[0].style.height = "2rem";
    document.getElementsByClassName("ql-container")[1].style.height = "2rem";
    this.ossconfig()
  }

  private change(file: any, fileList: any): void {
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      // this.getbase64(file);
      this.putObject(file, 'myQuillEditor')
    } else {
      Util.showToast("请上传图片文件", "warnning");
    }
  }
  private change2(file: any, fileList: any): void {
    if (file.raw.type === "image/jpeg" || file.raw.type === "image/png") {
      // this.getbase642(file);
      this.putObject(file, 'myQuillEditor2')
    } else {
      Util.showToast("请上传图片文件", "warnning");
    }
  }

  private getbase64(file: any): void {
    const reader = new FileReader();
    let data = reader.readAsDataURL(file.raw);
    reader.onloadend = () => {
      const strBase64 = (<string>reader.result).substring(0);
      // this.putObject(file)
      // new Column().uploadImage((res: any) => {
      //   let quill = this.$refs.myQuillEditor.quill;
      //   let length = quill.getSelection().index;
      //   quill.insertEmbed(length, "image", res.data.url);
      //   quill.setSelection(length + 1);
      // }, strBase64);
    };
  }

  private async putObject(file: any, domRef: string): void {
    let client = new Oss({
      region: "oss-cn-hangzhou",
      accessKeyId: this.ossconfigdata.accessKeyId,
      accessKeySecret: this.ossconfigdata.accessKeySecret,
      stsToken: this.ossconfigdata.securityToken,
      bucket: this.ossconfigdata.bucketName,
    });
    let ossArguments = `${this.ossconfigdata.bucketName }/${this.ossconfigdata.directory}/${this.ossconfigdata.userId}/${file.name}`
    let result = await client.put(ossArguments, file.raw)
    console.log(result)
    let quill = this.$refs[domRef].quill
    let length = quill.getSelection().index;
    console.log(result.url)
    quill.insertEmbed(length, "image", result.url);
    quill.setSelection(length + 1);
  }

  private getbase642(file: any): void {
    const reader = new FileReader();
    let data = reader.readAsDataURL(file.raw);
    reader.onloadend = () => {
      const strBase64 = (<string>reader.result).substring(0);
      new Column().uploadImage((res: any) => {
        let quill = this.$refs.myQuillEditor2.quill;
        let length = quill.getSelection().index;
        quill.insertEmbed(length, "image", res.data.url);
        quill.setSelection(length + 1);
      }, strBase64);
    };
  }
}
