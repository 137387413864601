



















































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import RoleVue from "./components/Role.vue";
import SetBtnVue from "./components/SetBtnVue.vue";

import { appUser, appAddUser } from "./components/appAll";

import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { FarmDetail } from "@/common/Views/system/farm/farmDetail/farmDetail";
import { groupCodeLazy } from "./components/groupCodeLazy";
import { AppUser } from "@/api/SystemModule/index";
@Component({
    name: "NewAppAddUser",
    components: { RoleVue, SetBtnVue },
})
export default class NewAppAddUser extends Vue {
    private form: any = {
        roleId: "1",
        username: "",
        mobile: "",
        companyId: "",
        groupCode: [],
        farmIds: [],
        hasAllData: 0, //1代表全部  0 个别
        status: "1",
        id: "", //用户id
    };

    private defaultProps: any = {
        children: "childList",
        label: "bankName",
    };
    private getKeyList(data: any, checkData: any) {
        //315选择后将选择的id数组赋值给对象
        this.CheckBankList = checkData.checkedKeys;
    }
    private roleGroupDialogVisible = false; //控制查看隶属关系弹窗
    private groupCodeLazy = new groupCodeLazy();
    private isBankTye = false;
    private centerDialogVisible = false;
    private CheckBankList: string[] = [];

    //权限角色
    private UserNameData: any[] = [];
    //设置权限角色Id
    private SetUserNameId(newVal: string) {
        console.log(newVal);
        this.form.roleId = newVal;
    }

    //姓名   v3.0.7新需求 不再需要校验

    //手机号
    private blurMobile() {
        //校验手机号
        // if(this.form.mobile.length > 10){
        //     new appAddUser().repeatNum(this.form.mobile , (data:any) => {})
        // }
    }

    //关联企业
    private linkEnterpriseText: string = this.$t("common.placeholder.select");
    private noneEnter: boolean = true;
    //关联企业渲染的数据数组
    private linkEnterpriseData: any[] = [];
    private showRoleGroug: boolean = true;
    //关联企业发生变化的时候
    private enterpriseChange() {
        if (this.form.companyId == this.SetData.companyId) {
            this.showRoleGroug = true;
        } else {
            this.showRoleGroug = false;
        }
        //请求组织机构
        this.getGroupCodeData();
        //清空组织机构
        this.RoleIdChangeClearOtherData();
    }

    //组织机构
    private GroupCodeData: any[] = [];
    private LinkGroupText: string = this.$t("system.web_user.farmIds");
    private linkGrounpPlaceHolderText: string = this.$t("system.web_user.wu");
    //组织机构隐藏 整个div
    private NoneGroupCodeDiv: boolean = true;
    //组织机构 清空数据用的 key
    private CascaderKey: number = 1;
    private linkGroupCodeHandleChange() {
        this.getFarmListData(); //请求养殖场数据
    }

    //选择养殖场
    private FarmDataList: any[] = [];
    private isMultiple: boolean = true; //默认是 true  开启多选  如果是银行保险  那么变为false
    private FarmNamePlaceHolder: string = this.$t("system.app_user.search");
    private changeFarmId(e: any) {
        if (this.isMultiple) {
            //判断是否是多选
            //判断已选的数组最后一个id 是否为 0 如果为 0  清除数组中其他的id
            if (e[e.length - 1] == "0") this.form.farmIds = ["0"];
            // 先判断 数组是否大于1  如果最后一个id  不为0  那么清除数组中 为0 的id
            if (e[1] && e[e.length - 1] != "0")
                if (e.includes("0"))
                    this.form.farmIds.splice(e.indexOf("0"), 1);
            // 判断 除了全部 全部都选了  那么那清除数组 让 数组 id = 0
        }
    }

    //启用状态
    private StatusList: any[] = [
        { value: "1", label: this.$t("system.app_user.status_0") },
        { value: "2", label: this.$t("system.app_user.status_1") },
        {
            value: "3",
            label: this.$t("system.app_user.status_2"),
            disabled: true,
        }
    ];

    //请求权限角色接口
    private getUserNameData() {
        new appUser().getUserName(false,(data: any) => {
            this.UserNameData = data.data;
            if (this.SetData.bossFlag && this.SetData.roleGroup) {
                this.setBossCheck(this.SetData.roleId);
            } else if (this.SetData.roleGroup) {
                this.setRoleGroupType();
            }
        });
    }

    //单独老板选中选中
    private setBossCheck(id: any) {
        //循环遍历this.UserNameData 当id与roleId相等时，设置roleGroupType为true
        this.UserNameData.forEach((item: any) => {
            if (item.roleId === id && item.roleGroup) {
                item.roleGroup = true;
            } else {
                item.roleGroup = false;
            }
        });
    }
    //根据roleGroup相当时，设置roleGroupType为true
    private setRoleGroupType() {
        this.UserNameData.forEach((item: any) => {
            if (
                item.roleGroup === this.SetData.roleGroup &&
                item.roleGroup &&
                item.roleId != "20" &&
                item.roleId != "101"
            ) {
                console.log(this.SetData.roleId, "this.SetData.roleId");
                if (item.roleId == "100" && this.SetData.isInternational) {
                    console.log(123123);
                    item.roleGroupType = true;
                } else if (
                    item.roleId != "100" &&
                    !this.SetData.isInternational
                ) {
                    item.roleGroupType = true;
                }
            } else {
                item.roleGroupType = false;
            }
        });
    }

    //请求关联企业数据接口
    private getLinkEnterPriseData() {
        new appAddUser().getLinkEnterprise(this.form.roleId, (data: any) => {
            this.linkEnterpriseData = data.data;
        });
    }
    private ActivatedgetLinkEnterPriseData() {
        new appAddUser().getLinkEnterprise(this.SetData.roleId, (data: any) => {
            this.linkEnterpriseData = data.data;
            this.$set(this.form, "companyId", this.SetData.companyId);
        });
    }
    private bankList = [];
    //请求银行数据
    private getBankListData(id: string, groupCode: string | number) {
        new AppUser().getBankList(id, groupCode, (res: any) => {
            //新增银行接口
            this.bankList = res.data;
            console.log(123123);
            this.getBindBankId(res.data); //获取用户选择的id
        });
    }

    private getBankIdList: string[] = [];
    private getBindBankId(data: any[]) {
        //获取后台返回的bankid
        for (let i = 0; i < data.length; i++) {
            //循环 传入数据
            if (data[i].ifBind) {
                //对传入数据 进行判断 为真 就把银行id push
                this.getBankIdList.push(data[i].bankId);
                this.CheckBankList.push(data[i].bankId);
            }
            if (data[i].childList) {
                //如果为真 那么就 递归 将当前数组传入
                this.getBindBankId(data[i].childList);
            }
        }
    }
    //请求养殖场组织机构
    private getGroupCodeData() {
        this.RoleIdChangeClearOtherData();
        new FarmDetail().getCity("0", this.form.roleId, (data: any) => {
            //赋值处理过的数据
            this.GroupCodeData = this.deleteLastChildren(data.data);
            //默认显示中国
            this.form.groupCode = [0];
            //请求养殖场数据
            this.getFarmListData();
        });
    }
    private ActivatedgetGroupCodeData() {
        if (this.form.roleId != 10) {
            new FarmDetail().getCity("", this.form.roleId, (data: any) => {
                //赋值处理过的数据
                this.GroupCodeData = this.deleteLastChildren(data.data);
                this.$nextTick(() => {
                    this.linkGrounpPlaceHolderText = this.SetData.groupName;
                });
                this.ActivatedgetFarmListData();
            });
        }
    }
    //请求养殖场列表数据
    private getFarmListData() {
        this.FarmDataList = [];
        this.form.farmIds = [];
        if (this.form.roleId == "13" || this.form.roleId == "30") {
            this.isMultiple = false; //变为单选
            // new appUser().getBankData(
            //     {
            //         id: this.form.companyId,
            //         groupCode:
            //             this.form.groupCode[this.form.groupCode.length - 1],
            //     },
            //     (res: any) => {
            //         this.ReSetListData(res.data); //处理数据
            //     }
            // );
            this.getBankListData(
                "",
                this.form.groupCode[this.form.groupCode.length - 1]
            );
        } else if (this.form.roleId == "14" || this.form.roleId == "40") {
            this.isMultiple = false; //变为单选
            new appUser().getInsuranceData(
                {
                    id: this.form.companyId,
                    groupCode: this.form.groupCode[
                        this.form.groupCode.length - 1
                    ],
                },
                (res: any) => {
                    this.ReSetListData(res.data); //处理数据
                }
            );
        } else {
            console.log(this.SetData.roleGroup, "this.SetData.roleGroup");
            this.isMultiple = true; //变为多选
            new appUser().getLinkFarm(
                {
                    id: this.form.companyId,
                    groupCode: this.form.groupCode[
                        this.form.groupCode.length - 1
                    ],
                },
                this.form.roleId,
                (data: any) => {
                    const deepCopy: DeepLoop = new DeepLoop();
                    const fatherData = deepCopy.deepCopy(data.data);
                    if (data.data.length > 0) {
                        fatherData.unshift({
                            farmId: "0",
                            farmName: this.$t("common.placeholder.all"),
                        });
                        this.FarmDataList = fatherData;
                        this.form.farmIds = ["0"];
                    }
                }
            );
        }
    }
    private ActivatedgetFarmListData() {
        this.form.farmIds = [];
        let obj = {
            id: this.SetData.companyId,
            groupCode: this.SetData.groupCode[
                this.SetData.groupCode.length - 1
            ],
        };
        if (this.SetData.roleId == "13" || this.SetData.roleId == "30") {
            this.isMultiple = false; //变为单选
            // new appUser().getBankData({ ...obj }, (res: any) => {
            //     this.ActivatedReSetListData(res.data); //处理数据
            //     this.$set(this.form, "farmIds", this.SetData.bankId);
            // });
        } else if (this.SetData.roleId == "14" || this.SetData.roleId == "40") {
            this.isMultiple = false; //变为单选
            new appUser().getInsuranceData({ ...obj }, (res: any) => {
                this.ActivatedReSetListData(res.data); //处理数据
                this.$set(
                    this.form,
                    "farmIds",
                    this.SetData.insuranceCompanyId
                );
            });
        } else {
            console.log(this.SetData.roleGroup, "this.SetData.roleGroup11111");
            this.isMultiple = true; //变为多选
            if (!this.SetData.roleGroup) {
                console.log(123123);
                new appUser().getLinkFarm(
                    { ...obj },
                    this.form.roleId,
                    (data: any) => {
                        const deepCopy: DeepLoop = new DeepLoop();
                        const fatherData = deepCopy.deepCopy(data.data);
                        if (data.data[data.data.length - 1]) {
                            fatherData.unshift({
                                farmId: "0",
                                farmName: this.$t("common.placeholder.all"),
                            });
                            this.FarmDataList = fatherData;
                        }
                        if (
                            this.SetData.hasAllData == 1 &&
                            this.FarmDataList.length > 0
                        ) {
                            this.$set(this.form, "farmIds", ["0"]);
                        } else {
                            this.SetData.farmList.forEach((item: any) => {
                                this.form.farmIds.push(item.farmId);
                            });
                        }
                    }
                );
            } else {
                console.log(456456);
                new appUser().getLinkFarmCom(this.SetData.id, (data: any) => {
                    const deepCopy: DeepLoop = new DeepLoop();
                    const fatherData = deepCopy.deepCopy(data.data.aboveFarms);
                    console.log(data, "fatherData5666");
                    if (data.data.aboveFarms[data.data.aboveFarms.length - 1] && data.data.showAllData == 1) {
                        fatherData.unshift({
                            farmId: "0",
                            farmName: this.$t("common.placeholder.all"),
                        });
                        
                    }
                    this.FarmDataList = fatherData;
                    if (
                        data.data.hasAllData == 1 &&
                        this.FarmDataList.length > 0
                    ) {
                        this.$set(this.form, "farmIds", ["0"]);
                    } else {
                        data.data.currentFarms.forEach((item: any) => {
                            this.form.farmIds.push(item.farmId);
                        });
                    }
                });
            }
        }
    }
    //规则对象
    private rules: any = {
        username: [
            {
                required: true,
                message: this.$t("system.app_user.enter_name"),
                trigger: ["blur", "change", "input"],
            },
        ],
        mobile: [
            {
                required: true,
                message: this.$t("system.app_user.enter_mobile"),
                trigger: ["blur", "change", "input"],
            },
            // { min:11, max: 11, message: '请输入11位手机号码', trigger: 'blur' }
        ],
        companyId: [
            {
                required: true,
                message: this.$t("system.app_user.Affiliated_enterprise"),
                trigger: ["blur"],
            },
        ],
        groupCode: [
            {
                required: true,
                message: this.$t("system.app_user.select_farm"),
                trigger: ["blur"],
            },
        ],
        farmIds: [
            {
                required: true,
                message: this.$t("system.app_user.select_farm"),
                trigger: ["blur"],
            },
        ],
        status: [
            {
                required: true,
                message: this.$t("system.app_user.select_Enable_status"),
                trigger: ["blur", "change"],
            },
        ],
    };
    //组织机构Props
    private props: any = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "id",
    };

    //监视
    @Watch("form.roleId")
    private changeRoleId(newVal: string) {
        if (newVal == "3" || newVal == "1" || newVal == "60") {
            //如果是屠宰场管理员、养殖企业管理员、合作商管理员 那么  检验更改为 false 其他 true
            this.NoRequired(false);
        } else {
            this.NoRequired(true);
        }
        this.RoleIdChangeGroupCode(newVal); //处理 不同权限角色的函数
        //this.RoleIdChangeClearOtherData(); // 每次切换的时候清楚部分数据
        //每次切换的时候 清空 绑定的值 和 清空渲染的数组
        //this.form.companyId = "";
        //this.linkEnterpriseData = [];
        //内部演示角色
        newVal == "9"
            ? (this.linkEnterpriseText = this.$t(
                  "system.app_user.Demo_account"
              ) as string)
            : (this.linkEnterpriseText = this.$t(
                  "common.placeholder.select"
              ) as string);
        //this.$refs.formRules.resetFields();
    }
    @Watch("form.farmIds")
    private changeFarmIds(newVal: any) {
        //判断 在 已选的数组中 能否找到 id 为 0  如果找到了  那么 this.form.hasAllData = 1 并且数组
        if (Array.isArray(newVal)) {
            if (newVal.includes("0")) {
                this.form.hasAllData = 1;
            } else {
                this.form.hasAllData = 0;
            }
        }
    }

    //处理组织机构树
    private deleteLastChildren(data: any) {
        let treeData: any[] = data;
        treeData.forEach((itemOne: any) => {
            itemOne.children.forEach((itemTwo: any) => {
                itemTwo.children.forEach((itemThree: any) => {
                    itemThree.children.forEach((itemFour: any) => {
                        itemFour.children.forEach((itemFive: any) => {
                            delete itemFive.children;
                        });
                    });
                });
            });
        });
        return treeData;
    }
    //处理养殖场  银行 保险数据
    private ReSetListData(data: any) {
        let newArray: any[] = [];
        data.map((item: any) => {
            let obj = {};
            this.form.roleId == 13 || this.form.roleId == 30
                ? (obj = { farmName: item.bankName, farmId: item.bankId })
                : (obj = {
                      farmName: item.insuranceCompanyName,
                      farmId: item.insuranceCompanyId,
                  });
            newArray.push(obj);
        });
        this.FarmDataList = newArray;
        this.FarmDataList[this.FarmDataList.length - 1]
            ? (this.form.farmIds = this.FarmDataList[0].farmId)
            : (this.form.farmIds = []);
    }
    //处理养殖场  银行 保险数据
    private ActivatedReSetListData(data: any) {
        let newArray: any[] = [];
        data.map((item: any) => {
            let obj = {};
            this.form.roleId == 13 || this.form.roleId == 30
                ? (obj = { farmName: item.bankName, farmId: item.bankId })
                : (obj = {
                      farmName: item.insuranceCompanyName,
                      farmId: item.insuranceCompanyId,
                  });
            newArray.push(obj);
        });
        this.FarmDataList = newArray;
    }
    //处理权限角色切换时的校验信息
    private RestCheckMsg(msg: string) {
        let RulerVc: any = this.$refs.formRules;
        RulerVc.rules.farmIds[0].message = msg;
    }
    //处理屠宰场 养殖场可以不填的 校验
    private NoRequired(flag: boolean) {
        let RulerVc: any = this.$refs.formRules;
        RulerVc.rules.farmIds[0].required = flag;
    }

    //权限角色变化时  组织机构
    private RoleIdChangeGroupCode(newVal: string) {
        //银行、保险、政府、伊禾牧业  隐藏关联企业
        //买家 隐藏组织结构
        switch (newVal) {
            //银行
            case "13":
                this.noneEnter = false; //隐藏关联企业
                this.LinkGroupText = this.$t(
                    "system.app_user.bankIds"
                ) as string;
                this.linkGrounpPlaceHolderText = this.$t(
                    "system.app_user.Bank_organization"
                ) as string;
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.select_bank"
                ) as string;
                this.RestCheckMsg(
                    this.$t("system.app_user.select_bank") as string
                ); //权限角色变化时处理 校验信息
                break;
            //银行
            case "30":
                this.noneEnter = false; //隐藏关联企业
                this.LinkGroupText = this.$t(
                    "system.app_user.bankIds"
                ) as string;
                this.linkGrounpPlaceHolderText = this.$t(
                    "system.app_user.Bank_organization"
                ) as string;
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.select_bank"
                ) as string;
                this.RestCheckMsg(
                    this.$t("system.app_user.select_bank") as string
                ); //权限角色变化时处理 校验信息
                break;
            //保险
            case "14":
                this.noneEnter = false; //隐藏关联企业
                this.LinkGroupText = this.$t(
                    "system.web_user.insuranceCompanyId"
                ) as string;
                this.linkGrounpPlaceHolderText = this.$t(
                    "system.app_user.Bank_organization"
                ) as string;
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.select_insurance"
                ) as string;
                this.RestCheckMsg(
                    this.$t("system.app_user.select_insurance") as string
                ); //权限角色变化时处理 校验信息
                break;
            //保险
            case "40":
                this.noneEnter = false; //隐藏关联企业
                this.LinkGroupText = this.$t(
                    "system.web_user.insuranceCompanyId"
                ) as string;
                this.linkGrounpPlaceHolderText = this.$t(
                    "system.app_user.Bank_organization"
                ) as string;
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.select_insurance"
                ) as string;
                this.RestCheckMsg(
                    this.$t("system.app_user.select_insurance") as string
                ); //权限角色变化时处理 校验信息
                break;
            //屠宰
            case "3":
                this.noneEnter = true; //显示关联企业
                this.LinkGroupText = this.$t(
                    "system.app_user.Affiliated_slaughterhouse"
                ) as string;
                this.linkGrounpPlaceHolderText = this.$t(
                    "system.app_user.Slaughterhouse_organization"
                ) as string;
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.select_slaughterhouse"
                ) as string;
                this.RestCheckMsg(
                    this.$t("system.app_user.select_slaughterhouse") as string
                );
                break;
            //屠宰
            case "4":
                this.noneEnter = true; //显示关联企业
                this.LinkGroupText = this.$t(
                    "system.app_user.Affiliated_slaughterhouse"
                ) as string;
                this.linkGrounpPlaceHolderText = this.$t(
                    "system.app_user.Slaughterhouse_organization"
                ) as string;
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.select_slaughterhouse"
                ) as string;
                this.RestCheckMsg(
                    this.$t("system.app_user.select_slaughterhouse") as string
                );
                break;
            //买家
            case "10":
                this.noneEnter = true; //显示关联企业
                this.NoneGroupCodeDiv = false; // 隐藏组织结构
                break;
            //政府
            case "15":
                this.noneEnter = false; //隐藏关联企业
                //this.linkGrounpPlaceHolderText = this.$t(
                //    "system.web_user.wu"
                //) as string;
                this.NoneGroupCodeDiv = true; //显示组织结构
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.search"
                ) as string;
                break;
            //推广员
            case "70":
                this.noneEnter = false; //隐藏关联企业
                //this.linkGrounpPlaceHolderText = this.$t(
                //    "system.web_user.wu"
                //) as string;
                this.NoneGroupCodeDiv = true; //显示组织结构
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.search"
                ) as string;
                break;
            //伊禾牧业
            case "16":
                this.noneEnter = false; //隐藏关联企业
                //this.linkGrounpPlaceHolderText = this.$t(
                //   "system.web_user.wu"
                //) as string;
                this.NoneGroupCodeDiv = true; //显示组织结构
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.search"
                ) as string;
                this.RestCheckMsg(
                    this.$t("system.app_user.select_farm") as string
                );
                break;
            default:
                this.noneEnter = true; //其他情况显示关联企业
                this.NoneGroupCodeDiv = true; //显示组织结构
                //this.linkGrounpPlaceHolderText = this.$t(
                //    "system.web_user.wu"
                //) as string;
                this.FarmNamePlaceHolder = this.$t(
                    "system.app_user.search"
                ) as string;
                this.LinkGroupText = this.$t(
                    "system.app_user.Associated_farm"
                ) as string;
                this.RestCheckMsg(
                    this.$t("system.app_user.select_farm") as string
                );
        }
    }

    //权限角色变化时 清空数据
    private RoleIdChangeClearOtherData() {
        this.CascaderKey++; //让组织机构的key变化
        this.form.groupCode = []; //每次切换的时候 清空组织机构 已选择的数组
        this.GroupCodeData = []; //每次切换的时候 清空组织机构 并清空渲染的数组
        this.FarmDataList = []; //每次切换清空养殖场 屠宰场 银行 保险 list
        this.form.farmIds = ""; //每次切换清空养殖场 屠宰场 银行 保险 id
        this.form.farmIds = []; //在切换权限角色的时候 清空养殖场 保险 银行已选择数据
    }

    //保存成功之后清空全部数据
    private ClearForm() {
        this.form = {
            roleId: "1",
            username: "",
            mobile: "",
            companyId: "",
            groupCode: [],
            farmIds: [],
            hasAllData: 0, //1代表全部  0 个别
            status: "1",
            id: "", //用户id
        };
        this.CheckBankList = [];
        this.getBankIdList = [];
    }

    activated() {
        this.ClearForm();
        this.SetData = JSON.parse(this.$route.query.rowData);
        this.SetData.groupCode =
            this.SetData.groupCode == null
                ? (this.SetData.groupCode = ["0"])
                : [this.SetData.groupCode.toString()];

        this.ResetFormData(); //将数据赋值
        this.getUserNameData();
        console.log(this.SetData, "SetData837");
        console.log(this.UserNameData, "UserNameData838");

        this.ActivatedgetLinkEnterPriseData();
        this.ActivatedgetGroupCodeData();
    }

    private SetData: any = {};
    private ResetFormData() {
        (window as any).roleId = this.SetData.roleId;
        this.$set(this.form, "roleId", this.SetData.roleId);
        this.$set(this.form, "username", this.SetData.username);
        this.$set(this.form, "mobile", this.SetData.mobile);
        this.$set(this.form, "groupCode", this.SetData.groupCode);
        this.$set(this.form, "id", this.SetData.id); //用户id
        this.$set(this.form, "status", this.SetData.status.toString()); //用户id
        this.form.hasAllData = this.SetData.hasAllData; //是否是选择全部养殖场
        this.form = JSON.parse(JSON.stringify(this.form)); //重新渲染 视图
        if (this.form.roleId == "13" || this.form.roleId == "30") {
            this.isBankTye = true;
            this.getBankListData(
                this.SetData.id,
                this.SetData.groupCode[this.SetData.groupCode.length - 1]
            );
        } else {
            this.isBankTye = false;
        }
        console.log(this.SetData);
    }

    private SetGroupCode() {
        this.$refs.SetBtnRef.form.groupCode = this.SetData.groupCode;
    }
}
