




















import { Component, Prop, Vue } from "vue-property-decorator";

import { appMenu } from "../../appfire/components/appAll";
interface treetype {
    id: string;
    parentId: string;
    name: string;
    url: string;
    describe: null;
    status: boolean;
    order: number;
    type: number;
    code: null;
    requirePermission: boolean;
    requireConfig: boolean;
    icon: null;
    deleteFlag: boolean;
    platform: number;
    children: treetype[];
}
@Component({})
export default class DropTree extends Vue {
    @Prop() treeData!: treetype[];
    @Prop() isAddNewColumn!: boolean;
    //拖拽成功完成时触发的事件
    private handleDrag(node: any, lastNode: any, event: any) {
        // 获取被拖拽节点的上一层
        const parent = this.getParentNode(lastNode);

        //判断id是否为真 设置被拖拽节点的 parentId 为上一层的 id
        if (parent) {
            //如果为真那么设置他的parentId
            node.data.parentId = parent;
        }
        //便利最外层的所有元素的parentId为3
        this.treeData.forEach((item: treetype) => {
            item.parentId = "3";
        });

        //计算树结构最大层级
        const maxLevel = this.findMaxLevel(this.treeData);
        if (maxLevel > 3) {
            //提示超出了3层
            this.$confirm("系统最多只可配置3级菜单", "移动菜单提示", {
                confirmButtonText: "确认",
                type: "warning"
            })
                .then(() => {
                    this.$message({
                        type: "success",
                        message: "已重置菜单"
                    });
                    this.$emit("EmitGetMenuData", "");
                })
                .catch(() => {
                    this.$message({
                        type: "success",
                        message: "已重置菜单"
                    });
                    //重置菜单
                    this.$emit("EmitGetMenuData", "");
                });
        } else {
            //如果没有超出三层 那就请求接口
            this.dragMenuFun();
        }
    }

    //获取结束拖拽时最后进入的节点的id
    getParentNode(lastNode: any) {
        return lastNode.data.id;
    }

    //递归获取层级
    private findMaxLevel(tree: any, currentLevel = 1): any {
        if (!tree || tree.length === 0) {
            // 如果树为空或已经遍历完，返回当前层级
            return currentLevel;
        }

        // 初始化最大层级为当前层级
        let maxLevel = currentLevel;

        for (const node of tree) {
            if (node.children && node.children.length > 0) {
                // // 如果节点有子节点，递归查找子节点的最大层级
                const childMaxLevel = this.findMaxLevel(
                    node.children,
                    currentLevel + 1
                );
                // 更新最大层级，将子节点的最大层级与当前最大层级进行比较
                maxLevel = Math.max(maxLevel, childMaxLevel);
            }
        }
        // 返回树的最大层级
        return maxLevel;
    }
    /**
     * @param {*} data 属性的数组中该节点所对应的对象
     * @param {*} node 节点对应的 Node
     * @param {*} vc 节点组件本身
     *
     *  */
    private clickNode(data: any, node: any, vc: any) {
        let arr = [];
        if (node.data.parentId == "3") {
            arr.unshift(node.data.id);
        } else if (node.parent.data.parentId == "3") {
            arr.unshift(node.data.parentId);
            arr.push(node.data.id);
        } else if (node.parent.parent.data.parentId == "3") {
            arr.unshift(node.parent.data.parentId);
            arr.push(node.parent.data.id);
            arr.push(node.data.id);
        }

        const { id } = data;
        let obj = {
            flag: false,
            data,
            arr,
            id
        };
        this.$emit("setEditAndData", obj);
    }

    //拖拽的请求
    private dragMenuFun() {
        let datas: any = {
            sysMenuList: this.treeData
        };
        this.$confirm("此操作将改变菜单层级顺序，是否继续？", "提示", {
            confirmButtonText: "确认",
            cancelButtonText: "取消",
            type: "warning"
        })
            .then(() => {
                this.$message({
                    type: "success",
                    message: "移动成功"
                });
                new appMenu().menuDrag(datas, (data: any) => {
                    this.$emit("EmitGetMenuData", "");
                });
            })
            .catch(() => {
                this.$message({
                    type: "success",
                    message: "已重置菜单"
                });
                //重置菜单
                this.$emit("EmitGetMenuData", "");
            });
    }
}
