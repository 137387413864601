















import { Watch, Prop, Component, Vue } from "vue-property-decorator";
import { dateFun } from "@/common/date";
@Component({})
export default class SelectDateComponetnsVue extends Vue {
    @Prop() LabelText: any;
    @Prop() width: any;
    @Prop() PlaceholderText: any;
    @Prop() FatherSelectData: any;

    private SelectTime: any = ""; //当前组件所绑定的值

    @Watch("SelectTime")
    private cjamgeSelectVal(newVal: string) {
        if (newVal == null) {
            this.$emit("SetSelecDateValue", ["", ""]);
            return;
        }
        if (newVal.length === 0) return;
        let arr = [];
        arr[0] =
            new dateFun().returnDate(newVal[0], "-") +
            " " +
            new dateFun().returnHourMinuteSecend(newVal[0], ":");
        arr[1] =
            new dateFun().returnDate(newVal[1], "-") +
            " " +
            new dateFun().returnHourMinuteSecend(newVal[1], ":");
        this.$emit("SetSelecDateValue", arr); //设置下拉框选择的值
    }

    @Watch("FatherSelectData", { immediate: true, deep: true })
    private changeFatherData(newVal: any) {
        if (newVal == "") this.SelectTime = "";
        // if(newVal) this.SelectTime = newVal
    }
}
