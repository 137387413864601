import { BasicsClass } from '@/common/BasicsClass';

export class AppUser {
    getBankList = (id: string, groupCode: string | number, cb: Function) => {
        new BasicsClass().BasicGet("/animal/web/appUserManagement/bankTreeList", { id, groupCode }, false, false, cb)
    }
}

export class WebUser {
    getLinkAppUser = (cb: Function) => {
        new BasicsClass().BasicGet("/animal/web/appUserManagement/userBaseList335", {}, false, false, cb)
    }
}
