



























































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";
import InputComonentVue from "@/views/basics/typeConfig/components/components/InputComonentVue.vue";

import { GroupCodeApi, FarmApi } from "@/api/CommonModule/index";
import { dateFun } from "@/common/date";
import {  getSystemAllCompanyList } from "@/api/index";
import {  Column } from "@/common/Views/system/column/column";
import { cameraManagement } from "@/api/HardwareModule/index";
import { FileApi } from "@/api/CommonModule/index";
import {
    SensorForm,
    addSensorForm,
    upSensorForm,
    AddList,
} from "@/Interface/HardwareModule/index";

@Component({
    components: { SelectComponentVue, InputComonentVue },
})
export default class SensorManagement extends Vue {
    private form = {
        deviceType: "",
        deviceCode: "",
        deviceStatus: "",
        stTime: "",
        edTime: "",
        companyId:'',
        farmId:'',
    };
    private SelectData = "";
    private tableData: any[] = []; //table 数据
    private tablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private statusList = [
        { id: "", name: "全部" },
        { id: 1, name: "在线" },
        { id: 0, name: "离线" },
    ];

    /**获取列表数据 */
    private getListData() {
        console.log("getListData");
        new cameraManagement().getList(
            this.form,
            this.tablePage,
            (res: any) => {
                this.tableData = res.data;
                console.log(this.tableData,"this.tableData");
                
                this.tablePage = { ...res.page };
            }
        );
    }
    //输入设备型号
    private SetModelValue(val: string) {
        this.form.deviceType = val;
    }
    //输入设备序列号
    private SetSerialValue(val: string) {
        this.form.deviceCode = val;
    }
    //选择设备状态
    private SetStatusValue(val: string) {
        this.form.deviceStatus = val;
    }
    private SetFarmValue(val: string) {
        this.form.farmId = val;
    }
    private SetCompanyValue(val: string) {
        this.form.companyId = val;
    }
    // 查询
    private search() {
        console.log( "search");
        
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getListData();
    }
    //重置
    private resetForm() {
        this.tableData = [];
        this.SelectData = "";
        this.form = {
            deviceType: "",
            deviceCode: "",
            deviceStatus: "",
            stTime: "",
            farmId:'',
            companyId:'',
            edTime: "",
        };
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getListData();
    }
    @Watch("SelectData", { deep: true }) //处理选择日期
    private handlerSelectData(newVal: any) {
        console.log(newVal);
        if (newVal) {
            this.form.stTime = new dateFun().returnAllDate(newVal[0], "-");
            this.form.edTime = new dateFun().returnAllDate(newVal[1], "-");
        } else {
            this.SelectData = "";
            this.form.edTime = "";
            this.form.stTime = "";
        }
    }
    private handlePageChange(val: any) {
        // 分页
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.getListData();
    }
    private EditData(row: any) {
        this.$router.push({
            path: "/cameraDetail",
            query: { data: JSON.stringify(row) },
        });
    }
    private FarmList: any = [];
    private getFarmList() {
        new FarmApi().getFarmComboList("", (res: any) => {
            res.data.unshift({
                id: "",
                name: "全部",
            });
            this.FarmList = res.data;
        });
    }
    private companyListForForm: any = [];
    activated() {
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getListData();
        getSystemAllCompanyList((res: any) => {
            res.data.unshift({
                id: "",
                name: "全部",
            });
            this.companyListForForm = [...res.data];
        });
        this.getFarmList();
    }
}
