import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';

export interface HarmlessForm {
    groupCode: string;
    name: string;
    platform: string;
    auditStatus: string
}

export interface HarmlessTable {
    createTime: string;
    createUserName: string;
    groupCode: string;
    groupName: string;
    id: string;
    mobile: string;
    name: string;
    person: string;
    status: boolean;
    typeIds: string;

    
}

export class Harmless extends BasicsClass {

    public getTable(form: HarmlessForm, tablePage: HandleTable, cb: Function): void {
        this.getTableList({
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1]
        }, tablePage, '/animal/web/harmlesstreatment/list', cb);
    }
}