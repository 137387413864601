




























































































import { Watch, Component, Vue } from "vue-property-decorator";
import { Column } from "@/common/Views/system/column/column";
import InputComonentVue from "@/components/BasicConponents/InputConponentsVue.vue";
import { merchant } from "@/common/Views/BeefOrdering/SupplierManagement/SupplierManagement";
import { customTitle } from "@/common/Views/basics/customTitle/customTitle";
@Component({
    components: {
        InputComonentVue,
    },
})
export default class AddMerchant extends Vue {
    private form: any = {
        id: "",
        homeTitle: "",
        enterpriseScreenTitle: "",
        welcomeTitle: "",
        homeTitleType: 1,
    };
    private updateType = false;
    private rules: any = {
        homeTitle: [
            { required: true, message: "请设置系统名称", trigger: "change" },
        ],
        enterpriseScreenTitle: [
            {
                required: true,
                message: "请设置企业数据大屏名称",
                trigger: "change",
            },
        ],
        welcomeTitle: [
            {
                required: true,
                message: "请设置欢迎名称",
                trigger: "change",
            },
        ],
    };

    private ForageTypeList: any[] = []; //草料类型 数据
    private AdressTreeList: any[] = []; //售卖区域
    private ShelfStatusList: any[] = [
        //上架状态
        { id: "1", name: "上架" },
        { id: "2", name: "下架" },
    ];

    //事件
    private SaveData() {
        //保存
        if (this.form.homeTitleType == 1) {
            this.form.homeTitle = this.homeTitle;
        } else if (this.form.homeTitleType == 2) {
            this.form.homeTitle = this.homeTitleimg;
        }
        if (!this.form.homeTitle) {
            this.$message({
                type: "warning",
                message: "请设置系统名称",
            });
            return;
        } else if (!this.form.enterpriseScreenTitle) {
            this.$message({
                type: "warning",
                message: "请设置企业数据大屏名称",
            });
            return;
        } else if (!this.form.welcomeTitle) {
            this.$message({
                type: "warning",
                message: "请设置欢迎名称",
            });
            return;
        }
        this.$refs.form.validate((flag: boolean) => {
            if (flag) {
                this.saveOrMerge(this.form);
            }
        });
    }

    //主页标题文案
    private SethomeTitleValue(val: string) {
        this.homeTitle = val;
    }
    private SetenterpriseScreenTitleValue(val: string) {
        //企业大屏标题文案
        this.form.enterpriseScreenTitle = val;
    }
    private SetwelcomeTitleValue(val: string) {
        //企业大屏标题文案
        this.form.welcomeTitle = val;
    }

    private saveOrMerge(form: any) {
        //新增
        new customTitle().saveOrMerge(form, (res: any) => {
            // this.$message({
            //     type: "success",
            //     message: "设置成功",
            // });
        });
    }
    // private updateMerchant(form: any) {
    //     //修改
    //     new merchant().updateMerchant(form, (res: any) => {
    //         this.$message({
    //             type: "success",
    //             message: "修改供应商成功",
    //         });
    //     });
    // }
    private ClearFormData() {
        //清楚Form数据
        this.form = {
            id: "",
            homeTitle: "",
            enterpriseScreenTitle: "",
            welcomeTitle: "",
            homeTitleType: 1,
        };
        this.homeTitle = "";
        this.homeTitleimg = "";
    }
    private ClearCheck() {
        //清除校验
        this.$refs.form.clearValidate();
    }
    private fileList: any = [];
    activated() {
        this.ossconfig();
        this.fileList = [];
        new customTitle().homeTitle((res: any) => {
            const data = res.data;
            this.form = {
                homeTitle: data.homeTitle,
                enterpriseScreenTitle: data.enterpriseScreenTitle,
                welcomeTitle: data.welcomeTitle,
                homeTitleType: data.homeTitleType ? data.homeTitleType : 1,
            };
            if (data.homeTitleType == 2) {
                this.fileList.push({
                    name: "titleUrl",
                    url: data.homeTitle,
                });
                this.homeTitleimg = data.homeTitle;
            } else {
                this.homeTitle = data.homeTitle;
            }
        });
        this.ClearFormData();
        this.ClearCheck();
    }
    private file: any = {};
    private imgDialogVisible = false;
    private dialogImageUrl = "";
    private handlePictureCardPreview(file: any) {
        this.dialogImageUrl = file.url;
        this.imgDialogVisible = true;
    }
    private handleRemove() {
        this.homeTitleimg = "";
        this.fileList = [];
    }
    private handleAvatarSuccess(res: any, file: any) {
        this.file = res;
        this.getbase64();
    }
    private getbase64(): void {
        this.homeTitleimg = "";
        const reader = new FileReader();
        let data = reader.readAsDataURL(this.file.raw);
        reader.onloadend = () => {
            const strBase64 = (<string>reader.result).substring(0);
            new Column().uploadImage((res: any) => {
                this.homeTitleimg = res.data.url;
                this.fileList.push({
                    name: "titleUrl",
                    url: res.data.url,
                });
            }, strBase64);
        };
    }
    //获取oss参数
    private ossconfigdata = {};
    private ossconfig(): void {
        new Column().ossconfig((res: any) => {
            this.ossconfigdata = res.data;
        });
    }
    private exceed(res: any) {
        this.$message({
            type: "warning",
            message: "图片上传到达限制，请删除后再进行添加",
        });
    }
    private homeTitle = "";
    private homeTitleimg = "";
    private setChange(e: any) {
        console.log(e);
        console.log(this.fileList, "260");
        console.log(this.homeTitleimg, "260");
        // if (e == 1) {
        //     console.log(e);
        //     this.homeTitle = "";
        // } else if (e == 2) {
        //     if (this.fileList.length > 0) {
        //         console.log(this.fileList[0].url, "260");
        //         this.homeTitleimg = this.fileList[0].url;
        //         console.log(this.homeTitleimg, "260");
        //     }
        // }
    }
}
