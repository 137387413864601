import { BasicsClass } from '@/common/BasicsClass';
import { SchoolList } from '@/common/Views/basics/schoolManage/schoolManage';
import { DeepLoop } from '@/common/deepLoop/deepLoop';

// export interface TypeConfigTable {
//     id: string;
//     name: string;
//     infoList: SchoolList<string>[]
// }

export class TypeConfig extends BasicsClass {
    //newConfAndWarn/varietiesList
    //confType/list

    public getMedicineConf(cb: Function) {
        this.BasicGet('/animal/web/newConfAndWarn/varietiesList', {}, false, true, cb);
    }

    public save(pig: string, cow: string, sheep: any, camel: any, cb: Function) {

        //newConfAndWarn/saveVarieties
        //web/confType/save

        this.BasicPost('/animal/web/newConfAndWarn/saveVarieties', {
            pig: pig.toString(),
            cow: cow.toString(),
            sheep: sheep.toString(),
            camel: camel.toString(),
        }, true, true, true, cb);
    }
}
