import {BasicsClass} from '@/common/BasicsClass';
import { Axios } from '@/common/http/http';
import { HandleTable } from '../../comm/handleTable';

export interface Form<T> {
    name: T;
}

export interface handleRole<T> extends Form<T> {
    describe: T;
}

export interface TableResult<T> extends handleRole<T> {
    id: T;
    createTime: T;
    updateTime: T;
    createUserId: T;
    updateUserId: T;
    code: number|T;
}

export class Role extends BasicsClass {
    private axios: Axios;
    constructor() {
        super();
        this.axios = new Axios();
    }

    public getRoleList(form: Form<string>, tablePage: HandleTable, cb: Function, platform: number=1) {
        this.BasicGet('/animal/web/role/list', {
            ...form,
            ...tablePage,
            platform
        }, false, true, cb)
        // let result: Array<TableResult<string>>;
        // let pageInfo: HandleTable;
        // try {
        //     const data = await this.axios.get<Array<TableResult<string>>>('/asset/assetRole/list', {
        //         ...form,
        //         ...tablePage,
        //     });
        //     result = data.data;
        //     pageInfo = data.page;
        // } catch (e) {
        //     this.vue.$message.error(e.message);
        //     result = [];
        //     pageInfo = tablePage;
        // }
        //
        // return {result, pageInfo};

    }

    public delRole(ids: string[], callback: Function, platform: number=1) {

        this.axios.post('/animal/web/role/delete', {
            id: ids.join(','),
            platform
        }).then((data) => {
            this.vue.$message.success(data.message);
            callback();
        }).catch((err) => {
            this.vue.$message.error(err.message);
        });
    }

}
