





























































import {Component, Prop, Vue} from "vue-property-decorator";
import {HandlePageChange, HandleTable} from "@/common/Views/comm/handleTable";
import {ModelTypeManage, ModelTypeManageTableData} from "@/common/Views/aiModel/modelTypeManage/modelTypeManage";
import {AxiosData} from "@/common/http/http";

@Component
export default class HelloWorld extends Vue {
   private name: string = '';

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private tableData: ModelTypeManageTableData[] = [];

    private modelTypeManage: ModelTypeManage = new ModelTypeManage();
    private activated() {
        this.search();
    }

    private restForm() {
        this.name = '';
        this.search();
    }

    private search() {
        this.tablePage.pageNo = 1;
        this.tablePage.totalCount = 0;
        this.tableData = [];
        this.getTable();
    }

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.tableData=[];
        this.getTable();
    }

    private getTable() {
        this.modelTypeManage.getTable(this.name, this.tablePage, (data: AxiosData<ModelTypeManageTableData[]>)=>{
            this.tableData = data.data;
            this.tablePage.totalCount = data.page.totalCount;
        })
    }

    private goDetail(row: ModelTypeManageTableData) {
        this.$router.push({
            path: "/modelTypeManageDetail",
            query: {
                modelTypeManageId: row.id
            }
        })
    }
}
