



































































import {Component , Watch , Prop , Vue} from 'vue-property-decorator'
import { appMenu } from './components/appAll';
import {DeepLoop, floatJson} from "@/common/deepLoop/deepLoop";

@Component({})
export default class AppMenu extends Vue{
    private treeData:any[] = []  //树形图 数据
    private prop:any ={label:'name'}    // //菜单props

    private TabModel:string = 'user'
    private fatherColumn:any[] = [] //父栏目数据数组
    private showMenu:boolean = false //是否显示右侧表单
    private delBtn:boolean = false //是否显示删除按键
    private ClickNodeData:any ={}
    private form:any ={//form数据
        type:'1',
        platform:'3',
        children:[],
        resources:[],
        parentId:'3',
        name:'',
        fatherColumSelect:[],
        url:'',
        order:'',
        describe:'',
    }
    private rules:any ={//规则对象
        name: [{ required: true, message: '请输入栏目名称', trigger: ['blur','change'] }],
        url:[{required :true , message:'请输入栏目路径' , trigger:['blur','change']}],
        order:[{required : true , message:'请输入栏目排列序号' , trigger:['blur','change']}],
    }

    private handleDrop(draggingNode:any, dropNode:any, dropType:any, ev:any){//菜单拖拽成功的事件
        if(dropType == 'inner'){
            if(dropNode.level == 3 && dropNode.parent.level == 2){//判断 被拖拽节点 的层级是否是3  如果是3 在拖拽后就是4  超过3级菜单 return
                //提示超过三级菜单
                this.$confirm('系统最多只可配置3级菜单','移动菜单提示',{confirmButtonText:'确认',type:'warning'}).then(() =>{
                    //重置数据格式
                    this.getTreeData(() =>{this.$message({type:'success',message:'已重置菜单'})})//在成功的回调中执行 提示信息
                })
                return//终止运行
            }
        }
        //拖拽成功完成时触发的事件 
        this.SetMoveTreeDatas(draggingNode,dropNode,dropType)
        this.SetOrder() //循环处理order  排序
        this.MoveTipMsg() //处理提示 和发送请求
    }

    private clickNode(data:any , node:any , vc:any){//节点被点击时的回调
        this.ClickNodeData = node
        this.showMenu = true //显示右侧表单
        this.delBtn = true //显示删除按钮
        //赋值操作
        this.resetFormData(data.name , [] , data.url , data.order , data.describe == null ? '' : data.describe)

        this.resetFatherView(node)
    }
    
    private SetMoveTreeDatas(draggingNode:any, dropNode:any, dropType:any){//处理移动的树数据结构
        if(dropType == 'before' || 'after'){
            if(dropNode.parent.parent == null){//判断 是不是在最外层 如果是  赋值3  //证明这就是在最外层
                draggingNode.data.parentId = '3' //赋值 parentId 为 3
            }else{// 如果不是最外层 赋值父级id
                dropNode.parent.data.children.forEach((item:any) => {
                    item.parentId = dropNode.parent.data.id//赋值 parentId 为父级 id
                });
            }
        }
        if(dropType == 'inner'){
            //inner 情况下 不可能是最外层 所以不用判断 是不是最外层 赋值 3 的操作
            dropNode.data.children.forEach((item:any) => {//循环被拖拽项 下所有的数据  并将其 parentId 赋值为 被拖拽项 id
                item.parentId = dropNode.data.id
            });
        }
    }
    
    private MoveTipMsg(){//处理移动后的提示信息 以及接口请求
        this.$confirm('此操作将改变菜单层级顺序，是否继续？','提示',{
            confirmButtonText:'确认',
            cancelButtonText:'取消',
            type:'warning'
        }).then(() =>{
            this.menuDragData() //发送拖拽请求
        }).catch(()=>{
            this.getTreeData()//重置树结构 获取最新的树结构
        })
    }
    
    private SetOrder(){//处理order
        this.treeData.forEach((item:any,index:number) =>{
            item.order = index
            if(item.children.length !=0 || null){
                item.children.forEach((itemOne:any,indexOne:number) => {
                    itemOne.order = indexOne
                    if(itemOne.children.length !=0 || null){
                        itemOne.children.forEach((itemTwo:any , indexTwo:number) => {
                            itemTwo.order = indexTwo
                        });
                    }
                });
            }
        })
    }
   
    private getTreeData(cb?:any){ //获取树结构数据
        this.loading() //加载loading
        new appMenu().getMenu((data:any) => {
            this.treeData = data.data
            const deepCopy: DeepLoop = new DeepLoop();
            const fatherData = deepCopy.deepCopy(this.treeData);
            fatherData.unshift({
                id:'3',
                name:'无',
                parentId: '3'
            })
            this.fatherColumn = fatherData
            this.loading().close() //关闭loading
            cb()
        },(err:any)=>[
            this.loading().close()//在错误的回调中执行  防止被挤掉之后loading无法结束
        ])
    }

    private menuDragData(){//拖拽菜单接口请求
        this.loading() //加载loading
        let MoveData:any = {sysMenuList:this.treeData}
        new appMenu().menuDrag(MoveData,(data:any) => {
            this.loading().close() //关闭loading
            this.$message({
                type:'success',
                message:'移动成功'
            })
            this.getTreeData()
        })
    }

    private addMenuData(){//添加菜单接口请求
        new appMenu().addMenu(this.form,((data:any) => {
            this.getTreeData()
        }))
    }

    private delMenuData(id:any){ //删除菜单接口
        new appMenu().delMenu(id,(data:any)=>{
            this.$message({type: 'success',message: '删除成功!'}),
            this.getTreeData()
        })
    }

    private changeTree(e:any){//父栏目选择时的事件
        console.log(e)
        // console.log(this.fatherColumn)

        if(!this.delBtn){//是新增  先判断是新增还是编辑
            if(this.GetLevel(e).level == 1){
                if(this.GetLevel(e).id == '3'){
                    this.form.parentId = '3'
                }else{
                    // console.log(this.GetLevel(e).id)
                    this.form.parentId = this.GetLevel(e).id
                }
            }else{
                console.log(this.GetLevel(e).id)
                this.form.parentId = this.GetLevel(e).id
            }
        }else{//是编辑
            // this.ClickNodeData  当前被修改的菜单 
            //假如这个时候切换父栏目  只需要将 this.ClickNodeData.data.parentId 更换为当前父栏目id
            // console.log(this.ClickNodeData.data.parentId)
            this.ClickNodeData.data.parentId = e[e.length-1]
        }
    }

    private addColum(){ //新增栏目事件
        this.showMenu = true //显示右侧表单
        this.resetFormData('' , [] , '' , '','')//清空表单
        setTimeout(() => this.$refs.ruleForm.clearValidate())//重置校验
        this.delBtn = false //隐藏删除按钮
    }

    private save(){//保存
        this.$refs.ruleForm.validate((valid:boolean) =>{
            if(valid){
                if(!this.delBtn){//是新增  先判断是新增还是编辑
                    this.addMenuData()
                }else{
                    new appMenu().setMenu(this.ClickNodeData.data,(data:any) => {
                        this.getTreeData()
                    })
                }
            }
        })
    }

    private resetForm(){//取消
        this.resetFormData('' , [] , '' , '','')//清空表单
    }

    private remove(){//删除菜单
        this.$confirm('删除栏目将无法恢复，相关子栏目、权限配置也将同步删除！是否确定删除该栏目？' , '操作确认' , {confirmButtonText: '确定',cancelButtonText: '取消',type: 'warning'}).then(() =>{
            this.delMenuData(this.ClickNodeData.data.id)
        }).catch(() =>{
            this.$message({type: 'info',message: '已取消删除'})
            this.getTreeData()
        })
        
    }

    private resetFormData(name:string , fatherColumSelect:any[] , url:string , order:any , describe:string){//赋值formshuju
        this.form.name = name
        this.form.fatherColumSelect = fatherColumSelect
        this.form.url = url
        this.form.order = order
        this.form.describe = describe
        this.form.parentId = '3'
    }

    private resetFatherView(node:any){//父栏目的回显
        if(node.level == 1){
            this.form.fatherColumSelect = [node.data.parentId]
        }
        if(node.level == 2){
            this.form.fatherColumSelect = [node.data.parentId , node.data.id]
        }
        if(node.level == 3){
            this.form.fatherColumSelect = [node.parent.data.parentId,node.data.parentId , node.data.id]
        }
    }

    private loading(){//加载loading
        return this.$loading({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
        });
    }

    private GetLevel(val:any[]){//获取层级
        let level :number = 1
        let id:string = ''
        this.fatherColumn.forEach((item:any )=>{
            if(item.id == val[val.length-1]){
                id = item.id
                level = 1
            }else if(item.children && item.children.length > 0){
                item.children.forEach((itemOne:any) =>{
                    if(itemOne.id == val[val.length-1]){
                        id = itemOne.id
                        level = 2
                    }else if(itemOne.children && itemOne.children.length > 0){
                        itemOne.children.forEach((itemTwo:any) => {
                            if(itemTwo.id == val[val.length-1]){
                                id = itemTwo.id
                                level = 3
                            }
                        });
                    }
                })
            }
        })
        return {level , id}
    }

    mounted(){
        this.getTreeData()//获取树结构数据
    }

    @Watch('route')
    private changeRoute(){
        this.loading().close()
    }

}
