



















































































































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";
import InputComonentVue from "@/views/basics/typeConfig/components/components/InputComonentVue.vue";
import { dateFun } from "@/common/date";

import { WeightInfo } from "@/Interface/BasicInfoModule/WeightForm";
import { BasicInfoModule } from "@/api/BasicInfoModule/index";
import { SimManage } from "@/common/Views/basics/simManage/simManage";

import { CommonModule } from "@/api/CommonModule/index";
import {
    HandleTable,
    HandlePageChange
} from "@/Interface/CommonModule/TableAndPage";
@Component({
    components: {
        SelectComponentVue,
        InputComonentVue
    }
})
export default class weightManagement extends Vue {
    private form: WeightInfo = {
        farmId: "",
        houseId: "",
        columnId: "",
        confTypeId: "",
        labelNumber: "",
        startDate: "",
        endDate: "",
        dealStatus: ""
    };
    private tablePage: HandleTable = {
        //分页数据
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500]
    };
    private statuList = [
        { id: "", name: "全部" },
        { id: 0, name: "否" },
        { id: 1, name: "是" }
    ];
    private SelectTime: any[] = []; //时间选择

    private FarmList = []; //养殖场数据
    private PlaceList = [];
    private SmallPlaceList = [];
    private SelectAllList = [];
    private tableData = []; //列表数据

    //emit
    private SetfarmIdValue(val: string) {
        //养殖场赋值
        this.form.farmId = val;
        this.form.houseId = "";
        this.form.columnId = "";
        this.form.confTypeId = "";
        this.getSelectAllList(val);
        if (val) this.getPlaceList(val);
    }
    private SetplaceIdValue(val: string) {
        this.form.houseId = val;
        this.form.columnId = "";
        if (val) this.getSmallPlace(val);
    }
    private SetsmallplaceIdValue(val: string) {
        this.form.columnId = val;
    }
    private SetbreedtypeValue(val: string) {
        this.form.confTypeId = val;
    }
    private SetstatusValue(val: any) {
        this.form.dealStatus = val;
    }
    private SetlabelNumberValue(val: string) {
        //耳标号赋值
        this.form.labelNumber = val;
    }

    //事件
    private SearchList() {
        console.log(this.form, "form");
        this.tablePage = {
            //分页数据
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500]
        };
        // //查询
        this.getWeightList();
    }
    private ResetList() {
        //重置
        this.clearData();
        this.getFarmList();
        this.getSelectAllList("");
        this.getWeightList();
    }
    ExportWeight() {
        let formData = { ...this.form };
        if (formData.dealStatus !== undefined && formData.dealStatus !== "") {
            formData.dealStatus = !!formData.dealStatus;
        }
        if (formData.farmId) {
            new SimManage().exportWeightList(formData);
        } else {
            this.$message.error("请选择养殖场");
        }
    }
    private handlePageChange(val: HandlePageChange) {
        //切换分页
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        //请求列表数据
        this.getWeightList();
    }
    private goDetail(row: any) {
        console.log(row);

        //列表点击查看
        this.$router.push({
            path: "/WeightManagementInfo",
            query: { id: row.id }
        });
    }
    private goUpdate(row: any) {
        //列表点击处理
        this.$router.push({
            path: "/WeightManagementUpdate",
            query: { id: row.id }
        });
    }
    //watch
    //数据处理
    @Watch("SelectTime") //处理下单时间
    private ChangeSlectTime(newVal: any[]) {
        console.log(newVal, "newval");

        if (newVal) {
            if (newVal.length === 0) {
                this.form.startDate = "";
                this.form.endDate = "";
                return;
            }
            this.form.startDate = new dateFun().returnDate(newVal[0], "-");
            this.form.endDate = new dateFun().returnDate(newVal[1], "-");
        } else {
            this.form.startDate = "";
            this.form.endDate = "";
            this.SelectTime = [];
        }
    }
    private clearData() {
        this.form = {
            farmId: "",
            houseId: "",
            columnId: "",
            confTypeId: "",
            labelNumber: "",
            startDate: "",
            endDate: "",
            dealStatus: ""
        };
        this.PlaceList = [];
        this.SmallPlaceList = [];
        this.tablePage = {
            //分页数据
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500]
        };
        this.SelectTime = [];
    }

    //数据请求
    private getFarmList() {
        new CommonModule().getFarmList("", (data: any) => {
            data.data.unshift({
                id: "",
                name: "全部"
            });
            this.FarmList = data.data;
        });
    }
    private getPlaceList(id: string) {
        new CommonModule().getPlaceList(id, (data: any) => {
            data.data.forEach((item: any) => {
                item.name = item.placeName;
            });
            data.data.unshift({
                id: "",
                name: "全部"
            });
            this.PlaceList = data.data;
        });
    }
    private getSmallPlace(id: string) {
        new CommonModule().getSmallPlace(id, (data: any) => {
            data.data.forEach((item: any) => {
                item.name = item.placeName;
            });
            data.data.unshift({
                id: "",
                name: "全部"
            });
            this.SmallPlaceList = data.data;
        });
    }
    private getSelectAllList(farmId: string) {
        new CommonModule().getSelectAll(farmId, (data: any) => {
            data.data.unshift({
                id: "",
                name: "全部"
            });
            this.SelectAllList = data.data;
        });
    }
    private getWeightList() {
        let formData = { ...this.form };
        if (formData.dealStatus !== undefined && formData.dealStatus !== "") {
            formData.dealStatus = !!formData.dealStatus;
        }
        new BasicInfoModule().getWeightList(
            formData,
            this.tablePage,
            (res: any) => {
                this.tableData = res.data;
                this.tablePage = res.page;
            }
        );
    }

    activated() {
        this.clearData();
        this.getSelectAllList("");
        this.getFarmList();
        this.getWeightList();
    }
}
