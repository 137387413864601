import {BasicsClass} from '@/common/BasicsClass';

export class Type extends BasicsClass{
    // 类型配置列表 /newConfAndWarn/typeList 
    public getTypeList(cb:Function){
        this.BasicGet('/animal/web/newConfAndWarn/typeList',{},false,false,cb)
    }

    //牛预警配置
    public setType(data:any,cb:Function){
        this.BasicPost('/animal/web/newConfAndWarn/saveCowWarnList',{...data},true,true,true,cb)
    }

    //牛预警配置列表/newConfAndWarn/cowWarnList
    public cowWarnList(cb:Function){
        this.BasicGet('/animal/web/newConfAndWarn/cowWarnList',{},false,false,cb)
    }
}