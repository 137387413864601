




















































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Personal, PersonalC, TableData} from "@/common/Views/system/personal/personal";
import {HandleTable, HandlePageChange} from "@/common/Views/comm/handleTable";
import {Util} from "@/common/Views/comm/util";
import personalIp from '@/views/system/personal/personalDetail/personalIp.vue';
import { PersonalDetailC, SchoolGradeClass } from '@/common/Views/system/personal/personalDetail';
import { TableResult } from '@/common/Views/system/role/role';
import {AxiosData} from "@/common/http/http";
import { Result, Column, ComboList } from '@/common/Views/system/column/column';
import { DeepLoop } from '@/common/deepLoop/deepLoop';

enum Status {
    '启用' = 1,
    '禁用' = 2,
    '登录锁定' = 3,
}
@Component({
    components: {
        'personal-ip': personalIp,
    }
})
export default class HelloWorld extends Vue {

    public column = new Column()

    private form: Personal<string> = {
        name: '',
        roleIds: '',
        mobile: '',
        status: '',
        groupCode: [],
        farmIds: '',
    }

    private auditForm: any = {
        id: '',
        auditStatus: ''
    }

    private personal: PersonalC;
    constructor() {
        super();
        this.personal = new PersonalC();
    }

    private dialogVisible:boolean = false

    private tableData: TableData<string>[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;
        this.getPersonalList();
    }

    private handlePageChange(val: HandlePageChange){

        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getPersonalList();
    }

    private activated(): void {
        this.getPersonalList();
        this.getAllRole();
        this.getTree();
        this.changeGroupCode();
    }

    private treeData: Result[] = [];

    private getTree(cb?: Function): void {
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        // column.getTreeData("/animal/web/group/treeList").then((result: Result[]) => {
        column.getTreeData("/animal/web/provinceCityDistrict/getForGroupCode").then((result: Result[]) => {
            

            this.treeData = new DeepLoop().deepCopy(result);

            if(cb) cb();
        }).catch(err => {
            this.$message.error(err.message);
        });
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    private comboList: ComboList[] = [];
    private changeGroupCode(): void {
        this.comboList = [];
        // this.form.farmIds = '';
        new Column().getFarm(this.form.groupCode[this.form.groupCode.length - 1], (data: AxiosData<ComboList[]>)=>{
            this.comboList = data.data;
        }, '100')
    }

    @Watch("form.groupCode")
    getgroupCode(newVal:Array<object>,oldVal:Array<object>) {
        if (newVal !== oldVal) {
            this.form.farmIds = '';
            // this.form.typeConfId = ''; 
        }
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        this.tablePage.pageNo = 1;

        this.getPersonalList();
    }

    private getPersonalList(): void {
        if(this.$getResourceCodes('user_list')){
            this.personal.getPersonalList(this.form, this.tablePage, 2)
                .then(data=>{
                    for(const i in data.result) {
                        data.result[i]['status'] = Status[data.result[i]['status']];
                    }
                    this.tableData = data.result;
                    this.$set(this.tablePage, 'totalCount', data.pageInfo.totalCount);
                })

        }

    }

    private removePerson(): void {
        // this.personal.removePersonal()
        let removeRecords: TableData<string>[] = (this.$refs.table as any).getSelectRecords();

        if (removeRecords.length === 0) {
            this.$message.error('你没有选中用户！');

        } else {
            this.$confirm('是否确认删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                const ids = removeRecords.map((value: TableData<string>) => value.id);

                this.personal.removeRole(ids.join(','), this.getPersonalList, 2);

            }).catch(() => {
                //   do nothing
            });
        }
    }

    private handlePerson(id: string=''): void {
        this.$router.push({
            path: '/weChatPersonalDetail',
            query: {
                weChatPersonalDetailId: id,
            }
        });
    }

    private restPassword(row: TableData<string>): void {

        this.$prompt(row.name, '重置密码').then((data: any)=>{
            if(data.value.length<6 || data.value.length>16){
                this.$message.error('密码长度不符合');
            }else{
                this.personal.restPassword(row.id, data.value, 2);
            }
        });
    }

    private loginIPDialog: boolean = false;
    private handleIp(id: string): void {
        this.loginIPDialog = true;

        this.getIpWithId = id;
    }

    private getIpWithId: string = '';

    private schoolNameOptions: SchoolGradeClass<string>[] = [];

    // private getAllSchoolList(): void {
    //     new PersonalDetailC().getAllSchoolList()
    //         .then(data=> {
    //
    //             this.schoolNameOptions = data;
    //         })
    // }


    private roleOptions: TableResult<string>[] = [];

    private getAllRole(): void {
        new PersonalDetailC().getRoleList(2)
            .then(data=>{

                this.roleOptions = data;
            }).catch(err=>{
            this.$message.error(err.message);
        });
    }


    private loginUnLock(row: TableData<string>): void {

        this.$confirm('是否确认解锁？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
        }).then(() => {

            this.personal.loginUnLock(row.id, ()=>{
                this.getPersonalList();
            }, 2);

        });


    }

    private handleIf(id: string): void {
        this.dialogVisible = true
        this.auditForm.id = id
    }

    private handleAudit(str: string): void {
        this.auditForm.auditStatus = str
        this.personal.audit(this.auditForm, () => {
            if(str === '1'){
                Util.showToast('审核通过')
            }else if(str === '2'){
                this.$message({
                    type: 'info',
                    message: '审核拒绝'
                });
            }
            this.dialogVisible = false
            // this.$router.go(0)
            this.getPersonalList()
        })
    }

    // private handleIf(id: string): void {
    //     let form = {
    //         id: id,
    //         auditStatus: ''
    //     }
    //     this.$confirm('是否审核通过?', '提示', {
    //         confirmButtonText: '通过',
    //         cancelButtonText: '拒绝',
    //         type: 'warning'
    //     }).then(() => {
    //         console.log(123)
    //         // form.auditStatus = '1'
    //         // this.personal.audit(form, () => {
    //         //     Util.showToast('审核通过')
    //         //     this.$router.go(0)
    //         // })
    //     }).catch((event) => {
    //         console.log(event)
    //         // form.auditStatus = '2'
    //         // this.personal.audit(form, () => {
    //         //     this.$message({
    //         //         type: 'info',
    //         //         message: '审核拒绝'
    //         //     });
    //         //     this.$router.go(0)
    //         // })         
    //     });
    // }
}
