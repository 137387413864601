import {BasicsClass} from '@/common/BasicsClass';
import {AxiosData} from '@/common/http/http';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {Util} from '@/common/Views/comm/util';

export interface ipData {
    [index: number]: ip;
}

export interface ip {
    time: string;
    ip: string;
}

export class PersonalGetIp extends BasicsClass {
    public async getIpList(id: string, tablePage: HandleTable) {
        let result: AxiosData<ipData>;
        try {

            const data = await this.$axios.get<ipData>('/edu/sys/user/loginLog', {
                id,
                ...tablePage,
            });

            result = data;
        } catch (e) {
            this.vue.$message.error(e.message);
            result = {
                data: [],
                message: '',
                page: tablePage,
                code: 200,
            };
        }

        return result;
    }

    public exportData(id: string) {

        this.getFile('/edu/sys/user/loginLogExport', {id});
    }
}
