


import { Component, Vue } from "vue-property-decorator";
@Component({})
export default class ProductionManagementPlatform extends Vue {
    activated() {
        // location.href = "http://beef.yimucloud.com/#/login";
        // location.replace("http://beef.yimucloud.com/#/login");
        // window.open("http://beef.yimucloud.com/#/login");
        // window.open("http://beef.yimucloud.com/#/login", "_blank");
    }

    test() {
        window.open("");
    }
}
