import { render, staticRenderFns } from "./AddDrug.vue?vue&type=template&id=32000777&scoped=true&"
import script from "./AddDrug.vue?vue&type=script&lang=ts&"
export * from "./AddDrug.vue?vue&type=script&lang=ts&"
import style0 from "./AddDrug.vue?vue&type=style&index=0&id=32000777&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32000777",
  null
  
)

export default component.exports