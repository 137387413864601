import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { AxiosData, Axios, Header } from '@/common/http/http';
import { labelManagementDetailForm, onClickExportForm } from '@/common/Views/configManage/labelManagement/onClickExport/onClickExport';
import { SchoolAttendanceDetail } from '@/common/Views/attendance/schoolAttendance/schoolAttendanceDetail/schoolAttendanceDetail';
// import { axios } from '_vue@2.6.12@vue/types/umd';
import { Util } from '../../comm/util';

export interface LabelManagementForm {
    type: string;
    time: Date[];
    number: string;
    schoolId: string;
}

export interface LabelManagementTableData<T> {
    groupId: T;
    ids: T;
    eduLevel: T;
    mchId: T;
    id: T;
    schoolId: T;
    number: T;
    remark: T;
    type: number;
    typeName: T;
    isBind: boolean;
    associated: T;
    createTime: T;
    updateTime: T;
    createUserId: T;
    updateUserId: T;
    createUsername: T;
    schoolName: T;
    deleteFlag: boolean;
}

enum LabelManagementType {
    '学生卡' = 1,
    '校徽卡',
    '教师卡'
}

export class LabelManagement extends BasicsClass {

    public getLabelManagementTableData(form: LabelManagementForm, tablePage: HandleTable, callBack: Function): void {
        const readSendData = new SchoolAttendanceDetail().handleArguments(form);
        this.getTableList(readSendData, tablePage, '/edu/data/eduCard/listAll', (data: AxiosData<LabelManagementTableData<string>[]>) => {
            for (const i in data.data) {
                data.data[i].typeName = LabelManagementType[data.data[i].type];
            }

            callBack(data);
        });
    }

    public deleteByCardIds(ids: string[], callBack: Function): void {
        this.postWithNoMessage({
            cardIds: ids.toString()
        }, '/edu/data/eduCard/deleteByCardIds', callBack, true);
    }

    public addSubmit(Form: labelManagementDetailForm<string>, mchId: string, callBack: Function): void {
        this.postWithNoMessage({
            ...Form,
            mchId,
        }, '/edu/data/eduCard/save', callBack, true);
    }

    public downLoadTemplate(): void {
        this.getFile('/edu/data/eduCard/template', {});
    }

    public importSubmit(form: onClickExportForm<string>, file: FileList, mchId: string, callBack: Function): void {
        this.ImportFile('/edu/data/eduCard/import', file, 'file', {
            ...form,
            mchId,
        }, callBack);
    }

    public addlabel(cb: Function, tagNumList: string[] | string, companyId: string,cause:string) {
        // this.BasicPost('/animal/web/tag/batchSave',
        //     labelNumber
        // , false, false, false, cb);
        // this.vue.$axios.post()
        // new Axios().post('/animal/web/tag/batchSave', labelNumber, false).then(data => {
        //     // this.vue.$message.success(data.message);
        //     // callback();
        // }).catch(err => {
        //     // this.vue.$message.error(err.message);
        // });
        typeof(tagNumList) === 'string' ? tagNumList = [tagNumList] : tagNumList;
        this.vue.$axios({
            method: 'post',
            url: '/animal/web/tag/batchSave',
            data: { tagNumList, companyId,cause }
        }).then((res) => {
            cb(res.data)
        })

    }

    public dellabels(cb: Function, labelNumber: string[]) {
        this.vue.$axios({
            method: 'post',
            url: '/animal/web/tag/batchDel',
            data: labelNumber
        }).then((res) => {
            cb(res.data)
        })
    }

    //模板下载
    public download() {
        this.getFile('/animal/web/tag/exportLabelTemplate', {});
    }

    // public download2() {
    //     this.getFile('/animal/web/tag/exportLabelTemplate', {});
    // }
    // 下载
    public down(): void {
        this.vue.$axios.get(
            '/animal/web/tag/exportLabelTemplate',
            {
                responseType: 'blob',

            }).then((res: any) => {
                console.log(res)
                const data = new Blob([res.data], { type: 'application/vnd.ms-excel;charset=UTF-8' })
                const url = URL.createObjectURL(data)
                const link = document.createElement('a')
                link.href = url
                link.setAttribute("download", `${new Date().getTime()}`)
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
            });
    }
    // 上传
    public up(userFile: FileList, form: string, cb: Function): void {
        let fData = new FormData()
        if (userFile[0]) {
            fData.append("userFile", userFile[0])
        }

        this.$headers['Content-Type'] = 'multipart/form-data';
        this.vue.$axios.post("/animal/web/tag/importExcel",
            fData,
            {
                headers: this.$headers,
            }).then((res: any) => {

                console.log(res)

            })


    }

    //批量上传
    public uploadFile(userFile: FileList, form: any, cb: Function): void {
        this.ImportFile('/animal/web/tag/importExcel', userFile, 'userFile', form, cb);
    }



    public uploadanimalImport(userFile: FileList, form: any, cb: Function): void {
        this.ImportFile('/animal/web/animalMark/animalImport', userFile, 'userFile', form, cb);
    }
}
