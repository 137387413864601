import {BasicsClass} from '@/common/BasicsClass';

export interface HarmlessDetailForm<T=string> {
    groupCode: T[];
    contactPhone: T;
    contactPerson: T;
    enableStatus: T | number;
    name: T;
    typeIds: T[];
    simpleAddress: T;
    detailAddress: T;
    auditStatus: T | number,
    createUserId: T | number,
    licenseUrl: T,
    doorUrl: T,
    destinationTypeId: T,
    type:T,
    companyType:T,
}

export interface Url {
    code: string;
    base64ImgData: string;
    url: string;
}

export interface HarmlessDetailDetail {
    createTime: string;
    createUserId: string;
    groupCode: string;
    id: string;
    mobile: string;
    name: string;
    // simpleAdressId: number;
    permissionFarmIdList: string[];
    permissionFarmIds: string;
    permissionGroupCode: string;
    person: string;
    status: boolean;
    typeIds: string;
    updateTime: string;
    auditStatus: number;
    simpleAddress: string;
    detailAddress: string;
    licenseUrl: string;
    simpleAdressId: string
}

export interface Pictures {
    code: string;
    base64ImgData: string;
    url: string;
}

export interface CityList {
    data: []
}

export class HarmlessDetail extends BasicsClass {
    public submit(id: string, form: HarmlessDetailForm, cb: Function): void {
        this.AddOrUpdate(id, {
            ...form,
            typeIds: form.typeIds.toString(),
            groupCode: form.groupCode[form.groupCode.length-1]
        }, '/animal/web/harmlesstreatment/update', '/animal/web/harmlesstreatment/insert', cb);
    }

    public getDetail(id: string, cb: Function): void {
        this.BasicGet('/animal/web/harmlesstreatment/get', {id}, false, true, cb);
    }

    public getAudit(id: string, cb: Function): void {
        this.BasicGet('/animal/web/harmlesstreatment/getaudit', {id}, false, true, cb);
    }

    public getCity(pid: string, cb: Function): void {
        this.BasicGet('/animal/web/provinceCityDistrict/get', {pid}, false, true, cb)
    }

    public audit(id: string, form: HarmlessDetailForm, cb:Function): void {
        this.BasicPost('/animal/web/audit/auditFarm', {
            id,
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            typeIds: form.typeIds.toString(),
        }, true, true, true, cb)
    }
}