
































































import {Component, Prop, Vue} from "vue-property-decorator";
import handleRole from "@/views/system/weChatRole/roleDetail/handleRole.vue";
import {Form, Role, TableResult} from "@/common/Views/system/role/role";
import {HandleTable, HandlePageChange} from "@/common/Views/comm/handleTable";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {Util} from "@/common/Views/comm/util";
import {AxiosData} from "@/common/http/http";

@Component({
    components: {
        'handle-role': handleRole,
    }
})
export default class HelloWorld extends Vue {

    private form: Form<string> = {
        name: '',
    }

    private role: Role;
    constructor() {
        super();
        this.role = new Role();
    }

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,

        pageSizes: [10, 20, 50, 100, 200, 500],
    }

    private handlePageChange(val: HandlePageChange){

        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

       this.getRoleList();

    }

    private search(): void {
        this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;
        this.getRoleList();
    }

    private tableData: TableResult<string>[] = [];

    private dialogTableVisible: boolean = false;


    private addRole(): void {

        this.row = new Util().clearObject(this.row);

        this.dialogTableVisible = true;
    }

    private activated(): void {
       this.getRoleList();
    }

    private getRoleList(): void {
        if(this.$getResourceCodes('role_list')){

        this.role.getRoleList(this.form, this.tablePage, (data: AxiosData<any>) => {
                this.tableData = data.data;
                this.tablePage.totalCount = data.page.totalCount
                // this.$set(this.tablePage, 'pagetotalCount', data.page.totalCount);
            }, 2);
        }
    }

    private removeRole(): void {
        let removeRecords: TableResult<string>[] = (this.$refs.table as any).getSelectRecords();

        if (removeRecords.length === 0) {
            this.$message.error('你没有选中角色！');

        } else {
            this.$confirm('是否确认删除？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                const ids = removeRecords.map((value: TableResult<string>) => value.id);

                this.delRole(ids);

            }).catch(() => {
            //   do nothing
            });
        }
    }

    private delRole(ids: string[]): void {

        this.role.delRole(ids, this.getRoleList, 2);
    }


    private row: TableResult<string> = {
        name: '',
        describe: '',
        id: '',
        createTime: '',
        createUserId: '',
        updateTime: '',
        updateUserId: '',
        code: '',
    };

    private handleRow(row: TableResult<string>): void {
        const deepCopy: DeepLoop = new DeepLoop();

        this.row = deepCopy.deepCopy(row, false);
        this.dialogTableVisible = true;
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        this.getRoleList();
    }
}
