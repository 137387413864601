
















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
import { Rule } from "@/common/Views/comm/formRule";
import {
  ModelArgsManage,
  ModelArgsManageTableData,
  ModelArgsManageDetailForm,
  ModelArgsManageDetailFormList,
} from "@/common/Views/aiModel/modelArgsManage/modelArgsManage";
import {
  ModelTypeManageTableData,
  ModelTypeManage,
} from "@/common/Views/aiModel/modelTypeManage/modelTypeManage";

@Component
export default class HelloWorld extends Vue {
  private form: ModelArgsManageDetailForm = {
    typeId: "",
    version: "",
    name: "",
    defaultParamGroup: "",
    isEnable: "",
    modelDesc: "",
  };

  private formList: ModelArgsManageDetailFormList[] = [];

  private rules: ModelArgsManageDetailForm<Rule[]> = {
    typeId: [{ required: true, message: "请选择模型类别" }],
    version: [{ required: true, message: "请填写版本号" }],
    name: [{ required: true, message: "请填写模型名称" }],
    defaultParamGroup: [
      { type: "number", required: true, message: "请选择是否作为模型参数组" },
    ],
    isEnable: [{ type: "number", required: true, message: "请选择状态" }],
    modelDesc: [{ required: true, message: "请填写描述" }],
  };

  private modelTypeList: ModelTypeManageTableData[] = [];
  private modelNameList: ModelTypeManageTableData[] = [];

  private modelTypeManage: ModelTypeManage = new ModelTypeManage();
  private modelArgsManage: ModelArgsManage = new ModelArgsManage();
  private getModelTypeList() {
    this.modelTypeManage.getTable(
      "",
      {},
      (data: AxiosData<ModelTypeManageTableData[]>) => {
        this.modelTypeList = data.data;
      }
    );
  }

  private activated() {
    this.getModelTypeList();
  }

  private checkname(name) {
    this.form.name = name;
    // this.$forceUpdate()
  }

  private changeModelType() {
    // this.form.typeId = '';
    this.modelNameList = [];
    this.getModelNameList();
  }

  private getModelNameList() {
    this.modelArgsManage.getModelNameList(
      this.form.typeId,
      (data: AxiosData<ModelTypeManageTableData[]>) => {
        this.modelNameList = data.data;
      }
    );
  }

  private querySearch(queryString: string, cb: Function) {
    const data = queryString
      ? this.modelNameList.filter((value) => value.name.includes(queryString))
      : this.modelNameList;
    cb(data);
  }

  @Watch("$route", { immediate: true })
  public handleRoute() {
    if (this.$route.query.modelArgsManageId) {
      this.form = new Util().clearObject(this.form);
      this.getDetail();
    }
  }

  private getDetail() {
    const infoDetail: ModelArgsManageTableData = JSON.parse(
      <string>this.$route.query.modelArgsManageInfo
    );
    this.form.typeId = infoDetail.typeId;
    this.form.defaultParamGroup = Number(infoDetail.defaultParamGroup);
    this.form.isEnable = infoDetail.isEnable;
    this.form.version = infoDetail.version;
    this.form.modelDesc = infoDetail.modelDesc;
    this.form.name = infoDetail.modelName;

    this.formList = [];
    try {
      const columnNames: string[] = infoDetail.columnNames.split(",");
      const columnValues: string[] = infoDetail.columnValues.split(",");
      const descriptions: string[] = infoDetail.descriptions.split(",");
      const ids: string[] = infoDetail.paramIds.split(",");
      const argsLength: number = columnNames.length;

      for (let i = 0; i < argsLength; i++) {
        this.formList.push({
          columnName: columnNames[i],
          columnValue: columnValues[i],
          description: descriptions[i],
          id: ids[i],
        });
      }
    } catch (e) {}
  }

  private addFromList() {
    this.formList.push({
      columnName: "",
      columnValue: "",
      description: "",
      id: "",
    });
  }

  private deleteArgs(index: number) {
    this.$confirm("是否确认删除？", "提示", {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning",
    })
      .then(() => {
        const data: ModelArgsManageDetailFormList = this.formList[index];
        if (!data.id) {
          this.formList.splice(index, 1);
          return;
        }
        const infoDetail: ModelArgsManageTableData = JSON.parse(
          <string>this.$route.query.modelArgsManageInfo
        );

        this.canDelete(infoDetail.groupId, (valid: boolean) => {
          // false 可删， true 不可删
          if (valid) {
            Util.showToast("该参数被绑定无法删除");
            return;
          }

          this.formList.splice(index, 1);
        });
      })
      .catch(() => {});
  }

  private canDelete(groupId: string, cb: Function) {
    this.modelArgsManage.canDelete(groupId, (data: AxiosData<number>) => {
      cb(!!data.data);
    });
  }

  private onSubmit() {
    (this.$refs.form as any).validate((valid: boolean) => {
      if (!valid) return;

      this.modelArgsManage.submit(
        <string>this.$route.query.modelArgsManageId,
        <string>this.$route.query.modelArgsManagegroupId,
        this.form,
        this.formList,
        () => {
          this.$router.go(-1);
        }
      );
    });
  }
}
