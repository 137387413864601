import { BasicsClass } from '@/common/BasicsClass';

import { SensorForm, addSensorForm, upSensorForm, AddList } from '@/Interface/HardwareModule/index'

// 传感器
export class SensorApi {
    /**@param getTypeList 获取传感器类型 */
    public getTypeList(cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/sensor/typeList', {}, false, false, cb)
    }
    /**@param getList 获取传感器列表 */
    public getList(form: SensorForm, page: any, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/sensor/list', { ...form, ...page }, false, false, cb)
    }
    /**@param addSensor 添加传感器 */
    public addSensor(form: addSensorForm, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/sensor/addOne", { ...form }, true, true, true, cb)
    }
    /**@param delSensor 删除单条传感器 */
    public delSensor(id: string, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/sensor/del', { id }, false, false, cb)
    }
    /**@param updateOne 修改传感器 */
    public updateOne(form: upSensorForm, cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/sensor/updateOne", { ...form }, true, true, true, cb)
    }
    /**@param delAll 批量删除传感器 */
    public delAll(list: String[], cb: Function) {
        new BasicsClass().BasicPost1("/animal/web/sensor/delAll", { list }, true, true, true, cb)
    }
    /**@param addList 批量导入传感器 */
    public addList(list: AddList, cb: Function, errcb: Function) {
        new BasicsClass().BasicPost1("/animal/web/sensor/addList", { list }, true, true, true, cb, errcb)
    }

}

//物联网智能设备管理
export class DeviceManagement {
    /**@param getTypeList 获取所有基站类型 */
    public getTypeList(cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/device/getLocationType', {}, false, false, cb)
    }
}
//AI摄像机管理
export class cameraManagement {
    /**@param getTypeList 获取摄像机管理列表 */
    public getList(form: any, page: any, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/cameraManage/list', { ...form, ...page }, false, false, cb)
    }
    /**@param geLogList 获取日志列表 */
    public geLogList(form: any, page: any, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/cameraManage/logRecord', { ...form, ...page }, false, false, cb)
    }
    /**@param gecfgList 获取配置文件列表 */
    public gecfgList(form: any, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/cameraManage/cfgList', { ...form}, false, false, cb)
    }
    /**@param changeIssue  */
    public changeIssue(form: any, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/cameraManage/issueCommand', { ...form}, false, false, cb)
    }
    /**@param /cameraManage/editCfg  */
    public editCfg(form: any, cb: Function) {
        new BasicsClass().BasicPost1('/animal/web/cameraManage/editCfg', { ...form}, false, true, true, cb)
    }
}
//升级包管理
export class pkgManagement {
    /**@param getList 获取升级包列表 */
    public getList(form: any, page: any, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/cameraFirmware/pageList', { ...form, ...page }, false, false, cb)
    }
    /**@param addPkg 提交新增升级包 */
    public addPkg(form: any, cb: Function) {
        new BasicsClass().BasicPost1('/animal/web/cameraFirmware/add', { ...form}, false, true, true, cb)
    }
    /**@param editPkg 修改升级包 */
    public editPkg(form: any, cb: Function) {
        new BasicsClass().BasicPost1('/animal/web/cameraFirmware/update', { ...form}, false, true, true, cb)
    }
    /**@param delPkg 删除升级包  */
    public delPkg(id: string, cb: Function) {
        new BasicsClass().BasicGet1('/animal/web/cameraFirmware/delete', { id }, false, false, cb)
    }
}
//升级记录
export class cameraUpgrade {
    /**@param getList 获取升级记录列表 */
    public getList(form: any, page: any, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/cameraUpgrade/pageList', { ...form, ...page }, false, false, cb)
    }
    /**@param getDetailList 获取升级记录详情列表 */
    public getDetailList(form: any, page: any, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/cameraUpgrade/pageItemList', { ...form, ...page }, false, false, cb)
    }
    /**@param addUpgrade 新增设备升级 */
    public addUpgrade(form: any, cb: Function) {
        new BasicsClass().BasicPost1('/animal/web/cameraUpgrade/add', { ...form}, false, true, true, cb)
    }
    /**@param getdeList 获取升级记录详情列表 */
    public getdeList(form: any, page: any, cb: Function) {
        new BasicsClass().BasicGet('/animal/web/cameraUpgrade/pageItemList', { ...form, ...page }, false, false, cb)
    }
}