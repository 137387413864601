






import {Component , Vue , Prop , Watch } from "vue-property-decorator"

@Component({
    name: "Role",
})
export default class Role extends Vue{

    @Prop() roleId:any

    @Prop() UserNameData:any

    @Prop() disabledFlag:any

    private RoleidId:any = '1'

    private changeUser(e:any){
    }

    @Watch('RoleidId' , {immediate:true})
    changeRoleidId(newVal:any){
        this.$emit('SetUserNameId' , newVal)
    }
    @Watch('roleId')
    changeRoleID(newVal:string){
        this.RoleidId = newVal
    }
}
