





























































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Result, Column} from "@/common/Views/system/column/column";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {optionSelect, Rule} from "@/common/Views/comm/formRule";
import {ConfType} from "@/common/Views/system/confType/confType";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {AxiosData} from "@/common/http/http";
import {HarmlessDetailForm, HarmlessDetail, HarmlessDetailDetail, CityList} from "@/common/Views/system/harmless/harmlessDetail/harmlessDetail";
import {Util} from "@/common/Views/comm/util";
import TitleSolt from '@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue';
import {Firm} from "@/common/Views/system/firm/firm";


@Component({
    components: {TitleSolt}
})

export default class HelloWorld extends Vue {
    mounted(){
        console.log(this.formLabelAlign)
    }

    public column = new Column()

    private firm: Firm = new Firm()

    public dialogVisible = false
    public ImgList = []

    private formLabelAlign: any = {
        mobile: "",
        userName: "",
        status: 1,
        name: "",
        groupCode: [],
        detailAddress: "",
        simpleAddress: "",
        id: "",
        type: ''
    };

    private oldAddress = ''

    // private props:Object = {
    //     checkStrictly: true,
    //     expandTrigger: "hover",
    //     hoverThreshold: undefined,
    //     label: "name",
    //     value: "name"
    // }

    public props: any = {
        expandTrigger: 'hover',
        value: 'name',
        label: 'name',
        lazy: true,
        lazyLoad(node: any, resolve: any) {
            console.log(node)
            // console.log(resolve(123))
            const column: Column = new Column();
            // if (node.level === 5) {
            //     return
            // }
            column.getTissuc2(node.data.id,1, (res: any) => {
                // if(res.data[0].level == 4){
                //     console.log(1)
                //     for(var i in res.data){
                //     }
                // }else{
                //     resolve(res.data)
                // }
                // console.log(res.data)
                // resolve(res.data)
                const nodes = res.data
                // if (node.level === 4) {
                nodes.forEach((item: any) => {
                    item.leaf = (item.level === 4)
                })    
                // }
                resolve(nodes)
            })
        }
    }

    private changeCity(a, b, c): void {
        // console.log(a)
        // console.log(b)
        // console.log(c)
        this.formLabelAlign.simpleAddress = this.formLabelAlign.groupCode.join('-')
    }


    private treeData: Result[] = [];

    private showMark1: boolean =  false;
    private showMark2: boolean =  false;
    private url: string =  '';

    private getTree(cb?: Function): void {
        
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        column.getTreeData().then((result: Result[]) => {

            // this.treeData = new DeepLoop().deepCopy(result);
            console.log(this.treeData)
            if(cb) cb();
        }).catch(err => {
            this.$message.error(err.message);
        });
    }

    private statusOptions: optionSelect<number>[] = [
        {
            value: 1,
            label: "启用",
        },
        {
            value: 2,
            label: "禁用",
        },

    ];


    private activated(): void {
        this.getTree();

        this.getDetail();
        this.confType();

        // console.log(12132131);
        const column: Column = new Column();
        column.getTissuc(0, (res: any) => {
            this.treeData = res.data
        })
    }


    private conTypeList: SchoolList<string>[] = [];

    private confType(): void {
        new ConfType().getList((data: AxiosData<SchoolList<string>[]>) => {
            this.conTypeList = data.data;
        });
    }

    private rules: HarmlessDetailForm<Rule[]> = {
        mobile: [
            {required: true, message: "请填写联系电话"},
            // {
            //     message: "手机号码输入错误", validator: (rule: any, value: any, callback: any) => {
            //         if (!/^[0-9]{11}$/g.test(value)) {
            //             callback(new Error());
            //         } else {
            //             callback();
            //         }
            //     }
            // },
        ],
        userName: [
            {required: true, message: "请填写联系人"}
        ],
        status: [
            {required: true, message: "请选择启用状态"}
        ],
        name: [
            {required: true, message: "请填写企业名称"}
        ],
        // typeIds: [
        //     // @ts-ignore
        //     {type: "array", required: true, message: "请添加养殖类型"}
        // ],
        simpleAddress: [
            {required: true, message: "请选择城市"}
        ],
        detailAddress: [
            {required: true, message: "请填写详细地址"}
        ],
        type: [
            {required: true, message: "请填写详细地址"}
        ]
        // typeIds: [],
    };

    private harmlessDetail: HarmlessDetail;

    constructor() {
        super();
        this.harmlessDetail = new HarmlessDetail();
    }

    private showDio(url: string) {
        this.dialogVisible = true;
        this.url = url;
    }

    private del(url: keyof FarmDetailForm) {
        (<string>this.formLabelAlign[url]) = '';
        this.showMark1 = false;
        this.showMark2 = false;
    }

    private save(): void {
        // console.log(this.ImgList);
        
        (this.$refs.form as any).validate((valid: boolean) => {
            if (valid) {
                let id = "1";
                if (this.$route.query.item) {
                    id = '2'
                }
                this.firm.submit1(id, this.formLabelAlign, () => {
                    this.$router.go(-1);

                });
                // console.log(this.formLabelAlign)
            }

        });

    }


    private getDetail(): void {
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        this.formLabelAlign.status = 1;
        // console.log(this.$route.query.item)
        if(this.$route.query.item){
            
            let item = JSON.parse(this.$route.query.item)
            this.formLabelAlign.name = item.name
            this.formLabelAlign.userName = item.username
            this.formLabelAlign.mobile = item.mobile
            this.formLabelAlign.detailAddress = item.detailAddress
            this.formLabelAlign.type = item.type
            if(item.simpleAddress){
                this.formLabelAlign.simpleAddress = item.simpleAddress.split('/').join('-')
            }
            this.formLabelAlign.id = item.id
            // console.log(item.simpleAddress)
        }
    }

    private cityList0 = []
    private cityList1 = []
    private cityList2 = []
    private city: any = {
        province: '',
        theCity: '',
        area: '',
    }
    private dataImg: boolean = false


    public resGroup: Result[] = []
    public handleCascader(start: any): void {
        console.log(start)
        if((start == false) && (this.formLabelAlign.groupCode.length != 0)){
            this.formLabelAlign.simpleAddress = this.formLabelAlign.groupCode.join('-')
            
        }
    }



    private getCity(pid: any, flag: boolean = true): void {
        console.log(1);
        
        if (!pid) {
            pid = 0
        }
        console.log(2);
        
        new HarmlessDetail().getCity(<any>pid, (data: CityList) => {
            if (pid == 0) {
                this.cityList0 = data.data
            } else if (pid < 100 && pid > 10) {
                this.cityList1 = data.data;
                if (flag) {

                    this.city.theCity = '';
                    this.city.area = ''
                }
            } else if (pid > 1000 && pid < 10000){
                this.cityList2 = data.data;
                if (flag) {

                    this.city.area = '';
                }
            }
            console.log(this.$refs)
            // this.formLabelAlign.simpleAddress = this.$refs.province.selectedLabel + this.$refs.theCity.selectedLabel + this.$refs.area.selectedLabel
            this.formLabelAlign.simpleAddress = this.$refs.area.value
        })
    }

    private handleCity(e: any): void {
        this.formLabelAlign.simpleAddress = e
    }

    private setImg(file: any, fileList: any): void {
        this.readImg(file, fileList, "licenseUrl");
    }

    private setImg1(file: any, fileList: any): void {
        this.readImg(file, fileList, "doorUrl");
    }

    private readImg(file: any, fileList: any, url: keyof FarmDetailForm) {
        const reader = new FileReader();
        let res = reader.readAsDataURL(file.raw);
        reader.onloadend = () => {
            this.dataImg = false;
            // var strBase64 = reader.result.substring(84);
            const strBase64 = (<string>reader.result).substring(0);
            // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: strBase64, url: ""};
            (<string>this.formLabelAlign[url]) = strBase64;
            console.log(this.formLabelAlign);
        };
        reader.onload = function(e) {
            console.log(e);
            // simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc
        };

    }

    // public changeCity = []

    
    public cityChange(ids: any): void {
        // console.log(ids)
        
        // if(ids.length === 1){
        //     this.column.getTissuc(ids[ids.length-1], (res: any) => {
        //         console.log(res.data)
        //         var list = this.treeData
        //         list[ids[0]-11].children = res.data
        //         this.treeData = []
        //         this.treeData = list
        //         console.log(this.treeData);
                
        //     })
        // }
        
    }

    private handleRemove(file: any): void {
        console.log(file);
        this.formLabelAlign.licenseUrl = ''
        this.ImgList = []
    };

    private handlePictureCardPreview(file: any): void {
        this.formLabelAlign.licenseUrl = file.url;
        this.dialogVisible = true;
    }
    
      

    @Watch("$route")
    public handleRoute(): void {
        this.resGroup = []
        // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: "", url: ""},
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        this.city = new Util().clearObject(this.city);
        // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: "", url: ""};
        this.formLabelAlign.auditStatus = "1";
        // this.$nextTick(()=>{
        //     (<any>this.$refs.upload).clearFiles();
        // })
        this.dataImg = false;
        this.getDetail();
        this.ImgList = []
    }
}
