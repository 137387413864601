import {BasicsClass} from '@/common/BasicsClass';
import { CountExceptionFrom } from '../countException/countException';
import { HandleTable } from '../../comm/handleTable';
import {Util} from '@/common/Views/comm/util';

export class HandleTemperatureException extends BasicsClass{
    public getHandleTemperatureReason(cb: Function) {
        this.BasicGet('/animal/web/handleTemperatureException/deathAndDeal', {
        }, false, true, cb);
    }

    public getTable(form: CountExceptionFrom, tablePage: HandleTable, cb: Function) {
        var obj = {
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length-1],
            warnTimeStartTime: (() => {
                if(form.warnTime){
                    return Util.dateTimeUtil(form.warnTime![0])
                }else{
                    return ''
                }
            })(),
            warnTimeEndTime: (() => {
                if(form.warnTime){
                    return Util.dateTimeUtil(form.warnTime![1])
                }else{
                    return ''
                }
            })(),
            handleStartTime: (() => {
                if(form.handleTime){
                    return Util.dateTimeUtil(form.handleTime![0])
                }else{
                    return ''
                }
            })(),
            handleEndTime: (() => {
                if(form.handleTime){
                    return Util.dateTimeUtil(form.handleTime![1])
                }else{
                    return ''
                }
            })(),
        }
        this.BasicGet('/animal/web/handleTemperatureException/list', obj, false, true, cb);
    }
    public Export(form: CountExceptionFrom, cb: Function) {
        this.getFile('/animal/web/handleTemperatureException/listExport', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length-1],
            warnTimeStartTime: Util.dateTimeUtil(form.warnTime![0]),
            warnTimeEndTime: Util.dateTimeUtil(form.warnTime![1]),
            handleStartTime: Util.dateTimeUtil(form.handleTime![0]),
            handleEndTime: Util.dateTimeUtil(form.warnTime![0]),
        });
    }
}

export interface HandleTemperatureReason {
    businessCode: number;
    name: string;
    pid: number;
    updateTime: string;
    updateUserId:string;
}
