import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {AxiosData} from '@/common/http/http';
import {Time} from '@/common/Views/basics/summaryAlarm/summaryAlarm';
import {Util} from '@/common/Views/comm/util';
import {LabelType} from '@/common/Views/basics/assetRegistration/assetRegistration';
import { DeviceGroupingDetail } from '../../configManage/deviceGrouping/deviceGrouping';
import {DeviceManageTable} from '@/common/Views/basics/deviceManage/deviceManage';

export interface LabelManageTable {
    // createTime: string;
    // createUserName: string;
    // farmId: string;
    // farmName: string;
    // groupCode: string;
    // groupName: string;
    // id: string;
    // isBind: boolean;
    // isGroup: boolean;
    // number: string;
    // registerTime: string;
    // registerUserName: string;
    // typeName: string;
    // warnType: number;
    orderNum: string;
    orderMsg: string;
    createTime: string   ;
    farmName: string;
    farmMobile: string;
    buyerName: string;
    buyerMobile: string;
    typeConf: string;
    quantity: string;
    payAmount: string;
    payPoundageTime: string   ;
    payStatus: string;
    isOrNotApply: string;
    endTime:string   ;
    payStartTime:string   ;
    companyId: any;

    name: string;
}

export interface Focu{
    Enterprisename:number | string ,
    contacts:number | string,
    tel:number | string,
    address:number | string,
    Fulladdress:number | string,
    state:number | string,
}

export interface LabelManageFrom extends DeviceGroupingDetail {
    number: string;
    isBind: ''|number;
}

export interface LabelManageDetailForm<T = string> {
    type: T;
    number: T;
}

export class LabelManage extends BasicsClass {
    public getTable(form: LabelManageFrom, tablePage: HandleTable, cb: Function): void {

        this.BasicGet('/animal/web/tagNb/list', { //列表
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length - 1],
        }, false, true, (data: AxiosData<LabelManageTable[]>) => {

            cb(data);
        });
    }

    public deviceGroup(form: LabelManageTable, deviceCode: LabelManageTable[], cb: Function) {
        const numbers: string[] = [];
        for (const i of deviceCode) {
            numbers.push(i.number);
        }

        this.BasicPost('/animal/web/tag/groupTags', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            numbers: numbers.toString()
        }, true, true, true, cb);
    }

    // public del(id: string, cb: Function): void {
    //     this.BasicPost('/asset/assetLabel/delete', {id}, true, true, false, cb);
    // }
    //
    // public addLabel(form: LabelManageDetailForm, cb: Function): void {
    //     this.BasicPost('/asset/assetLabel/save', form, true, true, true, cb);
    // }
    //
    public addMoreLabel(file: FileList, form: DeviceGroupingDetail, cb: Function): void {
        this.ImportFile('/animal/web/tag/groupTagsByExport', file, 'file', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length-1]
        }, cb);
    }

    public getTemplate(): void {
        this.getFile('/animal/web/tag/exportLabelTemplate', {});
    }
}
                //类名          
export class Addnumber extends BasicsClass{
            //方法名  //传的数据  //cb回调
    public addnumber(form : any,cb:Function) {
            //请求方式 BasicPost post  BasicGet get       //请求地址
        this.BasicPost('/animal/web/tagNb/saveNb',{
            ...form,//数据
            //...tablePage //页码
            // groupCode: form.groupCode[form.groupCode.length - 1],
        },true, true, true, cb)
    }
}
//买卖用户管理查询
export class Usermanagement extends BasicsClass{
    // public getTable(form: LabelManageFrom, tablePage: HandleTable, cb: Function): void {

    //     this.BasicGet('/animal/web/tagNb/list', { //列表
    //         ...form,
    //         ...tablePage,
    //         groupCode: form.groupCode[form.groupCode.length - 1],
    //     }, false, true, (data: AxiosData<LabelManageTable[]>) => {

    //         cb(data);
    //     });
    // }


    //获取订单列表
    public getTable(form : any , tablePage: HandleTable , cb:Function):void{
        // const startTime = Util.handleStartTimeAndEndTime(form.startTime);
        // const payStartTime = Util.handleStartTimeAndEndTime(form.payStartTime);
        // form.startTime = startTime.startTime;
        // form.payStartTime = payStartTime.startTime;
        // form.endTime	 = startTime.endTime;
        // form.payEndTime = payStartTime.endTime;
        // console.log(form)

        const startTime = Util.handleStartTimeAndEndTime(form.startTime);
        const payStartTime = Util.handleStartTimeAndEndTime(form.payStartTime);
        console.log(startTime)

        this.BasicGet('animal/web/orderManage/orderList',{
            ...form,
            ...tablePage,
            startTime: startTime.startTime,
            endTime: startTime.endTime,
            payStartTime: payStartTime.startTime,
            payEndTime: payStartTime.endTime
        },false,true,cb)
    }
    //添加企业
    public addEnterprise(form : any , cb:Function){
        this.BasicPost('',{
            ...form,
        },true, true, true, cb)
    }
    //查询
    public search(form : any , cb:Function){
        this.BasicGet('',{
            ...form,
        },false,true,cb)
    }
    //买家企业列表
    public getCompanyListSelect(form: any, cb: Function): void {
        this.BasicGet('animal/web/orderManage/companyList',{
            ...form
        }, false, true, cb);
    }
    //买家企业列表
    public getBuyerNameList(form: any, cb: Function): void {
        this.BasicGet('animal/web/buyerUser/buyerNameList',{
            ...form
        }, false, true, cb);
    }
    //订单详情页  table请求
    public GetAnimalList(form:any ,tablePage: HandleTable, cb:Function):void{
        console.log(form)
        this.BasicGet('animal/web/orderManage/animalList',{
            ...form,
            ...tablePage
        },false,false,cb)
    }
}