
























































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { HandlePageChange, HandleTable } from "@/common/Views/comm/handleTable";
import {
  ModelTypeManage,
  ModelTypeManageTableData,
} from "@/common/Views/aiModel/modelTypeManage/modelTypeManage";
import { AxiosData } from "@/common/http/http";
import {
  ArgsResult,
  ModelArgsManage,
  ModelArgsManageForm,
  ModelArgsManageTableData,
} from "@/common/Views/aiModel/modelArgsManage/modelArgsManage";
import { Util } from "@/common/Views/comm/util";

@Component
export default class HelloWorld extends Vue {
  private form: ModelArgsManageForm = {
    typeId: "",
    modelId: "",
    desc: "",
    isBind: "",
    status: "",
  };

  private tablePage: HandleTable = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private tableData: ModelArgsManageTableData[] = [];
  private modelNameList: ModelTypeManageTableData[] = [];

  private changeModelType() {
    this.modelNameList = [];
    this.form.modelId = "";

    this.getModelNameList();
  }

  private modelTypeManage: ModelTypeManage = new ModelTypeManage();
  private modelArgsManage: ModelArgsManage = new ModelArgsManage();

  private activated() {
    this.search();
    this.getModelTypeList();
  }

  private modelTypeList: ModelTypeManageTableData[] = [];

  private getModelTypeList() {
    this.modelTypeManage.getTable(
      "",
      {},
      (data: AxiosData<ModelTypeManageTableData[]>) => {
        this.modelTypeList = data.data;
      }
    );
  }

  private getModelNameList() {
    this.modelArgsManage.getModelNameList(
      this.form.typeId,
      (data: AxiosData<ModelTypeManageTableData[]>) => {
        this.modelNameList = data.data;
      }
    );
  }

  private restForm() {
    this.form = new Util().clearObject(this.form);
    this.modelNameList = [];
    this.search();
  }

  private search() {
    this.tablePage.pageNo = 1;
    this.tablePage.totalCount = 0;
    this.tableData = [];
    this.getTable();
  }

  private handlePageChange(val: HandlePageChange) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }

  private maxCount: number = 0;
  private getTable() {
    this.maxCount = 0;
    this.modelArgsManage.getTable(
      this.form,
      this.tablePage,
      (data: ArgsResult) => {
        // this.tableData = data.data;
        console.log(data);
        this.maxCount = data.maxCount;
        this.tableData = data.tableData;
        this.tablePage.totalCount = data.page.totalCount;
      }
    );
  }

  private goDetail(row: ModelArgsManageTableData) {
    console.log(row);
    this.$router.push({
      path: "/modelArgsManageDetail",
      query: {
        modelArgsManageId: row.groupId,
        modelArgsManagegroupId: row.id,
        modelArgsManageInfo: JSON.stringify(row),
      },
    });
  }
}
