import { render, staticRenderFns } from "./column.vue?vue&type=template&id=5d555c1d&scoped=true&"
import script from "./column.vue?vue&type=script&lang=ts&"
export * from "./column.vue?vue&type=script&lang=ts&"
import style0 from "./column.vue?vue&type=style&index=0&id=5d555c1d&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d555c1d",
  null
  
)

export default component.exports