import { render, staticRenderFns } from "./medicineConfig.vue?vue&type=template&id=9851b186&scoped=true&"
import script from "./medicineConfig.vue?vue&type=script&lang=ts&"
export * from "./medicineConfig.vue?vue&type=script&lang=ts&"
import style0 from "./medicineConfig.vue?vue&type=style&index=0&id=9851b186&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9851b186",
  null
  
)

export default component.exports