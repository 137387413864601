



































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Result, Column} from "@/common/Views/system/column/column";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {optionSelect, Rule} from "@/common/Views/comm/formRule";
import {ConfType} from "@/common/Views/system/confType/confType";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {AxiosData} from "@/common/http/http";
import {
    FarmDetailForm,
    FarmDetail,
    FarmDetailDetail,
    CityList, Url
} from "@/common/Views/system/farm/farmDetail/farmDetail";
import {Util} from "@/common/Views/comm/util";
import TitleSolt from '@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue';
import {Firm} from "@/common/Views/system/firm/firm";

@Component({
    components: {TitleSolt}
})
export default class HelloWorld extends Vue {
    private form:any={
        id:'',
        groupCode:[],
        detailAddress:'',
        contactPerson:'',
        contactPhone:'',
        auditStatus:'',
        enableStatus:'',
        name:'',
        companyType:'',
        newGroupCode:'',
        companyBreedType:''
    }

    private column = new Column();

    private rules: any= {
        name:[
            {required: true, message: "请填写企业名称"}
        ],
        contactPhone: [
            {required: true, message: "请填写联系电话"},
            // {
            //     message: "手机号码输入错误", validator: (rule: any, value: any, callback: any) => {
            //         if (!/^[0-9]{11}$/g.test(value)) {
            //             callback(new Error());
            //         } else {
            //             callback();
            //         }
            //     }
            // },
        ],
        contactPerson: [
            {required: true, message: "请填写联系人"}
        ],
        enableStatus: [
            {required: true, message: "请选择启用状态"}
        ],
        groupCode: [
            {required: true, message: "请选择所属地址"}
        ],
        detailAddress: [
            {required: true, message: "请填写详细地址"}
        ],
        auditStatus: [
            {required: true, message: "请选择审核状态"}
        ]
    };

    private treeData: Result[] = [];
    private props:any = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "id",
    }
    private getTree(cb?: Function): void {
        const column: Column = new Column();
        column.getTreeData1(undefined , (data:any) => {
            console.log(data)
            this.treeData = data.data;
        })
    }
    private changeGroupCode(val:any){
        this.form.newGroupCode = val[val.length-1] + ''
    }

    private audits: any[] = [
        {id: '1', name: "审核通过"},
        {id: '2', name: "审核拒绝"}
    ];
    private statusOptions: optionSelect<number>[] = [
        {
            value: 1,
            label: "启用",
        },
        {
            value: 2,
            label: "禁用",
        },

    ];

    //save
    private firm: Firm = new Firm()
    private save(){
        (this.$refs.form as any).validate((valid: boolean) => {
            
            if (valid) {
                if(this.form.auditStatus == '审核通过'){
                    this.form.auditStatus = '1'
                }
                if(this.form.auditStatus == '审核拒绝'){
                    this.form.auditStatus = '2'
                }

                //启用状态
                if(this.form.enableStatus == '启用'){
                    this.form.enableStatus = '1'
                }
                if(this.form.enableStatus == '禁用'){
                    this.form.enableStatus = '2'
                }
                this.firm.submit101(this.form, () => {
                    this.$router.go(-1);
                });
                // console.log(this.formLabelAlign)
            }
        });
    }
    private groupText:any = ''
    activated(){
        // console.log(JSON.parse(this.$route.query.item))
        
        if(this.$route.query.item){
            let activatedQuery:any = JSON.parse(this.$route.query.item)
            // routeQuery
            this.form.name = activatedQuery.name
            this.form.contactPerson = activatedQuery.contactPerson
            this.form.contactPhone = activatedQuery.contactPhone
            this.form.newGroupCode = activatedQuery.groupCode
            this.form.detailAddress = activatedQuery.detailAddress
            this.form.id = activatedQuery.id + ''
            this.form.companyType = activatedQuery.companyType
            let domsVc:any = this.$refs.cascaderRef
            domsVc.$el.children[0].children[0].value = activatedQuery.groupName
            this.groupText = activatedQuery.groupName

            this.form.auditStatus = activatedQuery.auditStatus
            this.form.enableStatus = activatedQuery.enableStatus + ''

            //启用状态
            if(this.form.enableStatus == 1){
                this.form.enableStatus = '启用'
            }
            if(this.form.enableStatus == 2){
                this.form.enableStatus = '禁用'
            }

            //待审核
            this.form.auditStatus = activatedQuery.enableStatus
            
            if(this.form.auditStatus == 1){
                this.form.auditStatus = '审核通过'
            }
            if(this.form.auditStatus == 2){
                this.form.auditStatus = '审核拒绝'
            }
            console.log(this.form)
        }
    }

    mounted(){
        this.getTree()
    }

    @Watch("$route" , {deep:true , immediate:true})
    private changeRoute(newVal:any){
        // console.log(newVal.query.item)
        if(newVal.query.item){
            let routeQuery = JSON.parse(newVal.query.item)
            console.log(routeQuery)
            // routeQuery
            this.form.name = routeQuery.name
            this.form.contactPerson = routeQuery.contactPerson
            this.form.contactPhone = routeQuery.contactPhone
            this.form.newGroupCode = routeQuery.groupCode
            this.form.detailAddress = routeQuery.detailAddress
            this.form.id = routeQuery.id + ''

            this.form.companyType = routeQuery.companyType


            this.groupText = routeQuery.groupName

            this.form.auditStatus = routeQuery.auditStatus
            this.form.enableStatus = routeQuery.enableStatus + ''

            this.$nextTick(() =>{
                this.form.companyBreedType = routeQuery.companyBreedType
                console.log(routeQuery.companyBreedType),
                this.$forceUpdate
            })

            //启用状态
            if(this.form.enableStatus == 1){
                this.form.enableStatus = '启用'
            }
            if(this.form.enableStatus == 2){
                this.form.enableStatus = '禁用'
            }

            //待审核
            this.form.auditStatus = routeQuery.enableStatus
            
            if(this.form.auditStatus == 1){
                this.form.auditStatus = '审核通过'
            }
            if(this.form.auditStatus == 2){
                this.form.auditStatus = '审核拒绝'
            }
        }
    }


    private resGroup: any[] = [];
    private handleCascader(start: any): void {
    if (start == true) {
      // console.log(this.resGroup.length);
      if (this.resGroup.length == 0) {
        const column: Column = new Column();
        this.resGroup = this.treeData;
        column.getTissuc(undefined, (res: any) => {
          // console.log(res, 11111)
          this.treeData = res.data;
          // this.form.groupCode = []
        });
      }
      if (this.treeData[0].id == this.resGroup[0].id) {
        const column: Column = new Column();
        this.resGroup = this.treeData;
        column.getTissuc(undefined, (res: any) => {
          // console.log(res, 11111)
          this.treeData = res.data;
          this.form.groupCode = [];
        });
      }
    } else {
      // console.log(this.form.groupCode);
      if (this.form.groupCode[0] != "0") {
        this.treeData = this.resGroup;
        this.form.groupCode.push(this.resGroup[0].id);
      }
    }
  }
  private addressNumberArray :any = []
  private flagBool : boolean = false

  private testValue(val:any){
    console.log(this.form.groupCode)
    this.addressNumberArray = this.form.groupCode
    this.form.newGroupCode = val[val.length-1] + ''
  }
  @Watch('addressNumberArray',{ deep: true })
  valueChange(newValue: any, oldValue: any) {
    console.log('addressNumberArray')
    console.log(newValue, oldValue)
    if(newValue.length <5){
      this.flagBool = true
    }else{
      return false
    }
  }

  private flagState(){
    if(this.form.groupCode){
      this.$message.error('所属机构，必须要选择到镇/街道级别');
    }
  }
}
