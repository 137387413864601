






import {Watch , Prop , Component , Vue} from 'vue-property-decorator'
@Component({})
export default class AdressComponentsVue extends Vue{
    @Prop() FormAdressVal:any
    @Prop() AdressTreeData:any
    @Prop() AdressPlaceHolderText:any
    private AdressChildTreeData:any[] = []
    private AdressVal:any[] = [] //选择所绑定的值
    
    private props:Object = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "id"
    }

    //处理数据
    private deleteLastChildren(data:any){//处理组织机构树
        let treeData:any[] = data
        treeData.forEach((itemOne:any) => {
            itemOne.children.forEach((itemTwo:any) => {
                itemTwo.children.forEach((itemThree:any) => {
                    itemThree.children.forEach((itemFour:any) => {
                        itemFour.children.forEach((itemFive:any) => {
                            delete itemFive.children
                        });
                    });
                });
            });
        });
        return treeData
    }

    private ChangeAdressValue(){
        this.$emit('SetAdressVal' , this.AdressVal)
    }

    @Watch('FormAdressVal',{immediate:true,deep:true})
    private changeData(newVal:any){
        this.AdressVal = newVal
        // let domsVc:any = this.$refs.CascaderRef
        // domsVc.$el.children[0].children[0].value = ""  //编辑数据赋值
    }
    @Watch('AdressTreeData',{immediate:true,deep:true})
    private changeAressData(newVal:any){
        if(newVal == undefined) return
        let data:any = JSON.parse(JSON.stringify(newVal))
        this.AdressChildTreeData = this.deleteLastChildren(data)
    }
}
