











































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
@Component({})
export default class StallManage extends Vue {
  private form: any = {
    username: "",
    mobile: "",
  };

  private tablePage: any = {
    pageNo: 1,
    pageSize: 0,
    totalCount: 0,
  };

  // 重置
  private restForm() {}

  // 查询
  private search() {}

  private handlePageChange() {}
}
