import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {Util} from '@/common/Views/comm/util';
import {CountExceptionFrom} from '@/common/Views/basics/countException/countException';

export class AppVersions extends BasicsClass {

    public getTable(form: CountExceptionFrom, tablePage: HandleTable, cb: Function) {
        const time = Util.handleStartTimeAndEndTime(form.time);
        this.BasicGet('/animal/web/appVersion/list', {
            ...form,
            time: undefined,
            ...tablePage,
            ...time,
        }, false, true, cb, cb);
    }
}