import { BasicsClass } from '@/common/BasicsClass';
import { ProductListInfo } from '@/Interface/WeChatApplet/AboutShop/index'
import { HandleTable } from '@/Interface/CommonModule/TableAndPage'
import { EditCheckStatusInfo } from '@/Interface/WeChatApplet/AboutShop/index'

export class AboutShop {
    public getProductList = (form: ProductListInfo, page: HandleTable, cb: Function) => { //商品列表
        new BasicsClass().BasicGet('/animal/web/appletProductManage/getProductList', { ...form, ...page }, false, false, cb)
    }

    public getAppletBreed = (cb: Function) => { //商品品种
        new BasicsClass().BasicGet('/animal/web/appletProductManage/getAppletBreed', {}, false, false, cb)
    }

    public getAppletType = (cb: Function) => { //商品类型
        new BasicsClass().BasicGet('/animal/web/appletProductManage/getAppletType', {}, false, false, cb)
    }

    public getDetail = (id: string, cb: Function) => { //获取商品详情
        new BasicsClass().BasicGet('/animal/web/appletProductManage/getDetail', { id }, false, false, cb)
    }

    public editCheckStatus = (form: EditCheckStatusInfo, cb: Function) => { //商品审核
        new BasicsClass().BasicPost1("/animal/web/appletProductManage/editCheckStatus", { ...form }, true, true, true, cb)
    }
}