


























































































































































































    import {Component, Vue} from "vue-property-decorator";
    import {HandlePageChange, HandleTable} from "@/common/Views/comm/handleTable";
    import {ModelTypeManage, ModelTypeManageTableData} from "@/common/Views/aiModel/modelTypeManage/modelTypeManage";
    import {AxiosData} from "@/common/http/http";
    import {ComboList, Column} from "@/common/Views/system/column/column";
    import {ConfType} from "@/common/Views/system/confType/confType";
    import {TransferRecord} from "@/common/Views/traceabilityOutManage/transferRecord";
    import {
        BenchmarkSetManage,
        BenchmarkSetManageFrom,
        BenchmarkSetManageTableData
    } from "@/common/Views/aiModel/benchmarkSetManage/benchmarkSetManage";
    import {Util} from "@/common/Views/comm/util";
    import {Rule} from "@/common/Views/comm/formRule";
    import {ArgsResult, ModelArgsManage} from "@/common/Views/aiModel/modelArgsManage/modelArgsManage";
    import {ArgsBindManage, ArgsBindManageForm} from "@/common/Views/aiModel/argsBindManage/argsBindManage";

    @Component
    export default class HelloWorld extends Vue {

        private form: ArgsBindManageForm = {
            farmId: "",
            houseId: "",
            columnId: "",
            labelNumber: "",
            markId: "",
            typeId: "",
            modelId: "",
            groupId: "",
        };

        private setFormModelId(data: any) {
            this.form.groupId = data.row.groupId;
            this.form.modelId = data.row.id;
        }

        private restForm() {
            this.form = new Util().clearObject(this.form);
            if (this.modelTypeList.length > 0) {
                this.form.typeId = this.modelTypeList[0].id;
                this.changeModelType();
            }
            this.search();
        }

        private tablePage: HandleTable = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };

        private tablePage1: HandleTable = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };

        private tableData: BenchmarkSetManageTableData[] = [];

        private benchmarkSetManage: BenchmarkSetManage = new BenchmarkSetManage();

        private async activated() {
            await this.changeGroupCode();
            await this.getModelTypeList();
            this.changeModelType();
            this.search();
        }


        private modelNameList: ModelTypeManageTableData[] = [];

        private changeModelType() {
            this.modelNameList = [];
            this.form.modelId = "";

            this.getModelNameList();
        }

        private getModelNameList() {
            this.modelArgsManage.getTypeTable(this.form.typeId, this.tablePage1, (data: AxiosData<ModelTypeManageTableData[]>) => {

                this.modelNameList = data.data;

                this.tablePage1.totalCount = data.page.totalCount;
            });
        }

        private modelTypeManage: ModelTypeManage = new ModelTypeManage();
        private modelArgsManage: ModelArgsManage = new ModelArgsManage();

        private modelTypeList: ModelTypeManageTableData[] = [];

        private getModelTypeList() {
            return new Promise(resolve => {
                this.modelTypeManage.getTable("", {}, (data: AxiosData<ModelTypeManageTableData[]>) => {
                    this.modelTypeList = data.data;
                    resolve();
                });

            });
        }

        private confType(): void {
            this.houseList = [];
            this.columnList = [];
            this.form.houseId = "";
            this.form.columnId = "";

            new ConfType().getSelectAll2(this.form.farmId,0, (data: AxiosData<ComboList[]>) => {
                this.houseList = data.data;
            });

        }

        private transferRecord: TransferRecord = new TransferRecord();

        private confHouse(): void {
            this.columnList = [];
            this.form.columnId = "";
			if(this.form.houseId!=''){
				this.transferRecord.getChilder2(this.form.houseId,(res: AxiosData<ComboList[]>) => {
				    this.columnList = res.data;
				});
			}
        }

        private comboList: ComboList[] = [];
        private houseList: ComboList[] = [];
        private columnList: ComboList[] = [];

        private changeGroupCode() {
            return new Promise((resolve) => {
                this.comboList = [];
                // this.form.farmId = "";
                new Column().getFarm("0", (data: AxiosData<ComboList[]>) => {
                    resolve();
                    this.comboList = data.data;
                });

            });
        }

        private search() {
            this.tablePage.pageNo = 1;
            this.tablePage.totalCount = 0;
            this.tableData = [];
            this.getTable();
        }

        private handlePageChange1(val: HandlePageChange) {
			console.log(val)
            this.tablePage1.pageNo = val.currentPage;
            this.tablePage1.pageSize = val.pageSize;

            this.getModelNameList();
        }

        private handlePageChange(val: HandlePageChange) {

            this.tablePage.pageNo = val.currentPage;
            this.tablePage.pageSize = val.pageSize;

            this.getTable();
        }


        private maxCount: number = 0;
        private argsBindManage: ArgsBindManage = new ArgsBindManage();

        private getTable() {
            this.argsBindManage.getTable(this.form, this.tablePage, (data: ArgsResult) => {
                this.maxCount = 0;
                this.tableData = data.tableData;
                this.tablePage.totalCount = data.page.totalCount;
            });
        }

        private b(method: string) {
            this.$confirm('是否确认操作？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {

                if (method === "bind") {
					if (!this.form.modelId || !this.form.groupId) {
					    this.$message.error("请选择模型参数组");
					    return;
					}
                    this.bind();
                    this.getTable();
                }
                if (method === "unBind") {
                    this.unBind();
                    this.getTable();
                }
            });

        }

        private bind() {
            new ArgsBindManage().bind(this.form, () => {
                this.changeModelType();
            });
        }

        private unBind() {
            new ArgsBindManage().unBind(this.form, () => {
                this.changeModelType();
            });
        }

        private goDetail(row: ModelTypeManageTableData) {
            this.$router.push({
                path: "/benchmarkSetManageDetail",
                query: {
                    benchmarkSetManageId: row.id,
                    benchmarkSetManageInfo: JSON.stringify(row),
                }
            });
        }
    }
