



















































































import {Component , Watch , Vue} from 'vue-property-decorator'
import InputComonentVue from '@/views/basics/typeConfig/components/components/InputComonentVue.vue'
import {SaveTransactionSubmit , getTransactionInfo} from '@/api/index'
interface TransactionConfigData{
    id:string
    offlineServiceCharge:number //线下服务费
    offlineCompany:string //线下拆帐公司名称
    offlineRatio:number //线下公司拆账占比（小数 例如:0.1 = 10%）
    offlineCompanyAccount:string  //线下账号
    onlineServiceCharge:number //线上服务费
    onlineCompany:string//线上拆帐公司名称
    onlineCompanyAccount:string //线上账号
    onlineRatio:number//线上公司拆账占比
    isPlatformSupply:boolean //是否平台供货
    isThirdpartySupply:boolean //是否第三方供货
}
@Component({
    components:{InputComonentVue}
})
export default class TransactionConfig extends Vue{
    private form:TransactionConfigData = {
        offlineServiceCharge: 0,
        offlineCompany: '',
        offlineRatio: 0,
        onlineServiceCharge: 0,
        onlineCompany: '',
        onlineRatio: 0,
        isPlatformSupply: false,
        isThirdpartySupply: false,
        id: '',
        offlineCompanyAccount: '',
        onlineCompanyAccount: ''
    }
    private onlineRatio:number = 0 // 线上本公司拆账占比
    private offlineRatio:number = 0// 线下本公司拆账占比
    private MarketName:string = ''

    private rules:any ={}


    //事件
    private SaveData(){
        (this.$refs.form as any).validate((flag:boolean)=>{
            if(flag){
                if(this.form.isPlatformSupply === null)this.form.isPlatformSupply = false
                if(this.form.isThirdpartySupply === null)this.form.isThirdpartySupply = false
                let form = JSON.parse(JSON.stringify(this.form))
                form.offlineRatio = Number(form.offlineRatio) / 100
                form.onlineRatio = Number(form.onlineRatio) / 100
                SaveTransactionSubmit(form , (data:any)=>{
                    this.$router.push({
                        path: "/MarkList",
                    });
                })
            }
        })
        
    }

    //watch
    @Watch('form.offlineRatio',{deep:true})
    private ChangeofflineServiceCharge(newVal:number){
        this.offlineRatio =  100 - newVal
    }
    @Watch('form.onlineRatio',{deep:true})
    private ChangeonlineRatioServiceCharge(newVal:number){
        this.onlineRatio =  100 - newVal
    }


    //emit
    private SetofflineCompanyValue(val:string){//线下拆帐公司名称
        this.form.offlineCompany = val
    }
    private SetofflineCompanyAccountValue(val:string){ //线下账号
        this.form.offlineCompanyAccount = val
    }
    private SetonlineRatioValue(val:string){//线上拆帐公司名称
        this.form.onlineCompany = val
    }
    private SetonlineCompanyAccountAccountValue(val:string){ //线上账号
        this.form.onlineCompanyAccount = val
    }


    activated(){
        let id = (this.$route.query.id as string)
        this.form.id = id
        this.MarketName = ( this.$route.query.name as string)
        getTransactionInfo(id,(data:any)=>{
            this.form = {...data.data}
            this.form.offlineRatio = Number(this.form.offlineRatio) * 100
            this.form.onlineRatio = Number(this.form.onlineRatio) * 100
        })
    }
    
    
}
