

































































import {Component, Prop, Vue} from "vue-property-decorator";
// import {AxiosData} from "@/common/http/http";
// import {Util} from "@/common/Views/comm/util";
import {HandleTable, HandlePageChange} from "@/common/Views/comm/handleTable";
// import LabelManageDetailImport from "@/views/basics/labelManage/labelManageDetail/labelManageDetailImport.vue";
// import {LabelManage, LabelManageFrom, LabelManageTable} from "@/common/Views/basics/labelManage/labelManage";
// import { ComboList, Column, Result } from '@/common/Views/system/column/column';
// import DeviceGroup from '@/views/basics/deviceManage/deviceGroup/deviceGroup.vue';
// import {DeviceManageTable} from '@/common/Views/basics/deviceManage/deviceManage';
// import {CountException, CountExceptionFrom} from "@/common/Views/basics/countException/countException";
// import {TemperatureInfo} from '@/common/Views/basics/temperatureInfo/temperatureInfo';
// import {MedicineInfo} from '@/common/Views/basics/medicineInfo/medicineInfo';
// import {SchoolList} from '@/common/Views/basics/schoolManage/schoolManage';
// import {ConfType} from '@/common/Views/system/confType/confType';
// import { InfoRegister } from '@/common/Views/basics/infoRegister/infoRegister';
import { InsuranceForm, insuranceIdentity, InsuranceCompanyConfiguration } from '@/common/Views/system/insuranceCompanyConfiguration/insuranceCompanyConfiguration'

@Component({
    // components: {DeviceGroup, "label-manage-detail-import": LabelManageDetailImport}
})
export default class HelloWorld extends Vue {
    
    // public column = new Column()

    private form: InsuranceForm = {
        companyId: '',
        insuranceCompanyName: '',
        businessList: [],
        deleteCompany: false
    };

    private Identity: insuranceIdentity = {
        companyId: '',
        deleteCompany: ''
    }


    private insuranceCompanyConfiguration: InsuranceCompanyConfiguration = new InsuranceCompanyConfiguration();

    constructor() {
        super();
    }

    private activated(): void {
        this.getTable();
        // this.getTree();
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }



    private tableData: any[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }


    private getTable(): void {
        // if(this.$getResourceCodes('label_list')){
            this.tableData = []
            this.insuranceCompanyConfiguration.getTable( this.tablePage, (data: any) => {
                this.tablePage.totalCount = data.page.totalCount;
                this.tableData = data.data;
                console.log(data)
            });

        // }
    }

    private changeSwitch(item: any): void {
        console.log(item)
        this.Identity.companyId = item.id
        console.log(item.status)
        if(item.status){
            this.Identity.deleteCompany = 1
        }else{
            this.Identity.deleteCompany = 0
        }
        
        this.insuranceCompanyConfiguration.handleDisabled(this.Identity, (res: any) => {
            console.log(res)
        })
    }

    public goDetail(item: any): void {
        console.log(item)
        this.$router.push({
            path: '/insuranceDate',
            query: {
                id: item.id
            }
        })
    }

}
