





































































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Result, Column} from "@/common/Views/system/column/column";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {optionSelect, Rule} from "@/common/Views/comm/formRule";
import {ConfType} from "@/common/Views/system/confType/confType";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {AxiosData} from "@/common/http/http";
import {HarmlessDetailForm, HarmlessDetail, HarmlessDetailDetail, CityList} from "@/common/Views/system/harmless/harmlessDetail/harmlessDetail";
import {Util} from "@/common/Views/comm/util";
import TitleSolt from '@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue';


@Component({
    components: {TitleSolt}
})
export default class HelloWorld extends Vue {

    public column = new Column();

    public dialogVisible = false;
    public ImgList: { name: string, url: string }[] = [];

    private formLabelAlign: HarmlessDetailForm = {
        groupCode: [],
        mobile: "",
        person: "",
        status: 1,
        name: "",
        typeIds: [],
        simpleAddress: "",
        detailAddress: "",
        licenseUrl: "",
        auditStatus: '审核通过',
        createUserId: "",
        doorUrl: "",
        destinationTypeId: ""
    };

    private auditNow: any = NaN;
    private showMark1: boolean =  false;
    private showMark2: boolean =  false;
    private url: string =  '';

    // private treeData: Result[] = [];

    /*private getTree(cb?: Function): void {
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        column.getTreeData().then((result: Result[]) => {

            // this.treeData = new DeepLoop().deepCopy(result);

            if(cb) cb();
        }).catch(err => {
            this.$message.error(err.message);
        });
    }*/

    private statusOptions: optionSelect<number>[] = [
        {
            value: 1,
            label: "启用",
        },
        {
            value: 0,
            label: "禁用",
        },

    ];


    private activated(): void {
        // this.getTree();

        this.confType();
        /*this.column.getTissuc(undefined, (res: any) => {
            this.treeData = res.data
        })*/
    }


    private conTypeList: SchoolList<string>[] = [];

    private confType(): void {
        new ConfType().getList((data: AxiosData<SchoolList<string>[]>) => {
            this.conTypeList = data.data;
        });
    }

    private showDio(url: string) {
        this.dialogVisible = true;
        this.url = url;
    }

    private del(url:  keyof HarmlessDetailForm) {
        (<string>this.formLabelAlign[url]) = '';
        this.showMark1 = false;
        this.showMark2 = false;
    }

    private rules: HarmlessDetailForm<Rule[]> = {
        groupCode: [
            // @ts-ignore
            {type: "array", required: true, message: "请选择组织机构"}
        ],
        mobile: [
            {required: true, message: "请填写联系电话"},
            {
                message: "手机号码输入错误", validator: (rule: any, value: any, callback: any) => {
                    if (!/^[0-9]{11}$/g.test(value)) {
                        callback(new Error());
                    } else {
                        callback();
                    }
                }
            },
        ],
        person: [
            {required: true, message: "请填写联系人"}
        ],
        status: [
            {required: true, message: "请选择启用状态"}
        ],
        name: [
            {required: true, message: "请填写养殖场名称"}
        ],
        typeIds: [
            // @ts-ignore
            {type: "array", required: true, message: "请添加养殖类型"}
        ],
        simpleAddress: [
            {required: true, message: "请选择城市"}
        ],
        detailAddress: [
            {required: true, message: "请填写详细地址"}
        ],
        // licensePicture: [
        //     {required: true, message: "请上传照片"}
        // ],
        auditStatus: [
            {required: true, message: "请选择审核状态"}
        ]
        // typeIds: [],
    };

    private farmDetail: HarmlessDetail;

    constructor() {
        super();
        this.farmDetail = new HarmlessDetail();
    }

    private save(): void {
        (this.$refs.form as any).validate((valid: boolean) => {
            if (valid) {
                let id = "";
                if (this.$route.query.harmlessAudit) {
                    id = <string> this.$route.query.harmlessAudit;
                }
                this.farmDetail.submit(id, this.formLabelAlign, () => {
                    this.$router.go(-1);
                });
            }
        });

    }

    private getDetail(): void {
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);

        this.formLabelAlign.auditStatus = 1;
        // this.formLabelAlign.licenseUrl = {code: "license", base64ImgData: "", url: ""};
        // this.formLabelAlign.doorUrl = {code: "doorPicture", base64ImgData: "", url: ""};
        this.formLabelAlign.status = 1;
        if (this.$route.query.harmlessAudit) {
            this.farmDetail.getAudit(<string> this.$route.query.harmlessAudit, (data: AxiosData<HarmlessDetailDetail>) => {
                const Data = data.data;
                console.log(Data);
                // this.formLabelAlign.typeIds = Data.typeIds.split(",");
                this.formLabelAlign.status = Number(Data.status);
                this.formLabelAlign.mobile = Data.mobile;
                this.formLabelAlign.person = Data.person;
                this.formLabelAlign.name = Data.name;
                this.formLabelAlign.simpleAddress = Data.simpleAdressId;
                this.formLabelAlign.createUserId = Data.createUserId;
                if (Data.simpleAdressId) {
                    const addressId = Data.simpleAdressId.toString();
                    this.city.province = parseInt(addressId.substring(0, 2));
                    this.getCity(this.city.province, false);
                    this.getCity(parseInt(addressId.substring(0, 4)), false);
                    this.getCity(parseInt(addressId.substring(0, 6)), false);
                    this.city.theCity = parseInt(addressId.substring(0, 4));
                    this.city.area = parseInt(addressId.substring(0, 6));
                    this.city.street = parseInt(addressId);
                    // this.city.province = Data.simpleAddress.split('-')[0]
                    // this.city.theCity = Data.simpleAddress.split('-')[1]
                    // this.city.area = Data.simpleAddress.split('-')[2]
                }
                this.formLabelAlign.destinationTypeId = Data.destinationTypeId
                this.formLabelAlign.detailAddress = Data.detailAddress;
                // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: "", url: Data.licenseUrl}
                this.formLabelAlign.licenseUrl = Data.licenseUrl;
                this.formLabelAlign.doorUrl = Data.doorUrl;
                // this.licensePicture = Data.licenseUrl;
                // if (this.ImgList.length == 0) {
                //     this.ImgList.push({name: "1", url: Data.licenseUrl});
                // }




                // const arr: any = [];
                // DeepLoop.getDataRoleForDeepLoop(this.treeData, Data.groupCode, arr);
                // this.formLabelAlign.groupCode = arr;
            });
        }
    }


    private cityList0 = [];
    private cityList1 = [];
    private cityList2 = [];
    private cityList3 = [];
    private city: any = {
        province: "",
        theCity: "",
        area: "",
        street: "",
    };
    private dataImg: boolean = false;

    private audits: any[] = [
        {id: 1, name: "审核通过"},
        {id: 2, name: "审核拒绝"}
    ];


    public resGroup: Result[] = [];


    // private city: any = []

    private getCity(pid: any, flag: boolean = true): void {
        if (!pid) {
            pid = 0;
        }
        new HarmlessDetail().getCity(<any> pid, (data: CityList) => {
            if (pid == 0) {
                this.cityList0 = data.data;
            } else if (pid < 100 && pid > 10) {
                this.cityList1 = data.data;
                if (flag) {
                    this.city.street = "";
                    this.city.theCity = "";
                    this.city.area = "";
                }
            } else if (pid > 1000 && pid < 10000) {
                this.cityList2 = data.data;
                if (flag) {
                    this.city.street = "";
                    this.city.area = "";
                }
            } else if (pid > 10000 && pid < 1000000) {
                this.cityList3 = data.data;
                if (flag) {

                    this.city.street = "";
                }
            }
            // this.formLabelAlign.simpleAddress = this.$refs.province.selectedLabel + '-' + this.$refs.theCity.selectedLabel + '-' + this.$refs.area.selectedLabel
            this.formLabelAlign.simpleAddress = (<any>this.$refs.street).value;
        });
    }

    private handleAudit(): void {
        // this.formLabelAlign.auditStatus = '0'
    }


    private setImg(file: any, fileList: any): void {
        this.readImg(file, fileList, "licenseUrl");
    }

    private setImg1(file: any, fileList: any): void {
        this.readImg(file, fileList, "doorUrl");
    }

    private readImg(file: any, fileList: any, url: keyof HarmlessDetailForm) {
        const reader = new FileReader();
        let res = reader.readAsDataURL(file.raw);
        reader.onloadend = () => {
            this.dataImg = false;
            // var strBase64 = reader.result.substring(84);
            const strBase64 = (<string>reader.result).substring(0);
            // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: strBase64, url: ""};
            (<string>this.formLabelAlign[url]) = strBase64;
            console.log(this.formLabelAlign);
        };
        reader.onload = function(e) {
            console.log(e);
            // simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc
        };

    }

    private audit(): void {
        // console.log(this.city)
        if ((typeof (<any>this.$refs.auditNow).value) == "number") {
            this.formLabelAlign.auditStatus = (<any>this.$refs.auditNow).value;
        }
        console.log(this.formLabelAlign);
        this.farmDetail.audit(<string> this.$route.query.harmlessAudit, this.formLabelAlign, (data: any) => {
            this.$router.push("/harmless");
        });
    }

    private created() {
        this.getDetail();
        this.getCity(0);
        this.formLabelAlign.auditStatus = 1;
        this.auditNow = "审核通过";
    }


    private handleRemove(file: any): void {
        /*console.log(file);
        this.formLabelAlign.licenseUrl.base64ImgData = '';
        this.formLabelAlign.licenseUrl.url = '';
        this.ImgList = [];*/
    };

    private handlePictureCardPreview(file: any): void {
        this.formLabelAlign.licenseUrl = file.url;
        this.dialogVisible = true;
    }


    @Watch("$route")
    public handleRoute(): void {
        this.getDetail();
        this.ImgList = [];
        this.city = {
            province: "",
            theCity: "",
            area: "",
            street: "",
        };
    }
}
