import {BasicsClass} from '@/common/BasicsClass';

export class ConfType extends BasicsClass {
    public getList(cb: Function): void {
        this.BasicGet('/animal/web/confType/selectAll', {}, false, true, cb);
    }

    public getSelectAll(farmId: string, cb: Function): void {
        this.BasicGet('/animal/web/confTypeInfo/selectAll', {farmId}, false, true, cb);
    }

    public getBreedAll(farmId: string, cb: Function): void {
        this.BasicGet('/animal/web/confTypeInfo/selectAllBreed', {farmId}, false, true, cb);
    }
	
	public getSelectAll2(farmId: string,parentId:string, cb: Function): void {
	    this.BasicGet('/animal/web/animalFarmPlace/getThisAnimalFarmPlaceList', {farmId,parentId}, false, true, cb);
	}

    public getSelectAll3(farmId: string, cb: Function): void {
        this.BasicGet('/animal/web/orderManage/breedList', {farmId}, false, true, cb);
    }

    public getListDetail(typeId: string, cb: Function): void {
        this.BasicGet('/animal/web/confTypeInfo/listForType', {typeId}, false, true, cb);
    }
    public ignoreWarning(labelNumberList:any, cb: Function) {
        this.BasicPost1('/animal/web/deviceAlarm/ignoreAll', {
            labelNumberList
        }, true, true, true, cb)
    }
}
