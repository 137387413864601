






import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { BasicsClass } from "@/common/BasicsClass";

@Component({
    name: "SaveBtnVue",
})
export default class Role extends Vue {
    @Prop() SaveData: any;
    @Prop() RulesRef: any;
    @Prop() CheckBankList: any;

    private form: any = {};

    //保存
    private saveDataFun() {
        let obj = JSON.stringify(this.SaveData);
        this.form = JSON.parse(obj);
        if (this.form.roleId == 13 || this.form.roleId == 30) {
            if (this.CheckBankList.length === 0) {
                this.$message({
                    message: "请选择银行",
                    type: "warning",
                });
                return;
            }
        }

        this.RulesRef.validate((valid: any) => {
            if (valid) {
                Array.isArray(this.form.groupCode)
                    ? (this.form.groupCode = this.form.groupCode
                          .slice(-1)
                          .toString())
                    : this.form.groupCode; //将grounpcode变为 字符串
                this.form.status = 1;
                this.IsBankOrInsurance();
                if (
                    this.form.groupCode == "" ||
                    this.form.groupCode == undefined ||
                    this.form.groupCode == null ||
                    (this.form.groupCode == "undefined" &&
                        this.form.roleId != "10")
                ) {
                    this.$message({
                        type: "warning",
                        message: "请重新选择所属区域",
                    });
                } else {
                    new BasicsClass().BasicPost(
                        "/animal/web/appUserManagement/addUser",
                        { ...this.form },
                        true,
                        true,
                        true,
                        () => {
                            this.$emit("ClearForm", "");
                            this.$router.push({ path: "/appUser" });
                        }
                    );
                }
            }
        });
    }

    //判断 权限角色为 银行 保险
    private IsBankOrInsurance() {
        if (
            this.form.roleId == 13 ||
            this.form.roleId == 30 ||
            this.form.roleId == 14 ||
            this.form.roleId == 40
        )
            this.form.hasAllData = 0; //银行 保险 默认传1
        if (this.form.roleId == 13 || this.form.roleId == 30) {
            if (this.form.farmIds) {
                //如果是银行 那么赋值
                delete this.form.farmIds; //赋值之后删除
            }
            if (this.CheckBankList) {
                this.form.bankIds = this.CheckBankList;
            }
        }
        if (this.form.roleId == 14 || this.form.roleId == 40) {
            if (this.form.farmIds) {
                //如果是保险那么赋值
                this.form.insuranceCompanyId = this.form.farmIds.toString();
                delete this.form.farmIds; //赋值之后删除
            }
        }
    }
}
