






































































































































































    import {Component, Prop, Vue, Watch} from "vue-property-decorator";
    import {AxiosData} from "@/common/http/http";
    import {Util} from "@/common/Views/comm/util";
    import {HandleTable, HandlePageChange} from "@/common/Views/comm/handleTable";
    import LabelManageDetailImport from "@/views/basics/labelManage/labelManageDetail/labelManageDetailImport.vue";
    import {LabelManage, LabelManageFrom, LabelManageTable} from "@/common/Views/basics/labelManage/labelManage";
    import { ComboList, Column, Result } from '@/common/Views/system/column/column';
    import DeviceGroup from '@/views/basics/deviceManage/deviceGroup/deviceGroup.vue';
    import {DeviceManageTable} from '@/common/Views/basics/deviceManage/deviceManage';
    import {CountException, CountExceptionFrom} from "@/common/Views/basics/countException/countException";
    import {TemperatureInfo} from '@/common/Views/basics/temperatureInfo/temperatureInfo';
    import {MedicineInfo} from '@/common/Views/basics/medicineInfo/medicineInfo';
    import {SchoolList} from '@/common/Views/basics/schoolManage/schoolManage';
    import {ConfType} from '@/common/Views/system/confType/confType';
    import { InfoRegister } from '@/common/Views/basics/infoRegister/infoRegister';
    import { AppVersions } from '@/common/Views/basics/appVersions/appVersions';
    import QRcode from 'qrcode'
    import UpAppVer from '@/views/bigData/components/upAppVer.vue'
    import AddAppVer from '@/views/bigData/components/addAppVer.vue'

    @Component({
        components: {
            DeviceGroup, 
            "label-manage-detail-import": LabelManageDetailImport,
            UpAppVer,
            AddAppVer
        }
    })
    export default class HelloWorld extends Vue {
        
        public column = new Column()

        private form: any = {
            // groupCode: [],
            appName: '',
            time: [],
            appType: '',
        };

        public showMask: boolean = false

        private versionsMessage: boolean = false

        private versionsType = ''


        private show: boolean = false;

        private infoRegister: InfoRegister;
        private appVersions: AppVersions = new AppVersions()

        private appform: any = ''

        constructor() {
            super();
            this.infoRegister = new InfoRegister();
            
        }

        private activated(): void {
            this.getTable();
            // this.getTree();
            // this.confType();
            // this.changeGroupCode();
            // this.column.getTissuc(undefined, (res: any) => {
            //     this.treeData = res.data
            // })
        }


        // private treeData: Result[] = [];
        // private getTree() {
        //     Util.getTree((result: Result[])=>{
        //         this.treeData = result
        //     })
        // }

        private appNameList: any = []
        private getAppName(): void {

        }

        private appTypeList: any = []
        private getAppType(): void {

        }

        private restForm(): void {
            this.form = new Util().clearObject(this.form);
            this.form.time = [];
            this.getTable();
            // this.getTree();
            // this.confType();
            // this.changeGroupCode();
            
        }

        private search(): void {
            this.tablePage.pageNo = 1;
            this.tableData = [];
            this.tablePage.totalCount = 0;

            this.getTable();
        }

        private conso(aa): void {
            console.log(aa)
        }


        private comboList: ComboList[] = [];
        // private changeGroupCode(): void {
        //     this.comboList = [];
        //     new Column().getFarm(this.form.groupCode[this.form.groupCode.length - 1], (data: AxiosData<ComboList[]>)=>{
        //         this.comboList = data.data;
        //     })
        //     this.confType();
        // }

        // @Watch("form.groupCode")
        // getgroupCode(newVal:Array<object>,oldVal:Array<object>) {
        //     if (newVal !== oldVal) {
        //         this.form.farmId = '';
        //         this.form.typeConfId = ''; 
        //     }
        // }

        private conTypeList: SchoolList<string>[] = [];

        // private confType(): void {

        //     this.conTypeList = [];
        //     // this.form.typeConfId = '';
        //     new ConfType().getSelectAll(this.form.farmId, (data: AxiosData<SchoolList<string>[]>) => {
        //         this.conTypeList = data.data;
        //         console.log(data)
        //     });
        // }

        @Watch("form.farmId")
        getfarmId(newVal:Array<object>,oldVal:Array<object>) {
            if (newVal !== oldVal) {
                this.form.typeConfId = ''; 
            }
        }


        private tableData: LabelManageTable[] = [];

        private tablePage: HandleTable = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };

        private handlePageChange(val: HandlePageChange) {
            this.tablePage.pageNo = val.currentPage;
            this.tablePage.pageSize = val.pageSize;

            this.getTable();
        }


        private getTable(): void {
            if(this.$getResourceCodes('label_list')){
                this.tableData = []
                this.appVersions.getTable(this.form, this.tablePage, (data: AxiosData<LabelManageTable[]>) => {
                    this.tablePage.totalCount = data.page.totalCount;
                    this.tableData = data.data;
                });

            }
        }

        private compile(data: any): void {
            this.appform = data
            this.versionsType = 'up'
            this.versionsMessage = true
        }

        private versionsMessages(val:boolean):void{
            this.versionsMessage = val
            this.getTable();
        }
        private addVersions(): void {
            this.versionsType = 'add'
            this.versionsMessage = true
        }

        private codeImg = ''
        private handleDome(data: any): void {
            console.log(data.url)
            QRcode.toDataURL(data.url).then((res: any) => {
				this.codeImg = res
			})
            this.showMask = true
        }

        private closeMask(): void {
            this.showMask = false
            this.codeImg = ''
        }



        private rowData: LabelManageTable[] = [];
    }
