


































































































import {Watch , Component , Vue} from 'vue-property-decorator'

import SelectComponentVue from '@/views/basics/typeConfig/components/components/SelectComponentVue.vue'
import InputComonentVue from '@/views/basics/typeConfig/components/components/InputComonentVue.vue'
import AdressComponentsVue from '@/views/basics/typeConfig/components/components/AdressComponentsVue.vue'

import {BasicsClass} from '@/common/BasicsClass';
import {dateFun} from '@/common/date'
import { SimManage } from "@/common/Views/basics/simManage/simManage";
import {FarmDetail} from '@/common/Views/system/farm/farmDetail/farmDetail'
import {groupCodeLazy} from '@/views/system/appfire/components/groupCodeLazy'
@Component({
    components:{
        SelectComponentVue,
        InputComonentVue,
        AdressComponentsVue
    }
})
export default class ForageDemand extends Vue{
    private groupCodeLazy = new groupCodeLazy();
    private form:any={
        farmId:"",
        userName:"",//养殖户
        mobile:"",//养殖户电话
        orderStatus:"",//订单状态
        startTime:"",//开始下单时间：年月日时分秒
        endTime:"",//结束下单时间：年月日时分秒
        groupCode:"0",//养殖地组织机构
        forageTenant:"",//草料商家
        confTypeId:"",//草料类别id
        promotersName:"",//推广员姓名
        promotersPhone:"",//推广员手机号码
    }
    private tableData:any[] = [] //table 数据
    private tablePage:any ={  //分页数据
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }
    private SelectTime:any[] = []  //时间
    private AdressTreeData:any[] = [] //养殖地组织机构
    private ForageTypeList:any = [] //草料类别
    private ShelfStatus:any[] = [//订单状态
        {id:"",name:"请选择"},
        {id:"1",name:"已下单"},
        {id:"2",name:"已接单"},
        {id:"3",name:"已退单"},
    ] 
    private FarmListData:any[] = []


    //事件
    private SearchList(){//查询
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getTableListReq()
    }
    private ResetList(){ //重置
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.ClearFormData()
        this.getTableListReq()
    }
    private handlePageChange(val: any) { //分页 事件
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.getTableListReq() //请求列表数据
    }
    private OrderReceiving(status:any,row:any){//接单
        this.RequestForageOrder(status,row)
    }
    private Chargeback(status:any,row:any){//退单
        this.RequestForageOrder(status,row)
    }
    private exportlableList(){//导出
        this.ExportListReq()
    }



    // emit事件
    private SetFarmIdValue(val:string){
        this.form.farmId = val
    }
    private SetUserNameInputValue(val:string){ //养殖户姓名
        this.form.userName = val
    }
    private SetMobileInputValue(val:string){ //养殖户电话
        this.form.mobile = val
    }
    private SetOrderStatusValue(val:string){ //订单状态
        this.form.orderStatus = val
    }
    private SetGroupCodeValue(val:any[]){//养殖地组织机构
        this.form.groupCode = val[val.length-1]
    }
    private SetForageTenantValue(val:string){//草料商家
        this.form.forageTenant = val
    }
    private SetPromotersNameValue(val:string){ //推广员姓名
        this.form.promotersName = val
    }
    private SetPromotersPhoneValue(val:string){ //推广员手机号码
        this.form.promotersPhone = val
    }
    private SetConfTypeIdValue(val:string){ //草料类别id
        this.form.confTypeId = val
    }


    //数据处理
    @Watch('SelectTime')//处理下单时间
    private ChangeSlectTime(newVal:any[]){
        if(newVal.length === 0) {
            this.form.startTime = ''
            this.form.endTime = ''
            return
        }
        this.form.startTime = new dateFun().returnDate(newVal[0] , '-') + ' ' + new dateFun().returnHourMinuteSecend(newVal[0], ':')
        this.form.endTime = new dateFun().returnDate(newVal[1] , '-') + ' ' + new dateFun().returnHourMinuteSecend(newVal[1], ':')
    }
    private ClearFormData(){
        this.form = {
            farmId:"",
            userName:"",//养殖户
            mobile:"",//养殖户电话
            orderStatus:"",//订单状态
            startTime:"",//开始下单时间：年月日时分秒
            endTime:"",//结束下单时间：年月日时分秒
            groupCode:["0"],//养殖地组织机构
            forageTenant:"",//草料商家
            confTypeId:"",//草料类别id
            promotersName:"",//推广员姓名
            promotersPhone:"",//推广员手机号码
        }
        this.SelectTime = []
    }
    //处理组织机构树
    private deleteLastChildren(data:any){
        let treeData:any[] = data
        treeData.forEach((itemOne:any) => {
            itemOne.children.forEach((itemTwo:any) => {
                itemTwo.children.forEach((itemThree:any) => {
                    itemThree.children.forEach((itemFour:any) => {
                        itemFour.children.forEach((itemFive:any) => {
                            delete itemFive.children
                        });
                    });
                });
            });
        });
        return treeData
    }




    //请求数据
    private getTableListReq(){//请求table数据
    
        let FormData:any =JSON.parse(JSON.stringify(this.form))
        let obj:any ={...FormData}
        obj.groupCode = FormData.groupCode[FormData.groupCode.length-1]
        new BasicsClass().BasicGet('/animal/web/forageOrder/list',{...obj , ...this.tablePage},false,false,(data:any)=>{
            this.tableData = data.data
            this.tablePage.totalCount = data.page.totalCount
        })
    }
    private ExportListReq(){ //导出数据
        new SimManage().exportList2(this.form)
    }
    private getForageTypeListReq(){//草料类别
        this.ForageTypeList = []
        new BasicsClass().BasicGet('/animal/web/forage/getConfTypeId',{},false,false,(data:any)=>{
            this.ForageTypeList = data.data
            this.ForageTypeList.unshift({id:"",name:"请选择"})
        })
    }
    private getFarmListReq(){//获取养殖场列表
        this.FarmListData = []
        new BasicsClass().BasicGet('/animal/web/rebate/farmList',{},false,false,(data:any)=>{
            data.data.forEach((item:any) => {
                this.FarmListData.push({
                    id:item.farmId,
                    name:item.farmName
                })
            });
            this.FarmListData.unshift({id:"",name:"请选择"})
        })
    }
    private RequestForageOrder(status:any,row:any){//订单处理
        let obj:any ={
            orderStatus:status,
            orderDetailId:row.orderDetailId,
        }
        new BasicsClass().BasicPost('/animal/web/forageOrder/deal',{...obj},false,false,false,(data:any)=>{
            this.getTableListReq()
        })
    }
    //请求养殖场组织机构
    private getGroupCodeData(){
        new FarmDetail().getCity('','',(data:any)=>{
            //赋值处理过的数据
            this.AdressTreeData = this.deleteLastChildren(data.data)
            //默认显示中国
            this.form.groupCode = [0]
        })
    }


    activated(){
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getGroupCodeData()
        this.getForageTypeListReq()
        this.getFarmListReq()
        this.getTableListReq()
    }
}
