


























































































































































































































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import { BasicInfoModule } from "@/api/BasicInfoModule";
import LivePlayer from "@liveqing/liveplayer";
@Component({
  components: { PageBox, LivePlayer },
})
export default class merchandiseInfo extends Vue {
  private infoData = {};
  private weightImgList: any[] = []; // 体重图片列表
  private showBigVideo: boolean = false; // 是否显示大视频
  private BigUrl: string = ""; // 大视频地址
  private autoplay: boolean = true; // 是否自动播放
  private showControl: boolean = true; // 是否显示控制条
  private weightList: any[] = [
    "体长",
    "体高",
    "胸宽",
    "前脚长",
    "前腿宽",
    "脖宽",
  ]; // 体重列表
  private playVido() {
    this.showBigVideo = true;
    this.BigUrl = (this.infoData as { videoUri: string }).videoUri;
    console.log(this.BigUrl);
    
  }
  private closeVideo() {
    this.showBigVideo = false;
    this.BigUrl = "";
  }
  private failedPlay(e: any) {
    console.log("播放失败", e);
  }
  private getInfo() {
    const query = this.$route.query;
    new BasicInfoModule().getWeightInfo(query.id, (res: any) => {
      let data = res.data;
      data.bodySize = this.bodySizeAddName(data.bodySize);
      data.lastBodySize = this.bodySizeAddName(data.lastBodySize);
      this.infoData = data;
      this.weightImgList.push(data.weighingSnap);
      this.weightImgList.push(data.lastWeighingSnap);
    });
  }
  private bodySizeAddName(val: any) {
    val = JSON.parse(val);
    if (val == "" || val == null) {
      val = Array(this.weightList.length).fill({ name: "", partSize: "" });
    }
    val = val.map((element: any, index: any) => {
      return { name: this.weightList[index], partSize: element.partSize };
    });
    return val;
  }
  activated() {
    this.weightImgList = [];
    this.getInfo();
  }
  beforeDestroy() {
    this.infoData = {}; 
  }
}
