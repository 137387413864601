import { render, staticRenderFns } from "./SupplierManagement.vue?vue&type=template&id=58c82ba4&scoped=true&"
import script from "./SupplierManagement.vue?vue&type=script&lang=ts&"
export * from "./SupplierManagement.vue?vue&type=script&lang=ts&"
import style0 from "./SupplierManagement.vue?vue&type=style&index=0&id=58c82ba4&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58c82ba4",
  null
  
)

export default component.exports