











import { Component, Prop, Vue ,Watch} from "vue-property-decorator";
interface E {
  target: HTMLInputElement;
}
@Component
export default class HelloWorld extends Vue {
  @Prop() url!: any;


  private fileName: string = "请上传安装包文件";

  private file: FileList | null = null;

  private clean(): void {
    this.file = null;
    this.fileName = "";
    (<HTMLInputElement>this.$refs.input).value = "";
  }
  @Watch("url",{immediate:true,deep:true})
  private geturl():void{
    console.log(this.url)
    if(this.url){
      this.fileName=this.url
    }
  }
  private getFile(e: E): void {
    try {
      this.fileName = (<HTMLInputElement>e.target).files![0].name;
      this.file = (<HTMLInputElement>e.target).files;
      console.log(this.file)
      this.$emit("submit", this.file);
    } catch (e) {
      this.file = null;
      this.fileName = "";
      (<HTMLInputElement>this.$refs.input).value = "";
      this.$message.error("请选择导入的文件");
    }
  }
}
