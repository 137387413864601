import { BasicsClass } from '@/common/BasicsClass';
import { Time } from '../summaryAlarm/summaryAlarm';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { AxiosData } from '@/common/http/http';
import { Util } from '@/common/Views/comm/util';

export interface AssetRegistrationForm extends Time {
    locationId: string;
    addressId: string;
    typeId: string;
    labelType: string;
    labelNumber: string;
    name: string;
}

export interface AssetRegistrationTable {
    id: string;
    LocationId: string;
    locationName: string;
    addressId: string;
    addressName: string;
    typeId: string;
    typeName: string;
    name: string;
    identifier: string;
    labelType: number;
    labelNumber: string;
    labelTypeName: string;
    createTime: string;
    buyTime: string;
    createUserName: string;
    departmentName: string;
    locationId: string;
}

export interface ImportAsset<T> {
    locationId: T;
    itemType: T;
}

export enum LabelType {
    '电流监控标签' = 1,
    '有源电子标签',
    '无源电子标签'
}

export interface AssetTypeList {
    id: string;
    mchId: string;
    name: string;
    createTime: string;
    updateTime: string;
    createUserId: string;
    updateUserId: string;
    deleteFlag: boolean;
}

export interface RegisteringAssetForm<T> {
    locationId: T;
    addressId: T;
    typeId: T;
    name: T;
    identifier: T;
    labelType: T;
    labelNumber: T;
    departmentName: T;
    owner: T;
    buyTime: T;
}

export class AssetRegistration extends BasicsClass {
    public getTable(form: AssetRegistrationForm, tablePage: HandleTable, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time);
        this.BasicGet('/asset/assetItem/list', {
            ...form,
            ...tablePage,
            ...time
        }, false, true, (data: AxiosData<AssetRegistrationTable[]>) => {
            for (const i in data.data) {
                if (data.data[i].labelType) {
                    data.data[i].labelTypeName = LabelType[data.data[i].labelType];
                }
            }
            cb(data);
        });
    }

    public del(row: AssetRegistrationTable[], cb: Function): void {
        const ids: string[] = [];
        for (const i of row) {
            ids.push(i.id);
        }

        this.BasicPost('/asset/assetItem/delete', {
            id: ids.toString()
        }, true, true, false, cb);
    }

    public downLoadTemplate(): void {
        this.getFile('/asset/assetItem/exportItemTemplate', {});
    }


    public getbankList(form: any, cb: Function) {
        this.BasicGet('/animal/web/loanBasicInformationConfig/bankList', form, false, true, cb)
    }

    public getIntroduce(cb: Function) {
        this.BasicGet('/animal/web/basicLoan/getIntroduce', {}, false, true, cb)
    }


    public insertAllNew(form: any, cb: Function) {
        this.BasicPost('/animal/web/basicLoan/insertAllNew', form, true, true, true, cb)
    }

    public addOrUpdateBank(form: any, cb: Function): void {
        this.BasicPost('/animal/web/loanBasicInformationConfig/addOrUpdateBank', form, true, true, true, cb);
    }

    public getLoanUsedList(cb: Function) {
        this.BasicGet('/animal/web/basicLoan/getLoanUsedList', {}, false, true, cb)
    }

    public getConfTypeInfoList(cb: Function) {
        this.BasicGet('/animal/web/basicLoan/ConfTypeInfoList', {}, false, true, cb)
    }

    public getAllTypeInfo(cb: Function) {
        this.BasicGet('/animal/web/basicLoan/getAllTypeInfo', {}, false, true, cb)
    }

    public uploadFile(file: FileList, form: ImportAsset<string>, cb: Function): void {
        this.ImportFile('/asset/assetItem/importItemExcel', file, 'file', form, cb);
    }

    public insertAll(form: any, cb: Function): void {
        this.BasicPost('/animal/web/basicLoan/insertAll', form, true, true, true, cb);
    }

    public addAsset(form: { name: string }, cb: Function): void {
        this.BasicPost('/asset/assetType/save', form, true, true, false, cb);
    }

    public getDetail(id: string, cb: Function): void {
        this.BasicGet('/asset/assetItem/get', { id }, false, true, cb);
    }

    public submit(id: string | null, form: RegisteringAssetForm<string>, cb: Function): void {
        this.AddOrUpdate(id, {
            ...form,
            buyTime: Util.dateTimeUtil(form.buyTime),
        }, '/asset/assetItem/update', '/asset/assetItem/save', cb);
    }

    public Export(form: AssetRegistrationForm): void {
        this.getFile('/asset/assetItem/export', form)
    }

    //资产类别
    public getAssetTypeList(cb: Function): void {
        this.BasicGet('/asset/assetType/list', {}, false, true, cb);
    }

    //位置 级联
    public getAssetAdressList(cb: Function): void {
        this.BasicGet('/asset/location/list', {}, false, true, cb);

    }

    public getAddressByLocationId(locationId: string, cb: Function): void {
        this.BasicGet('/asset/assetAdress/getAddressByLocationId', { locationId }, false, true, cb);

    }

    public getAddAppVer(file: any, form: any, cb: Function): void {
        if (file) {
            this.ImportFile('/animal/web/appVersion/add', file, 'file', form, cb);
        } else {
            file = '';
            this.ImportFile('/animal/web/appVersion/add', file, 'file', form, cb);

        }
        // this.BasicPost('/animal/web/appVersion/add', form, true, true, false, cb);

    }

    public getAddAppVer2(file: any, form: any, cb: Function): void {
        if (file) {
            this.ImportFile('/animal/web/appVersion/update', file, 'file', form, cb);
        } else {
            file = '';
            this.ImportFile('/animal/web/appVersion/update', file, 'file', form, cb);

        }

    }

    public getAddAppVer3(form: any, cb: Function): void {
        this.BasicPost('/animal/web/appVersion/add', form, true, true, false, cb);
        // this.BasicPost('/animal/web/app/getVersion', form, true, true, false, cb);
    }
    public getAddAppVer4(form: any, cb: Function): void {
        this.BasicPost('/animal/web/appVersion/update', form, true, true, false, cb);
        // this.ImportFile('/animal/web/appVersion/update', file, 'file', form, cb);

    }
}
