





















































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Column, Result } from "@/common/Views/system/column/column";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { Firm } from "@/common/Views/system/firm/firm";
import { TableStudentData } from "@/common/Views/basics/studentManage/studentManage";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
import {
    getCompanysList,
    getprovinceCityDistrictForGroupCode,
} from "@/api/index";

import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";

@Component({
    components: { SelectComponentVue },
})
export default class HelloWorld extends Vue {
    public column = new Column();

    private props: Object = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "id",
    };

    private form: any = {
        groupCode: "0",
        // name: "",
        contactPerson: "",
        contactPhone: "",
        newGroupCode: "0",
        isInternational: "",
        auditStatus: "",
        enableStatus: "",
    };
    private auditStatusList = [
        {
            id: "",
            name: "全部",
        },
        {
            id: "0",
            name: "待审核",
        },
        {
            id: "1",
            name: "审核通过",
        },
        {
            id: "2",
            name: "审核拒绝",
        },
    ];
    private SetauditStatusValue(val: string) {
        this.form.auditStatus = val;
    }
    private enableStatusList = [
        {
            id: "",
            name: "全部",
        },
        {
            id: "1",
            name: "启用",
        },
        {
            id: "2",
            name: "禁用",
        },
    ];
    private SetenableStatusValue(val: string) {
        this.form.enableStatus = val;
    }

    private activated(): void {
        this.getTree(() => {
            this.getTable();
            this.getEnterprise(this.form.groupCode, this.form.isInternational);
        });

        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    // private querySearchAsync(queryString: string, cb: Function) {
    //     this.firm.queryCompany(this.form.simpleAddress, (data: AxiosData<{value: string}[]>)=>{
    //         console.log(data.data)
    //         cb(data.data);
    //     })
    // }

    private restaurants: any[] = [];

    private getEnterprise(queryCompany: any, isInternational: any): void {
        this.restaurants = [];
        new Firm().queryCompany(queryCompany, isInternational, (res: any) => {
            res.data.forEach((item: any) => {
                this.restaurants.push({
                    name: item,
                    value: item,
                });
            });
        });
    }

    // private querySearch(queryString: any, cb: Function): void  {
    //     var restaurants = this.restaurants;
    //     var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
    //     cb(results);
    // }

    private createFilter(queryString: any) {
        return (restaurant: any) => {
            return (
                restaurant.value
                    .toLowerCase()
                    .indexOf(queryString.toLowerCase()) === 0
            );
        };
    }

    // private handleSelect(item: any): void {
    //     this.form.name = item;
    // }

    // private handleSelect(item: any): void  {
    //     if(item.enterpriseName){
    //         this.form.enterpriseName = item.enterpriseName
    //     }else{
    //         this.form.farmName = item.name
    //     }

    //     console.log(item)
    // }

    private treeData: Result[] = [];
    private getTree(cb?: Function): void {
        getprovinceCityDistrictForGroupCode((data: any) => {
            this.treeData = new DeepLoop().deepCopy(data.data);
            this.form.isInternational = this.treeData[0].isInternational;
            this.form.groupCode = this.treeData[0].id;
            cb ? cb() : "";
        });
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.getTable();
    }

    private restForm(): void {
        this.form = new Util().clearObject(this.form);
        console.log(this.form);

        this.getTree(() => {
            this.getTable();
        });
        this.getEnterprise(this.form.groupCode, this.form.isInternational);
    }

    private tableData: any[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }

    private firm: Firm;

    constructor() {
        super();
        this.firm = new Firm();
    }

    private newGroupCode: any = "";
    private changeGroupCode(val: any) {
        let nodeData: any = (this.$refs.TreeRef as any).getCheckedNodes();
        this.form.isInternational = nodeData[0].data.isInternational;
        if (val.length > 1) {
            this.form.newGroupCode = val[val.length - 1];
        }
        if (val.length == 1) {
            this.form.newGroupCode = "0";
        }
        let groupCode: any = val[val.length - 1];
        this.getEnterprise(groupCode, this.form.isInternational);
    }

    private getTable(): void {
        console.log(this.form);
        this.tableData = [];
        getCompanysList(this.form, this.tablePage, (data: any) => {
            this.tablePage.totalCount = data.page.totalCount;
            this.tableData = data.data;
        });
    }

    private addFlag: boolean = true;
    private addFirm(): void {
        this.$router.push({
            path: "/firmDetail",
            query: {
                addFlag: true,
            },
        });
    }

    private goDetail(row: any): void {
        this.$router.push({
            path: "/firmDetail",
            query: {
                item: JSON.stringify(row),
                addFlag: false,
            },
        });
    }

    private goAudit(row: any): void {
        this.$router.push({
            path: "/firmAudit",
            query: {
                item: JSON.stringify(row),
            },
        });
    }

    private scrollEvent() {
        this.closeAllDropDown();
    }
    closeAllDropDown() {
        const SELECTWRAP_DOWNALL = document.querySelectorAll(
            ".el-autocomplete-suggestion"
        ); // select下拉框
        SELECTWRAP_DOWNALL.forEach((item: any) => {
            item.style.display = "none";
        });
    }
}
