
























import { Component, Prop, Vue } from "vue-property-decorator";

import { BasicsClass } from "@/common/BasicsClass";

//引入请求ts
import { FarmData } from "@/views/components/FarmGetData";
@Component({})
export default class FarmSearchAndClear extends Vue {
    @Prop() form: any;

    @Prop() sendUrl: any;

    @Prop() tablePage: any;

    @Prop() sendExportUrl: any;

    private resultList: any[] = [];

    //查询列表
    private getSearchData() {
        new BasicsClass().BasicGet(
            this.sendUrl,
            { ...this.form, ...this.tablePage },
            false,
            false,
            (data: any) => {
                this.resultList = data;

                //将结果传递给 父组件
                this.$emit("searchList", this.resultList);
            }
        );
    }

    private search() {
        //执行查询
        this.getSearchData();
    }

    private restForm() {
        //重置
        this.$emit("restForm", "");
    }

    // '/animal/web/breedAndInsuredStatistics/exportBreedList'
    //导出
    private sendExportUrlStr: any = "/animal/web/" + this.sendExportUrl;
    private exportlableList() {
        new FarmData().exportlableList2(
            this.sendExportUrlStr,
            this.form,
            (data: any) => {
                // alert(data)
            }
        );
    }
}
