import {BasicsClass} from '@/common/BasicsClass';
import { FarmDetailForm } from '../../farm/farmDetail/farmDetail';

export interface SlaughterDetailForm<T=string> {
    groupCode: T[];
    mobile: T;
    person: T;
    status: T | number;
    name: T;
    // typeIds: T[];
    simpleAddress: T;
    detailAddress: T;
    licenseUrl: T;
    auditStatus: T | number,
    destinationTypeId: T,
    createUserId: T | Number,
    doorUrl: T;
}

export interface Url {
    code: string;
    base64ImgData: string;
    url: string;
}

export interface SlaughterDetailDetail {
    createTime: string;
    simpleAdressId: string;
    createUserId: string;
    groupCode: string;
    id: string;
    mobile: string;
    name: string
    permissionFarmIdList: string[];
    permissionFarmIds: string;
    permissionGroupCode: string;
    person: string;
    status: boolean;
    typeIds: string;
    updateTime: string;
    auditStatus: number;
    simpleAddress: string;
    detailAddress: string;
    licenseUrl: string;
}

interface Pictures {
    code: string;
    base64ImgData: string;
    url: string;
}

export interface CityList {
    data: []
}

export class SlaughterDetail extends BasicsClass {
    public submit(id: string, form: FarmDetailForm, cb: Function): void {
        this.AddOrUpdate(id, {
            ...form,
            // typeIds: form.typeIds.toString(),
            groupCode: form.groupCode[form.groupCode.length-1]
        }, '/animal/web/slaughter/update', '/animal/web/slaughter/insert', cb);
    }

    public getDetail(id: string, cb: Function): void {
        this.BasicGet('/animal/web/slaughter/get', {id}, false, true, cb);
    }

    public getAudit(id: string, cb: Function): void {
        this.BasicGet('/animal/web/slaughter/getaudit', {id}, false, true, cb);
    }

    public getCity(pid: string, cb: Function): void {
        this.BasicGet('/animal/web/provinceCityDistrict/get', {pid}, false, true, cb)
    }

    public audit(id: string, form: FarmDetailForm, cb:Function): void {
        this.BasicPost('/animal/web/audit/auditFarm', {
            id,
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            // typeIds: form.typeIds.toString(),
        }, true, true, true, cb)
    }

    //请求所属企业
    public getLinkFarm(cb:Function){
        this.BasicGet('/animal/web/appUserManagement/companyList',{roleId:3},false,false,cb)
    }

    //养殖场组织机构
    public getFarmData(cb? :Function){
        this.BasicGet('/animal/web/provinceCityDistrict/getForGroupCode',{},false,false , cb)
    }

    //上传图片到oss
    public upImgToOss(data:any , cb:any){
        this.BasicGet('/animal/web/common/oss/config',data,false,false,cb)
    }

    //添加屠宰场
    public addFarm(form:any,cb:Function){
        this.BasicPost('/animal/web/slaughter/insert',{...form},true,true,true,cb)
    }

    //修改屠宰场
    public setFarm(form:any,cb:Function){
        if(typeof(form.groupCode) == 'object'){
            form.groupCode =form.groupCode[form.groupCode.length-1]
        }
        // form.groupCode = form.groupCodeStr
        if(form.status =='启用'){
            form.status = 1
        }else{
            form.status = 0
        }
        this.BasicPost('/animal/web/slaughter/update',{...form},true,true,true,cb)
    }

    //编辑默认数据
    public resetData(id:any , cb:Function){
        this.BasicGet('/animal/web/slaughter/get',{id},false,false,cb)
    }
}
