




























import {Prop , Component , Vue} from 'vue-property-decorator'

import SelectComponentVue from '@/views/basics/typeConfig/components/components/SelectComponentVue.vue'
import NewPidAdressVue from '@/views/basics/typeConfig/components/components/NewPidAdressVue.vue'

import {FarmDetail} from '@/common/Views/system/farm/farmDetail/farmDetail'
import {BasicsClass} from '@/common/BasicsClass';
@Component({
    components:{
        SelectComponentVue,
        NewPidAdressVue
    }
})
export default class ForageAndDrugFormSearch extends Vue{
    @Prop() TitleType:any
    @Prop() BusinessTitle:any
    @Prop() AddTitle:any
    private form:any ={
        forageTenant:"",
        confTypeId:"",
        groupCode:["0"],
        enableFlag:"",
    }
    private PartnerList:any[] = [] //合作商家列表
    private ForageTypeList:any[] = [] //草料类别
    private AdressData:any[] = []//售卖区域
    private ShelfStatus:any[] = [//上架状态
        {id:"",name:"请选择"},
        {id:"1",name:"已上架"},
        {id:"2",name:"已下架"},
    ] 


    //事件
    private SearchList(){ //搜索
        this.$emit('SearchList', this.form)
    }
    private ResetList(){ //重置
        this.ClearFormData()
        if(this.AddTitle == '新增产品信息'){
            this.getForageMerchantsListReq()
            this.getForageTypeListReq()
        }else{
            this.getDrugMerchantsListReq()
            this.getDrupNameListReq()
        }
        this.$emit('ResetList',this.form)
    }
    private AddForagType(){//新增草料类别
        this.$emit('AddForagTypeEmit' , true) //打开新增草料弹出层
    }
    private AddConfig(){ //新增草料配置  新增药品配置跳转按钮
        if(this.AddTitle == '新增产品信息'){
            this.$router.push({
                path:"AddForageRackConfiguration"
            })
        }else{
            this.$router.push({
                path:"AddDrug"
            })
        }
        
    }


    //emit 事件
    private SetPartnerListValue(val:string){ //合作商家
        this.form.forageTenant = val
    }
    private SetAdressVal(val:any[]){ //售卖区域
        this.form.groupCode = val
    }
    private SetForageTypeValue(val:string){ //草料类别
        this.form.confTypeId = val
    }
    private SetShelfStatusValue(val:string){ //上架状态
        this.form.enableFlag = val
    }

    // 请求
    private getForageMerchantsListReq(){ //草料商家
        this.PartnerList =[]
        new BasicsClass().BasicGet('/animal/web/forage/getForageTenantList',{} , false,false , (data:any)=>{
            data.data.forEach((item:any) => {
                this.PartnerList.push({
                    id:item,
                    name:item
                })
            });
            this.PartnerList.unshift({id:"",name:"请选择"})
        })
    }
    private getDrugMerchantsListReq(){ //药品商家
        this.PartnerList =[]
        new BasicsClass().BasicGet('/animal/web/drug/getDrugTenantList',{} , false,false , (data:any)=>{
            data.data.forEach((item:any) => {
                this.PartnerList.push({
                    id:item,
                    name:item
                })
            });
            this.PartnerList.unshift({id:"",name:"请选择"})
            console.log(this.PartnerList)
        })
    }
    private getForageTypeListReq(){//草料类别
        this.ForageTypeList = []
        new BasicsClass().BasicGet('/animal/web/forage/getConfTypeId',{},false,false,(data:any)=>{
            this.ForageTypeList = data.data
            this.ForageTypeList.unshift({id:"",name:"请选择"})
        })
    }
    private getDrupNameListReq(){ //药品名称
        this.ForageTypeList = []
        new BasicsClass().BasicGet('/animal/web/drug/getDrugList',{} ,false,false,(data:any)=>{
            data.data.forEach((item:any) => {
                this.ForageTypeList.push({
                    id:item,
                    name:item
                })
            });
            this.ForageTypeList.unshift({id:"",name:"请选择"})
        })
    }
    private getAdressReq(){ //售卖区域
        new FarmDetail().getCity1('-1',(data:any)=>{
            this.AdressData = data.data
        })
    }


    //数据处理 
    private ClearFormData(){//清除form数据
        this.form.forageTenant=""
        this.form.confTypeId=""
        this.form.groupCode=["0"]
        this.form.enableFlag=""
    }

    activated(){
        this.getAdressReq()
        if(this.AddTitle == '新增产品信息'){
            this.getForageMerchantsListReq()
            this.getForageTypeListReq()
        }else{
            this.getDrugMerchantsListReq()
            this.getDrupNameListReq()
        }
    }

}
