






















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Result, Column } from "@/common/Views/system/column/column";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { optionSelect, Rule } from "@/common/Views/comm/formRule";
import { SchoolList } from "@/common/Views/basics/schoolManage/schoolManage";
import { AxiosData } from "@/common/http/http";
import {
  HarmlessDetailForm,
  HarmlessDetail,
  CityList,
} from "@/common/Views/system/harmless/harmlessDetail/harmlessDetail";
import { Util } from "@/common/Views/comm/util";
import TitleSolt from "@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue";
import { Firm } from "@/common/Views/system/firm/firm";
import { getMarketListAll,saveUser } from "@/api/index";

@Component({
  components: { TitleSolt },
})
export default class HelloWorld extends Vue {
  mounted() {
    console.log(this.formLabelAlign);
  }

  public column = new Column();

  private firm: Firm = new Firm();

  public dialogVisible = false;
  public ImgList = [];

  private formLabelAlign: any = {
    name: "",
    mobile: "",
    password: "",
    marketId: "",
    role: "",
    id: "",
  };
  private roleList:object = [
    {
      value: '4',
      label: '市场经纪人'
    },
    {
      value: '5',
      label: '市场安全员'
    }
  ]

  private marketListAll: any = [];
  private getMarketAllList(): void {
    getMarketListAll({},(res: any)=>{
      debugger
      if (res.code == 200) {
        this.marketListAll = res.data;
      }
    });
  }

  private oldAddress = "";

  public props: any = {
    expandTrigger: "hover",
    value: "name",
    label: "name",
    lazy: true,
    lazyLoad(node: any, resolve: any) {
      const column: Column = new Column();
      column.getTissuc2(node.data.id, 1, (res: any) => {
        const nodes = res.data;
        nodes.forEach((item: any) => {
          item.leaf = item.level === 4;
        });
        resolve(nodes);
      });
    },
  };

  private showMark1: boolean = false;
  private showMark2: boolean = false;
  private url: string = "";



  private activated(): void {
    this.getMarketAllList();
  }

  private conTypeList: SchoolList<string>[] = [];


  private rules: HarmlessDetailForm<Rule[]> = {
    mobile: [
      { required: true, message: "请填写联系电话" },
      {
        message: "手机号码输入错误",
        validator: (rule: any, value: any, callback: any) => {
          if (!/^[0-9]{11}$/g.test(value)) {
            callback(new Error());
          } else {
            callback();
          }
        },
      },
    ],
    password: [{ required: true, message: "请填写密码" }],
    name: [{ required: true, message: "请填写姓名" }],
    marketId:[{ required: true, message: "请选择交易市场" }],
    role:[{ required: true, message: "请选择角色" }],
  };

  private harmlessDetail: HarmlessDetail;

  constructor() {
    super();
    this.harmlessDetail = new HarmlessDetail();
  }

  private save(): void {
    (this.$refs.form as any).validate((valid: boolean) => {
      if (valid) {
        // 执行保存操作
        saveUser(this.formLabelAlign,(res:any)=>{
          if(res&& res.code == 200){
            this.$router.go(-1);
          }
        })
      }
    });
  }

  private cityList0 = [];
  private cityList1 = [];
  private cityList2 = [];
  private city: any = {
    province: "",
    theCity: "",
    area: "",
  };
  private dataImg: boolean = false;

  public resGroup: Result[] = [];
  public handleCascader(start: any): void {
    console.log(start);
  }

  private getCity(pid: any, flag: boolean = true): void {
    console.log(1);

    if (!pid) {
      pid = 0;
    }
    console.log(2);

    new HarmlessDetail().getCity(<any>pid, (data: CityList) => {
      if (pid == 0) {
        this.cityList0 = data.data;
      } else if (pid < 100 && pid > 10) {
        this.cityList1 = data.data;
        if (flag) {
          this.city.theCity = "";
          this.city.area = "";
        }
      } else if (pid > 1000 && pid < 10000) {
        this.cityList2 = data.data;
        if (flag) {
          this.city.area = "";
        }
      }
      console.log(this.$refs);
    });
  }

  private handleCity(e: any): void {}

  private setImg(file: any, fileList: any): void {
    this.readImg(file, fileList, "licenseUrl");
  }

  private setImg1(file: any, fileList: any): void {
    this.readImg(file, fileList, "doorUrl");
  }

  private readImg(file: any, fileList: any, url: keyof FarmDetailForm) {
    const reader = new FileReader();
    let res = reader.readAsDataURL(file.raw);
    reader.onloadend = () => {
      this.dataImg = false;
      // var strBase64 = reader.result.substring(84);
      const strBase64 = (<string>reader.result).substring(0);
      // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: strBase64, url: ""};
      (<string>this.formLabelAlign[url]) = strBase64;
      console.log(this.formLabelAlign);
    };
    reader.onload = function (e) {
      console.log(e);
      // simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc
    };
  }


  public cityChange(ids: any): void {
  }

  private handleRemove(file: any): void {
    console.log(file);
    this.formLabelAlign.licenseUrl = "";
    this.ImgList = [];
  }

  private handlePictureCardPreview(file: any): void {
    this.formLabelAlign.licenseUrl = file.url;
    this.dialogVisible = true;
  }

  @Watch("$route")
  public handleRoute(): void {
    this.resGroup = [];
    // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: "", url: ""},
    this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
    this.city = new Util().clearObject(this.city);
    // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: "", url: ""};
    this.formLabelAlign.auditStatus = "1";
    // this.$nextTick(()=>{
    //     (<any>this.$refs.upload).clearFiles();
    // })
    this.dataImg = false;
    this.ImgList = [];
  }
}
