
































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Column, ComboList, Result } from "@/common/Views/system/column/column";
import TemperatureInfoDetail from "@/views/basics/temperatureInfo/temperatureInfoDetail/temperatureInfoDetail.vue";
import { Util } from "@/common/Views/comm/util";

@Component({
    components: {
        TemperatureInfoDetail
    }
})
export default class HelloWorld extends Vue {
    private form: any = {
        id: ""
    };
    private dialogVisible: boolean = false;
    private forms: any = {
        markId: "",
        status: "",
        remark: "",
        deathCode: "",
        deathTime: ""
    };
    private auditStatus: number = 1;
    private srcList: string[] = [];
    private activated(): void {
        let data = this.$route.query as any;
        this.form.id = data.id;
        this.auditStatus = data.auditStatus;
        console.log(this.auditStatus);
        this.getdeathAuditDetail();
    }
    private tabledata: any = {};
    private getdeathAuditDetail(): void {
        new Column().getdeathAuditDetail((res: any) => {
            console.log(res);
            this.tabledata = res.data;
            this.forms.deathTime = this.tabledata.deathTime;
            this.srcList.push(this.tabledata.bodyUrl);
            this.srcList.push(this.tabledata.electronicUrl);
            this.srcList.push(this.tabledata.preventionUrl);
            this.srcList.push(this.tabledata.harmlessUrl);
        }, this.form);
    }

    private onSubmit(status: number): void {
        this.forms.markId = this.tabledata.markId;
        this.forms.status = status;
        this.forms.deathCode = this.tabledata.deathCode;
        if (status == 2 && this.forms.remark == "") {
            Util.showToast(
                (this.$t("breeding_module.die.detail.err_tip") as string),
                "warnning"
            );
            return;
        }
        new Column().deathAuditDetail((res: any) => {
            this.forms.remark = "";
            this.dialogVisible = false;
            setTimeout(() => {
                this.$router.push("/deathAnimal");
            }, 500);
        }, this.forms);
    }
}
