

















































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { cameraManagement } from "@/api/HardwareModule/index";
import { dateFun } from "@/common/date";
@Component({})
export default class equipmentLog extends Vue {
    @Prop() private cameraDetail!: any;
    private SelectData = "";
    //分页
    private tablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private logTableDate: any[] = [];
    //获取设备日志列表
    private configForm = {
        deviceCode: "",
        stTime: "",
        edTime: "",
    };
    private handlePageChange(val: any) {
        // 分页
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.getLogList();
    }
    private getLogList(): void {
        this.configForm.deviceCode = this.cameraDetail.deviceCode;
        new cameraManagement().geLogList(
            this.configForm,
            this.tablePage,
            (res: any) => {
                if (res.code == 200) {
                    this.logTableDate = res.data;
                    console.log(this.logTableDate, "this.logTableDate");
                } else {
                    this.$message.error(res.msg);
                }
            }
        );
    }
    private search() {
        this.configForm.stTime = this.SelectData[0];
        this.configForm.edTime = this.SelectData[1];
        this.getLogList();
    }
    @Watch("SelectData", { deep: true }) //处理选择日期
    private handlerSelectData(newVal: any) {
        console.log(newVal);
        if (newVal) {
            this.configForm.stTime = new dateFun().returnDate(newVal[0], "-");
            this.configForm.edTime = new dateFun().returnDate(newVal[1], "-");
        } else {
            this.SelectData = "";
            this.configForm.edTime = "";
            this.configForm.stTime = "";
        }
    }
    private resetForm() {
        this.SelectData = "";
        this.configForm.stTime = "";
        this.configForm.edTime = "";
        this.getLogList();
    }
    private activated(): void {
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getLogList();
    }

}
