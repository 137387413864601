
















































































































































































































import { Component, Prop, Watch, Vue } from "vue-property-decorator";
import CascaderComponentsVue from "@/components/BasicConponents/CascaderComponentsVue.vue";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";

import { appMenu } from "../../appfire/components/appAll";
interface formType {
    id: string;
    fatherColumSelect: Array<any>;
    fatherColumn: string;
    tab: string;
    name: string;
    url: string;
    order: string | number;
    describe: string;
    type: string;
    platform: string;
    groupId: string;
    parentId: string;
}
@Component({
    components: { CascaderComponentsVue, SelectComponentsVue }
})
export default class ColumnsAndResources extends Vue {
    @Prop() InfoByConfTypeList!: Array<any>; ////获取菜单分组数据
    @Prop() treeData!: any[];
    @Prop() isAddNewColumn!: boolean;
    @Prop() currentNode!: formType;
    @Prop() resourceTableData!: any[];
    @Prop() showSourceFlag!: boolean;
    @Prop() TabModel!: string;
    private TabModelFlag = "";

    private form: formType = {
        id: "",
        fatherColumSelect: ["3"],
        fatherColumn: "",
        tab: "",
        name: "", //名称
        url: "", //链接
        order: "", //排序
        describe: "", //备注
        type: "1", //类型app的话永远是1
        platform: "3", //平台(1-web ,3-app平台)
        parentId: "3", //父id 一级的话 web是 ： 1 app是：3
        groupId: "" //314新增菜单属性
    };

    //校验form
    private rules: any = {
        name: [
            {
                required: true,
                message: "请输入栏目名称",
                trigger: ["blur", "change"]
            }
        ],
        url: [
            {
                required: true,
                message: "请输入栏目路径",
                trigger: ["blur", "change"]
            }
        ],
        order: [
            {
                required: true,
                message: "请输入栏目排列序号",
                trigger: ["blur", "change"]
            }
        ],
        groupId: [
            {
                required: true,
                message: "请选择分组",
                trigger: ["blur", "change"]
            }
        ]
    };

    //设置组织机构
    private SetFaherVal(val: any) {
        if (val.length == 3) {
            this.form.parentId = "";
            this.$message.error("配置失败，系统最多只可配置3级菜单");
            this.form.fatherColumSelect = [];
            return false;
        } else if (val.length >= 1) {
            this.form.parentId = val[val.length - 1];
        } else if (val[0] == "3") {
            this.form.parentId = "3";
        }
    }
    //设置分组id
    private SelectId(val: string) {
        this.form.groupId = val;
    }

    @Watch("currentNode", { deep: true, immediate: true })
    private changeCurrentNode(newVal: formType) {
        if (newVal) this.form = { ...newVal };
    }
    @Watch("TabModel", { immediate: true })
    private changeTabModel(newVal: any) {
        this.TabModelFlag = newVal;
    }

    //保存
    private addNewData() {
        (this.$refs.ruleForm as any).validate((valid: boolean) => {
            if (valid) {
                if (!this.isAddNewColumn) {
                    //修改
                    new appMenu().setMenu(this.form, (data: any) => {
                        this.resetFrom();
                        this.$emit("EmitGetMenuData", this.form);
                    });
                } else {
                    //保存
                    new appMenu().addMenu(this.form, (data: any) => {
                        this.resetFrom();
                        this.$emit("EmitGetMenuData", this.form);
                    });
                }
            }
        });
    }

    //取消
    private resetFrom() {
        this.form = {
            id: "",
            fatherColumSelect: ["3"],
            fatherColumn: "",
            tab: "",
            name: "", //名称
            url: "", //链接
            order: "", //排序
            describe: "", //备注
            type: "1", //类型app的话永远是1
            platform: "3", //平台(1-web ,3-app平台)
            parentId: "3", //父id 一级的话 web是 ： 1 app是：3
            groupId: "" //314新增菜单属性
        };
    }

    //删除
    private delColumn() {
        this.$confirm(
            "删除栏目将无法恢复，相关子栏目、权限配置也将同步删除！是否确定删除该栏目？",
            "操作确认",
            {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }
        )
            .then(() => {
                this.$message({
                    type: "success",
                    message: "删除成功!"
                }),
                    new appMenu().delMenu(this.currentNode.id, (data: any) => {
                        this.$emit("EmitGetMenuData", "");
                        this.$emit("SendCurrentNode", "");
                    });
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除"
                });
                this.$emit("EmitGetMenuData", "");
            });
    }

    //资源相关
    private tableData: any[] = [];
    private showEdit = false;
    private addNewSourceFlag = true;
    private formData: any = {
        name: "",
        url: "",
        describe: "",
        type: "",
        platform: ""
    };
    //添加资源
    private addNewSource() {
        this.showEdit = true; //显示弹出层
        this.addNewSourceFlag = true; //标识添加状态
    }
    //编辑
    private editEvent(row: any) {
        let { name, url, describe, id } = row;
        this.showEdit = true; //打开弹出层
        this.addNewSourceFlag = false; //标识编辑状态
        this.formData = { name, url, describe, id };
    }
    //删除
    private removeEvent(row: any) {
        this.$confirm(
            "删除栏目将无法恢复，相关子栏目、权限配置也将同步删除！是否确定删除该栏目？",
            "操作确认",
            {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }
        )
            .then(() => {
                this.$message({
                    type: "success",
                    message: "删除成功!"
                });
                new appMenu().delMenu(row.id, (data: any) => {
                    this.$emit("EmitGetMenuData", "");
                });
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除"
                });
                this.$emit("EmitGetMenuData", "");
            });
    }

    //双击
    private cellDBLClickEvent({ row }) {
        this.editEvent(row);
    }
    //提交
    private submitNewSource() {
        const { id, platform } = this.currentNode;
        let obj = { ...this.formData, parentId: id, type: 3, platform };
        if (this.addNewSourceFlag) {
            //保存
            new appMenu().addMenu(obj, (data: any) => {
                this.showEdit = false; //关闭弹出层
                this.$emit("EmitGetMenuData", "");
            });
        } else {
            //修改
            const { id } = this.formData; //获取当前条目id
            obj.id = id;
            new appMenu().setMenu(obj, (data: any) => {
                this.$emit("EmitGetMenuData", "");
            });
        }
        this.resetSource();
        this.showEdit = false;
    }
    //重置
    private resetSource() {
        this.formData.name = "";
        this.formData.url = "";
        this.formData.describe = "";
    }
    //向父级传递tabname
    private handleClick(newVal: any) {
        this.$emit("tab-changed", newVal.name);
    }
}
