
































































































































































































































































































































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";
import InputComonentVue from "@/views/basics/typeConfig/components/components/InputComonentVue.vue";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import { Column } from "@/common/Views/system/column/column";
import { GroupCodeApi, FarmApi } from "@/api/CommonModule/index";
import { dateFun } from "@/common/date";

import {
    cameraUpgrade,
    pkgManagement,
    cameraManagement,
} from "@/api/HardwareModule/index";
import { FileApi } from "@/api/CommonModule/index";
import {
    SensorForm,
    addSensorForm,
    upSensorForm,
    AddList,
} from "@/Interface/HardwareModule/index";

@Component({
    components: { SelectComponentVue, InputComonentVue, SelectComponentsVue },
})
export default class upPackageManagement extends Vue {
    private form = {
        version: "",
        createtimeEnd: "",
        createtimeStart: "",
    };
    private sbform = {
        deviceCode: "",
        deviceStatus: "1",
    };
    private addForm = {
        versionId: "",
        upgradeType: "1",
        deviceModel: "ZR2798",
        isAll: false,
        count: "",
        upgradeContent: "",
        deviceCodeList: [],
    };
    private upTarget = "AI摄像头";
    private upVersion = "请选择升级版本号";
    private SelectData = "";
    private tableData: any[] = []; //table 数据
    private tablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private uvtablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private SBtablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private fileList: any[] = [];
    get DialogRules() {
        return {
            versionId: [
                {
                    required: true,
                    message: "请选择升级版本号",
                    trigger: "change",
                },
            ],
            upgradeType: [
                {
                    required: true,
                    message: "请选择升级类型",
                    trigger: "change",
                },
            ],
            upTarget: [
                {
                    required: true,
                    message: "请选择升级对象",
                    trigger: "change",
                },
            ],
            isAll: [
                {
                    required: true,
                    message: "请选择升级范围",
                    trigger: "change",
                },
            ],
            deviceModel: [
                {
                    required: true,
                    message: "请选择设备型号",
                    trigger: "change",
                },
            ],
            upgradeContent: [
                {
                    required: true,
                    message: "请输入升级文案",
                    trigger: "change",
                },
            ],
        };
    }
    private showLabelSelectInputFlag = false;
    private addPkg() {
        this.showLabelSelectInputFlag = true;
        this.SetEquVal("ZR2798");
    }
    private clearAddForm() {
        this.addForm = {
            versionId: "",
            upgradeType: "1",
            deviceModel: "ZR2798",
            isAll: false,
            count: "",
            upgradeContent: "",
            deviceCodeList: [],
        };
        this.selectSBData = [];
        this.SetSBVal("");
        this.upVersion = "请选择升级版本号";
        this.showUpVersionTable = false;
        if (this.$refs.SBtable) {
            this.$refs.SBtable.clearCheckboxRow();
            this.$refs.SBtable.clearCheckboxReserve();
        }

        this.showSBTableDig = false;
    }
    private selectSBData: any[] = [];
    private selectSBDataCopy: any[] = [];
    private selectAllEvent({ checked, records, reserves, row }: any) {
        if (checked) {
            //第一次选数据，还未进行翻页时
            if (reserves.length == 0) {
                this.selectSBData = records;
            } else {
                //数据集合，翻页存在已选中的数据时,拼接新选中的数据
                this.selectSBData = [...reserves, ...records];
            }
        } else {
            //取消时则删除对应的key
            //取消全选时,直接将翻页数据赋值，当前页数据不用加上
            this.selectSBData = reserves;
        }
    }
    private selectChangeEvent({ checked, records, reserves, row }: any) {
        if (checked) {
            //第一次选数据，还未进行翻页时
            if (reserves.length == 0) {
                this.selectSBData = records;
            } else {
                //数据集合，翻页存在已选中的数据时,拼接新选中的数据
                this.selectSBData = [...reserves, ...records];
            }
        } else {
            //取消选中时
            this.selectSBData = this.selectSBData.filter(
                (item: any) => item.id !== row.id
            );
        }
    }
    //关闭新增弹窗
    private CloseDialog() {
        this.clearAddForm();
        this.showLabelSelectInputFlag = false;
        this.showSBTableDig = false;
        if (this.$refs.radiotable) {
            this.$refs.radiotable.clearRadioRow();
        }
    }
    //设备型号选择
    private EquList: any[] = [{ name: "ZR2798", id: "ZR2798" }];
    private SetEquVal(val: string) {

        this.addForm.deviceModel = val;
    }

    //升级范围选择
    private SBList: any[] = [
        {
            name:
                "全部设备" +
                (this.SBtablePage.totalCount
                    ? "(" + this.SBtablePage.totalCount + ")"
                    : ""),
            id: "1",
        },
        {
            name:
                "自定义设备" +
                (this.selectSBData.length
                    ? "(" + this.selectSBData.length + ")"
                    : ""),
            id: "0",
        },
    ];
    @Watch("selectSBData.length", { deep: true })
    private updateSBList(newLength: number) {
        this.updateSBListData(newLength, this.SBtablePage.totalCount);
    }

    @Watch("SBtablePage.totalCount", { deep: true })
    private updateSBListForTotalCount(newTotalCount: number) {
        this.updateSBListData(this.selectSBData.length, newTotalCount);
    }

    private updateSBListData(newLength: number, newTotalCount: number) {
        this.SBList = [
            {
                name:
                    "全部设备" +
                    (newTotalCount ? "(" + newTotalCount + ")" : ""),
                id: "1",
            },
            {
                name: "自定义设备" + (newLength ? "(" + newLength + ")" : ""),
                id: "0",
            },
        ];
    }
    private goDetial(row: any) {
        this.$router.push({
            path: "/upRecordDetail",
            query: {
                id: row.id,
            },
        });
    }
    private selectSB = "";
    private showSBType = false;
    private addSBType = false;
    private SetSBVal(val: string) {
        this.selectSB = val;
        this.visibleVal = val;
        if (val == "1") {
            this.addForm.isAll = true;
            this.showSBType = false;
        } else if (val == "0" && !this.showSBType) {
            this.addForm.isAll = false;
            this.selectSBDataCopy = [...this.selectSBData];
            this.showSBTable();
            // this.showSBType = true;
        }
    }
    private visibleVal = "";
    private visibleChange(val: any) {
        if (val) {
            this.selectSB = "";
        } else {
            this.selectSB = this.visibleVal;
        }
    }
    //选择升级版本号列表弹窗
    private showUpVersionTable = false;
    private showSelectUpVersion() {
        this.showUpVersionTable = true;
        setTimeout(() => {
            this.showLabelSelectInputFlag = false;
        }, 0);
        
        this.radioObjCopy.versionId = this.radioObj.versionId;
        this.radioObjCopy.upVersion = this.radioObj.upVersion;
    }
    //关闭选择升级版本号列表弹窗
    private CloseUVTDialog() {
        this.showUpVersionTable = false;
        this.showLabelSelectInputFlag = true;
        // this.upVersion = "请选择升级版本号";
        // this.addForm.versionId = "";
        this.$refs.radiotable.clearRadioRow();
        this.radioObj.versionId = this.radioObjCopy.versionId;
        this.radioObj.upVersion = this.radioObjCopy.upVersion;
        this.uvTableData.forEach((row) => {
            if (row.id === this.radioObj.versionId) {
                // 如果相等，则执行 setRadioRow(row)
                this.$refs.radiotable.setRadioRow(row);
            }
        });
    }
    private ridioType = false;
    //选中升级版本号
    private AddUVTDialodData() {
        this.showUpVersionTable = false;
        this.showLabelSelectInputFlag = true;
        this.upVersion = this.radioObj.upVersion;
        this.addForm.versionId = this.radioObj.versionId;
        this.ridioType = true;
    }
    private radioObj: any = {
        upVersion: "",
        versionId: "",
    };
    private radioObjCopy: any = {
        upVersion: "",
        versionId: "",
    };
    private radioRow: any = {};
    private radioChangeEvent(row: any) {
        this.radioObj.upVersion = row.data[row.rowIndex].version;
        this.radioObj.versionId = row.data[row.rowIndex].id;
        this.radioRow = row;
    }
    //选择设备列表弹窗
    private showSBTableDig = false;
    private showSBTable() {
        this.showSBTableDig = true;
        setTimeout(() => {
            this.showLabelSelectInputFlag = false;
            
        }, 0);
    }
    //关闭选择升级版本号列表弹窗
    private CloseSBTDialog() {
        this.showSBTableDig = false;
        this.showLabelSelectInputFlag = true;
        this.resetFormSB();
        if (!this.addSBType) {
            this.selectSBData = [];
            this.showSBType = false;
            this.SetSBVal("");
            if (this.$refs.SBtable) {
                this.$refs.SBtable.clearCheckboxRow();
                this.$refs.SBtable.clearCheckboxReserve();
            }
            return;
        }
        this.selectSBData = [...this.selectSBDataCopy];
        this.changeCheckbox();
    }
    private changeCheckbox() {
        if (this.$refs.SBtable) {
            this.$refs.SBtable.clearCheckboxRow();
            this.$refs.SBtable.clearCheckboxReserve();
        }
        this.SBTableData.forEach((item: any) => {
            const found = this.selectSBData.find(
                (selectItem: any) => selectItem.id === item.id
            );
            if (found) {
                this.$refs.SBtable.setCheckboxRow(item, true);
            } else {
                this.$refs.SBtable.setCheckboxRow(item, false);
            }
        });
    }
    //选中升级设备
    private AddSBDialodData() {
        this.showSBTableDig = false;
        this.showLabelSelectInputFlag = true;
        this.showSBType = false;
        this.addSBType = true;
    }
    /**获取列表数据 */
    private getListData() {
        new cameraUpgrade().getList(this.form, this.tablePage, (res: any) => {
            res.data.forEach((item: any, index: number) => {
                item.upTarget = "AI摄像头";
            });
            this.tableData = res.data;
            this.tablePage = { ...res.page };
        });
    }
    //提交升级包
    private AddDialodData() {
        if (this.addForm.isAll) {
            this.addForm.count = this.SBtablePage.totalCount;
        } else {
            this.addForm.count = this.selectSBData.length.toString();
        }
        this.selectSBData.forEach((item: any) => {
            this.addForm.deviceCodeList.push(item.deviceCode);
        });
        this.addForm.deviceCodeList = [...new Set(this.addForm.deviceCodeList)];
        if (this.addForm.versionId == "") {
            this.$message({
                type: "warning",
                message: "请选择升级版本号",
            });
            return;
        } else if (!this.addForm.isAll && this.addForm.deviceCodeList == 0) {
            this.$message({
                type: "warning",
                message: "请选择升级范围",
            });
            return;
        } else if (this.addForm.upgradeContent == "") {
            this.$message({
                type: "warning",
                message: "请填写升级文案",
            });
            return;
        }

        new cameraUpgrade().addUpgrade(this.addForm, (res: any) => {
            this.$message({
                type: "success",
                message: "新增升级包成功",
            });
            if (this.$refs.radiotable) {
                this.$refs.radiotable.clearRadioRow();
            }
            if (this.$refs.SBtable) {
                this.$refs.SBtable.clearCheckboxRow();
                this.$refs.SBtable.clearCheckboxReserve();
            }
            this.ridioType = false;
            this.addSBType = false;
            this.CloseDialog();
            this.clearAddForm();
            this.resetForm();
        });
    }
    //校验版本号
    private isValidVersion(version: string) {
        const pattern = /^[0-9]+(\.[0-9]+){2}$/;
        return pattern.test(version);
    }
    //查询设备
    private searchSB() {
        this.SBtablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getSBListData();
    }
    //输入设备号
    private SetdeviceCodeValue(val: any) {
        this.sbform.deviceCode = val;
    }
    //重置设备
    private resetFormSB() {
        this.SBTableData = [];
        (this.sbform.deviceCode = ""),
            (this.SBtablePage = {
                totalCount: 0,
                pageNo: 1,
                pageSize: 10,
                pageSizes: [10, 20, 50, 100, 200, 500],
            });
        this.getSBListData();
    }
    //删除选中设备
    private delSBItem(row: any, i: any) {
        this.selectSBData.splice(i, 1);
        //删除对应页码的对应的id
        this.changeCheckbox();
    }
    // 查询
    private search() {
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getListData();
    }
    //重置
    private resetForm() {
        this.tableData = [];
        this.SelectData = "";
        this.form = {
            version: "",
            createtimeEnd: "",
            createtimeStart: "",
        };
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getListData();
    }
    private handlePageChange(val: any) {
        // 分页
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.getListData();
    }
    private handleuvPageChange(val: any) {
        // 分页
        this.uvtablePage.pageNo = val.currentPage;
        this.uvtablePage.pageSize = val.pageSize;
        this.getUVListData();
    }
    private handleSBPageChange(val: any) {
        // 分页
        this.SBtablePage.pageNo = val.currentPage;
        this.SBtablePage.pageSize = val.pageSize;
        this.getSBListData();
    }
    //填写序列号
    private SetVersionValue(val: any) {
        this.form.version = val;
    }

    private upgradeType = "固件升级";
    private PlaceholderText = "请选择升级范围";

    /**获取版本号列表数据 */
    private uvTableData: any[] = [];
    private UVform = {};
    private getUVListData() {
        new pkgManagement().getList(
            this.UVform,
            this.uvtablePage,
            (res: any) => {
                this.uvTableData = res.data;
                this.uvtablePage = { ...res.page };
            }
        );
    }
    /**获取设备列表数据 */
    private SBTableData: any[] = [];
    private getSBListData() {
        new cameraManagement().getList(
            this.sbform,
            this.SBtablePage,
            (res: any) => {
                this.SBTableData = res.data;
                this.SBtablePage = { ...res.page };
                this.SBTableData.forEach((item: any) => {
                    const found = this.selectSBData.find(
                        (selectItem: any) => selectItem.id === item.id
                    );
                    if (found) {
                        this.$refs.SBtable.setCheckboxRow(item, true);
                    } else {
                        this.$refs.SBtable.setCheckboxRow(item, false);
                    }
                });
                this.showSBType = false;
            }
        );
    }
    @Watch("SelectData", { deep: true }) //处理选择日期
    private handlerSelectData(newVal: any) {
        if (newVal) {
            this.form.createtimeStart = new dateFun().returnAllDate(
                newVal[0],
                "-"
            );
            this.form.createtimeEnd = new dateFun().returnAllDate(
                newVal[1],
                "-"
            );
        } else {
            this.SelectData = "";
            this.form.createtimeEnd = "";
            this.form.createtimeStart = "";
        }
    }

    activated() {
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };

        this.getUVListData();
        this.getListData();
        this.getSBListData();
    }
}
