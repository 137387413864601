


































































































































































import { Component, Watch, Vue } from "vue-property-decorator";
import SelectComponentVue from "@/views/basics/typeConfig/components/components/SelectComponentVue.vue";
import InputComonentVue from "@/views/basics/typeConfig/components/components/InputComonentVue.vue";
import { dateFun } from "@/common/date";
import { BreedingModule } from "@/api/BreedingModule/index";
import { CommonModule } from "@/api/CommonModule/index";

//interface
import {
    QuarantineInspectionStatisticsInfo,
    getSampleListInfo,
} from "@/Interface/BreedingModule/QuarantineInspectionStatistics";
import {
    FarmListDataInfo,
    CompanyListDataInfo,
} from "@/Interface/CommonModule/CommonApi";
import {
    HandleTable,
    HandlePageChange,
} from "@/Interface/CommonModule/TableAndPage";
@Component({
    components: {
        SelectComponentVue,
        InputComonentVue,
    },
})
export default class QuarantineInspectionStatistics extends Vue {
    private form: QuarantineInspectionStatisticsInfo = {
        groupCode: "",
        companyId: "",
        farmId: "",
        labelNumber: "",
        startQuarantineDate: "",
        endQuarantineDate: "",
        confTypeId: "",
    };
    private GgroupCode: any[] = [];
    private tableData: any[] = []; //列表数据
    private tablePage: HandleTable = {
        //分页数据
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private groupCodeList: any[] = []; //组织机构数据
    private CompanyList: CompanyListDataInfo[] = []; //企业列表数据
    private FarmList: FarmListDataInfo[] = []; //养殖场列表数据
    private SampleList: getSampleListInfo[] = []; //采集类别
    private SelectTime: any[] = []; //时间选择
    private Cascaderprops: any = {
        expandTrigger: "hover",
        value: "id",
        label: "name",
    };

    //事件
    private ChangeCodeFun() {
        //组织机构变化
        this.GgroupCode
            ? (this.form.groupCode = this.GgroupCode.at(-1).toString())
            : (this.form.groupCode = "");
        this.form.companyId = "";
        this.getCompanyListData(); //更新组织机构后 请求对应的企业
    }
    private SearchList() {
        this.tablePage = {
            //分页数据
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        //查询
        this.getList(); //请求数据列表
    }
    private ResetList() {
        //重置
        this.clearData();
        this.getAllData();
    }
    private exportlableList() {
        //导出
    }
    private handlePageChange(val: HandlePageChange) {
        //切换分页
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        //请求列表数据
        this.getList();
    }

    //emit
    private SetcompanyIdValue(val: string) {
        //企业赋值
        this.form.companyId = val;
        this.form.farmId = "";
        this.getFarmListData(); //请求养殖场数据
    }
    private SetfarmIdValue(val: string) {
        //养殖场赋值
        this.form.farmId = val;
    }
    private SetlabelNumberValue(val: string) {
        //耳标号赋值
        this.form.labelNumber = val;
    }
    private SetconfTypeIdValue(val: string) {
        //样本赋值
        this.form.confTypeId = val;
    }

    //数据处理
    @Watch("SelectTime") //处理下单时间
    private ChangeSlectTime(newVal: any[]) {
        if (newVal) {
            if (newVal.length === 0) {
                this.form.startQuarantineDate = "";
                this.form.endQuarantineDate = "";
                return;
            }
            this.form.startQuarantineDate = new dateFun().returnDate(
                newVal[0],
                "-"
            );
            this.form.endQuarantineDate = new dateFun().returnDate(
                newVal[1],
                "-"
            );
        } else {
            this.form.startQuarantineDate = "";
            this.form.endQuarantineDate = "";
            this.SelectTime = [];
        }
    }
    private DelLastTree(data: any) {
        //删除空的树
        data.forEach((item: any) => {
            if (item.children.length != 0) {
                this.DelLastTree(item.children);
            } else {
                delete item.children;
            }
        });
    }

    private clearData() {
        //清空数据
        this.GgroupCode = [];
        this.form = {
            groupCode: "",
            companyId: "",
            farmId: "",
            labelNumber: "",
            startQuarantineDate: "",
            endQuarantineDate: "",
            confTypeId: "",
        };
        this.tablePage = {
            //分页数据
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.SelectTime = [];
    }

    //接口请求
    private getList() {
        //获取列表
        new BreedingModule().getQuarantineRecorList(
            this.form,
            this.tablePage,
            (data: any) => {
                this.tableData = data.data;
                this.tablePage = data.page;
            }
        );
    }
    private getForGroupCodeData() {
        //获取组织机构
        new CommonModule().getForGroupCode((data: any) => {
            this.DelLastTree(data.data);
            this.groupCodeList = data.data;
        });
    }
    private getCompanyListData() {
        //获取企业列表信息
        new CommonModule().getCompanyList(this.form.groupCode, (data: any) => {
            data.data.unshift({
                id: "",
                name: "全部",
            });
            this.CompanyList = data.data;
        });
    }
    private getFarmListData() {
        new CommonModule().getFarmList(this.form.companyId, (data: any) => {
            data.data.unshift({
                id: "",
                name: "全部",
            });
            this.FarmList = data.data;
        });
    }
    private getSampleListData() {
        new BreedingModule().getSampleList((data: any) => {
            //样本列表信息
            data.data.unshift({
                id: "",
                name: "全部",
            });
            this.SampleList = data.data;
        });
    }

    private getAllData() {
        //请求全部数据
        this.getForGroupCodeData(); //获取组织机构
        this.getCompanyListData();
        this.getFarmListData();
        this.getSampleListData();
        this.getList(); //请求列表数据
    }

    activated() {
        this.clearData(); //初始化搜索数据
        this.getAllData();
    }
}
