















































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Result, Column} from "@/common/Views/system/column/column";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {optionSelect, Rule} from "@/common/Views/comm/formRule";
import {ConfType} from "@/common/Views/system/confType/confType";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {AxiosData} from "@/common/http/http";
import {HarmlessDetailForm, HarmlessDetail, HarmlessDetailDetail, CityList} from "@/common/Views/system/harmless/harmlessDetail/harmlessDetail";
import {Util} from "@/common/Views/comm/util";
import TitleSolt from '@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue';


@Component({
    components: {TitleSolt}
})

export default class HelloWorld extends Vue {

    public column = new Column()

    public dialogVisible = false
    public ImgList = []

    private formLabelAlign: HarmlessDetailForm = {
        groupCode: [],
        mobile: "",
        person: "",
        status: 1,
        name: "",
        typeIds: [],
        simpleAddress: "",
        detailAddress: "",
        // licensePicture: {code: "license", base64ImgData: "", url: ""},
        auditStatus: '1',
        licenseUrl: "",
        doorUrl: "",
    };


    private treeData: Result[] = [];

    private showMark1: boolean =  false;
    private showMark2: boolean =  false;
    private url: string =  '';

    private getTree(cb?: Function): void {
        
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        column.getTreeData().then((result: Result[]) => {

            // this.treeData = new DeepLoop().deepCopy(result);
            console.log(this.treeData)
            if(cb) cb();
        }).catch(err => {
            this.$message.error(err.message);
        });
    }

    private statusOptions: optionSelect<number>[] = [
        {
            value: 1,
            label: "启用",
        },
        {
            value: 0,
            label: "禁用",
        },

    ];


    private activated(): void {
        this.getTree();

        this.getDetail();
        this.confType();

        // console.log(12132131);
        const column: Column = new Column();
        column.getTissuc(undefined, (res: any) => {
            this.treeData = res.data
        })
    }


    private conTypeList: SchoolList<string>[] = [];

    private confType(): void {
        new ConfType().getList((data: AxiosData<SchoolList<string>[]>) => {
            this.conTypeList = data.data;
        });
    }

    private rules: HarmlessDetailForm<Rule[]> = {
        groupCode: [
            // @ts-ignore
            {type: "array", required: true, message: "请选择组织机构"}
        ],
        mobile: [
            {required: true, message: "请填写联系电话"},
            {
                message: "手机号码输入错误", validator: (rule: any, value: any, callback: any) => {
                    if (!/^[0-9]{11}$/g.test(value)) {
                        callback(new Error());
                    } else {
                        callback();
                    }
                }
            },
        ],
        person: [
            {required: true, message: "请填写联系人"}
        ],
        status: [
            {required: true, message: "请选择启用状态"}
        ],
        name: [
            {required: true, message: "请填写无害化处理场名称"}
        ],
        // typeIds: [
        //     // @ts-ignore
        //     {type: "array", required: true, message: "请添加养殖类型"}
        // ],
        // simpleAddress: [
        //     {required: true, message: "请选择城市"}
        // ],
        detailAddress: [
            {required: true, message: "请填写详细地址"}
        ],
        doorUrl: [
            {required: true, message: "请上传照片"}
        ],
        // typeIds: [],
    };

    private harmlessDetail: HarmlessDetail;

    constructor() {
        super();
        this.harmlessDetail = new HarmlessDetail();
    }

    private showDio(url: string) {
        this.dialogVisible = true;
        this.url = url;
    }

    private del(url: keyof FarmDetailForm) {
        (<string>this.formLabelAlign[url]) = '';
        this.showMark1 = false;
        this.showMark2 = false;
    }

    private save(): void {
        // console.log(this.ImgList);
        
        (this.$refs.form as any).validate((valid: boolean) => {
            if (valid) {
                let id = "";
                if (this.$route.query.harmlessId) {
                    id = <string> this.$route.query.harmlessId;
                }
                this.harmlessDetail.submit(id, this.formLabelAlign, () => {
                    this.$router.go(-1);

                });
            }

        });

    }


    private getDetail(): void {
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        this.formLabelAlign.status = 1;
        this.getTree(()=>{
            console.log(1213213213213213)
            if (this.$route.query.harmlessId) {
                this.harmlessDetail.getDetail(<string>this.$route.query.harmlessId, (data: AxiosData<HarmlessDetailDetail>)=>{
                    const Data = data.data;
                    console.log(Data)
                    console.log(Data.simpleAddress)
                    // this.formLabelAlign.typeIds = Data.typeIds.split(',');
                    this.formLabelAlign.status = Number(Data.status);
                    this.formLabelAlign.mobile = Data.mobile;
                    this.formLabelAlign.person = Data.person;
                    this.formLabelAlign.name = Data.name;
                    this.formLabelAlign.auditStatus = Data.auditStatus
                    // this.formLabelAlign.simpleAddress = Data.simpleAdressId
                    this.formLabelAlign.licenseUrl = Data.licenseUrl
                    if(this.ImgList.length == 0){
                        this.ImgList.push({name: "1", url: Data.licenseUrl})
                    }
                    
                    // if(Data.simpleAddress){
                    const addressId = Data.simpleAdressId.toString();
                    this.city.province = parseInt(addressId.substring(0, 2));
                    // this.getCity(this.city.province, false);
                    // this.getCity(parseInt(addressId.substring(0, 4)), false);
                    // this.city.theCity = parseInt(addressId.substring(0, 4));
                    // this.city.area = parseInt(addressId);
                        // this.city.province = Data.simpleAddress.split('-')[0]
                        // this.city.theCity = Data.simpleAddress.split('-')[1]
                        // this.city.area = Data.simpleAddress.split('-')[2]
                    // }
                    this.formLabelAlign.detailAddress = Data.detailAddress
                    this.formLabelAlign.licenseUrl = Data.licenseUrl;
                    this.formLabelAlign.doorUrl = Data.doorUrl;
                    if(Data.licenseUrl){
                        this.dataImg = true
                    }
                    // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: '', url: Data.licenseUrl}
                    

                    this.treeData = Data.provinceList
                    this.formLabelAlign.groupCode = []
                    this.formLabelAlign.groupCode.push(Data.provinceList[0].id)

                    const arr: any = [];
                    DeepLoop.getDataRoleForDeepLoop(this.treeData, Data.groupCode, arr);
                    // this.formLabelAlign.groupCode = arr;
                });
            }
        })
    }

    private cityList0 = []
    private cityList1 = []
    private cityList2 = []
    private city: any = {
        province: '',
        theCity: '',
        area: '',
    }
    private dataImg: boolean = false


    public resGroup: Result[] = []
    public handleCascader(start: any): void {
        if(start == true){
            console.log(this.resGroup.length)
            if(this.resGroup.length == 0){
                const column: Column = new Column();
                this.resGroup = this.treeData
                column.getTissuc(undefined,(res: any) => {
                    // console.log(res, 11111)
                    this.treeData = res.data
                    // this.formLabelAlign.groupCode = []
                })
            }
            if(this.treeData[0].id == this.resGroup[0].id){
                const column: Column = new Column();
                this.resGroup = this.treeData
                column.getTissuc(undefined,(res: any) => {
                    // console.log(res, 11111)
                    this.treeData = res.data
                    this.formLabelAlign.groupCode = []
                })
            }
            
        } else {
            console.log(this.formLabelAlign.groupCode)
            if(this.formLabelAlign.groupCode[0] != '0'){
                this.treeData = this.resGroup
                this.formLabelAlign.groupCode.push(this.resGroup[0].id)
            }
        }
    }



    private getCity(pid: any, flag: boolean = true): void {
        console.log(1);
        
        if (!pid) {
            pid = 0
        }
        console.log(2);
        
        new HarmlessDetail().getCity(<any>pid, (data: CityList) => {
            if (pid == 0) {
                this.cityList0 = data.data
            } else if (pid < 100 && pid > 10) {
                this.cityList1 = data.data;
                if (flag) {

                    this.city.theCity = '';
                    this.city.area = ''
                }
            } else if (pid > 1000 && pid < 10000){
                this.cityList2 = data.data;
                if (flag) {

                    this.city.area = '';
                }
            }
            console.log(this.$refs)
            // this.formLabelAlign.simpleAddress = this.$refs.province.selectedLabel + this.$refs.theCity.selectedLabel + this.$refs.area.selectedLabel
            this.formLabelAlign.simpleAddress = this.$refs.area.value
        })
    }

    private handleCity(e: any): void {
        this.formLabelAlign.simpleAddress = e
    }

    private setImg(file: any, fileList: any): void {
        this.readImg(file, fileList, "licenseUrl");
    }

    private setImg1(file: any, fileList: any): void {
        this.readImg(file, fileList, "doorUrl");
    }

    private readImg(file: any, fileList: any, url: keyof FarmDetailForm) {
        const reader = new FileReader();
        let res = reader.readAsDataURL(file.raw);
        reader.onloadend = () => {
            this.dataImg = false;
            // var strBase64 = reader.result.substring(84);
            const strBase64 = (<string>reader.result).substring(0);
            // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: strBase64, url: ""};
            (<string>this.formLabelAlign[url]) = strBase64;
            console.log(this.formLabelAlign);
        };
        reader.onload = function(e) {
            console.log(e);
            // simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc
        };

    }

    public changeCity = []

    
    public cityChange(ids: any): void {
        // console.log(ids)
        
        // if(ids.length === 1){
        //     this.column.getTissuc(ids[ids.length-1], (res: any) => {
        //         console.log(res.data)
        //         var list = this.treeData
        //         list[ids[0]-11].children = res.data
        //         this.treeData = []
        //         this.treeData = list
        //         console.log(this.treeData);
                
        //     })
        // }
        
    }

    private handleRemove(file: any): void {
        console.log(file);
        this.formLabelAlign.licenseUrl = ''
        this.ImgList = []
    };

    private handlePictureCardPreview(file: any): void {
        this.formLabelAlign.licenseUrl = file.url;
        this.dialogVisible = true;
    }
    
      

    @Watch("$route")
    public handleRoute(): void {
        this.resGroup = []
        // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: "", url: ""},
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);
        this.city = new Util().clearObject(this.city);
        // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: "", url: ""};
        this.formLabelAlign.auditStatus = "1";
        this.$nextTick(()=>{
            (<any>this.$refs.upload).clearFiles();
        })
        this.dataImg = false;
        this.getDetail();
        this.ImgList = []
    }
}
