




































































import {Watch , Component , Vue} from 'vue-property-decorator'

import SelectComponentVue from '@/views/basics/typeConfig/components/components/SelectComponentVue.vue'
import InputComonentVue from '@/views/basics/typeConfig/components/components/InputComonentVue.vue'
import { SimManage } from "@/common/Views/basics/simManage/simManage";
import { BasicsClass } from '@/common/BasicsClass';
import {dateFun} from '@/common/date'
@Component({
    components:{
        SelectComponentVue,
        InputComonentVue
    }
})
export default class DrugDemand extends Vue{

    private form:any={
        userName:"",//下单人姓名
        mobile:"",//下单人电话
        orderStatus:"",//订单状态
        drugTenant:"",//商家名称
        drugName:"",//药品名称
        startTime:"",
        endTime:"",
    }
    private tableData:any[] = [] //table 数据
    private tablePage:any ={  //分页数据
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }
    private SelectTime:any[] = []

    private ForageTypeList:any[] = []
    private ShelfStatus:any[] = [//订单状态
        {id:"",name:"请选择"},
        {id:"1",name:"已下单"},
        {id:"2",name:"已接单"},
        {id:"3",name:"已退单"},
    ] 



    //事件
    private SearchList(){//查询
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        console.log(this.form.orderStatus)
        this.getListReq()
    }
    private ResetList(){ //重置
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.SelectTime = []
        this.form.startTime = ''
        this.form.endTime = ''
        this.ClearFormData()
        this.getListReq()
    }
    private handlePageChange(val: any) { //分页 事件
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        //请求列表数据
        this.getListReq()
    }
    private OrderReceiving(status:any,row:any){//接单
        this.RequestForageOrder(status,row)
    }
    private Chargeback(status:any,row:any){//退单
        this.RequestForageOrder(status,row)
    }
    private exportlableList(){//导出
        this.ExportListReq()
    }



    // emit事件
    private SetUserNameValue(val:string){//下单人姓名
        this.form.userName = val
    }
    private SetMobileValue(val:string){//下单人电话
        this.form.mobile = val
    }
    private SetOrderStatusValue(val:string){//订单状态
        this.form.orderStatus = val
    }
    private SetDrugTenantValue(val:string){//商家名称
        this.form.drugTenant = val
    }
    private SetDrugNameValue(val:string){ //药品名称
        this.form.drugName = val
    }
    private SetPartnerInputValue(){}
    private SetForageTypeValue(){}


    //数据处理
    @Watch('SelectTime')//处理下单时间
    private ChangeSlectTime(newVal:any){
        if(newVal.length === 0) {
            this.form.startTime = ''
            this.form.endTime = ''
            return
        }
        this.form.startTime = new dateFun().returnDate(newVal[0] , '-') + ' ' + new dateFun().returnHourMinuteSecend(newVal[0], ':')
        this.form.endTime = new dateFun().returnDate(newVal[1] , '-') + ' ' + new dateFun().returnHourMinuteSecend(newVal[1], ':')
    }
    private ClearFormData(){
        this.form ={
            userName:"",//下单人姓名
            mobile:"",//下单人电话
            orderStatus:"",//订单状态
            drugTenant:"",//商家名称
            drugName:"",//药品名称
            startTime:"",
            endTime:"",
        }
    }


    //请求数据
    private getListReq(){//请求table数据
        new BasicsClass().BasicGet1('/animal/web/drugOrder/list',{...this.form,...this.tablePage},false,false,(data:any)=>{
            this.tableData = data.data
            this.tablePage.totalCount = data.page.totalCount
        })
    }
    private ExportListReq(){ //导出数据
        new SimManage().exportList3(this.form)
    }
    private RequestForageOrder(status:any,row:any){//订单处理
        let obj:any ={
            orderStatus:status,
            orderDetailId:row.orderDetailId,
        }
        new BasicsClass().BasicPost('/animal/web/drugOrder/deal',{...obj},false,false,false,(data:any)=>{
            this.getListReq()
        })
    }
    private getDrupNameListReq(){ //药品名称
        this.ForageTypeList = []
        new BasicsClass().BasicGet('/animal/web/drug/getDrugList',{} ,false,false,(data:any)=>{
            data.data.forEach((item:any) => {
                this.ForageTypeList.push({
                    id:item,
                    name:item
                })
            });
            this.ForageTypeList.unshift({id:"",name:"请选择"})
        })
    }

    activated(){
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getDrupNameListReq()
        this.getListReq()
    }
}
