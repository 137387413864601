

















































import {Component , Vue} from 'vue-property-decorator'
import InputComonentVue from '@/views/basics/typeConfig/components/components/InputComonentVue.vue'
import {getMarketList , DeleteMarket} from '@/api/index'
interface MarkListData {
    id:number,
    name:string, //交易场名称
    groupCode:string,//code
    addressDetail:string,//详细地址
    transactionType:number//交易类型
    proceedsType:number //收款类型
    createTime:string//创建时间
}
@Component({
    components:{
        InputComonentVue
    }
})
export default class MarkList extends Vue{
    private form :{name:string} ={
        name:""
    }
    private tablePage:{totalCount:number,pageNo:number,pageSize:number,pageSizes:number[]} ={  //分页数据
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    }
    private tableData:MarkListData[] = []  //列表数组


    //事件处理
    private SearchList(){ //查询
        this.getMarketListData()
    }
    private ResetList(){ //重置
        this.form.name = ""
        //请求列表信息
        this.getMarketListData()
    }
    private addMark(){ //新增
        this.$router.push({
            path: "/AddMarket"
        });
    }
    private MarkOption(row:MarkListData){//市场设置
        console.log(row)
        let id = row.id
        this.$router.push({
            path: "/EditMarket",
            query:{
                id
            }
        });
    }
    private MarkDistribution(row:MarkListData){//交易配置
        this.$router.push({
            path: "/TransactionConfig",
            query:{
                id:row.id,
                name:row.name,
            }
        });
    }
    private MarkDel(row:MarkListData){//删除
        this.$confirm('确定删除此交易市场吗?', '删除', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
            DeleteMarket(row.id,(data:any)=>{
                this.tableData = []
                this.tablePage.pageNo = 1
                this.tablePage.pageSize = 10
                this.getMarketListData()
                this.$message({
                    type: 'success',
                    message: '删除成功!'
                });
            })
          
        }).catch(() => {
            this.$message({
                type: 'info',
                message: '已取消删除'
            });          
        });
    }
    private handlePageChange(val: any) { //分页 事件
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        //请求列表数据
        this.getMarketListData()
    }


    //emit
    private SetUserNameValue(val:string){  //交易市场
        this.form.name = val
    }


    //数据请求
    private getMarketListData(){
        getMarketList(this.form.name,this.tablePage,(data:any)=>{
            this.tableData = data.data
            this.tablePage.totalCount = data.page.totalCount
        })
    }


    activated(){
        this.tableData = []
        this.tablePage.pageNo = 1
        this.tablePage.pageSize = 10
        this.getMarketListData()
    }

}
