import { BasicsClass } from '@/common/BasicsClass';
import { HandleTable } from '@/common/Views/comm/handleTable';
import { Util } from '@/common/Views/comm/util';
import { DeepLoop } from '@/common/deepLoop/deepLoop';


export class SimManage extends BasicsClass {
    public getList(form: any, page: any, cb: Function): void {
        const openTime = Util.handleStartTimeAndEndTime(form.openTime)
        const endTime = Util.handleStartTimeAndEndTime(form.endTime)
        this.BasicGet('/animal/web/simControl/simList', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            // startTime: time.startTime,
            // endTime: time.endTime,
            openTime: NaN,
            endTime: NaN,
            openStartTime: openTime.startTime,
            openEndTime: openTime.endTime,
            endStartTime: endTime.startTime,
            endEndTime: endTime.endTime,
            ...page
        }, false, true, cb)
    }

    public exportList(form: any, cb: Function): void {
        const openTime = Util.handleStartTimeAndEndTime(form.openTime)
        const endTime = Util.handleStartTimeAndEndTime(form.endTime)
        this.getFile('/animal/web/simControl/exportList', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            openTime: NaN,
            endTime: NaN,
            openStartTime: openTime.startTime,
            openEndTime: openTime.endTime,
            endStartTime: endTime.startTime,
            endEndTime: endTime.endTime,
        })
    }

    public exportList1(form: any, cb?: Function) {
        this.getFile('/animal/web/rebate/export', {
            ...form
        })
    }

    public exportList2(form: any, cb?: Function) {
        this.getFile('/animal/web/forageOrder/export', {
            ...form
        })
    }

    public exportList3(form: any, cb?: Function) {
        this.getFile('/animal/web/drugOrder/export', {
            ...form
        })
    }
    public exportWeightList(form: any, cb?: Function) {
        this.getFile('/animal/web/weight/export', {
            ...form
        })
    }

    public getPackage(cb: Function): void {
        this.BasicGet('/animal/web/simControl/packageList', {}, false, true, cb)
    }
}