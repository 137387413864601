































































import {Watch , Component , Vue} from 'vue-property-decorator'

import SelectComponentVue from '@/views/basics/typeConfig/components/components/SelectComponentVue.vue'
import InputComonentVue from './components/InputComonentVue.vue'
import UpLoadImgComponentVue from '@/views/basics/typeConfig/components/components/UpLoadImgComponentVue.vue'
import NewPidAdressVue from '@/views/basics/typeConfig/components/components/NewPidAdressVue.vue'
import UpLoadImgComponentListVue from '@/views/basics/typeConfig/components/components/UpLoadImgComponentListVue.vue'
import UpLoadImgAndViedeoComponentListVue from '@/views/basics/typeConfig/components/components/UpLoadImgAndViedeoComponentListVue.vue'

import {BasicsClass} from '@/common/BasicsClass';
import {FarmDetail} from '@/common/Views/system/farm/farmDetail/farmDetail'
@Component({
    components:{
        SelectComponentVue,
        InputComonentVue,
        UpLoadImgComponentVue,
        NewPidAdressVue,
        UpLoadImgComponentListVue,
        UpLoadImgAndViedeoComponentListVue
    }
})
export default class EditForageRackConfiguration extends Vue{
    private form:any = {
        confTypeId:"",//草料类型id
        forageTenant:"",//草料商家名称
        introduction:"",//草料简介
        groupCode:["0"],//售卖区域
        amount:"",//价格
        unit:"",//规格
        coverImage:"",//草料封面图
        bannerImageList:[],//草料banner
        detailImageList:[],//草料详情图
        enableFlag:"1",//上架状态
        contactPhone:"",//联系方式
        productName:"",//产品名称
    }
    private checkVal:any = '1'
    private disabledFlag:boolean = true
    private FormSelectList:any[] = [] //草料类型 数据
    private AdressTreeList:any[] = [] //售卖区域
    private ShelfStatusList:any[] = [//上架状态
        {id:"1",name:"上架"},
        {id:"2",name:"下架"},
    ] 
    private DetailsData:any ={}

    private rules:any ={
        confTypeId: [
            { required: true, message: '请选择饲料类别', trigger: 'change' }
        ],
        forageTenant:[
            { required: true, message: '请输入生产商家', trigger: 'change' }
        ],
        introduction:[
            { required: true, message: '请输入产品简介', trigger: 'change' }
        ],
        groupCode:[
            { required: true, message: '请选择售卖区域', trigger: 'change' }
        ],
        amount:[
            // { required: true, message: '请输入价格', trigger: ['change' , 'blur']  }
            { validator: this.checkamount, trigger: ['change' , 'blur']}
        ],
        coverImage:[
            { required: true, message: '请上传饲料封面图', trigger: ['change' , 'blur'] }
        ],
        bannerImageList:[
            { required: true, message: '请上传饲料banner图', trigger: ['change' , 'blur']}
        ],
        detailImageList:[
            { required: true, message: '请上传饲料详情图', trigger: ['change' , 'blur'] }
        ],
        enableFlag:[
            { required: true, message: '请选择上架状态', trigger: 'change' }
        ],
        productName:[
            { required: true, message: '请输入产品名称', trigger: 'change' }
        ],
        unit:[
            { required: true, message: '请输入规格', trigger: 'change' }
        ]
    }
    private checkamount(rule:any, value:any, callback:Function){
        console.log(rule , value)
        if(this.checkVal == 1 && value == '') return callback(new Error('请输入价格'));
        if(this.checkVal == 2) callback()
        callback()
    }


    //事件
    private SaveData(){ //保存提交按钮
        this.$refs["formRef"].validate((flag:boolean)=>{
            if(flag){
                let form:any = this.handleFormData()
                this.AddForageDataReq(form)
            }
        })
        

    }
    private InputEvent(e:any){ //价格输入框 输入事件
        if(e > 999999999){
            this.form.amount = 999999999
        }
    }
    @Watch('form.amount',{deep:true})
    private changeAmount(newVal:string){
        if(newVal.toString().includes('.'))return
        if(newVal== '')return
        this.form.amount=newVal.toString().replace(/^\D*(\d*(?:\.\d{0,2})?).*$/g, '$1')
        // this.form.amount = this.checkStr(newVal)
    }
    private checkStr(str:any){
        let result:any = str
        // utf8mb4 正则
        const utf8mb4Reg = /[^\u0000-\uFFFF]/g;
        // emoji 表情正则
        const emojiReg = /(\ud83c[\udf00-\udfff])|(\ud83d[\udc00-\ude4f])|(\ud83d[\ude80-\udeff])/g;
        // 包含 utf8mb4 的 4 字节
        const isUTF8MB4 = utf8mb4Reg.test(result);
        // 包含 emoji 表情
        const isEmoj = emojiReg.test(result);
        // 校验特殊字符 并删除
        isUTF8MB4 && (result = result.replace(utf8mb4Reg, ''));
        isEmoj && (result = result.replace(emojiReg, ''));
        return result
    }

    //emit事件
    private SetForageTypeValue(val:string){ //草料类型
        this.form.confTypeId = val
    }
    private SetPartnerInputValue(val:string){ //合作商家
        this.form.forageTenant = val
    }
    private SetShopSelfInputValue(val:string){ //商品简介
        this.form.introduction = val
    }
    private SetAdressVal(val:any[]){ //售卖区域
        this.form.groupCode = val
    }
    private SetStatusValue(val:string){ //上架状态
        this.form.enableFlag = val
    }
    private SetNewImgUrl(val:string){ //草料封面图
        this.form.coverImage = val
    }
    private SetNewImgVideoUrlList(val:any[]){ //草料banner
        this.form.bannerImageList = val
    }
    private SetDetailImageListNewImgUrlList(val:any[]){ //草料详情图
        this.form.detailImageList = val
    }
    private SetPhoneInputValue(val:string){ //；联系方式
        this.form.contactPhone = val
    }
    private SetProductNameInputValue(val:string){//产品名称
        this.form.productName = val
    }
    private SetUnitInputValue(val:string){ //规格
        this.form.unit = val
    }


    //获取数据 request
    private getForageTypeListReq(){ //草料类型
        this.FormSelectList = []
        new BasicsClass().BasicGet('/animal/web/forage/getConfTypeId',{},false,false,(data:any)=>{
            this.FormSelectList = data.data
            this.FormSelectList.unshift({id:"",name:"请选择"})
        })
    }
    private getAdressListReq(){ //获取地址数据
        new FarmDetail().getCity1('-1',(data:any)=>{
            this.AdressTreeList = data.data
        })
    }
    private AddForageDataReq(form:any){ //编辑修改接口
        new BasicsClass().BasicPost1('/animal/web/forage/updateForage',{...form,id:this.$route.query.rowData.id},true,true,true,(data:any)=>{
            this.$router.push({
                path:"/ForageRackConfiguration"
            })
        })
    }
    private getEditDataReq(id:string){ //编辑详情
        new BasicsClass().BasicGet('/animal/web/forage/getForagedetail',{id},false,false,(data:any)=>{
            this.DetailsData = data.data
            
            this.EditDetailData(this.DetailsData)
        })
    }


    //处理数据
    private handleFormData(){ //处理数据
        let obj:any = JSON.parse(JSON.stringify(this.form))
        //处理组织机构
        Array.isArray(this.form.groupCode) ? obj.groupCode = this.form.groupCode.slice(-1).toString() : this.form.groupCode //将grounpcode变为 字符串
        //处理价格
        if(this.checkVal == 2)obj.amount = -1
        //处理上架下架
        obj.enableFlag == "1" ? obj.enableFlag = true : obj.enableFlag  = false
        
        return obj
    }
    private ClearFormData(){ //清除数据
        this.form = {
            confTypeId:"",//草料类型id
            forageTenant:"",//草料商家名称
            introduction:"",//草料简介
            groupCode:["0"],//售卖区域
            amount:"",//价格
            unit:"",//单位直接传字符串
            coverImage:"",//草料封面图
            bannerImageList:[],//草料banner
            detailImageList:[],//草料详情图
            enableFlag:"1",//上架状态-
            contactPhone:"",//联系方式
            productName:"",//产品名称
        }
        this.checkVal = '1'
    }
    private EditDetailData(data:any){
        this.form.confTypeId = data.confTypeId
        this.form.forageTenant = data.forageTenant
        this.form.introduction = data.introduction
        this.form.unit = data.unit
        this.form.groupCode = [data.groupCode]
        if(data.amount == -1){
            this.form.amount = ""
            this.checkVal = "2"
        }else{
            this.form.amount = data.amount
        }
        this.form.coverImage = data.coverImage
        this.form.bannerImageList = data.bannerImageList
        this.form.detailImageList = data.detailImageList
        this.form.enableFlag = data.enableFlag
        data.enableFlag == true ? this.form.enableFlag = '1' : this.form.enableFlag  = '2'
        this.form.contactPhone = data.contactPhone
        this.form.productName = data.productName
        // this.
        let doms:any = this.$refs.FatherAdress
        doms.$refs.CascaderRef.$el.children[0].children[0].value = data.groupCodeName
    }

    //监视价格选择
    @Watch('checkVal',{immediate:true})
    private ChangeCheckVal(newVal:any){
        this.form.amount = ''
        if(newVal == 2) this.disabledFlag = true
        if(newVal == 1) this.disabledFlag = false
    }


    activated(){
        this.ClearFormData()
        this.getForageTypeListReq()
        this.getAdressListReq()
        this.getEditDataReq(this.$route.query.rowData.id)

    }

}
