











































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import { User } from "@/api/AppletWeb";
import configFile from "./components/configFile.vue";
import equipmentLog from "./components/equipmentLog.vue";
import { cameraManagement } from "@/api/HardwareModule/index";
import { dateFun } from "@/common/date";
import { quillEditor } from "vue-quill-editor"; //调用编辑器
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
@Component({
    components: { PageBox, SelectComponentsVue, configFile, equipmentLog },
})
export default class ViewAndEditAppletUser extends Vue {
    private cameraDetail: any = {};
    private issueForm: any = {
        deviceCodeList: "",
        code: "", //01固件升级 02模型升级 03上报配置 04更新配置 05重启 06上报日志
        subcode: "",
    };
    private RestartSB(): void {
        this.$confirm("是否重启设备？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
            this.issueForm.deviceCodeList = this.cameraDetail.deviceCode;
            this.issueForm.code = "05";
            new cameraManagement().changeIssue(this.issueForm, (res: any) => {
                if (res.code == 200) {
                    this.$message.success("重启成功");
                } else {
                    this.$message.error(res.msg);
                }
            });
        });
    }
    get DialogRules() {
        return {
            SelectSetData: [
                {
                    required: false,
                    message: "请选择日志范围",
                    trigger: "change",
                },
            ],
        };
    }
    private tabType = false;
    //上报
    private setUp(row: any) {
        this.issueForm.deviceCodeList = this.cameraDetail.deviceCode;
        this.issueForm.subcode = row.subcode;
        this.issueForm.code = "03";
        new cameraManagement().changeIssue(this.issueForm, (res: any) => {
            if (res.code == 200) {
                this.$message.success("上报成功");
                this.resetConfigForm();
            } else {
                this.$message.error(res.msg);
            }
        });
    }
    //下发后上报
    private setDowUp(row: any) {
        this.issueForm.deviceCodeList = row.deviceid;
        this.issueForm.subcode = row.subcode;
        this.issueForm.code = "04";
        new cameraManagement().changeIssue(this.issueForm, (res: any) => {
            if (res.code == 200) {
                this.$message.success("下发成功");
                this.resetConfigForm();
            } else {
                this.$message.error(res.msg);
            }
        });
    }
    private showFBFlag = false;
    private CloseFBDialog() {
        this.showFBFlag = false;
    }
    private setFBDow() {
        this.dowForm.content = this.dowForm.content.replace(
            /<p[^>]*>(.*?)<\/p>/gi,
            "$1"
        );
        this.dowForm.content = this.dowForm.content.replace(
            /<span[^>]*>(.*?)<\/span>/gi,
            "$1"
        );
        new cameraManagement().editCfg(this.dowForm, (res: any) => {
            if (res.code == 200) {
                this.showFBFlag = false;
                this.setDowUp(this.dowForm);
            } else {
                this.$message.error(res.msg);
                this.showFBFlag = false;
            }
        });
    }
    //下发
    private setDow(row: any) {
        this.dowForm.content = row.content;
        this.dowForm.subcode = row.subcode;
        this.dowForm.deviceid = this.cameraDetail.deviceCode;
        this.showFBFlag = true;
    }
    private dowForm = {
        deviceid: "",
        subcode: "",
        content: "",
    };
    private editorOption: any = {
        modules: {
            toolbar: [
                ["blockquote", "code-block"], // 引用  代码块
                [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                ["clean"], // 清除文本格式
            ],
        },
    };
    private editorVisible = false;
    mounted() {
        this.editorVisible = true;
    }
    private updated() {
        this.$nextTick(() => {
            if (this.$refs.myQuillEditor) {
                const quill = this.$refs.myQuillEditor.quill;
                // const formattedJson = JSON.stringify(this.dowForm.content, null, 2);
                quill.on("text-change", this.handleTextChange);
                // quill.setContents([{ insert: formattedJson }]);
            }
        });
    }
    private CloseDowDialog(): void {
        this.showFBFlag = false;
    }
    private handleTextChange(delta: any, oldDelta: any, source: any) {
        if (this.$refs.myQuillEditor) {
            const quill = this.$refs.myQuillEditor.quill;
            if (source === "user" && !delta.ops[1].delete) {
                const selection = quill.getSelection(true);
                if (selection) {
                    const index = selection.index - 1;
                    quill.formatText(index, 1, "color", "red");
                }
            }
        }
    }
    private removeColor() {
        if (this.$refs.myQuillEditor) {
            const quill = this.$refs.myQuillEditor.quill;
            const length = quill.getLength();
            quill.removeFormat(0, length);
        }
    }
    private changeTab(val: any): void {
        //切换tab并且刷新数据并且给予样式.active
        this.tabType = val;
        if (!this.tabType) {
            this.configForm = {
                deviceCode: "",
                stTime: "",
                edTime: "",
            };
            this.getConfigFileList();
        } else {
            this.logForm = {
                deviceCode: "",
                stTime: "",
                edTime: "",
            };
            this.tablePage = {
                totalCount: 0,
                pageNo: 1,
                pageSize: 10,
                pageSizes: [10, 20, 50, 100, 200, 500],
            };
            this.getLogList();
        }
    }
    private activated(): void {
        let data = JSON.parse(this.$route.query.data);
        this.cameraDetail = data;
        this.tabType = false;
        this.SelectConfigData = "";
        this.SelectLogData = "";
        this.SelectSetData = "";
        this.configForm = {
            deviceCode: "",
            stTime: "",
            edTime: "",
        };
        this.logForm = {
            deviceCode: "",
            stTime: "",
            edTime: "",
        };
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };

        this.getConfigFileList();
    }
    //获取配置文件列表
    private configForm = {
        deviceCode: "",
        stTime: "",
        edTime: "",
    };
    private SelectConfigData = "";
    private configTableDate: any = [];
    private getConfigFileList(): void {
        this.configTableDate = [];
        this.configForm.deviceCode = this.cameraDetail.deviceCode;
        new cameraManagement().gecfgList(this.configForm, (res: any) => {
            if (res.code == 200) {
                this.configTableDate = res.data;
            } else {
                this.$message.error(res.msg);
            }
        });
    }
    private resetConfigForm() {
        //重置配置文件
        this.SelectConfigData = "";
        this.configForm.stTime = "";
        this.configForm.edTime = "";
        this.getConfigFileList();
    }
    private resetLogForm() {
        //重置日志
        this.SelectLogData = "";
        this.logForm.stTime = "";
        this.logForm.edTime = "";
        this.getLogList();
    }
    //查询
    private searchConfig(): void {
        this.getConfigFileList();
    }

    private SelectLogData = "";
    //分页
    private tablePage: any = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };
    private logTableDate: any[] = [];
    //获取设备日志列表
    private logForm = {
        deviceCode: "",
        stTime: "",
        edTime: "",
    };
    private handlePageChange(val: any) {
        // 分页
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;
        this.getLogList();
    }
    private getLogList(): void {
        this.logForm.deviceCode = this.cameraDetail.deviceCode;
        new cameraManagement().geLogList(
            this.logForm,
            this.tablePage,
            (res: any) => {
                if (res.code == 200) {
                    this.logTableDate = res.data;
                    this.tablePage = { ...res.page };
                } else {
                    this.$message.error(res.msg);
                }
            }
        );
    }
    private searchLog() {
        this.getLogList();
    }
    @Watch("SelectLogData", { deep: true }) //处理日志选择日期
    private handlerLogSelectData(newVal: any) {
        if (newVal) {
            this.logForm.stTime = new dateFun().returnAllDate(newVal[0], "-");
            this.logForm.edTime = new dateFun().returnAllDate(newVal[1], "-");
        } else {
            this.SelectLogData = "";
            this.logForm.edTime = "";
            this.logForm.stTime = "";
        }
    }
    @Watch("SelectConfigData", { deep: true }) //处理配置选择日期
    private handlerSelectConfigData(newVal: any) {
        if (newVal) {
            this.configForm.stTime = new dateFun().returnAllDate(
                newVal[0],
                "-"
            );
            this.configForm.edTime = new dateFun().returnAllDate(
                newVal[1],
                "-"
            );
        } else {
            this.SelectConfigData = "";
            this.configForm.edTime = "";
            this.configForm.stTime = "";
        }
    }
    private SelectUpData = "";
    @Watch("SelectSetData", { deep: true }) //处理上报选择日期
    private handlerSelectUpData(newVal: any) {
        if (newVal) {
            this.timeT = new dateFun().returnAllDate(newVal[0], "-");
            this.timeE = new dateFun().returnAllDate(newVal[1], "-");
        } else {
            this.SelectSetData = "";
            this.timeT = "";
            this.timeE = "";
        }
    }

    private upForm = {
        deviceCodeList: "",
        code: "06",
        subcode: "",
    };
    private timeT = "";
    private timeE = "";
    private setLog() {
        this.showSelectDateFlag = true;
    }
    private showSelectDateFlag = false;
    private SelectSetData = "";
    private CloseDialog() {
        this.showSelectDateFlag = false;
        this.SelectSetData = "";
    }
    private setLogUp() {
        this.upForm.deviceCodeList = this.cameraDetail.deviceCode;
        if (!this.timeT || !this.timeE) {
            this.$message.error("请选择日志范围");
            return;
        }
        this.upForm.subcode = this.timeT + "/" + this.timeE;
        new cameraManagement().changeIssue(this.upForm, (res: any) => {
            if (res.code == 200) {
                this.$message.success("上报成功");
                this.SelectSetData = "";
                this.resetLogForm();
            } else {
                this.$message.error(res.msg);
            }
        });
        this.showSelectDateFlag = false;
    }

    private dowloadLog(url: string) {
        // window.open(url);
        const link = document.createElement("a");
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
