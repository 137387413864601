import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';

export interface InsuranceForm {
    companyId: string; //保险公司id
    insuranceCompanyName: string; //保险公司名字
    businessList: BusinessList[]; //配置列表
    deleteCompany: boolean //是否禁用
}

export interface insuranceIdentity {
    companyId: string; //保险公司id
    deleteCompany: Number|string;
}

export interface BusinessList {
    id: string; // 
    confId: string; //类型id
    confName: string, // 类型名称
    confInfoId: string, //详细类型id
    confInfoName: string, //详细类型名称
    startWeight: string; //最新体重
    endWeight: string; //最大体重
    payMoney: string; //赔付金额
}

export class InsuranceCompanyConfiguration extends BasicsClass {

    public getTable(tablePage: HandleTable, cb: Function): void {
        this.BasicGet('/animal/web/insurance/list', tablePage, false, true, cb);
    }

    public handleDisabled(form: insuranceIdentity, cb: Function): void {
        console.log(form)
        this.BasicPost('/animal/web/insurance/stop', form, false, true, true, cb)
    }

    public getDetail(id: string, cb: Function): void {
        this.BasicGet('/animal/web/insurance/getCompanyDetail', id, false, true, cb)
    }

    public insuranceSave(form: InsuranceForm, cb: Function): void {
        this.BasicPost('/animal/web/insurance/save', form, true, true, true, cb)
    }
}