































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Form, TableResult} from "@/common/Views/system/role/role";
import {Column, Result} from "@/common/Views/system/column/column";
import {DeepLoop} from '@/common/deepLoop/deepLoop';
import { PersonalDetailC } from '@/common/Views/system/personal/personalDetail';
import { HandleRow } from '@/common/Views/comm/handleTable';
import {Power} from "@/common/Views/system/power/power";

@Component
export default class HelloWorld extends Vue {
    private form: Form<string> = {
        name: '',
    }

    private person: PersonalDetailC;
    private power: Power;
    constructor() {
        super();
        this.person = new PersonalDetailC();
        this.power = new Power();
    }

    private treeData: Result[] = [];

    private allResource: Set<string> = new Set<string>();

    private getTreeData(): void {

        if(this.$getResourceCodes('menu_role_menus')){

            const column: Column = new Column();
            column.getTreeData().then((result: Result[])=>{
                console.log(result)
                this.treeData = result;

            }).catch(err=>{
                this.$message.error(err.message);
            });
        }
    }

    private roleOptions: TableResult<string>[] = [];

    private getRoleList(): void {
        this.person.getRoleList()
            .then(data=>{
                this.roleOptions = data;
            })
    }

    private activated(): void {
        this.getTreeData();

        this.getRoleList();
    }

    private powerData: Result[] = [];

    private showData(row: Result) {

        this.powerData = row.resources;
        this.setTableChecked();
    }
    
    private handleCheckChange(data: TableResult<string>, check: boolean, row: Result): void {

        if(check){
            this.allResource.add(data.id);
        }else {
            this.allResource.delete(data.id);
        }

    }

    private selectAll(row: HandleRow<TableResult<string>>): void {
        if(row.checked){
            for (const i in row.selection){
                this.allResource.add(row.selection[i].id);
            }

            // this.allResource = [...new Set(this.allResource)];

        }else{

            for (const i in this.powerData){
                const index = this.allResource.delete(this.powerData[i].id);

            }
        }

    }

    private checkbox(row: HandleRow<TableResult<string>>): void {
        if(row.checked){
            this.allResource.add(row.row.id);
        }else{
            this.allResource.delete(row.row.id);
        }

    }

    private selectRole(): void {

        this.person.getRoleResource(this.form.name)
            .then(data=>{

                const deepCopy: DeepLoop = new DeepLoop();

                this.allResource = new Set<string>(deepCopy.deepFlatJsonWithResource(data));

                (this.$refs.tree as any).setCheckedKeys([...this.allResource]);

                this.setTableChecked();
            });
    }

    private setTableChecked(): void {

        (this.$refs.xTable as any).setSelection(this.powerData, false);

        (this.$refs.xTable as any).setSelection(this.powerData.filter(value => this.allResource.has(value.id)), true);
    }

    private save(): void{
        if (!this.form.name) {
            this.$message.error('请先选择角色');
            return;
        }

        this.power.save(this.form.name, [...this.allResource]);
    }
}
