









































































































































































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { AxiosData } from "@/common/http/http";
import { Util } from "@/common/Views/comm/util";
import { HandleTable, HandlePageChange } from "@/common/Views/comm/handleTable";
import deviceGroupingDetail from "@/views/configManage/deviceGrouping/deviceGroupingDetail/deviceGroupingDetail.vue";
import {
    DeviceManage,
    DeviceManageForm,
    DeviceManageTable,
} from "@/common/Views/basics/deviceManage/deviceManage";
import DeviceGroup from "@/views/basics/deviceManage/deviceGroup/deviceGroup.vue";
import { DeviceGroupingDetail } from "@/common/Views/configManage/deviceGrouping/deviceGrouping";
import { ComboList, Column, Result } from "@/common/Views/system/column/column";
import { BasicsClass } from "@/common/BasicsClass";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import { getJDAnimalFarmList316 } from "@/api/index";
import { DeviceManagement } from "@/api/HardwareModule/index";
import { dateFun } from "@/common/date";
@Component({
    components: { DeviceGroup, SelectComponentsVue },
})
export default class HelloWorld extends Vue {
    public column = new Column();
    private showLabelSelectInputFlag: boolean = false;
    private form: any = {
        groupCode: [],
        farmId: "",
        deviceCode: "",
        deviceType: "",
        destinationTypeId: "",
        locationType: "",
        isOutType: "",
        simNumber: "",
    };

    private typeList = [];
    private show: boolean = false;
    private FarmListData: any[] = [];
    private SetFarmVal(val: string) {
        this.DialogForm.farmId = val;
    }

    private deviceManage: DeviceManage;

    private childComboList: ComboList[] = [];

    private destinationTypeList = [
        {
            id: "0",
            name: "养殖场",
        },
        {
            id: "2",
            name: "屠宰场",
        },
    ];

    constructor() {
        super();
        this.deviceManage = new DeviceManage();
    }

    private getFarmListData() {
        this.FarmListData = [];
        getJDAnimalFarmList316((res: any) => {
            res.data.forEach((item: any) => {
                this.FarmListData.push({
                    id: item.farmId,
                    name: item.farmName,
                });
            });
        });
    }

    private activated(): void {
        this.getTree(() => {
            this.getTable();
        });
        this.changeGroupCode();
        this.getFarmListData();
        new DeviceManagement().getTypeList((res: any) => {
            this.typeList = res.data;
            console.log(this.typeList);
        });
        // this.column.getTissuc(undefined, (res: any) => {
        //     this.treeData = res.data
        // })
    }

    private treeData: Result[] = [];

    private getTree(cb: Function) {
        Util.getTree((result: Result[]) => {
            this.treeData = result;
            cb ? cb() : "";
        });
    }

    private restForm(): void {
        this.SelectData = "";
        this.form = new Util().clearObject(this.form);
        this.tablePage = {
            totalCount: 0,
            pageNo: 1,
            pageSize: 10,
            pageSizes: [10, 20, 50, 100, 200, 500],
        };
        this.getTable();
    }
    @Watch("SelectData", { deep: true }) //处理选择日期
    private handlerSelectData(newVal: any) {
        console.log(newVal);
        if (newVal) {
            this.form.stExpiryDate = new dateFun().returnDate(newVal[0], "-");
            this.form.edExpiryDate = new dateFun().returnDate(newVal[1], "-");
        } else {
            this.SelectData = "";
            this.form.stExpiryDate = "";
            this.form.edExpiryDate = "";
        }
    }
    private SelectData = "";
    private dialogTableVisible: boolean = false;
    private onClickDeviceGroupingVisible: boolean = false;
    private rowData: DeviceManageTable[] = [];

    private deviceGrouping(): void {
        let removeRecords: DeviceManageTable[] = (this.$refs
            .table as any).getSelectRecords();
        if (removeRecords.length === 0) {
            this.$message.error("请勾选数据后再操作");
            return;
        }

        this.rowData = removeRecords;
        this.dialogTableVisible = true;
        var isComboList = true;
        this.$nextTick(() => {
            (<any>this.$refs.deviceGroup).getTree();
        });
    }

    private onClickDeviceGrouping(): void {
        this.rowData = [];
        this.onClickDeviceGroupingVisible = true;
        this.$nextTick(() => {
            (<any>this.$refs.onClickDevice).getTree();
        });
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;

        this.getTable();
    }

    private tableData: DeviceManageTable[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }

    private comboList: ComboList[] = [];

    private changeGroupCode(): void {
        this.comboList = [];
        this.form.farmId = "";
        // new Column().getFarm(this.form.groupCode[this.form.groupCode.length - 1], (data: AxiosData<ComboList[]>) => {
        //     this.comboList = data.data;
        // });
        new Column().getAll(
            this.form.groupCode[this.form.groupCode.length - 1],
            (data: AxiosData<ComboList[]>) => {
                this.comboList = data.data;
            }
        );
    }

    @Watch("form.groupCode")
    getgroupCode(newVal: Array<object>, oldVal: Array<object>) {
        if (newVal !== oldVal) {
            this.form.farmId = "";
            // this.form.typeConfId = '';
        }
    }

    private getTable(): void {
        if (this.$getResourceCodes("device_list")) {
            this.deviceManage.getTable(
                this.form,
                this.tablePage,
                (data: AxiosData<DeviceManageTable[]>) => {
                    this.tableData = data.data;
                    this.tablePage.totalCount = data.page.totalCount;
                }
            );
        }
    }

    private success(data: DeviceGroupingDetail): void {
        let flag: boolean = false;
        for (const i of this.rowData) {
            if (i.isBind) {
                flag = true;
                break;
            }
        }

        if (flag) {
            this.confirm(() => {
                this.groupMapping(data);
            });
            return;
        }

        this.groupMapping(data);
    }

    private pushdeviceCodes(code: any): void {
        // this.rowData=[]
        this.rowData = code;
        this.dialogTableVisible = true;
        this.$nextTick(() => {
            (<any>this.$refs.deviceGroup).getTree();
        });
    }
    private groupMapping(data: DeviceGroupingDetail): void {
        this.deviceManage.deviceGroup(data, this.rowData, () => {
            this.dialogTableVisible = false;
            this.onClickDeviceGroupingVisible = false;
            this.getTable();
        });
    }

    private confirm(cb: Function) {
        this.$confirm(
            "当前选中设备已绑定养殖场，重新分组后，之前绑定的养殖场将自动解绑，确认是否继续操作？",
            "提示",
            {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }
        ).then(() => {
            cb();
        });
    }

    private handleDelete() {
        let removeRecords: DeviceManageTable[] = (this.$refs
            .table as any).getSelectRecords();
        if (removeRecords.length === 0) {
            this.$message.error("请勾选数据后再操作");
            return;
        }

        const deviceCodes: string[] = [];
        removeRecords.forEach((value) => deviceCodes.push(value.deviceCode));

        this.deviceDelete(deviceCodes);
    }

    private deviceDelete(deviceCode: string[]) {
        this.$confirm("是否确定删除", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
        }).then(() => {
            new DeviceManage().deviceDelete(deviceCode, () => {
                this.getTable();
            });
        });
    }

    private DialogForm: any = {
        custNo: "",
        custName: "",
        farmId: "",
    };
    private DialogRules: any = {
        custNo: [
            { required: true, message: "此字段为必填字段", trigger: "change" },
        ],
        custName: [
            { required: true, message: "此字段为必填字段", trigger: "change" },
        ],
        farmId: [
            { required: true, message: "此字段为必填字段", trigger: "change" },
        ],
    };
    private SelectListValue: any[] = [];
    private SelectListDeviceCode: any[] = [];
    private selectChangeEvent(data: any) {
        this.SelectListValue = data.selection;
        this.HandlerSelectData();
    }
    private selectAllEvent(data: any) {
        this.SelectListValue = data.selection;
        this.HandlerSelectData();
    }
    private deviceTypeData: string = "";
    private LabelPushArr: any[] = [];
    private HandlerSelectData() {
        if (this.SelectListValue.length < 0) return;
        this.LabelPushArr = [];
        this.SelectListDeviceCode = [];
        this.deviceTypeData = this.SelectListValue[0].deviceType;
        for (let i: number = 0; i < this.SelectListValue.length; i++) {
            // this.SelectListDeviceCode
            this.LabelPushArr.push({
                number: this.SelectListValue[i].deviceCode,
                deviceModel: this.SelectListValue[i].deviceType,
            });
            this.SelectListDeviceCode.push(this.SelectListValue[i].deviceCode);
            if (this.SelectListValue[i].isBind) {
                this.LabelPushArr = [];
                this.$message({
                    message: "勾选中有已绑定值",
                    type: "warning",
                });

                return;
            }
        }
    }

    private AddDialodData() {
        this.$refs["DialogRef"].validate((flag: boolean) => {
            if (flag) {
                let obj: any = {
                    ...this.DialogForm,
                    labelNumberList: this.SelectListDeviceCode,
                };
                if (this.SelectListDeviceCode.length === 0) {
                    this.$message({
                        message: "请勾选耳标",
                        type: "warning",
                    });
                    return;
                }
                new BasicsClass().BasicPost(
                    "/animal/web/JDAnimal/deviceBind",
                    { ...obj },
                    true,
                    true,
                    true,
                    (data: any) => {
                        this.ClearDialogObj();
                        this.showLabelSelectInputFlag = false;
                        this.getTable();
                    }
                );
            }
        });
    }

    private ShowDinlogFun() {
        let flag: boolean = false;
        for (let i: number = 0; i < this.SelectListValue.length; i++) {
            if (this.SelectListValue[i].deviceType != this.deviceTypeData) {
                flag = false;
                this.$message({
                    message: "不支持多种设备一起操作，请重新选择",
                    type: "warning",
                });
                this.LabelPushArr = [];
                return;
            } else {
                flag = true;
            }
        }
        if (flag) this.showLabelSelectInputFlag = true;
    }
    private ClearDialogObj() {
        this.LabelPushArr = [];
        this.SelectListValue = [];
    }
}
