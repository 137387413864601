import { render, staticRenderFns } from "./newPreventRecord.vue?vue&type=template&id=9e40b0a8&scoped=true&"
import script from "./newPreventRecord.vue?vue&type=script&lang=ts&"
export * from "./newPreventRecord.vue?vue&type=script&lang=ts&"
import style0 from "./newPreventRecord.vue?vue&type=style&index=0&id=9e40b0a8&prod&scoped=true&lang=stylus&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e40b0a8",
  null
  
)

export default component.exports