import { BasicsClass } from '@/common/BasicsClass';

import {FarmDetail} from '@/common/Views/system/farm/farmDetail/farmDetail'

export class groupCodeLazy extends BasicsClass{

    public props1: any = {
        expandTrigger: 'hover',
        value: 'id',
        label: 'name',
        lazy: true,
        lazyLoad(node: any, resolve: any) {
            if(node.children == undefined){
                return
            }
            new FarmDetail().getCity(node.value,(window as any).roleId, (res: any) => {
                try {
                    let nodes = res.data
                    nodes.forEach((item: any) => {
                        item.leaf = (item.level === 4)
                    })
                    // console.log(nodes)
                    resolve(nodes)
                } catch (e) {
                    // console.log(e)
                }
            })
        }
    }
    public props2: any = {
        expandTrigger: 'hover',
        value: 'id',
        label: 'name',
        lazy: true,
        lazyLoad(node: any, resolve: any) {
            if(node.children == undefined){
                return
            }
            new FarmDetail().getCity1(node.value, (res: any) => {
                try {
                    let nodes = res.data
                    nodes.forEach((item: any) => {
                        item.leaf = (item.level === 4)
                    })
                    // console.log(nodes)
                    resolve(nodes)
                } catch (e) {
                    // console.log(e)
                }
            })
        }
    }
}