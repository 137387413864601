import {BasicsClass} from '@/common/BasicsClass';
import {SchoolList} from '@/common/Views/basics/schoolManage/schoolManage';

export interface MedicineConfigTable {
    medicines: SchoolList<string>[];
    vaccines: SchoolList<string>[];
}

export class MedicineConfig extends BasicsClass {

    public getMedicineConf(cb: Function) {
        this.BasicGet('/animal/web/MedicineConf/getMedicineConf', {}, false, true, cb);
    }

    public save(form: any[], cb: Function) {
        const data = {medicines: '', vaccines: ''};
        const medicines: string[] = [];
        const vaccines: string[] = [];
        for (let i = 0; i < form.length; i++) {
            for (const j of form[i]) {
                if (i === 0) {
                    console.log(j.name)
                    medicines.push(j.name);
                } else {
                    vaccines.push(j.name);
                }
            }
        }
        data.medicines = medicines.toString();
        data.vaccines = vaccines.toString();

        this.BasicPost('/animal/web/MedicineConf/SaveMedicineConf', data, true, true, true, cb);
    }

    public getDisease(cb:Function){
        this.BasicGet('/animal/web/disease/getList',{},false,false,cb)
    }


    public saveDisease(form: any[], cb: Function) {
        const data = {pigDisease: '', cowDisease: '' , sheepDisease:''};
        const pigDisease: string[] = [];
        const cowDisease: string[] = [];
        const sheepDisease: string[] = [];
        for (let i = 0; i < form.length; i++) {
            for (const j of form[i]) {
                if (i === 0) {
                    console.log(j.name)
                    pigDisease.push(j.name);
                }
                if(i === 1){
                    cowDisease.push(j.name);
                }
                if(i === 2){
                    sheepDisease.push(j.name);
                }
            }
        }
        data.pigDisease = pigDisease.toString();
        data.cowDisease = cowDisease.toString();
        data.sheepDisease = sheepDisease.toString();

        this.BasicPost('/animal/web/disease/saveDisease', data, true, true, true, cb);
    }
}
