















































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Result, ComboList, Column } from "@/common/Views/system/column/column";
import { Util } from "@/common/Views/comm/util";
import { AxiosData } from "@/common/http/http";
import {
  LabelManage,
  LabelManageFrom,
  LabelManageTable,
  Usermanagement,
  Focu,
} from "@/common/Views/basics/transaction/labelManage";
import { Rule } from "@/common/Views/comm/formRule";
import { DeviceManage, DeviceManageTable, } from "@/common/Views/basics/deviceManage/deviceManage";
@Component
export default class DeviceGroup extends Vue{


	
	private addEnterprise: Focu = {
		Enterprisename: "",
		contacts: "",
		tel: "",
		address: "",
		Fulladdress: "",
		state: "",
	}

	//formRule
	private formRule: Focu<Rule[]> = {
		Enterprisename: [
			{
				type: "string",
				required: true,
				message: "请输入企业名称",
				trigger: "blur",
			},
		],
		contacts: [
			{
				type: "string",
				required: true,
				message: "请输入联系人",
				trigger: "blur",
			},
		],
		tel: [
			{
				type: "string",
				required: true,
				message: "请输入联系电话",
				trigger: "blur",
				max: 11,
				min: 11
			},
		],
		address: [
			{
				type: "string",
				required: true,
				message: "请输入所属地址",
				trigger: "blur",
			},
		],
		Fulladdress: [
			{
				type: "string",
				required: true,
				message: "请输入详细地址",
				trigger: "blur",
			},
		],
	}
	//getThree
	private getTree() {
		// this.form = new Util().clearObject(this.form);
		Util.getTree((result: Result[]) => {
			// this.treeData = result;
		});
	}
	//电话正则匹配
	private regularTel(){
		// if(this.addEnterprise.tel.length === '10' || this.addEnterprise.tel.length === 10){

		// }
	}
	//clearform
	private clearform(){
		this.addEnterprise.Enterprisename=''
		this.addEnterprise.contacts=''
		this.addEnterprise.tel=''
		this.addEnterprise.address=''
		this.addEnterprise.Fulladdress=''
		this.addEnterprise.state=''
	}
	//submit
	private submit(): void{
		// console.log(this.addEnterprise)
		//执行请求
		
		// this.clearform();

		//执行请求列表接口重新获取最新的列表信息

		// (this.$refs.addEnterprise as any).validate((valid: boolean) => {
		// 	console.log(valid)
		// 	console.log(this)
		// 	//volid 判断内容是否全部填了
		// 	if(valid){
		// 		console.log(this.addEnterprise)
				
		// 	}
		// 	this.clearform();

		// })
		// this.$emit("success", this.form);
		//关闭
		this.$emit("success");
	}


}
