
























































































































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {AxiosData} from "@/common/http/http";
import {Util} from "@/common/Views/comm/util";
import {HandleTable, HandlePageChange} from "@/common/Views/comm/handleTable";
import LabelManageDetailImport from "@/views/basics/labelManage/labelManageDetail/labelManageDetailImport.vue";
import {LabelManage, LabelManageFrom, LabelManageTable} from "@/common/Views/basics/labelManage/labelManage";
import { ComboList, Column, Result } from '@/common/Views/system/column/column';
import DeviceGroup from '@/views/basics/deviceManage/deviceGroup/deviceGroup.vue';
import {DeviceManageTable} from '@/common/Views/basics/deviceManage/deviceManage';
import {CountException, CountExceptionFrom} from "@/common/Views/basics/countException/countException";
import {TemperatureInfo} from '@/common/Views/basics/temperatureInfo/temperatureInfo';
import {MedicineInfo} from '@/common/Views/basics/medicineInfo/medicineInfo';
import {SchoolList} from '@/common/Views/basics/schoolManage/schoolManage';
import {ConfType} from '@/common/Views/system/confType/confType';
import { LeaveHouse } from '@/common/Views/basics/leaveHouse/leaveHouse';
import {
    TransferRecord,
    TransferRecordForm
} from "@/common/Views/traceabilityOutManage/transferRecord";
import { InsureRecord } from '@/common/Views/basics/insureRecord/insureRecord'

@Component({})
export default class HelloWorld extends Vue {

    private transferRecord: TransferRecord = new TransferRecord()
    
    public column = new Column()

    private form: any = {
        groupCode: [],
        enterpriseName: '',
        farmName: '',
        orderNo: '',
        createTime: [],
        dealTime: [],
        houseId: '',
        confTypeId: '',
        confId: '',
        companyId: '',
        insuredStatus: ''
    };


    private show: boolean = false;

    private InsureRecord: InsureRecord;

    constructor() {
        super();
        this.InsureRecord = new InsureRecord();
        
    }

    private activated(): void {
        if(this.$route.query.sendGroupCodeId != undefined){
            this.form.groupCode = [this.$route.query.sendGroupCodeId]
            this.$refs.AdressRef.$el.children[0].children[0].value = this.$route.query.sendGroupName
        }
        this.getTable();
        this.getTree();
        this.getEnterprise()
        this.confType();
        this.changeGroupCode();
        this.getInsuranceCompany()
    }


    private treeData: Result[] = [];
    private getTree() {
        Util.getTree((result: Result[])=>{
            this.treeData = result
        })
    }

    private restForm(): void {
        this.$refs.AdressRef.$el.children[0].children[0].value =''
        this.form = new Util().clearObject(this.form);
        this.form.time = [];
        this.getTable();
        this.getTree();
        this.changeGroupCode();
        this.getEnterprise()
    }

    private conTypeList: SchoolList<string>[] = [];

    private confType(): void {

        this.conTypeList = [];
        // this.form.typeConfId = '';
        new ConfType().getSelectAll(this.form.farmId, (data: AxiosData<SchoolList<string>[]>) => {
            this.conTypeList = data.data;
            console.log(data)
        });
    }

    private insuranceCompany: any[] = []

    private getInsuranceCompany(): void {
        new InsureRecord().getInsuranceCompany((res: any) => {
            this.insuranceCompany = res.data
        })
    }

    private search(): void {
        this.tablePage.pageNo = 1;
        this.tableData = [];
        this.tablePage.totalCount = 0;

        this.getTable();
    }

    private Export(): void {
        this.InsureRecord.export(this.form, this.tablePage)
    }


    private comboList: ComboList[] = [];
    private changeGroupCode(): void {
        this.comboList = [];
        new Column().getFarm(this.form.groupCode[this.form.groupCode.length - 1], (data: AxiosData<ComboList[]>)=>{
            this.comboList = data.data;
        })
    }

    @Watch("form.groupCode")
    getgroupCode(newVal:Array<object>,oldVal:Array<object>) {
        if (newVal !== oldVal) {
            // this.form.farmId = '';
            this.form.farmName = ''
            this.form.typeConfId = ''; 
        }
    }


    private tableData: LabelManageTable[] = [];

    private tablePage: HandleTable = {
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
        pageSizes: [10, 20, 50, 100, 200, 500],
    };

    private handlePageChange(val: HandlePageChange) {
        this.tablePage.pageNo = val.currentPage;
        this.tablePage.pageSize = val.pageSize;

        this.getTable();
    }

    private handleSelect(item: any): void  {
        if(item.enterpriseName){
            this.form.enterpriseName = item.enterpriseName
        }else{
            this.form.farmName = item.name
        }
        
        console.log(item)
    }

    private handleIconClick(ev: any): void {
        // console.log(ev);
    }

    private restaurants: any[] = []
    
    private state: string =  ''

    private querySearch(queryString: any, cb: Function): void  {
        var restaurants = this.restaurants;
        var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
        cb(results);
    }
    private querySearch2(queryString: any, cb: Function): void  {
        var restaurants = this.comboList;
        var results = queryString ? restaurants.filter(this.createFilter2(queryString)) : restaurants;
        cb(results);
    }

    private createFilter(queryString: any) {
        return (restaurant: any) => {
            return (restaurant.enterpriseName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    }
    private createFilter2(queryString: any) {
        return (restaurant: any) => {
            return (restaurant.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
    }

    private getEnterprise(): void {
        new InsureRecord().getEnterprise((res: any) => {
            this.restaurants = res.data
        })
    }


    private getTable(): void {
        // if(this.$getResourceCodes('label_list')){
            this.tableData = []
            this.InsureRecord.getTable(this.form, this.tablePage, (data: AxiosData<LabelManageTable[]>) => {
                this.tablePage.totalCount = data.page.totalCount;
                this.tableData = data.data;
            });

        // }
    }

    private goDetail(row: any, str: string) {
        console.log(row)
        this.$router.push({
            path: '/insureRecordDetail',
            query: {
                id: row.insuredId,
                type: str
            }
        })
    }



    private rowData: LabelManageTable[] = [];
}
