import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {Util} from '@/common/Views/comm/util';
import {DeepLoop} from '@/common/deepLoop/deepLoop';

export interface ImmuneRecordForm {
    groupCode: [],
    farmId: string,
    labelNumber: string,
    time: [],
    // type: string,
    vaccineId: string,
    batchNum: string,
}

export class ImmuneRecord extends BasicsClass {
    public getList(form: ImmuneRecordForm, page: any, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.BasicGet('/animal/web/immuneRecord/listOld', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN,
            ...page
        }, false, true, cb)
    }

    public exportList(form: ImmuneRecordForm, cb: Function): void {
        const time = Util.handleStartTimeAndEndTime(form.time)
        this.getFile('/animal/web/immuneRecord/exportList', {
            ...form,
            groupCode: form.groupCode[form.groupCode.length - 1],
            startTime: time.startTime,
            endTime: time.endTime,
            time: NaN,
        })
    }


	public exportList2(form: ImmuneRecordForm, cb: Function): void {
	    const time = Util.handleStartTimeAndEndTime(form.time)
	    this.getFile('/animal/web/animalMark/exportList', {
	        ...form,
	        groupCode: form.groupCode[form.groupCode.length - 1],
	        startTime: time.startTime,
	        endTime: time.endTime,
	        time: NaN,
	    })
	}

    public exportList3(form: ImmuneRecordForm, cb: Function): void {
	    const time = Util.handleStartTimeAndEndTime(form.time)
	    this.getFile('/animal/web/orderManage/exportOrderList', {
	        ...form,
	        groupCode: form.groupCode[form.groupCode.length - 1],
	        startTime: time.startTime,
	        endTime: time.endTime,
	        time: NaN,
	    })
	}

    public getVaccine(cb: Function): void {
        this.BasicGet('/animal/web/immuneRecord/vaccineList', {}, false, true, cb)
    }
}