





















































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import RoleVue from "./components/Role.vue";
import SaveBtnVue from "./components/SaveBtnVue.vue";

import { appUser, appAddUser } from "./components/appAll";

import { FarmDetail } from "@/common/Views/system/farm/farmDetail/farmDetail";
import { groupCodeLazy } from "./components/groupCodeLazy";
import { DeepLoop } from "@/common/deepLoop/deepLoop";
import { AppUser } from "@/api/SystemModule/index";
@Component({
    name: "NewAppAddUser",
    components: { RoleVue, SaveBtnVue },
})
export default class NewAppAddUser extends Vue {
    private form: any = {
        roleId: "1",
        username: "",
        mobile: "",
        companyId: "",
        groupCode: [],
        farmIds: [],
        hasAllData: 0, //1代表全部  0 个别
    };
    private groupCodeLazy = new groupCodeLazy();
    private isBankTye = false; //判断当前是否是银行角色
    private centerDialogVisible = false; //显示银行选择
    private bankList = [];
    private checkBankListData: any[] = [];
    private CheckBankList: any[] = [];
    private defaultProps: any = {
        children: "childList",
        label: "bankName",
    };
    private getKeyList(data: any, checkData: any) {
        //315选择后将选择的id数组赋值给对象
        this.checkBankListData = checkData.checkedKeys;
        console.log(this.checkBankListData);
    }

    //权限角色
    private UserNameData: any[] = [];
    //设置权限角色Id
    private SetUserNameId(newVal: string) {
        this.checkBankListData = [];
        (window as any).roleId = newVal;
        this.form.roleId = newVal;
    }

    //姓名   v3.0.7新需求 不再需要校验

    //手机号
    private blurMobile() {
        //校验手机号
        // if(this.form.mobile.length > 10){
        //     new appAddUser().repeatNum(this.form.mobile , (data:any) => {})
        // }
    }

    //关联企业
    private linkEnterpriseText: string = this.$t("common.placeholder.select");
    private noneEnter: boolean = true;
    //关联企业渲染的数据数组
    private linkEnterpriseData: any[] = [];
    //关联企业发生变化的时候
    private enterpriseChange() {
        //请求组织机构
        this.getGroupCodeData();
        //清空组织机构
        this.RoleIdChangeClearOtherData();
    }

    //组织机构
    private GroupCodeData: any[] = [];
    private LinkGroupText: string = this.$t("system.web_user.farmIds");
    private linkGrounpPlaceHolderText: string = this.$t("system.web_user.wu");
    //组织机构隐藏 整个div
    private NoneGroupCodeDiv: boolean = true;
    //组织机构 清空数据用的 key
    private CascaderKey: number = 1;
    private linkGroupCodeHandleChange() {
        this.getFarmListData(); //请求养殖场数据
    }

    //选择养殖场
    private FarmDataList: any[] = [];
    private isMultiple: boolean = true; //默认是 true  开启多选  如果是银行保险  那么变为false
    private FarmNamePlaceHolder: string = this.$t("system.app_user.search");
    private changeFarmId(e: any) {
        if (this.isMultiple) {
            //判断是否是多选
            //判断已选的数组最后一个id 是否为 0 如果为 0  清除数组中其他的id
            if (e[e.length - 1] == "0") this.form.farmIds = ["0"];
            // 先判断 数组是否大于1  如果最后一个id  不为0  那么清除数组中 为0 的id
            if (e[1] && e[e.length - 1] != "0")
                if (e.includes("0"))
                    this.form.farmIds.splice(e.indexOf("0"), 1);
            // 判断 除了全部 全部都选了  那么那清除数组 让 数组 id = 0
        }
    }

    //请求银行数据
    private getBankListData(id: string, groupCode: string | number) {
        new AppUser().getBankList(id, groupCode, (res: any) => {
            //新增银行接口
            this.bankList = res.data;
        });
    }
    //请求权限角色接口
    private getUserNameData() {
        new appUser().getUserName(true,(data: any) => {
            this.UserNameData = data.data;
        });
    }
    //请求关联企业数据接口
    private getLinkEnterPriseData() {
        new appAddUser().getLinkEnterprise(this.form.roleId, (data: any) => {
            this.linkEnterpriseData = data.data;
        });
    }
    //请求养殖场组织机构
    private getGroupCodeData() {
        new FarmDetail().getCity("", this.form.roleId, (data: any) => {
            //赋值处理过的数据
            this.GroupCodeData = this.deleteLastChildren(data.data);
            //默认显示中国
            this.form.groupCode = [0];

            //请求养殖场数据
            this.getFarmListData();
        });
    }
    //请求养殖场列表数据
    private getFarmListData() {
        this.FarmDataList = [];
        this.form.farmIds = [];
        if (this.form.roleId == "13" || this.form.roleId == "30") {
            this.isMultiple = false; //变为单选
            // new appUser().getBankData(
            //     {
            //         id: this.form.companyId,
            //         groupCode:
            //             this.form.groupCode[this.form.groupCode.length - 1],
            //     },
            //     (res: any) => {
            //         this.ReSetListData(res.data); //处理数据
            //     }
            // );
            this.getBankListData(
                "",
                this.form.groupCode[this.form.groupCode.length - 1]
            );
        } else if (this.form.roleId == "14" || this.form.roleId == "40") {
            this.isMultiple = false; //变为单选
            new appUser().getInsuranceData(
                {
                    id: this.form.companyId,
                    groupCode: this.form.groupCode[
                        this.form.groupCode.length - 1
                    ],
                },
                (res: any) => {
                    this.ReSetListData(res.data); //处理数据
                }
            );
        } else {
            this.isMultiple = true; //变为多选
            new appUser().getLinkFarm(
                {
                    id: this.form.companyId,
                    groupCode: this.form.groupCode[
                        this.form.groupCode.length - 1
                    ],
                },
                this.form.roleId,
                (data: any) => {
                    const deepCopy: DeepLoop = new DeepLoop();
                    const fatherData = deepCopy.deepCopy(data.data);
                    if (data.data.length > 0) {
                        fatherData.unshift({ farmId: "0", farmName: this.$t('common.placeholder.all') });
                        this.FarmDataList = fatherData;
                        this.form.farmIds = ["0"];
                    }
                }
            );
        }
    }
    //规则对象
    private rules: any = {
        username: [
            {
                required: true,
                message: this.$t("system.app_user.enter_name"),
                trigger: ["blur", "change", "input"],
            },
        ],
        mobile: [
            {
                required: true,
                message: this.$t("system.app_user.enter_mobile"),
                trigger: ["blur", "change", "input"],
            },
            // { min:11, max: 11, message: '请输入11位手机号码', trigger: 'blur' }
        ],
        companyId: [
            { required: true, message: this.$t("system.app_user.Affiliated_enterprise"), trigger: ["blur"] },
        ],
        farmIds: [
            { required: false, message: this.$t("system.app_user.select_farm"), trigger: ["blur"] },
        ], //初始 不校验 因为权限角色初始值为养殖企业管理员
    };
    //组织机构Props
    private props: any = {
        checkStrictly: true,
        expandTrigger: "hover",
        hoverThreshold: undefined,
        label: "name",
        value: "id",
    };

    //监视
    @Watch("form.roleId")
    private changeRoleId(newVal: string) {
        if (newVal == "13" || newVal == "30") {
            this.isBankTye = true;
        } else {
            this.isBankTye = false;
        }
        if (newVal == "3" || newVal == "1" || newVal == "60") {
            //如果是屠宰场管理员、养殖企业管理员、合作商管理员 那么  检验更改为 false 其他 true
            this.NoRequired(false);
        } else {
            this.NoRequired(true);
        }
        this.RoleIdChangeGroupCode(newVal); //处理 不同权限角色的函数
        this.RoleIdChangeClearOtherData(); // 每次切换的时候清楚部分数据
        this.noneEnter ? this.getLinkEnterPriseData() : ""; //每次切换的时候重新请求关联企业数据
        //每次切换的时候 清空 绑定的值 和 清空渲染的数组
        this.form.companyId = "";
        this.linkEnterpriseData = [];
        //内部演示角色
        newVal == "9"
            ? (this.linkEnterpriseText = this.$t("system.app_user.Demo_account") as string)
            : (this.linkEnterpriseText = this.$t("common.placeholder.select") as string);
        (this.$refs.formRules as any).resetFields();
    }
    @Watch("form.farmIds")
    private changeFarmIds(newVal: any) {
        //判断 在 已选的数组中 能否找到 id 为 0  如果找到了  那么 this.form.hasAllData = 1 并且数组
        if (Array.isArray(newVal)) {
            if (newVal.includes("0")) {
                this.form.hasAllData = 1;
            } else {
                this.form.hasAllData = 0;
            }
        }
    }

    //处理组织机构树
    private deleteLastChildren(data: any) {
        let treeData: any[] = data;
        treeData.forEach((itemOne: any) => {
            itemOne.children.forEach((itemTwo: any) => {
                itemTwo.children.forEach((itemThree: any) => {
                    itemThree.children.forEach((itemFour: any) => {
                        itemFour.children.forEach((itemFive: any) => {
                            delete itemFive.children;
                        });
                    });
                });
            });
        });
        return treeData;
    }
    //处理养殖场  银行 保险数据
    private ReSetListData(data: any) {
        let newArray: any[] = [];
        data.map((item: any) => {
            let obj = {};
            this.form.roleId == 13 || this.form.roleId == 30
                ? (obj = { farmName: item.bankName, farmId: item.bankId })
                : (obj = {
                      farmName: item.insuranceCompanyName,
                      farmId: item.insuranceCompanyId,
                  });
            newArray.push(obj);
        });
        this.FarmDataList = newArray;
        this.FarmDataList[this.FarmDataList.length - 1]
            ? (this.form.farmIds = this.FarmDataList[0].farmId)
            : (this.form.farmIds = []);
    }
    //处理权限角色切换时的校验信息
    private RestCheckMsg(msg: string) {
        let RulerVc: any = this.$refs.formRules;
        RulerVc.rules.farmIds[0].message = msg;
    }
    //处理屠宰场 养殖场可以不填的 校验
    private NoRequired(flag: boolean) {
        let RulerVc: any = this.$refs.formRules;
        RulerVc.rules.farmIds[0].required = flag;
    }

    //权限角色变化时  组织机构
    private RoleIdChangeGroupCode(newVal: string) {
        //银行、保险、政府、伊禾牧业  隐藏关联企业
        //买家 隐藏组织结构
        switch (newVal) {
            //银行
            case "13":
                this.noneEnter = false; //隐藏关联企业
                this.LinkGroupText = this.$t("system.app_user.bankIds") as string;
                this.linkGrounpPlaceHolderText = this.$t("system.app_user.Bank_organization") as string;
                this.FarmNamePlaceHolder = this.$t("system.app_user.select_bank") as string;
                this.getGroupCodeData();
                this.RestCheckMsg(this.$t("system.app_user.select_bank") as string); //权限角色变化时处理 校验信息
                break;
            //银行
            case "30":
                this.noneEnter = false; //隐藏关联企业
                this.LinkGroupText = this.$t("system.app_user.bankIds") as string;
                this.linkGrounpPlaceHolderText = this.$t("system.app_user.Bank_organization") as string;
                this.FarmNamePlaceHolder = this.$t("system.app_user.select_bank") as string;
                this.getGroupCodeData();
                this.RestCheckMsg(this.$t("system.app_user.select_bank") as string); //权限角色变化时处理 校验信息
                break;
            //保险
            case "14":
                this.noneEnter = false; //隐藏关联企业
                this.LinkGroupText = this.$t("system.web_user.insuranceCompanyId") as string;
                this.linkGrounpPlaceHolderText = this.$t("system.app_user.Bank_organization") as string;
                this.FarmNamePlaceHolder = this.$t("system.app_user.select_insurance") as string;
                this.RestCheckMsg(this.$t("system.app_user.select_insurance") as string); //权限角色变化时处理 校验信息
                this.getGroupCodeData();
                break;
            //保险
            case "40":
                this.noneEnter = false; //隐藏关联企业
                this.LinkGroupText = this.$t("system.web_user.insuranceCompanyId") as string;
                this.linkGrounpPlaceHolderText = this.$t("system.app_user.Bank_organization") as string;
                this.FarmNamePlaceHolder = this.$t("system.app_user.select_insurance") as string;
                this.RestCheckMsg(this.$t("system.app_user.select_insurance") as string); //权限角色变化时处理 校验信息
                this.getGroupCodeData();
                break;
            //屠宰
            case "3":
                this.noneEnter = true; //显示关联企业
                this.LinkGroupText = this.$t("system.app_user.Affiliated_slaughterhouse") as string;
                this.linkGrounpPlaceHolderText = this.$t("system.app_user.Slaughterhouse_organization");
                this.FarmNamePlaceHolder =  this.$t("system.app_user.select_slaughterhouse") as string;
                this.RestCheckMsg(this.$t("system.app_user.select_slaughterhouse") as string);
                break;
            //屠宰
            case "4":
                this.noneEnter = true; //显示关联企业
                this.LinkGroupText = this.$t("system.app_user.Affiliated_slaughterhouse") as string;
                this.linkGrounpPlaceHolderText = this.$t("system.app_user.Slaughterhouse_organization") as string;
                this.FarmNamePlaceHolder = this.$t("system.app_user.select_slaughterhouse") as string;
                this.RestCheckMsg(this.$t("system.app_user.select_slaughterhouse") as string);
                break;
            //买家
            case "10":
                this.noneEnter = true; //显示关联企业
                this.NoneGroupCodeDiv = false; // 隐藏组织结构
                break;
            //政府
            case "15":
                this.noneEnter = false; //隐藏关联企业
                this.linkGrounpPlaceHolderText = this.$t("system.web_user.wu") as string;
                this.NoneGroupCodeDiv = true; //显示组织结构
                this.getGroupCodeData();
                this.FarmNamePlaceHolder = this.$t("system.app_user.search") as string;
                break;
            //推广员
            case "70":
                this.noneEnter = false; //隐藏关联企业
                this.linkGrounpPlaceHolderText = this.$t("system.web_user.wu") as string;
                this.NoneGroupCodeDiv = true; //显示组织结构
                this.getGroupCodeData();
                this.FarmNamePlaceHolder =this.$t("system.app_user.search") as string;
                break;
            //伊禾牧业
            case "16":
                this.noneEnter = false; //隐藏关联企业
                this.linkGrounpPlaceHolderText = this.$t("system.web_user.wu") as string;
                this.NoneGroupCodeDiv = true; //显示组织结构
                this.FarmNamePlaceHolder =this.$t("system.app_user.search") as string;
                this.getGroupCodeData();
                this.RestCheckMsg(this.$t("system.app_user.select_farm") as string);
                break;
            default:
                this.noneEnter = true; //其他情况显示关联企业
                this.NoneGroupCodeDiv = true; //显示组织结构
                this.linkGrounpPlaceHolderText = this.$t("system.web_user.wu") as string;
                this.FarmNamePlaceHolder = this.$t("system.app_user.search") as string;
                this.LinkGroupText = this.$t("system.app_user.Associated_farm") as string;
                this.RestCheckMsg(this.$t("system.app_user.select_farm") as string);
        }
    }

    //权限角色变化时 清空数据
    private RoleIdChangeClearOtherData() {
        this.CascaderKey++; //让组织机构的key变化
        this.form.groupCode = []; //每次切换的时候 清空组织机构 已选择的数组
        this.GroupCodeData = []; //每次切换的时候 清空组织机构 并清空渲染的数组
        this.FarmDataList = []; //每次切换清空养殖场 屠宰场 银行 保险 list
        this.form.farmIds = ""; //每次切换清空养殖场 屠宰场 银行 保险 id
        this.form.farmIds = []; //在切换权限角色的时候 清空养殖场 保险 银行已选择数据
    }

    //保存成功之后清空全部数据
    private ClearForm() {
        this.form = {
            roleId: "1",
            username: "",
            mobile: "",
            companyId: "",
            groupCode: [],
            farmIds: [],
            hasAllData: 0, //1代表全部  0 个别
        };
    }

    activated() {
        (window as any).roleId = "1";
        this.getUserNameData();
        this.getLinkEnterPriseData();
        this.getBankListData("", "");
    }
}
