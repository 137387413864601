































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Util} from "@/common/Views/comm/util";
import { ConfType } from '@/common/Views/system/confType/confType';
import { t } from 'vxe-table';
import { BasicsClass } from '@/common/BasicsClass';

@Component
export default class HelloWorld extends Vue {
    public basics: BasicsClass = new BasicsClass()

    public list: any[] = [
        {
            type: '温度（℃）',
            minNumber: '',
            maxNumber: '',
            showInput: false
        },

        {
            type: '湿度（%）',
            minNumber: '',
            maxNumber: '',
            showInput: false
        },
    ]

    public lastMin = ''
    public lastMax = ''

    public activated(): void {
        this.basics.BasicGet(
            'animal/web/sysSet/getEnvControlSets',
            {},
            false,
            true,
            (res: any) => {
                console.log(res)
                let obj = res.data
                for(var i in obj){
                    if(obj[i].key == 'ENV_CONTROL_TEMPERATURE_MAX'){
                        this.list[0].maxNumber = obj[i].value
                    }else if(obj[i].key == 'ENV_CONTROL_TEMPERATURE_MIN'){
                        this.list[0].minNumber = obj[i].value
                    }
                    else if(obj[i].key == 'ENV_CONTROL_HUMIDITY_MAX'){
                        this.list[1].maxNumber = obj[i].value
                    }
                    else if(obj[i].key == 'ENV_CONTROL_HUMIDITY_MIN'){
                        this.list[1].minNumber = obj[i].value
                    }
                }
            }
        )
    }

    public compile(nub: number): void {
        // console.log(nub)
        this.lastMin = this.list[nub].minNumber
        this.lastMax = this.list[nub].maxNumber
        this.list[nub].showInput = true
    }

    public cacel(nub: number): void {
        this.list[nub].minNumber = this.lastMin
        this.list[nub].maxNumber = this.lastMax
        this.lastMin = ''
        this.lastMax = ''
        this.list[nub].showInput = false
    }

    public save(nub: number): void {
        const vue = new Vue()
        let datas = ''

        if(!this.list[nub].minNumber){
            vue.$message.warning('请输入最小值')
            return
        }

        if(!this.list[nub].maxNumber){
            vue.$message.warning('请输入最大值')
            return
        }

        if(nub == 0){
            datas = 'ENV_CONTROL_TEMPERATURE_MIN|' + this.list[nub].minNumber + ',ENV_CONTROL_TEMPERATURE_MAX|' + this.list[nub].maxNumber
        }else if(nub == 1){
            datas = 'ENV_CONTROL_HUMIDITY_MIN|' + this.list[nub].minNumber + ',ENV_CONTROL_HUMIDITY_MAX|' + this.list[nub].maxNumber
        }


        if(this.list[nub].maxNumber > this.list[nub].minNumber){
            
            this.basics.BasicPost(
                'animal/web/sysSet/updateEnvControlSets',
                {datas},
                true,
                false,
                false,
                (res: any) => {
                    this.lastMin = ''
                    this.lastMax = ''
                    this.list[nub].showInput = false
                }
            )
        }else{
            vue.$message.warning('请正确输入')
        }
        
        
    }


}
