










































































































import { Component, Vue } from "vue-property-decorator";
import PageBox from "@/components/PageBox/PageBox.vue";
import { FarmData } from "@/views/components/FarmGetData";
import SelectComponentsVue from "@/components/BasicConponents/SelectComponentsVue.vue";
import { CattleEarlyWarningConfigurationApi } from "@/api/ConfigModule/index";
import InputConponentsVue from "@/components/BasicConponents/InputConponentsVue.vue";
@Component({
    components: { PageBox, SelectComponentsVue, InputConponentsVue },
})
export default class cattleEarlyWarningConfigurationEdit extends Vue {
    private form: { [key: string]: string } = {
        breedId: "",
        firstBreedDays: "",
        afterBirthBreedDays: "",
        pregnancyWarningDays: "",
        birthWarningDays: "",
        id: "",
    };
    private rulus: any = {
        breedId: [{ required: true, message: "  " }],
        firstBreedDays: [{ required: true, message: "  " }],
        afterBirthBreedDays: [{ required: true, message: "  " }],
        pregnancyWarningDays: [{ required: true, message: "  " }],
        birthWarningDays: [{ required: true, message: "  " }],
    };
    private breedList: Array<any> = [];
    //emit品种选择
    private EmitBreedId(breedId: string) {
        console.log(breedId, "breedId");
        
        this.form.breedId = breedId;
    }
    //emit 发情
    private EmitHeat(val: string) {
        this.form.firstBreedDays = val;
    }
    //emit 配种
    private EmitHybridization(val: string) {
        this.form.afterBirthBreedDays = val;
    }
    //emit 孕检
    private EmitpregnancyTest(val: string) {
        this.form.pregnancyWarningDays = val;
    }
    //emit 分娩
    private EmitChildbirth(val: string) {
        this.form.birthWarningDays = val;
    }
    //品种
    private getAllBreed(farmId: any) {
        this.breedList = [];
        new FarmData().getAllBreedAdd(farmId, (data: any) => {
            this.breedList = data.data;
        });
    }
    //event 保存数据
    private saveData() {
        if (this.form.breedId === "") {
            this.$message({
                message: this.$t("config.beef_config.warningOne") as string,
                type: "warning",
            });
            return;
        } else if (this.form.firstBreedDays === "") {
            this.$message({
                message: this.$t("config.beef_config.warningTwo") as string,
                type: "warning",
            });
            return;
        } else if (this.form.afterBirthBreedDays === "") {
            this.$message({
                message: this.$t("config.beef_config.warningThree") as string,
                type: "warning",
            });
            return;
        } else if (this.form.pregnancyWarningDays === "") {
            this.$message({
                message: this.$t("config.beef_config.warningFour") as string,
                type: "warning",
            });
            return;
        } else if (this.form.birthWarningDays === "") {
            this.$message({
                message: this.$t("config.beef_config.warningFive") as string,
                type: "warning",
            });
            return;
        }
        (this.$refs.form as any).validate((flag: boolean) => {
            new CattleEarlyWarningConfigurationApi().editCattleEarlyWarning(
                this.form,
                () => {
                    this.$router.push({ path: "/beefconfig" });
                }
            );
        });
    }
    private breedName = "";
    activated() {
        console.log(this.$route.query, "this.$route.query");
        
        this.form.afterBirthBreedDays = this.$route.query.afterBirthBreedDays;
        this.form.birthWarningDays = this.$route.query.birthWarningDays;
        this.form.breedId = this.$route.query.breedId;
        this.form.firstBreedDays = this.$route.query.firstBreedDays;
        this.form.pregnancyWarningDays = this.$route.query.pregnancyWarningDays;
        this.form.id = this.$route.query.id;
        this.breedName = this.$route.query.breedName;

        this.getAllBreed("");
    }
}
