




































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Result, Column} from "@/common/Views/system/column/column";
import {DeepLoop} from "@/common/deepLoop/deepLoop";
import {optionSelect, Rule} from "@/common/Views/comm/formRule";
import {ConfType} from "@/common/Views/system/confType/confType";
import {SchoolList} from "@/common/Views/basics/schoolManage/schoolManage";
import {AxiosData} from "@/common/http/http";
import {
    FarmDetailForm,
    FarmDetail,
    FarmDetailDetail,
    CityList, Url
} from "@/common/Views/system/farm/farmDetail/farmDetail";
import {Util} from "@/common/Views/comm/util";
import TitleSolt from '@/views/traceabilityManagement/traceabilityOutManage/traceabilityOutManageDetail/titleSolt.vue';
import {Firm} from "@/common/Views/system/firm/firm";

@Component({
    components: {TitleSolt}
})
export default class HelloWorld extends Vue {

    public column = new Column();

    private firm: Firm = new Firm()

    public dialogVisible = false;
    public ImgList: { name: string, url: string }[] = [];

    private formLabelAlign: any = {
        groupCode: [],
        mobile: "",
        userName: "",
        status: 1,
        enterpriseName: "",
        simpleAddress: "",
        detailAddress: "",
        auditStatus: '1',
        id: ""
    };

    private auditNow: any = NaN;
    private showMark1: boolean =  false;
    private showMark2: boolean =  false;
    private url: string =  '';

    public props: any = {
        expandTrigger: 'hover',
        value: 'name',
        label: 'name',
        lazy: true,
        lazyLoad(node: any, resolve: any) {
            console.log(node)
            // console.log(resolve(123))
            const column: Column = new Column();
            if (!node.data) {
                return
            }
            column.getTissuc2(node.data.id,1, (res: any) => {
                // if(res.data[0].level == 4){
                //     console.log(1)
                //     for(var i in res.data){
                //     }
                // }else{
                //     resolve(res.data)
                // }
                // console.log(res.data)
                // resolve(res.data)
                const nodes = res.data
                // if (node.level === 4) {
                nodes.forEach((item: any) => {
                    item.leaf = (item.level === 4)
                })    
                // }
                resolve(nodes)
            })
        }
    }

    // private treeData: Result[] = [];

    /*private getTree(cb?: Function): void {
        if (!this.$getResourceCodes("menu_tree")) {
            return;
        }
        const column: Column = new Column();
        column.getTreeData().then((result: Result[]) => {

            // this.treeData = new DeepLoop().deepCopy(result);

            if(cb) cb();
        }).catch(err => {
            this.$message.error(err.message);
        });
    }*/

    private statusOptions: optionSelect<number>[] = [
        {
            value: 1,
            label: "启用",
        },
        {
            value: 2,
            label: "禁用",
        },

    ];


    private activated(): void {
        // this.getTree();

        this.confType();
        this.column.getTissuc(0, (res: any) => {
            this.treeData = res.data
        })
    }

    private treeData: Result[] = [];


    private conTypeList: SchoolList<string>[] = [];

    private confType(): void {
        new ConfType().getList((data: AxiosData<SchoolList<string>[]>) => {
            this.conTypeList = data.data;
        });
    }

    private showDio(url: string) {
        this.dialogVisible = true;
        this.url = url;
    }

    private del(url:  keyof FarmDetailForm) {
        (<string>this.formLabelAlign[url]) = '';
        this.showMark1 = false;
        this.showMark2 = false;
    }

    private rules: FarmDetailForm<Rule[]> = {
        mobile: [
            {required: true, message: "请填写联系电话"},
            // {
            //     message: "手机号码输入错误", validator: (rule: any, value: any, callback: any) => {
            //         if (!/^[0-9]{11}$/g.test(value)) {
            //             callback(new Error());
            //         } else {
            //             callback();
            //         }
            //     }
            // },
        ],
        userName: [
            {required: true, message: "请填写联系人"}
        ],
        status: [
            {required: true, message: "请选择启用状态"}
        ],
        enterpriseName: [
            {required: true, message: "请填写养殖场名称"}
        ],
        simpleAddress: [
            {required: true, message: "请选择城市"}
        ],
        detailAddress: [
            {required: true, message: "请填写详细地址"}
        ],
        auditStatus: [
            {required: true, message: "请选择审核状态"}
        ]
    };

    private farmDetail: FarmDetail;

    constructor() {
        super();
        this.farmDetail = new FarmDetail();
    }

    private save(): void {
        (this.$refs.form as any).validate((valid: boolean) => {
            if (valid) {
                // let id = "";
                // if (this.$route.query.farmAudit) {
                //     id = <string> this.$route.query.farmAudit;
                // }
                this.firm.audit1(this.formLabelAlign, () => {
                    this.$router.go(-1);
                });
                // console.log(this.formLabelAlign)
            }
        });

    }

    private getDetail(): void {
        this.formLabelAlign = new Util().clearObject(this.formLabelAlign);

        this.formLabelAlign.auditStatus = '1';
        // this.formLabelAlign.licenseUrl = {code: "license", base64ImgData: "", url: ""};
        // this.formLabelAlign.doorUrl = {code: "doorPicture", base64ImgData: "", url: ""};
        this.formLabelAlign.status = 1;
        if(this.$route.query.item){
            
            let item = JSON.parse(this.$route.query.item)
            this.formLabelAlign.enterpriseName = item.name
            this.formLabelAlign.userName = item.username
            this.formLabelAlign.mobile = item.mobile
            this.formLabelAlign.detailAddress = item.detailAddress
            this.formLabelAlign.simpleAddress = item.simpleAddress.split('/').join('-')
            this.formLabelAlign.id = item.id
            // console.log(item.simpleAddress)
        }
    }


    private cityList0 = [];
    private cityList1 = [];
    private cityList2 = [];
    private cityList3 = [];
    private city: any = {
        province: "",
        theCity: "",
        area: "",
        street: "",
    };
    private dataImg: boolean = false;

    private audits: any[] = [
        {id: '1', name: "审核通过"},
        {id: '2', name: "审核拒绝"}
    ];


    public resGroup: Result[] = [];


    // private city: any = []

    // private getCity(pid: any, flag: boolean = true): void {
    //     if (!pid) {
    //         pid = 0;
    //     }
    //     new FarmDetail().getCity(<any> pid, (data: CityList) => {
    //         if (pid == 0) {
    //             this.cityList0 = data.data;
    //         } else if (pid < 100 && pid > 10) {
    //             this.cityList1 = data.data;
    //             if (flag) {
    //                 this.city.street = "";
    //                 this.city.theCity = "";
    //                 this.city.area = "";
    //             }
    //         } else if (pid > 1000 && pid < 10000) {
    //             this.cityList2 = data.data;
    //             if (flag) {
    //                 this.city.street = "";
    //                 this.city.area = "";
    //             }
    //         } else if (pid > 10000 && pid < 1000000) {
    //             this.cityList3 = data.data;
    //             if (flag) {

    //                 this.city.street = "";
    //             }
    //         }
    //         // this.formLabelAlign.simpleAddress = this.$refs.province.selectedLabel + '-' + this.$refs.theCity.selectedLabel + '-' + this.$refs.area.selectedLabel
    //         this.formLabelAlign.simpleAddress = (<any>this.$refs.street).value;
    //     });
    // }

    private handleAudit(str: string): void {
        this.formLabelAlign.auditStatus = str
        // console.log(a)
    }


    private setImg(file: any, fileList: any): void {
        this.readImg(file, fileList, "licenseUrl");
    }

    private setImg1(file: any, fileList: any): void {
        this.readImg(file, fileList, "doorUrl");
    }

    private readImg(file: any, fileList: any, url: keyof FarmDetailForm) {
        const reader = new FileReader();
        let res = reader.readAsDataURL(file.raw);
        reader.onloadend = () => {
            this.dataImg = false;
            // var strBase64 = reader.result.substring(84);
            const strBase64 = (<string>reader.result).substring(0);
            // this.formLabelAlign.licensePicture = {code: "license", base64ImgData: strBase64, url: ""};
            (<string>this.formLabelAlign[url]) = strBase64;
            console.log(this.formLabelAlign);
        };
        reader.onload = function(e) {
            console.log(e);
            // simgSrc = this.result;   // 注意:这里的this.result中,这个this不是vue实例,而是reader对象,所以之前用that接收vue示例  that.imgSrc
        };

    }

    public handleCascader(start: any): void {
        console.log(start)
        if((start == false) && (this.formLabelAlign.groupCode.length != 0)){
            this.formLabelAlign.simpleAddress = this.formLabelAlign.groupCode.join('-')
            
        }
    }

    private created() {
        this.getDetail();
        // this.getCity(0);
        this.formLabelAlign.auditStatus = '1';
        this.auditNow = "审核通过";
    }


    private handleRemove(file: any): void {
        /*console.log(file);
        this.formLabelAlign.licenseUrl.base64ImgData = '';
        this.formLabelAlign.licenseUrl.url = '';
        this.ImgList = [];*/
    };

    private handlePictureCardPreview(file: any): void {
        this.formLabelAlign.licenseUrl = file.url;
        this.dialogVisible = true;
    }


    @Watch("$route")
    public handleRoute(): void {
        if(this.$route.name == 'firmAudit'){
            this.formLabelAlign.auditStatus == '1'
        }
        this.getDetail();
        this.ImgList = [];
        this.city = {
            province: "",
            theCity: "",
            area: "",
            street: "",
        };
    }
}
