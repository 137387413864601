import { render, staticRenderFns } from "./upPackageManage.vue?vue&type=template&id=7ed10650&scoped=true&"
import script from "./upPackageManage.vue?vue&type=script&lang=ts&"
export * from "./upPackageManage.vue?vue&type=script&lang=ts&"
import style0 from "./upPackageManage.vue?vue&type=style&index=0&id=7ed10650&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ed10650",
  null
  
)

export default component.exports