import {BasicsClass} from '@/common/BasicsClass';
import {HandleTable} from '@/common/Views/comm/handleTable';
import {Util} from '@/common/Views/comm/util';
import {CountExceptionFrom} from '@/common/Views/basics/countException/countException';

export class TemperatureInfo extends BasicsClass {
    public getTable(form: CountExceptionFrom, tablePage: HandleTable, cb: Function) {
        const time = Util.handleStartTimeAndEndTime(form.time);
        this.BasicGet('/animal/web/temperatureRecord/list1', {
            ...form,
            ...tablePage,
            groupCode: form.groupCode[form.groupCode.length - 1],
            ...time,
        }, false, true, cb, cb);
    }

    public getLine(markId: string, time: Date[], cb: Function) {
        if(time){
            const data = Util.handleStartTimeAndEndTime(time);
            console.log(data.startTime.split(" ")[0])
            this.BasicGet('/animal/web/temperatureHistory/queryHistory3', {
                markId,
                stDate: data.startTime,
                edDate: data.endTime
            }, false, true, cb)
        }else{
            
        }
    }

    public getLine2(markId: string, flag: number, time: Date[], cb: Function) {
        const data = Util.handleStartTimeAndEndTime(time);
        // console.log(data.startTime.split(" ")[0])
        let checkTime = {
            startTime: '',
            endTime: ''
        }
        if(flag == 3){
            checkTime.startTime = data.startTime
            checkTime.endTime = data.endTime
        }
        this.BasicGet('/animal/web/healthScore/comboList', {
            markId,
            ...checkTime,
            flag
        }, false, true, cb)
    }
}
